import {atomFamily, selectorFamily, selector} from "recoil";
import {
    preProcessedFilteredSearchDataSelector
} from "../../../../../../../dataProvider/vissights/search/filters/filters";
import FACET from "../../../../../../../dataProvider/vissights/utility/facet-extraction-utility";
import {topicsAtom} from "../../../../../../../dataProvider/vissights/overview/overview";
import {recursiveDeepClone} from "../../../../../../../utility";

export const chartDataTreeMapChartSelectorFamily = selectorFamily({
    key: 'chartDataTreeMapChartSelectorFamily-Key',
    get: id => async ({get}) => {
        const dataModel = await get(preProcessedFilteredSearchDataSelector);
        const topicData = get(topicsAtom);
        let returnData = dataModel[FACET.TOPIC];
        const newReturnData = recursiveDeepClone(returnData);

        newReturnData.map((d) => {
            let topicInfosForPin =  topicData[d.id];
            topicInfosForPin = [...topicInfosForPin.word, ...topicInfosForPin.phrase]
            let newTopicData = [];
            topicInfosForPin.forEach((d,i) => {
                newTopicData.push({name: d[0], amount:  (100 * d[1]).toFixed(1), value: d[1], id: i, nestedTopicData: true})
            })
             d.children = newTopicData
        })
        return newReturnData;
    },
});


export const treeMapChartDataAtomFamily = atomFamily({
    key: "treeMapChartDataAtomFamily-Key",
    default: selectorFamily({
        key: 'treeMapChartDataAtomFamily/Default',
        get: id =>  ({get}) => {
            return chartDataTreeMapChartSelectorFamily(id);
        },
    }),
});



export const treeMapChartDataActiveAtomFamily = atomFamily({
    key: "treeMapChartDataActiveAtomFamily-Key",
    default: selectorFamily({
        key: 'treeMapChartDataActiveSeceltorFamily/Default',
        get: id =>  ({get}) => {
            return treeMapChartDataActiveSelectorFamily(id);
        },
    }),
});





export const maxAmountSlicesTreeMapChartDataAtomFamily = atomFamily({
    key: "maxAmountSlicesTreeMapChartDataAtomFamily-Key",
    default: 10
});




/*
export const treeMapChartDataAtomFamily = atomFamily({
    key: "treeMapChartDataAtomFamily-Key",
    default: selectorFamily({
        key: 'treeMapChartDataAtomFamily/Default',
        get: id => () => {
            return treeMapChartDataSelectorFamily(id);
        },
    }),
});
*/

/*
export const treeMapChartDataSelectorFamily = selectorFamily({
    key: 'treeMapChartDataSelectorFamily-Key',
    get: id => ({get}) => {
        const rawData = get(filteredDataSelector);
        const data = recursiveDeepClone(rawData);
        data.map((d, i) => {
            d.id = i;
            return d;
        });
        return data;
    }
});
*/

export const treeMapChartRightControlDataAtomFamily = atomFamily({
    key: "treeMapChartRightControlDataAtomFamily-Key",
    default: selectorFamily({
        key: 'treeMapChartRightControlDataAtomFamily/Default',
        get: id => () => {
            return treeMapChartRightControlDataSelectorFamily(id);
        },
    }),
});


export const treeMapChartRightControlDataSelectorFamily = selectorFamily({
    key: 'treeMapChartRightControlDataSelectorFamily-Key',
    get: id => ({get}) => {
        const rawData = get(treeMapChartDataAtomFamily(id));
        const data = recursiveDeepClone(rawData);
        // activate first 50 items.
        data.map((d, i) => {
            if (i < 15) {
                d['active'] = true;
            }
            return d;
        });
        return data;
    }
});



export const treeMapChartDataActiveSelectorFamily = selectorFamily({
    key: 'treeMapChartDataActiveSelectorFamily-Key',
    get: id => ({get}) => {
        // const topics = get(topicsAtom);
        const rightControlItems = get(treeMapChartRightControlDataAtomFamily(id));
        const rightControlItemsActive = rightControlItems.filter((d) => d.active);
        let rightControlItemsActiveIds = [];

        rightControlItemsActive.map((d) => {
            rightControlItemsActiveIds.push(d.id)
        })

        const chartData = get(treeMapChartDataAtomFamily(id));

        let clone = recursiveDeepClone(chartData);

        clone = clone.filter((d) => rightControlItemsActiveIds.includes(d.id))

        // refine topics by their names from ids.
       // clone.map((d) => {
        //    d.topics = [FACET.getTopicNameById(topics, Object.keys(d.topics)[0])]
       // })
        return clone;
    }
});



















/*
export const temporalOverviewChartDataPrevAtomFamily = atomFamily({
    key: "temporalOverviewChartDataPrevAtomFamily-Key",
    default: selectorFamily({
        key: 'temporalOverviewChartDataPrevAtomFamily/Default',
        get: id =>  ({get}) => {
            return chartDataTemporalOverviewSelectorFamily('Years');
        },
    }),
});
*/



