import React, {useEffect, useRef, useState} from "react";
import * as d3 from 'd3';
import {
    activeFillColorForBars,
    activeStrokeColorForBars,
    calculateStandardFillColorForBarsBySignalStrength,
    pathStrokeColor,
    standardStrokeColorForBars
} from "../../../../../../../../styles/colors";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {
    visualizationPinAtomFamily,
    visualizationScaleAtomFamily,
    visualizationTranslationXAtomFamily
} from "../../../../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import {
    alwaysDisplayNumbersAtomFamily, bandWidthLOESSRegressionAtomFamily,
    displayColorsAtomFamily,
    displayLinearRegressionAtomFamily,
    displayLinesAtomFamily, displayLOESSRegressionAtomFamily,
    displayNeighborsAtomFamily
} from "../temporal-overview-chart-state";
import {regressionLinear, regressionLoess} from 'd3-regression';
import FACET from "../../../../../../../../dataProvider/vissights/utility/facet-extraction-utility";

function TemporalOverviewChart(props) {
    const componentId = props.id;
    const ref = useRef()
    const containerWidth = props.width;
    const containerHeight = props.height;
    const toggleFacetFilter = props.toggleFacetFilter;
    // const data = props.data;
    const data = props.data;
    //console.log(data);
    const prevData = props.prevData;
    let minYear = 0;
    let maxYear = 0;
    let zoom;
    const [scale, setNewScale] = useRecoilState(visualizationScaleAtomFamily(componentId));
    const resetScale = useResetRecoilState(visualizationScaleAtomFamily(componentId));
    const [translationX, setTranslationX] = useRecoilState(visualizationTranslationXAtomFamily(componentId));
    const resetTranslationX = useResetRecoilState(visualizationTranslationXAtomFamily(componentId));
    const alwaysDisplayNumbers = useRecoilValue(alwaysDisplayNumbersAtomFamily(componentId));
    const displayColors = useRecoilValue(displayColorsAtomFamily(componentId));
    const displayNeighbors = useRecoilValue(displayNeighborsAtomFamily(componentId));
    const displayLines = useRecoilValue(displayLinesAtomFamily(componentId));
    const displayLinearRegression = useRecoilValue(displayLinearRegressionAtomFamily(componentId));
    const displayLOESSRegression = useRecoilValue(displayLOESSRegressionAtomFamily(componentId));
    const bandWidthLOESSRegression = useRecoilValue(bandWidthLOESSRegressionAtomFamily(componentId));
    const [pinId, setPinId] = useRecoilState(visualizationPinAtomFamily(componentId));
    // const [displayContextMenu, setDisplayContextMenu] = useRecoilState(displayContextMenuAtom)
    // const [contextMenuData, setContextMenuData] = useRecoilState(contextMenuDataAtom);
    const toggleContextMenu = props.toggleContextMenu;
    // let [prevData, setPrevData] = useState([]);
    //const prevData = useRef();

    // we have to redraw completely on pin events because otherwise it would not working for resetting pins.
    useEffect(() => {
        d3.select(ref.current)
            .select('g')
            .select('.bars')
            .selectAll('g')
            .remove();
        updateChart();
        updateChart();
    }, [pinId, setPinId]);


    useEffect(() => {
        updateChart();
    })


    // reset scale and translation and update chart
    useEffect(() => {
        if (scale !== 1 || translationX !== 0) {
            /*
            zoom = d3.zoom().scaleExtent([1, 5])
                .translateExtent([[0, 0], [containerWidth, 0]])
                .extent([[0, 0], [containerWidth, containerHeight]])
                .on('zoom',
                    () => {
                        handleZoom(d3.event.transform);
                    }
                )
            bindZoom(zoom);

            const transform = d3.zoomIdentity
                .translate(0, 0)
                .scale(1);
            d3.select(ref.current).select('g')
                // .transition()
                // .duration(500)
                .call(zoom.transform, transform);

            d3.select(ref.current)
                .select('g')
                .attr('transform', `translate(${0},0)`);
*/
            resetTranslationX();
            resetScale();
            updateChart();
            updateChart();
        }
    }, [containerWidth, containerHeight, props.data])

    useEffect(() => {
        zoom = d3.zoom().scaleExtent([1, 5])
            .translateExtent([[0, 0], [containerWidth, 0]])
            .extent([[0, 0], [containerWidth, containerHeight]])
            .on('zoom',
                () => {
                    handleZoom(d3.event.transform);
                }
            )
        bindZoom(zoom);
        d3.select(ref.current)
            .select('g')
            .attr('transform', `translate(${translationX},0)`);
        updateChart();
    }, [containerWidth, containerHeight, translationX]);

    /*
    const createChart = () => {
        // rebind zoom on changes.
        //if(zoom === null){


        zoom = d3.zoom().scaleExtent([1, 5])
            .translateExtent([[0, 0], [containerWidth, 0]])
            .extent([[0, 0], [containerWidth, containerHeight]])
            .on('zoom',
                () => {
                    handleZoom(d3.event.transform);
                }
            )
        bindZoom(zoom);

        updateChart();
    }
     */

    const updateChart = () => {
        const scales = _scaleXY();
        drawChart(scales);
        drawAxis(scales);
    }

    const handleZoom = (event) => {
        const newTranslationX = event.x;
        const newScale = event.k;
        setTranslationX(newTranslationX);
        setNewScale(newScale);
    }

    const bindZoom = (zoom) => {
        d3.select(ref.current)
            .call(zoom)
            .on('dblclick.zoom', null);
    }

    const _scaleXY = () => {
        const w = containerWidth;
        const h = containerHeight;
        // const data = chartData;
        // const scale = scale;

        //if (data.length > 0) {
            minYear = data[0].year;
            maxYear = data[data.length - 1].year;
       // } else {
       //     minYear = 2000;
      //      maxYear = 2019;
       // }
        //minYear = minYear;
        // maxYear = maxYear;

        const maxAmount = d3.max(data, (e) => e.amount);
        return {
            x: d3.scaleBand()
                .domain(d3.range(minYear, maxYear + 1))
                .range([50, w * scale], 0, 0),
            y: d3.scaleLinear()
                .domain([0, maxAmount])
                .range([h - 20, 10])
        };
    }

    const drawChart = (scales) => {
        // information about the tools activated / deactivated
        const yFloor = containerHeight <= 0 ? containerHeight : containerHeight - 20;
        // bind the scaling function

        const xScale = scales.x;
        const yScale = scales.y;
        // The bandwidth refers to the calculated width of each bar.
        const xSize = xScale.bandwidth();

        const svg = d3.select(ref.current);

        const mainGroup = svg.select('g');

        const regLineGroup =
            svg
                .selectAll('g .regGroup');

        /************************** CREATE LINEAR REGRESSION LINE *************************/
        if (displayLinearRegression) {
            // calculate the linear regression with an advance package for d3
            const linearRegression = regressionLinear()
                .x(d => d.year)
                .y(d => d.amount)
                .domain([minYear - 1, maxYear]);

            // remove old regression line
            regLineGroup
                .selectAll('line')
                .remove();

            // draw new regression line
            regLineGroup
                .append('line')
                .attr('class', 'regressionLine')
                .datum(linearRegression(data))
                .attr('x1', d => xScale(d[0][0]))
                .attr('x2', d => xScale(d[1][0]))
                .attr('y1', d => yScale(d[0][1]))
                .attr('y2', d => yScale(d[1][1]))
                .attr('opacity', 1)
                .attr('stroke', 'darkgrey')
                .attr('stroke-width', 2)
                .attr('cursor', 'pointer')
                // to make the line points stick to the middle of each year
                .attr('transform', 'translate(' + xSize / 2 + ',0)');

        } else {
            // remove old regression line
            regLineGroup
                .selectAll('line')
                .remove();
        }

        /************************** CREATE LOESS REGRESSION LINE *************************/
        if (displayLOESSRegression) {
            const loessRegression = regressionLoess()
                .x(d => d.year)
                .y(d => d.amount)
                .bandwidth(bandWidthLOESSRegression);

            // remove old regression line
            regLineGroup
                .selectAll('path')
                .remove();

            const lineLoess = d3.line()
                .x(d => xScale(d[0]))
                .y(d => yScale(d[1]));

            // draw new regression line
            regLineGroup
                .append('path')
                .attr('class', 'regressionLine')
                .datum(loessRegression(data))
                .attr('d', lineLoess)
                .attr('opacity', 1)
                .attr('stroke', 'darkgrey')
                .attr('stroke-width', 2)
                .attr('fill', 'none')
                .attr('cursor', 'pointer')
                // to make the line points stick to the middle of each year
                .attr('transform', 'translate(' + xSize / 2 + ',0)');
        } else {
            // remove old regression line
            regLineGroup
                .selectAll('path')
                .remove();
        }


        // defines how to cut off the bars so that they create a nice looking flow.
        const path = (d, i) => {
            if(d !== undefined) {
                const y = yScale(d.amount);
                const yPrev = 0.5 * (yScale(i === 0 ? d.amount : data[i - 1].amount) + y);
                const yNext = 0.5 * (yScale(i === data.length - 1 ? d.amount : data[i + 1].amount) + y);
                const xMin = xScale(d.year);
                const xMax = xMin + xSize;
                let p = `M ${xMin} ${yFloor}`;
                p += ` V ${yPrev}`;
                p += ` C ${xMin + xSize / 8} ${(yPrev + y) / 2} ${xMin + xSize / 4} ${y} ${xMin + xSize / 2} ${y}`;
                p += ` C ${xMax - xSize / 4} ${y} ${xMax - xSize / 8} ${(y + yNext) / 2} ${xMax} ${yNext}`;
                p += ` V ${yFloor}`;
                p += ' Z';

                return p;
            } else {
                return inititalPath();
            }

        };

        const inititalPath = () => {
            const yFloor = containerHeight <= 0 ? containerHeight : containerHeight - 20;
            const y = yFloor;
            const yPrev = yFloor;
            const yNext = yFloor;
            const xMin = 0;
            const xMax = xMin + xSize;
            let p = `M ${xMin} ${yFloor}`;
            p += ` V ${yPrev}`;
            p += ` C ${xMin + xSize / 8} ${(yPrev + y) / 2} ${xMin + xSize / 4} ${y} ${xMin + xSize / 2} ${y}`;
            p += ` C ${xMax - xSize / 4} ${y} ${xMax - xSize / 8} ${(y + yNext) / 2} ${xMax} ${yNext}`;
            p += ` V ${yFloor}`;
            p += ' Z';
            return p;
        }





        // helper function to calc change between 2 points as percentage
        const calcPercentage = (curr, other) => {
            return Math.round(((other - curr) / curr) * 100);
        };


        // for the lines
        let minYears = [];
        let maxYears = [];
        let xStart;
        let xEnd;
        let avg;
        if (data.length > 0) {
            xStart = xScale(data[0].year);
            xEnd = xScale(data[data.length - 1].year) + xSize;
            avg = d3.sum(data, (e) => e.amount) / data.length;


            let min = Number.MAX_VALUE;
            let max = Number.MIN_VALUE;

            const minBarNumber = Math.round(-translationX / xSize);
            const maxBarNumber = Math.round((-translationX + containerWidth) / xSize);

            data.forEach((d, i) => {
                if ((i >= minBarNumber) && (i < maxBarNumber)) {
                    if (d.amount === 0) return;
                    if (min > d.amount) {
                        min = d.amount;
                        minYears = [d];
                    } else if (min === d.amount) {
                        minYears.push(d);
                    }
                    if (max < d.amount) {
                        max = d.amount;
                        maxYears = [d];
                    } else if (max === d.amount) {
                        maxYears.push(d);
                    }
                }
            });
        }


        // mouse events ---> highlights hovered bar
        const onMouseOver = (d, i, n) => {
            if (d.amount === 0) {
                return;
            }
            // Da man die this Referenz von d3 nicht so einfach in Angular verwenden kann nutzern wir einfach den aktuellen node (n[i])
            const currentElement = n[i];
            const currentElementAmount = d.amount;
            const currId = i;

            // we have to define various different things for the menu functions on Hover
            if (displayNeighbors) {

                // prev element to the current hovered
                const neighborPrev = n[currId - 1];
                // add a check for first element. --> avoid array index out of bounds exception
                const neighborPrevAmount = currId !== 0 ? neighborPrev.__data__.amount : 0;
                const differencePrevToCurrent = neighborPrevAmount - currentElementAmount;
                const differencePrevToCurrentPercentage = calcPercentage(currentElementAmount, neighborPrevAmount);

                // next element to the current hovered
                const neighborNext = n[currId + 1];
                // add a check for last element. --> avoid array index out of bounds exception
                const neighborNextAmount = currId < n.length - 1 ? n[currId + 1].__data__.amount : 0;
                const differenceNextToCurrent = neighborNextAmount - currentElementAmount;
                const differenceNextToCurrentPercentage = calcPercentage(currentElementAmount, neighborNextAmount);

                // Colorize previous bar
                d3.select(neighborPrev)
                    .select('path')
                    .attr('fill', differencePrevToCurrentPercentage < 0 ? '#eb4f51' : '#90eb6a')
                    .attr('stroke', activeStrokeColorForBars());
                // Colorize next bar
                d3.select(neighborNext)
                    .select('path')
                    .attr('fill', differenceNextToCurrentPercentage < 0 ? '#eb4f51' : '#90eb6a')
                    .attr('stroke', activeStrokeColorForBars());

                d3.select(neighborPrev).select('text')
                    .attr('font-weight', 'bold')
                    .text(differencePrevToCurrentPercentage < 0 ? '' + differencePrevToCurrentPercentage + '%' : '+' + differencePrevToCurrentPercentage + '%');

                d3.select(neighborNext).select('text')
                    .attr('font-weight', 'bold')
                    .text(differenceNextToCurrentPercentage < 0 ? '' + differenceNextToCurrentPercentage + '%' : '+' + differenceNextToCurrentPercentage + '%');
            }

            if (displayLines) {
                const overlay = svg
                    .select('g')
                    .select('.overlay');

                overlay.append('path')
                    .attr('d', `M ${xStart} ${yScale(d.amount) + 40} H ${xEnd}`);

                overlay
                    .append('path')
                    .attr('pointer-events', 'none')
                    .attr('shape-rendering', 'crispEdges')
                    .attr('stroke-dasharray', '10')
                    .attr('stroke', '#ff7b08')
                    .attr('stroke-width', 3)
                    .attr('d', `M ${xStart} ${yScale(avg)} H ${xEnd}`);

                overlay
                    .append('text')
                    .attr('alignment-baseline', 'central')
                    .attr('text-anchor', 'start')
                    .attr('x', (containerWidth / 2) + translationX * -1)
                    .attr('y', yScale(avg) - 10)
                    .attr('stroke', '#ff7b08')
                    .text('average: ' + `${avg.toFixed(2)}`);


                // display only 1 line for this approach.
                const singleMinYear = minYears[0];


                // minYears.forEach((m) => {
                const minLine = overlay
                    .append('line')
                    .attr('pointer-events', 'none')
                    .attr('shape-rendering', 'crispEdges')
                    .attr('stroke-width', 2)
                    .attr('stroke', '#ff7b08')
                    .attr('x1', xScale(singleMinYear.year) + xSize / 2)
                    .attr('y1', yScale(singleMinYear.amount))
                    .attr('x2', xScale(singleMinYear.year) + xSize / 2)
                    .attr('y2', yScale(d.amount) + 9);

                minLine
                    .attr('marker-end', 'url(#triangleUp)');
                // .attr('transform', `translate(0,9)`);

                // Querlinie
                overlay.append('path')
                    .attr('pointer-events', 'none')
                    .attr('shape-rendering', 'crispEdges')
                    .attr('stroke-width', 2)
                    .attr('stroke', '#ff7b08')
                    .attr('d', `M ${xScale(singleMinYear.year) + xSize / 2} ${yScale(d.amount)} H ${xScale(maxYears[0].year) + xSize / 2}`);

                overlay
                    .append('text')
                    // .style(styleText)
                    .attr('alignment-baseline', 'central')
                    .attr('text-anchor', 'start')
                    .attr('x', xScale(singleMinYear.year) + xSize / 2 + 1.5)
                    .attr('y', (yScale(singleMinYear.amount) + yScale(d.amount)) / 2)
                    .text('\u2206 min:' + `${d.amount - singleMinYear.amount}`)
                    .attr('fill', '#ff7b08');
                // });

                overlay
                    .append('path')
                    .attr('pointer-events', 'none')
                    .attr('shape-rendering', 'crispEdges')
                    .attr('stroke-width', 2)
                    .attr('stroke', '#ff7b08')
                    .attr('d', `M ${xStart} ${yScale(maxYears[0].amount)} H ${xEnd}`);

                const singleMaxYear = maxYears[0];

                // maxYears.forEach((m) => {
                const maxLine = overlay
                    .append('path')
                    .attr('pointer-events', 'none')
                    .attr('shape-rendering', 'crispEdges')
                    .attr('stroke-width', 2)
                    .attr('stroke', '#ff7b08')
                    // - 9  bc of the arrow down.
                    .attr('d', `M ${xScale(singleMaxYear.year) + xSize / 2} ${yScale(d.amount) - 9} V ${yScale(singleMaxYear.amount)}`);

                maxLine
                    .attr('marker-start', 'url(#triangleDown)');
                // .attr('transform', `translate(0,-9)`);

                overlay
                    .append('text')
                    // .style(styleText)
                    .attr('alignment-baseline', 'central')
                    .attr('text-anchor', 'end')
                    .attr('x', xScale(singleMaxYear.year) + xSize / 2)
                    .attr('y', (yScale(singleMaxYear.amount) + yScale(d.amount)) / 2)
                    .text('\u2206 max:' + `${singleMaxYear.amount - d.amount}`)
                    .attr('fill', '#ff7b08');
                //  });
            }

            d3.select(currentElement)
                .select('path')
                .attr('fill', activeFillColorForBars())
                .attr('stroke', activeStrokeColorForBars());

            d3.select(currentElement)
                .select('text')
                .attr('font-weight', 'bold')
                .attr('fill', 'black')
                .text(d.amount);
        };

        // mouse events ---> removes hover from bar
        const onMouseOut = (d, i, n) => {
            if (d.amount === 0) {
                return;
            }
            const currentElement = n[i];
            const neighborPrev = n[i - 1];
            const neighborNext = n[i + 1];

            d3.select(currentElement)
                .select('path')
                .attr('fill', (e) => displayColors ? d.color : calculateStandardFillColorForBarsBySignalStrength(e.signal))
                .attr('stroke', displayColors ? '#b7b4a9' : standardStrokeColorForBars());

            d3.select(neighborPrev)
                .select('path')
                .attr('fill', (e) => displayColors ? d.color : calculateStandardFillColorForBarsBySignalStrength(e.signal))
                .attr('stroke', displayColors ? '#b7b4a9' : standardStrokeColorForBars());

            d3.select(neighborNext)
                .select('path')
                .attr('fill', (e) => displayColors ? d.color : calculateStandardFillColorForBarsBySignalStrength(e.signal))
                .attr('stroke', displayColors ? '#b7b4a9' : standardStrokeColorForBars());


            d3.select(currentElement)
                .select('text')
                .attr('font-weight', 'normal')
                .text(d.year !== pinId ? (!alwaysDisplayNumbers || d.amount === 0 ? '' : d.amount) : d.amount);


            if (displayNeighbors) {
                d3.select(neighborPrev)
                    .select('text')
                    .attr('font-weight', 'normal')
                    .text((parseInt(d.year, 10)).toString() !== pinId ? (!alwaysDisplayNumbers || d.amount === 0 ? '' : d.amount) : d.amount);

                d3.select(neighborNext)
                    .select('text')
                    .attr('font-weight', 'normal')
                    .text((parseInt(d.year, 10)).toString() !== pinId ? (!alwaysDisplayNumbers || d.amount === 0 ? '' : d.amount) : d.amount);

            }

            svg
                .select('g')
                .select('.overlay')
                .selectAll('path')
            .remove();
            svg
                .select('g')
                .select('.overlay')
                .selectAll('line')
            .remove();
            svg
                .select('g')
                .select('.overlay')
                .selectAll('text')
            .remove();
        };

        // the calculation of the opacity
        const calculateOpacity = (d) => {
            if (pinId !== undefined && pinId !== '-1') {
                if (pinId === d.year) {
                    return 1;
                } else {
                    return 0.4;
                }
            } else {
                const date = new Date();
                const year = date.getFullYear();
                if (d.year > (year - 1)) {
                    return 0.4;
                } else {
                    return 1;
                }
            }
        };

        const pinEvent = (pin) => {
            if (pin === pinId) {
                setPinId('-1');
            } else {
                setPinId(pin)
            }
        }


        // The group all bars are appended to
        svg
            .attr('width', containerWidth)
            .attr('height', containerHeight)

        //.attr('class', 'barGroup')
        // as yFloor makes the lowest point of the chart to -20 we must transform.
        // barGroup.attr('transform', `translate(0,${20})`)
        //mainGroup.select('.bars')
        // .selectAll('g')
        //.remove();

        // The group all bars are appended to
        const bar = mainGroup.select('.bars')
            .selectAll('g').data(data);

        const enter = bar
            .enter()
            // each bar is an entering group element
            .append('g')
            .on('click', (d) => pinEvent(d.year))
            .on('dblclick', (d) => toggleFacetFilter(FACET.YEAR, d.year))
            .on('contextmenu', (d) => toggleContextMenu(d.year, ref.current))
            .attr('class', 'bar');

        // .dblTap((d) => setFacetFilter(d.year))


        // to each group we append the rect which is the visible bar. And we append rext.
        enter
            .append('rect')
            .attr('opacity', 0);


        enter.append('path')
            .attr('stroke', pathStrokeColor())


        // define text and position of the text to display.
        //  bars.select('text')
        // append a text element to each bar group
        enter.append('text')
            .attr('pointer-events', 'none')
            .attr('dy', '.3em')
            .attr('text-anchor', 'middle');


        // rectangle sizings
        bar.select('rect')
            .attr('x', (d) => xScale(d.year))
            .attr('y', 0)
            .attr('width', xSize)
            .attr('height', yFloor)


        bar.on('mouseover', onMouseOver)
            .on('mouseout', onMouseOut)





        // cut off the bar group with path element
        bar.select('path')
            // bind mouse functions to the chart
            // barGroup
            .attr('cursor', (d) => d.amount > 0 ? 'pointer' : '')
            .attr('fill', (d) => displayColors ? d.color : calculateStandardFillColorForBarsBySignalStrength(d.signal))
            .attr('stroke', displayColors ? pathStrokeColor() : standardStrokeColorForBars())
            .attr('opacity', (d) => calculateOpacity(d))
            .attr('d', (d, i) => prevData !== undefined ? path(prevData[i], i) : path(d, i))
            .transition()
            .attr('d', (d, i) => path(d, i))


        bar.select('text')
            .text((d) => {
                if (d.year !== pinId) {
                    if (!alwaysDisplayNumbers || d.amount === 0) {
                        return '';
                    } else {
                        return d.amount;
                    }
                } else {
                    return d.amount;
                }
            })
            // .transition()
            .attr('x', (d) => xScale(d.year) + xSize / 2)
            .attr('y', (d) => (yFloor + yScale(d.amount)) / 2);

        bar
            .exit()
            .remove();

    }

    const drawAxis = (scales) => {
        const w = containerWidth;
        const h = containerHeight;
        const Sscale = scale;
        const svg = d3.select(ref.current)

        let tickValues = scales.x.domain();
        if (w * Sscale < tickValues.length * (32 + 8) / 5) {
            tickValues = scales.x.domain().filter((d) => d % 10 === 0);
        } else if (w * Sscale < tickValues.length * (32 + 8) / 2) {
            tickValues = scales.x.domain().filter((d) => d % 5 === 0);
        } else if (w * Sscale < tickValues.length * (32 + 8)) {
            tickValues = scales.x.domain().filter((d) => d % 2 === 0);
        }

        const xAxis = d3.axisBottom()
            .scale(scales.x)
            .tickValues(tickValues)
            .tickSize(1)
            .tickPadding(4);


        const mainGroup = svg.select('g')

        mainGroup
            .select('.axis')
            .attr('transform', `translate(0,${h - 20})`)
            //.transition()
            .call(xAxis);

        const yAxis = d3.axisLeft()
            .scale(scales.y)
            .tickSize(1)
            .tickPadding(4);

        mainGroup
            .select('.yaxis')
            .attr('transform', 'translate(50, 0)')
            //.transition()
            .call(yAxis);

        const ytick = yAxis.ticks();

        const lineData = yAxis
            .scale()
            .ticks(ytick[0]);

        mainGroup
            .select('.lines')
            .selectAll('path')
            .remove();

        const line = mainGroup
            .select('.lines')
            .selectAll('path')
            .data(lineData);

        line.enter()
            .append('path')
            .attr('d', (d) => {
                const y = scales.y(d);
                return 'M 50,' + y + 'h' + w * Sscale;
            })
            .attr('stroke-dasharray', '0.5%, 0.5%')
            .attr('stroke', 'grey'); // temporalOverviewOverlayLineColor());
    }

    return (
        <svg ref={ref}>
            <g className="mainGroup">
                <g className="bars"/>
                <g className="axis"/>
                <g className="yaxis"/>
                <g className="lines"/>
                <g className="overlay"/>
                <g className="regGroup"/>

                <marker id="triangleUp" viewBox="0 0 10 10"
                        refX="1" refY="5"
                        markerUnits="strokeWidth"
                        markerWidth="5" markerHeight="5"
                        orient="auto">
                    <path d="M 0 0 L 10 5 L 0 10 z"  style={{fill: '#ff7b08'}}/>
                </marker>
                <marker id="triangleDown" viewBox="0 0 10 10"
                        refX="1" refY="5"
                        markerUnits="strokeWidth"
                        markerWidth="5" markerHeight="5"
                        orient="auto-start-reverse">
                    <path d="M 0 0 L 10 5 L 0 10 z" style={{fill: '#ff7b08'}}/>
                </marker>


            </g>
        </svg>
    );

}

export default TemporalOverviewChart;


