import {atom, selector} from "recoil";
import {
    initialChartsSpatioTemporalDataModel,
    initialChartsTemporalDataModel,
    initialChartsWholeDataModel
} from '../../../../config/visualizations'
import {moduleToDisplayAtom} from "../../DashboardLayout";
import {activeDataModelSelector} from "../../../../dataProvider/vissights/search/search";
import {
    dashboardHeightSelector,
    dashboardWidthSelector
} from "../../../../config/sizings/sizings";
import uuid from "react-uuid";
import _ from "lodash";
import {
    displayCreatePoiModalAtom
} from "../../../VisualizationModules/zoomAndFilter/visualizations/PoiChart/modals/CreateNewPoiModal";


export const forceStaticGridAtom = atom({
    key: "forceStaticGridAtom-Key",
    default: false
});


// here we load all data for the search
export const elementsDraggableSelector = selector({
    key: "elementsDraggableSelector-Key",
    get: ({get}) => {
        const isForcedStatic = get(forceStaticGridAtom);
        const isDisplayingCreatePoiModal = get(displayCreatePoiModalAtom);
        return !isForcedStatic && !isDisplayingCreatePoiModal;
    }
});





export const layoutGridAtom = atom({
    key: "layoutGrid-Key",
    default: []
});

export const gridConfigAtom = atom({
    key: "gridConfig-Key",
    default: {cols: 120, rowHeight: 1, useCSSTransforms: false}
});

export const AREA = {
    'small': 'Small',
    'standard': 'Standard',
    'large': 'Large',
    'spaceFill': 'Space Filling'
}

export const areaSizeState = atom({
    key: 'areaSize',
    default: AREA.spaceFill
})

// if true, elements must maintain the aspect ratio of their standard width and height in space filling mode
export const keepAspectRatioState = atom({
    key: 'keepAspectRatio',
    default: true
})

// if true, elements must maintain the aspect ratio between each other in space filling mode
// example: if an element is twice as high as another element in standard size, this should also apply in space filling mode
export const keepElementsRatioState = atom({
    key: 'keepElementsRatio',
    default: true
})


export const visualizationItemsSelector = selector({
    key: "visualizationItemsForModel-Key",
    // default: initialChartsWholeDataModel
    get: ({get}) => {
        const dataModel = get(activeDataModelSelector);
        if (dataModel[0].name === "Whole") {
            return initialChartsWholeDataModel;
        } else if (dataModel[0].name === "Spatio Temporal") {
            return initialChartsSpatioTemporalDataModel
        } else if (dataModel[0].name === "Temporal") {
            return initialChartsTemporalDataModel
        }
    }
});

export const visualizationItemsAtom = atom({
    key: "visualizationItemsAtom-Key",
    default: visualizationItemsSelector
});

// here we load all data for the search
export const filteredVisualizationItemsForModule = selector({
    key: "filteredVisualizationItemsForModule-Key",
    get: ({get}) => {
        const visualizationItems = get(visualizationItemsAtom);
        const moduleToDisplay = get(moduleToDisplayAtom);
        return visualizationItems.filter((d) => d.module === moduleToDisplay);
    }
});

export const filteredWithLayout = selector({
    key: "filteredWithLayout-Key",
    get: async ({get}) => {
        const visualizationItems = get(filteredVisualizationItemsForModule);
        const keepAspectRatio = get(keepElementsRatioState);
        const keepElementsRatio = get(keepElementsRatioState);
        const window = {width: get(dashboardWidthSelector) || 100 , height: get(dashboardHeightSelector) || 100};
        const area = get(areaSizeState);
        /*
               console.log(area, visualizationItems, [], window, keepAspectRatio, keepElementsRatio);


               const newLayout = await calculateLayout(area, visualizationItems, [], window, keepAspectRatio, keepElementsRatio)

               console.log(newLayout);
               if(newLayout !== undefined){
                   newLayout.map((d) => d['randomKey'] = uuid())
               }


               console.log(newLayout);

               */
        const generateLayoutForCharts = (items, dashboardHeight) => {
               const clone = _.cloneDeep(items);
               const amountVisItems = clone.length;
               const area = get
               clone.map((d, index) => {
                   d['minW'] = d.dashboardConfig.minW;
                   d['minH'] = d.dashboardConfig.minH;
                   d['w'] = amountVisItems === 1 ? 120 : 60;
                   d['h'] = amountVisItems < 3 ? (dashboardHeight / 11) : (dashboardHeight / 22);
                   d['x'] = index % 2 === 0 ? 0 : 60;
                   d['y'] = (index !== 3 && index !== 2) ? 0 : dashboardHeight / 20;
                   //  we create a random key which is used as component key. This causes an update to run whenever we change the items. (problem related to RGL)
                   d['randomKey'] = uuid();
                   return d;
               });
               return clone;
           }



        return generateLayoutForCharts(visualizationItems, window.height);
    }
});
