import {atom, selector} from "recoil";
import * as d3 from 'd3';
import {searchDatabase} from "../search/search";
import {genericSort} from "../../../utility";
import {vissightsConfig} from "../vissightsConfig";
import * as _ from "lodash";
import {recoilPersist} from "recoil-persist";
import keycloak from "../authentication/keycloak";

const {persistAtom} = recoilPersist()
/** Raw Data from API  **/
// Load available Databases
export const fetchAvailableDatabases = selector({
    key: 'fetchAvailDbsSelector',
    get: async () => {
        try {
            const availDbUrl = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/databases";
            const availDbData = await fetch(availDbUrl);
            // first request the topicStats
            const availDbDataJson = await availDbData.json();
            // const filteredOutDbs = vissightsConfig.notWorkingAvailableDatabases;
            // we filter out all that we do not want.
            return availDbDataJson; // .filter((d) => !filteredOutDbs.includes(d));
        } catch (error) {
            throw error;
        }
    }
});

// save available databases in an atom
export const availableDatabases = atom({
    key: "availableDatabasesKey",
    // already filtered dbs with icons and display name
    default: vissightsConfig.dbConfig // fetchAvailableDatabases,
});

// Load topic stats
export const fetchTopicStats = selector({
    key: 'fetchTopicStatsSelector',
    get: async ({get}) => {
        const db = get(searchDatabase);
        try {
            const topicStatsUrl = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/topicstats?db=" + db.name;
            const topicStatsData = await fetch(topicStatsUrl);
            const topicStatsDataJson = topicStatsData.json()

            // console.log(topicStatsDataJson)
            // first request the topicStats
            return topicStatsDataJson
        } catch (error) {
            throw error;
        }
    }
});

// save topic stats in an atom
export const topicStatsAtom = atom({
    key: "topicStatsKey",
    default: fetchTopicStats,
});

// Load topics (names)
export const fetchTopics = selector({
    key: 'fetchTopicsSelector',
    get: async ({get}) => {
        const db = get(searchDatabase);
        console.log(db)
        try {
            const topicNamesUrl = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/topics?db=" + db.name;
            const topicData = await fetch(topicNamesUrl)
            return topicData.json();
        } catch (error) {
            throw error;
        }
    },
    effects_UNSTABLE: [persistAtom],
});

export const topicsAtom = atom({
    key: "topicsKey",
    default: fetchTopics,
});

// Load topic search history data
export const fetchSearchHistory = selector({
    key: 'searchHistorySelector',
    get: async ({get}) => {
        const db = get(searchDatabase);
        try { //https://api.morpheus.fbmd.h-da.de/db/searchhistory?db=springer
            const searchHistoryUrl = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/searchhistory?db=" + db.name;
            const searchHistory = await fetch(searchHistoryUrl);
            const searchHistoryData = await searchHistory.json();
            //console.log(searchHistoryData)
            return formatSearchHistoryData(searchHistoryData, 12);
        } catch (error) {
            throw error;
        }
    },
    effects_UNSTABLE: [persistAtom],
});

// call the fetchSearchHistory selector and store it as default value for the data atom
export const searchHistoryDataAtom = atom({
    key: "searchHistoryDataKey",
    default: fetchSearchHistory,
});



export const fetchMonitoringItemsSelector = selector({
    key: 'fetchMonitoringItemsSelector-Key',
    get: async ({get}) => {
        let monitoringItemsList;

        const userId = keycloak?.tokenParsed?.sub;

        // localStorage.removeItem(keycloak.tokenParsed.preferred_username);
        if(localStorage.getItem(userId) !== null){
            monitoringItemsList = JSON.parse(localStorage.getItem(userId));
        } else {
            localStorage.setItem(userId,JSON.stringify([{monitoringItems: []}]))
            monitoringItemsList = JSON.parse(localStorage.getItem(userId));
        }
        return monitoringItemsList[0].monitoringItems;
    } // ,
      //effects_UNSTABLE: [persistAtom],
});



export const monitoringDataAtom = atom({
    key: "monitoringDataAtom-Key",
    default: fetchMonitoringItemsSelector,
});




// Load topic search history data
export const fetchAmountPubsPerYearSelector = selector({
    key: 'fetchAmountPubsPerYearSelector-Key',
    get: async ({get}) => {
        const db = get(searchDatabase);
        try {
            const pubsPerYearUrl = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/publicationsperyear?db=" + db.name;
            const pubsPerYear = await fetch(pubsPerYearUrl);
            const pubsPerYearData = await pubsPerYear.json();
            return pubsPerYearData;
        } catch (error) {
            throw error;
        }
    },
    effects_UNSTABLE: [persistAtom],
});

// call the fetchSearchHistory selector and store it as default value for the data atom
export const amountPubsPerYearAtom = atom({
    key: "amountPubsPerYearAtom-Key",
    default: fetchAmountPubsPerYearSelector,
});


//*****
// Topic overview
//*****
// Load topic overview data and preprocess
export const fetchTopicOverview = selector({
    key: 'topicOverviewSelector',
    get: async ({get}) => {
        try {
            const topicNamesData = get(topicsAtom);
            const topicStatsData = get(topicStatsAtom);
            const pubsPerYearData = get(amountPubsPerYearAtom);
            return await combineTopicsWithTopicStatsAndCalculateMaxTotalGrowth(topicNamesData, topicStatsData, pubsPerYearData);
        } catch (error) {
            throw error;
        }
    },
    effects_UNSTABLE: [persistAtom],
});


// call the fetchTopicOverview selector and store it as default value for the data atom
export const overviewDataAtom = atom({
    key: "overviewDataKey",
    default: fetchTopicOverview,
});


export const topicLineChartDataAtom = atom({
    key: "topicLineChartDataKey",
    default: []
});


function calculateTrends(temporalTopics, topics) {
    const periodLength = 4;
    const weightingMethod = 'linear';

    const getDataAndCombine = (topicStats, topicNamesById) => {
        const combinedData = [];
        topicStats.forEach((d, i) => {
            const oneEntry = {};
            // oneEntry[()] = topicStats[i];
            oneEntry.name = (topicNamesById[i]['word'][0][0]).toString();
            oneEntry.values = topicStats[i];
            combinedData.push(oneEntry)
        })
        return combinedData
    }

    const getYearTotals = (topicStats) => {
        const listWithStackedYears = {};

        topicStats.forEach((entry, i) => {

            const keys = Object.keys(entry);
            const values = Object.values(entry);

            keys.forEach((key, i) => {
                if (key in listWithStackedYears) {
                    listWithStackedYears[key] = listWithStackedYears[key] + values[i]
                } else {
                    listWithStackedYears[key] = values[i]
                    return listWithStackedYears
                }
            });
            /*
           entry.each((el, j) => {
           const key = el;
           const value = entry[i]
               if (key in listWithStackedYears) {
                   listWithStackedYears[key] = listWithStackedYears[key] + value
               } else {
                   listWithStackedYears[key] = value
                   return listWithStackedYears
               }
           })
             */
        });
        return listWithStackedYears;
    }

    const yearsTotal = getYearTotals(temporalTopics);
    const combinedData = getDataAndCombine(temporalTopics, topics);

    // console.log(yearsTotal);
    // console.log(combinedData);

    // dY is the total number of documents in a year y
    const dY = yearsTotal
    // tY is a list containing the number of documents in in year y that contain a certain topic t.
    // for test purposes we access the first element which is the topic learning with its occurance over the years:
    const tY = combinedData; //[topicnumber]

    //  Normalization function
    const normalizeFrequenzysAndSplitTuples = (tY, dY) => {
        const tYNormalized = [];
        // console.log(tY, dY);
        tY.forEach((d, i) => {
            const years = Object.keys(d.values);
            const amount = Object.values(d.values);
            // console.log(years, amount)
            const normalizedAmountsArray = [];
            years.forEach((d, i) => {
                const normalizedAmount = amount[i] / dY[d];
                normalizedAmountsArray.push(normalizedAmount);
            })

            const tuplesArray = [];
            const avgArray = [];
            let oneTuple = [];
            let yearsWithNormalizedAmounts = {};

            years.forEach((d, i) => {
                if ((i % periodLength !== 0) || (i === 0)) {
                    yearsWithNormalizedAmounts[d.toString()] = normalizedAmountsArray[i];
                } else {
                    yearsWithNormalizedAmounts[d.toString()] = normalizedAmountsArray[i];
                    // oneTuple.push(yearsWithNormalizedAmounts)
                    tuplesArray.push(yearsWithNormalizedAmounts);
                    avgArray.push(_.mean(Object.values(yearsWithNormalizedAmounts)));
                    // oneTuple = [];
                    yearsWithNormalizedAmounts = {};
                }
                /*
                if ((i % periodLength !== 0) || (i === 0)){
                    entity.values.push(data[i])
                    oneSplitTupleList.push(entity)
                } else {
                    listWithSplittedTuples.push(oneSplitTupleList)
                    //reset
                    oneSplitTupleList = [];
                    entity = {};
                    entity.values.push(data[i])
                    oneSplitTupleList.push(entity)
                }
                 */
            })
            tYNormalized.push({name: d.name, values: tuplesArray, averages: avgArray});
            // console.log(tYNormalized)
            // d.values.forEach((e,j) => {
            //    console.log(e)
            // })
        })
        return tYNormalized;
        // for i in tY[topicName]:
        // normalizedDict = (i, tY[topicName][str(i)] / dY[i])
        // tYNormalized.append(normalizedDict)
        // return tYNormalized
    }
    const tYNormalized = normalizeFrequenzysAndSplitTuples(tY, dY);
}

/*
   const splitIntoPeriods = (data, periodLength) => {
        console.log(data);
    }
    const test = splitIntoPeriods(tYNormalized, periodLength);

        const listWithSplittedTuples = [];
        let oneSplitTupleList = [];

        data.forEach((e,j) => {
            const keys = Object.keys(e.values);
            const values = Object.values(e.values);


            values.forEach((d, i) => {
                oneSplitTupleList.name = e.name;
                let entity = {};
                if ((i % periodLength !== 0) || (i === 0)){
                    entity.values.push(data[i])
                    oneSplitTupleList.push(entity)
                } else {
                    listWithSplittedTuples.push(oneSplitTupleList)
                    //reset
                    oneSplitTupleList = [];
                    entity = {};
                    entity.values.push(data[i])
                    oneSplitTupleList.push(entity)
                }
            })
        })
        return listWithSplittedTuples
    }

    const test = splitIntoPeriods(tYNormalized, periodLength);

    console.log(test);
*/

// Calculate the data for the overview charts.
function combineTopicsWithTopicStatsAndCalculateMaxTotalGrowth(topics, temporalTopics, pubsPerYear) {
    const date = new Date();
    const year = date.getFullYear();
    const yearMin = year - 6;
    const yearMax = year - 1;

    /** Trend Analytics **/
        //  calculateTrends(temporalTopics, topics);
    const temporalData = temporalTopics.map((d, i) => {
            // die Infos braucht man für topicCloud & line chart!
            let lastFiveAmount = 0;
            let lastFiveMax = 0;
            for (let j = yearMin; j < year; j++) {
                if (d[j] !== undefined) {
                    if (d[j] > lastFiveMax) {
                        lastFiveMax = d[j];
                    }
                    lastFiveAmount = lastFiveAmount + d[j];
                }
            }

            const vals = Object.values(d);

            // _.forOwn(pubsPerYear[0], (v, k) =>{
            //     return value = value / v;
            // });

            // TODO: implement division  of years / pubsPerYear

            const normalizedVals = {}


            _.forOwn(d, (value, key) => {
                normalizedVals[key] = ((value / pubsPerYear[0][key])).toFixed(4);
            });

            const valsNormalized = Object.values(normalizedVals);


            // console.log(normalizedVals);

            /*
                const normalizedYears = d.map((e) => {
                    const newArray = [];
                    pubsPerYear.forEach((f) => {
                        newArray.push(e / f)
                    })
                    return newArray;
                })

             */

            // TODO: very basic exception handling
            const topicName = getTopicNameById(topics, i);
            return {
                id: i,
                color: Math.random()*360,
                text: getTopicNameById(topics, i),
                years: d,
                total: d3.sum(vals),
                amount: d3.sum(vals),
                max: d3.max(vals),
                growth: (isNaN(d[yearMax] - d[yearMin])) || (topicName === undefined) || (topicName.trim() === '') ? 0 : d[yearMax] - d[yearMin],
                // normalized data
                yearsNormalized: normalizedVals,
                totalNormalized: d3.sum(valsNormalized).toFixed(4),
                amountNormalized: d3.sum(valsNormalized).toFixed(4),
                maxNormalized: d3.max(valsNormalized),
                // information needed for topiccloud & line chart!
                growthNormalized: (isNaN(normalizedVals[yearMax] - normalizedVals[yearMin])) || (topicName === undefined) || (topicName.trim() === '') ? 0 : (normalizedVals[yearMax] - normalizedVals[yearMin]).toFixed(4),
                // TODO: very basic exception handling
                malformedData: (isNaN(d[yearMax] - d[yearMin])) || (topicName === undefined) || (topicName.trim() === ''),
                lastMax: lastFiveMax,
                lastTotal: lastFiveAmount
            }
        });

    console.log(temporalData)
    return temporalData;
}

// Gets the name of a topic by its id. Takes topic or phrase dependant of the higher value
function getTopicNameById(topics, topicid) {
    if (topics.length === 0) return topicid;
    const [[pTerm, pValue]] = topics[topicid].phrase;
    if (pValue > 0.05) {
        return pTerm;
    }
    const [[w1Term, w1Value], [w2Term, w2Value]] = topics[topicid].word;
    if (w1Value > w2Value * 2) {
        return w1Term;
    }
    if (w2Term.indexOf(w1Term) !== -1) {
        return w1Term;
    }
    if (w1Term.indexOf(w2Term) !== -1) {
        return w2Term;
    }
    return `${w1Term}, ${w2Term}`;
}

// format data for chart:
const formatSearchHistoryData = (searchRequests, amount) => {
    const keys = Object.keys(searchRequests);
    const values = Object.values(searchRequests);
    const formattedData = [];
    keys.forEach((d, i) => {
        formattedData.push({amount: values[i], text: keys[i], index: i});
    });
    const sortedData = formattedData.sort(genericSort('amount', 'desc'));
    return sortedData.slice(0, amount);
}


/*

// Calculate the data for the overview charts.
function combineTopicsWithTopicStatsAndCalculateMaxTotalGrowth(topics, temporalTopics) {
    const date = new Date();
    const year = date.getFullYear();
    // For last 5 years...
    const yearMin = year - 7;
    const yearMax = year - 2;

    const temporalData = temporalTopics.map((d, i) => {
        // die Infos braucht man für topiccloud & line chart!
        let lastFiveAmount = 0;
        let lastFiveMax = 0;
        for (let j = yearMin; j < year; j++) {
            if (d[j] !== undefined) {
                if (d[j] > lastFiveMax) {
                    lastFiveMax = d[j];
                }
                lastFiveAmount = lastFiveAmount + d[j];
            }
        }
        const vals = Object.values(d);
        // TODO: very basic exception handling

        const topicName = getTopicNameById(topics, i);


        if ((isNaN(d[yearMax] - d[yearMin])) || (topicName === undefined) || (topicName.trim() === '')) {
            return {
                id: i,
                text: getTopicNameById(topics, i),
                years: d,
                total: d3.sum(vals),
                amount: d3.sum(vals),
                max: d3.max(vals),
                // die Infos braucht man für topiccloud & line chart!
                growth: 0,
                // TODO: very basic exception handling
                malformedData: true,
                lastMax: lastFiveMax,
                lastTotal: lastFiveAmount
            }
        } else {
            return {
                id: i,
                text: getTopicNameById(topics, i),
                years: d,
                total: d3.sum(vals),
                amount: d3.sum(vals),
                max: d3.max(vals),
                // die Infos braucht man für topiccloud & line chart!
                growth: d[yearMax] - d[yearMin],
                lastMax: lastFiveMax,
                lastTotal: lastFiveAmount
            }
        }
    });
    return temporalData;
}
 */

