import {atom, atomFamily, noWait, selector, selectorFamily, waitForNone} from "recoil";
import {
    preProcessedRawDataSelector,
    searchDataAtom
    } from "../../../dataProvider/vissights/search/search";
import * as _ from "lodash";
import {
    activeFilterSetIdAtom,
    activePoisWithDataSelectorFamily,
    facetFiltersAtomFamily,
    poiDataSelectorFamily
} from "../../../dataProvider/vissights/search/filters/filters";
import FACET from "../../../dataProvider/vissights/utility/facet-extraction-utility";
import {topicsAtom} from "../../../dataProvider/vissights/overview/overview";
import DATA from "../../../dataProvider/vissights/search/filters/data";
import {genericSort} from "../../../utility";


// the data for each filtersets left nav facets is calculated here by the current active id.
export const leftNavigationFacetsData = selector({
    key: "leftNavigationFacetsData-Key",
    get: async ({get}) => {
        // get the raw data from search. We have to aggregate independently
        const rawData = await get(searchDataAtom);
        const activeFilterSetId = get(activeFilterSetIdAtom);
        // we iterate only over the current active filterSet.
        const facetFilters = get(facetFiltersAtomFamily(activeFilterSetId));
        const poiFilters = get(activePoisWithDataSelectorFamily(activeFilterSetId));
        const sliderValues = get(sliderValuesAtomFamily(activeFilterSetId));
        const filteredData = applyFilter(rawData, facetFilters, poiFilters, sliderValues);
        const topics = get(topicsAtom);
        return FACET.extractAllFacets(filteredData, topics);
    }
});


export const createLeftNavigationFacets = selector({
    key: "createLeftNavigationFacetsKey",
    get: async ({get}) => {
        // the content of the left nav facets is always dependent on the filterSet that is active.
        const activeFilterSetId = get(activeFilterSetIdAtom);
        // state of display all not only 3 preview.
        const previewState = get(previewAtom);
        // the already toggled ones. They show the first three elements
        const defaultOpened = get(defaultOpenStateAtom);
        // we store for each active filterset different open states. :)
        const facetSortingState = get(facetSortingStateAtomFamily(activeFilterSetId))
        const searchResults = await get(leftNavigationFacetsData);
        // bring the data in the correct form.
        if (searchResults !== undefined) {
            let currentFacetKeys = Object.keys(searchResults)
            const previews = currentFacetKeys.map((d, i) => {
                const sortingStateForFacet = facetSortingState.find((e) => e.facet === d.toString());
                // we create an exception for the Years facet.
                return {
                    facet: d,
                    // all facets that are stored in default opened are set.
                    initialOpen: defaultOpened.find((e) => e === d) !== undefined,
                    data: {
                        // previewElements: searchResults[d.toString()].slice(0, 3),
                        allElements: searchResults[d.toString()]
                    },
                    sorting: {
                        // we take the initial open state from another atomFamily. That gives opportunity to later on change it for each independent.
                        // method: sortingState.method,    // d !== 'Years' ? 'amount' : 'az',
                        // order is defaulted to descendant
                        // order: sortingState.order,// 'desc'
                        azSorting: sortingStateForFacet.azSorting,
                        amountSorting: sortingStateForFacet.amountSorting,
                        activeSortingMethod: sortingStateForFacet.activeSortingMethod

                    }
                }
            });

            /** Preview State **/
            const clone = _.cloneDeep(previews)
            // initial filling...
            if (previewState.length <= 0) {
                clone.map((d) => {
                    d.onlyPreview = true;
                });
            } else {
                // change if we already have values.
                clone.map((d) => {
                    previewState.forEach((e) => {
                        if (d.facet === e.facet) {
                            d.onlyPreview = e.onlyPreview;
                        }
                    })
                    return d;
                });
            }


            // sort the data the right way.
            clone.map((d) => {
                // const method = d.sorting.method === 'az' ? 'name' : 'amount';
                const sortingData = d.sorting.activeSortingMethod === 'az' ? d.sorting.azSorting : d.sorting.amountSorting;
                const method = sortingData.method === 'az' ? 'name' : 'amount';

                return d.data.allElements.sort(genericSort(method, sortingData.order));
            })

            return clone;
        }
    }
});

// all Facets that are listed here are open, others are closed on default
export const defaultOpenStateAtom = atom({
    key: "defaultOpenStateAtomKey",
    default: [FACET.YEAR, FACET.TOPIC, FACET.AUTHOR, FACET.COUNTRY, FACET.AFFILIATION],
});

export const facetSortingStateAtomFamily = atomFamily({
    key: "facetSortingStateAtomFamily-Key",
    default: (id) => [
        {
            facet: FACET.YEAR,
            azSorting: {method: 'az', order: 'desc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'az'
        },
        {
            facet: FACET.TOPIC,
            azSorting: {method: 'az', order: 'asc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'amount'
        },
        {
            facet: FACET.COUNTRY,
            azSorting: {method: 'az', order: 'asc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'amount'
        },
        {
            facet: FACET.AFFILIATION,
            azSorting: {method: 'az', order: 'asc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'amount'
        },
        {
            facet: FACET.AUTHOR,
            azSorting: {method: 'az', order: 'asc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'amount'
        },
        {
            facet: FACET.TYPE,
            azSorting: {method: 'az', order: 'asc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'amount'
        },
        {
            facet: FACET.VENUE,
            azSorting: {method: 'az', order: 'asc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'amount'
        },
        {
            facet: FACET.PREFIX,
            azSorting: {method: 'az', order: 'asc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'amount'
        },
    ],
});

export const previewAtom = atom({
    key: "previewKey",
    default: [],
});

export const leftNavigationFacetsAtom = atom({
    key: "leftNavigationFacets-Key",
    default: createLeftNavigationFacets,
});

export const poiAtomFamily = atomFamily({
    key: "poiAtomFamily-Key",
    default: [],
});

// we filter for only active. We need this for filtersets and for updating inside PointsOfInterest.js.
export const activePoiSelectorFamily = selectorFamily({
    key: "activePoiSelectorFamily-Key",
    get: (id) => ({get}) => {
        const pois = get(poiAtomFamily(id));
        return pois.filter((d) => d.active === true);
    },
});


export const poiExternalDataSelectorFamily = selectorFamily({
    key: "poiExternalDataSelectorFamily-Key",
    get: (id) => ({get}) => {
        const rawPois = get(poiAtomFamily(id));
        const clone = _.cloneDeep(rawPois)
        const newData =  get(waitForNone(clone.map(d => d.data = poiDataSelectorFamily(d.name))));
        return newData;
    }
});


export const poiWithDataSelectorFamily = selectorFamily({
    key: "poiWithDataSelector-Key",
    get: (id) => async ({get}) => {
        const rawPois = get(poiAtomFamily(id));
        const clone = _.cloneDeep(rawPois)
        if (rawPois.length > 0) {
            const newData = get(poiExternalDataSelectorFamily(id)); // await get(waitForAll(clone.map(d => d.data = poiDataSelectorFamily(d.name))));
            // const newData = await Promise.all(clone.map((d) => poiDataSelectorFamily(d.name)));
            if (newData[newData.length - 1].state === 'loading') {
                clone.map((d) => {
                    return d.data = [];
                });
            } else {
                clone.map((d, i) => {
                    return d.data = newData[i].contents;
                });
            }
            // console.log(newData);
        }
        return clone;
    }
});


export const sliderMinMaxValuesSelectorFamily = selectorFamily({
    key: "sliderMinMaxValuesSelectorKey",
    get: (id) => async ({get}) => {
        const response = await get(preProcessedRawDataSelector);
        const len = response['Years'].length;
        let values;
        if (len === 0) {
            values = [2020, 2020]
        } else if (len === 1) {
            values = [Number(response['Years'][0].name), Number(response['Years'][0].name)]
        } else if (len > 1) {
            // first and last element refer to min and max year.
            values = [Number(response['Years'][len - 1].name), Number(response['Years'][0].name)]
        }
        // first and last element refer to min and max year.
        return values;
        // }
    }
});

export const sliderValuesSelectorFamily = selectorFamily({
    key: "sliderValuesSelectorFamily-Key",
    get: (id) => async ({get}) => {
        const response = await get(leftNavigationFacetsData);
        const len = response['Years'].length;
        let values;
        if (len === 0) {
            values = [2020, 2020]
        } else if (len === 1) {
            values = [Number(response['Years'][0].name), Number(response['Years'][0].name)]
        } else if (len > 1) {
            // first and last element refer to min and max year.
            values = [Number(response['Years'][len - 1].name), Number(response['Years'][0].name)]
        }
        return values;
    },
});

// refers to min max of whole dataset loaded
export const sliderMinMaxValuesAtomFamily = atomFamily({
    key: "sliderMinMaxValuesKey",
    default: (id) => sliderMinMaxValuesSelectorFamily(id)
});

// refers to current filtered data min max initially, than it changes by using the filters.
export const sliderValuesAtomFamily = atomFamily({
    key: "sliderValuesAtomFamily-Key",
    default: (id) => sliderMinMaxValuesSelectorFamily(id) // sliderMinMaxValuesSelectorFamily(id)// sliderValuesSelectorFamily(id),
});

export const isSliderActiveSelectorFamily = selectorFamily({
    key: "isSliderActiveKey",
    get: (id) => ({get}) => {
        const minMax = get(sliderMinMaxValuesAtomFamily(id));
        const sliderVals = get(sliderValuesAtomFamily(id));
        const facetFilters = get(facetFiltersAtomFamily(id));
        const facetFilterSet = !facetFilters.find((d) => d.facet === 'Years');
        const sliderMoved = (minMax[0] !== sliderVals[0]) || (minMax[1] !== sliderVals[1]);
        // if there are differences the slider is active.
        return facetFilterSet && sliderMoved
    }
});

// goes to true when user manually changes slider.
export const manualSliderControlAtomFamily = atomFamily({
    key: "manualSliderControlAtomFamily-Key",
    default: (id) => false
});


const applyFilter = (data, filter, filterPOI, sliderValues) => {
    // apply poi filter
    filterPOI.forEach((poi) => {
        if(poi.data.length > 0){
            data = _applyFilterPOI(data, poi);
        }
    });
    const minYear = sliderValues[0];
    const maxYear = sliderValues[1];

    if (data !== null && data !== undefined && data.length > 0) {
        data = data.filter((d) => {
            return d.year <= maxYear && d.year >= minYear;
        });
    }


    // apply facet filter
    for (const facet in filter) {
        if (!filter.hasOwnProperty(facet)) continue;
        data = _applyFilterFacet(data, filter[facet].facet, filter[facet].values);
    }
    return data;
}


const _applyFilterFacet = (data, facet, values) => {
    if (values.length === 0) return data;
    return data.filter((entry) => {
        let amount = 0;
        FACET.eachFacet(facet, entry, (id) => {
            if (values.indexOf(id) !== -1) amount++;
        });
        return amount === values.length;
    });

}


const _applyFilterPOI = (data, poi) => {
    // no data? return!
    if (!poi.data) return data;
    // if we have data we apply the poi filters.
    return data.filter((entry) => poi.data.find((e) => e === DATA.Key(entry)));
}