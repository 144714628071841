import {atomFamily, selectorFamily} from "recoil";
import {
    amountRawDataSelector
} from "../../../../../../dataProvider/vissights/search/search";
import {
    visualizationPinAtomFamily
} from "../../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import {activeFacetAtomFamily} from "../../../../overview/visualizations/state";
import {preProcessedFilteredSearchDataSelector} from "../../../../../../dataProvider/vissights/search/filters/filters";
import {calculateSignalStrengthOld, genericSort, recursiveDeepClone} from "../../../../../../utility";

export const bubbleChartDataAtomFamily = atomFamily({
    key: "bubbleChartDataAtomFamily-Key",
    default: selectorFamily({
        key: 'bubbleChartDataAtomFamily/Default',
        get: id => () => {
            return bubbleChartDataSelectorFamily(id);
        },
    }),
});


export const bubbleChartDataPrevAtomFamily = atomFamily({
    key: "bubbleChartDataPrevAtomFamily-Key",
    default: selectorFamily({
        key: 'bubbleChartDataPrevAtomFamily/Default',
        get: id =>  ({get}) => {
            return bubbleChartDataSelectorFamily(id);
        },
    }),
});



export const bubbleChartDataSelectorFamily = selectorFamily({
    key: 'bubbleChartDataSelectorFamily-Key',
    get: id => ({get}) => {
        const dataModel = get(preProcessedFilteredSearchDataSelector);
        const searchAnswerLength = get(amountRawDataSelector);
        // const normalization = get(normalizeAtomFamily(id));
        const facet = get(activeFacetAtomFamily(id));
        const pinId = get(visualizationPinAtomFamily(id));
        const data = recursiveDeepClone(dataModel[facet]);


        // sort the data. We want the most relevant which is always the modst amount of something... we could also make it part of switch case and sort by anything else
        data.sort(genericSort('amount', 'desc'));

        // slice it
        let dataFiltered = data.slice(0, 60);
        // add lookup for data extending
        dataFiltered.lookup = data.lookup;

        // if a user clicks somewhere the data gets extended by data concerning the pinID.
        if (pinId !== '-1') {
            dataFiltered = extendData(dataFiltered, pinId);
        }

        // remove lookup to keep payloads small
        dataFiltered.lookup = {};

        // const totalCurrentAmount = dataFiltered.reduce((accum, item) => accum + item.amount, 0);
        // TODO: we must debate which signal algorithms are best.
        // data.map((d) => d.signal = this.calculateSignalStrengthWithAverageOfAllAmounts(d, totalCurrentAmount));
        // data.map((d) => d.signal = this.calculateSignalStrengthWithTotalAmount(d, totalCurrentAmount));

        dataFiltered.map((d) => d.signal = calculateSignalStrengthOld(d, searchAnswerLength));

        return dataFiltered

    }
});

/****** Extend dataset like in the old Bubble chart. We moved it to the data provider
 * ---> if we have a pin we display the links
 * ******/
const extendData = (data, id) => {
    const lookup = data.lookup;

    // check if pin is set and the id is inside the lookup
    if (id === '-1' || !lookup.hasOwnProperty(id)) return data;

    // add nodes, that are linked to node with id
    const extended = data.slice(); // copy
    const links = lookup[id].links;
    for (const link in links) {
        if (links.hasOwnProperty(link) && !extended.some((d) => {
            return d.id === link;
        })) {
            extended.push(dataEntry(lookup[link]));
        }
    }
    // extended.sort(this.sorter);
    return extended;
}

const dataEntry = (f) => {
    return {
        id: f.id,
        name: f.name,
        links: f.links,
        amount: f.amount,
    }
}
