
export function isoString(): string {
  return '[\n' +
    ' {\n' +
    '   "name": "Afghanistan",\n' +
    '   "iso": "AF"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Ãland Islands",\n' +
    '   "iso": "AX"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Albania",\n' +
    '   "iso": "AL"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Algeria",\n' +
    '   "iso": "DZ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "American Samoa",\n' +
    '   "iso": "AS"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Andorra",\n' +
    '   "iso": "AD"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Angola",\n' +
    '   "iso": "AO"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Anguilla",\n' +
    '   "iso": "AI"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Antarctica",\n' +
    '   "iso": "AQ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Antigua and Barbuda",\n' +
    '   "iso": "AG"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Argentina",\n' +
    '   "iso": "AR"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Armenia",\n' +
    '   "iso": "AM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Aruba",\n' +
    '   "iso": "AW"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Australia",\n' +
    '   "iso": "AU"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Austria",\n' +
    '   "iso": "AT"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Azerbaijan",\n' +
    '   "iso": "AZ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "The Bahamas",\n' +
    '   "iso": "BS"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Bahrain",\n' +
    '   "iso": "BH"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Bangladesh",\n' +
    '   "iso": "BD"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Barbados",\n' +
    '   "iso": "BB"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Belarus",\n' +
    '   "iso": "BY"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Belgium",\n' +
    '   "iso": "BE"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Belize",\n' +
    '   "iso": "BZ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Benin",\n' +
    '   "iso": "BJ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Bermuda",\n' +
    '   "iso": "BM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Bhutan",\n' +
    '   "iso": "BT"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Bolivia",\n' +
    '   "iso": "BO"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Bonaire, Sint Eustatius and Saba",\n' +
    '   "iso": "BQ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Bosnia and Herzegovina",\n' +
    '   "iso": "BA"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Botswana",\n' +
    '   "iso": "BW"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Bouvet Island",\n' +
    '   "iso": "BV"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Brazil",\n' +
    '   "iso": "BR"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "British Indian Ocean Territory",\n' +
    '   "iso": "IO"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Brunei",\n' +
    '   "iso": "BN"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Bulgaria",\n' +
    '   "iso": "BG"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Burkina Faso",\n' +
    '   "iso": "BF"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Burundi",\n' +
    '   "iso": "BI"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Cambodia",\n' +
    '   "iso": "KH"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Cameroon",\n' +
    '   "iso": "CM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Canada",\n' +
    '   "iso": "CA"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Cape Verde",\n' +
    '   "iso": "CV"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Cayman Islands",\n' +
    '   "iso": "KY"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Central African Republic",\n' +
    '   "iso": "CF"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Chad",\n' +
    '   "iso": "TD"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Chile",\n' +
    '   "iso": "CL"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "China",\n' +
    '   "iso": "CN"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Christmas Island",\n' +
    '   "iso": "CX"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Cocos (Keeling) Islands",\n' +
    '   "iso": "CC"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Colombia",\n' +
    '   "iso": "CO"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Comoros",\n' +
    '   "iso": "KM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Republic of the Congo",\n' +
    '   "iso": "CG"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Democratic Republic of the Congo",\n' +
    '   "iso": "CD"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Cook Islands",\n' +
    '   "iso": "CK"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Costa Rica",\n' +
    '   "iso": "CR"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Ivory Coast",\n' +
    '   "iso": "CI"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Croatia",\n' +
    '   "iso": "HR"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Cuba",\n' +
    '   "iso": "CU"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "CuraÃ§ao",\n' +
    '   "iso": "CW"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Cyprus",\n' +
    '   "iso": "CY"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Czech Republic",\n' +
    '   "iso": "CZ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Denmark",\n' +
    '   "iso": "DK"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Djibouti",\n' +
    '   "iso": "DJ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Dominica",\n' +
    '   "iso": "DM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Dominican Republic",\n' +
    '   "iso": "DO"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Ecuador",\n' +
    '   "iso": "EC"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Egypt",\n' +
    '   "iso": "EG"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "El Salvador",\n' +
    '   "iso": "SV"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Equatorial Guinea",\n' +
    '   "iso": "GQ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Eritrea",\n' +
    '   "iso": "ER"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Estonia",\n' +
    '   "iso": "EE"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Ethiopia",\n' +
    '   "iso": "ET"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Falkland Islands",\n' +
    '   "iso": "FK"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Faroe Islands",\n' +
    '   "iso": "FO"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Fiji",\n' +
    '   "iso": "FJ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Finland",\n' +
    '   "iso": "FI"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "France",\n' +
    '   "iso": "FR"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "French Guiana",\n' +
    '   "iso": "GF"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "French Polynesia",\n' +
    '   "iso": "PF"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "French Southern and Antarctic Lands",\n' +
    '   "iso": "TF"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Gabon",\n' +
    '   "iso": "GA"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Gambia",\n' +
    '   "iso": "GM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Georgia",\n' +
    '   "iso": "GE"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Germany",\n' +
    '   "iso": "DE"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Ghana",\n' +
    '   "iso": "GH"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Gibraltar",\n' +
    '   "iso": "GI"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Greece",\n' +
    '   "iso": "GR"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Greenland",\n' +
    '   "iso": "GL"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Grenada",\n' +
    '   "iso": "GD"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Guadeloupe",\n' +
    '   "iso": "GP"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Guam",\n' +
    '   "iso": "GU"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Guatemala",\n' +
    '   "iso": "GT"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Guernsey",\n' +
    '   "iso": "GG"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Guinea",\n' +
    '   "iso": "GN"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Guinea Bissau",\n' +
    '   "iso": "GW"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Guyana",\n' +
    '   "iso": "GY"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Haiti",\n' +
    '   "iso": "HT"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Heard Island and McDonald Islands",\n' +
    '   "iso": "HM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Holy See (Vatican City State)",\n' +
    '   "iso": "VA"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Honduras",\n' +
    '   "iso": "HN"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Hong Kong",\n' +
    '   "iso": "HK"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Hungary",\n' +
    '   "iso": "HU"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Iceland",\n' +
    '   "iso": "IS"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "India",\n' +
    '   "iso": "IN"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Indonesia",\n' +
    '   "iso": "ID"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Iran",\n' +
    '   "iso": "IR"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Iraq",\n' +
    '   "iso": "IQ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Ireland",\n' +
    '   "iso": "IE"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Isle of Man",\n' +
    '   "iso": "IM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Israel",\n' +
    '   "iso": "IL"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Italy",\n' +
    '   "iso": "IT"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Jamaica",\n' +
    '   "iso": "JM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Japan",\n' +
    '   "iso": "JP"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Jersey",\n' +
    '   "iso": "JE"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Jordan",\n' +
    '   "iso": "JO"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Kazakhstan",\n' +
    '   "iso": "KZ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Kenya",\n' +
    '   "iso": "KE"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Kiribati",\n' +
    '   "iso": "KI"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "North Korea",\n' +
    '   "iso": "KP"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "South Korea",\n' +
    '   "iso": "KR"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Kuwait",\n' +
    '   "iso": "KW"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Kyrgyzstan",\n' +
    '   "iso": "KG"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Laos",\n' +
    '   "iso": "LA"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Latvia",\n' +
    '   "iso": "LV"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Lebanon",\n' +
    '   "iso": "LB"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Lesotho",\n' +
    '   "iso": "LS"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Liberia",\n' +
    '   "iso": "LR"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Libya",\n' +
    '   "iso": "LY"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Liechtenstein",\n' +
    '   "iso": "LI"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Lithuania",\n' +
    '   "iso": "LT"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Luxembourg",\n' +
    '   "iso": "LU"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Macao",\n' +
    '   "iso": "MO"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Macedonia",\n' +
    '   "iso": "MK"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Madagascar",\n' +
    '   "iso": "MG"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Malawi",\n' +
    '   "iso": "MW"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Malaysia",\n' +
    '   "iso": "MY"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Maldives",\n' +
    '   "iso": "MV"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Mali",\n' +
    '   "iso": "ML"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Malta",\n' +
    '   "iso": "MT"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Marshall Islands",\n' +
    '   "iso": "MH"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Martinique",\n' +
    '   "iso": "MQ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Mauritania",\n' +
    '   "iso": "MR"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Mauritius",\n' +
    '   "iso": "MU"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Mayotte",\n' +
    '   "iso": "YT"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Mexico",\n' +
    '   "iso": "MX"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Micronesia, Federated States of",\n' +
    '   "iso": "FM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Moldova",\n' +
    '   "iso": "MD"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Monaco",\n' +
    '   "iso": "MC"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Mongolia",\n' +
    '   "iso": "MN"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Montenegro",\n' +
    '   "iso": "ME"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Montserrat",\n' +
    '   "iso": "MS"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Morocco",\n' +
    '   "iso": "MA"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Mozambique",\n' +
    '   "iso": "MZ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Myanmar",\n' +
    '   "iso": "MM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Namibia",\n' +
    '   "iso": "NA"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Nauru",\n' +
    '   "iso": "NR"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Nepal",\n' +
    '   "iso": "NP"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Netherlands",\n' +
    '   "iso": "NL"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "New Caledonia",\n' +
    '   "iso": "NC"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "New Zealand",\n' +
    '   "iso": "NZ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Nicaragua",\n' +
    '   "iso": "NI"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Niger",\n' +
    '   "iso": "NE"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Nigeria",\n' +
    '   "iso": "NG"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Niue",\n' +
    '   "iso": "NU"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Norfolk Island",\n' +
    '   "iso": "NF"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Northern Mariana Islands",\n' +
    '   "iso": "MP"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Norway",\n' +
    '   "iso": "NO"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Oman",\n' +
    '   "iso": "OM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Pakistan",\n' +
    '   "iso": "PK"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Palau",\n' +
    '   "iso": "PW"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Palestine, State of",\n' +
    '   "iso": "PS"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Panama",\n' +
    '   "iso": "PA"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Papua New Guinea",\n' +
    '   "iso": "PG"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Paraguay",\n' +
    '   "iso": "PY"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Peru",\n' +
    '   "iso": "PE"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Philippines",\n' +
    '   "iso": "PH"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Pitcairn",\n' +
    '   "iso": "PN"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Poland",\n' +
    '   "iso": "PL"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Portugal",\n' +
    '   "iso": "PT"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Puerto Rico",\n' +
    '   "iso": "PR"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Qatar",\n' +
    '   "iso": "QA"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "RÃ©union",\n' +
    '   "iso": "RE"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Romania",\n' +
    '   "iso": "RO"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Russia",\n' +
    '   "iso": "RU"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Rwanda",\n' +
    '   "iso": "RW"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Saint BarthÃ©lemy",\n' +
    '   "iso": "BL"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Saint Helena, Ascension and Tristan da Cunha",\n' +
    '   "iso": "SH"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Saint Kitts and Nevis",\n' +
    '   "iso": "KN"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Saint Lucia",\n' +
    '   "iso": "LC"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Saint Martin (French part)",\n' +
    '   "iso": "MF"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Saint Pierre and Miquelon",\n' +
    '   "iso": "PM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Saint Vincent and the Grenadines",\n' +
    '   "iso": "VC"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Samoa",\n' +
    '   "iso": "WS"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "San Marino",\n' +
    '   "iso": "SM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Sao Tome and Principe",\n' +
    '   "iso": "ST"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Saudi Arabia",\n' +
    '   "iso": "SA"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Senegal",\n' +
    '   "iso": "SN"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Republic of Serbia",\n' +
    '   "iso": "RS"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Seychelles",\n' +
    '   "iso": "SC"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Sierra Leone",\n' +
    '   "iso": "SL"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Singapore",\n' +
    '   "iso": "SG"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Sint Maarten (Dutch part)",\n' +
    '   "iso": "SX"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Slovakia",\n' +
    '   "iso": "SK"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Slovenia",\n' +
    '   "iso": "SI"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Solomon Islands",\n' +
    '   "iso": "SB"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Somaliland",\n' +
    '   "iso": "SO"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "South Africa",\n' +
    '   "iso": "ZA"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "South Georgia and the South Sandwich Islands",\n' +
    '   "iso": "GS"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "South Sudan",\n' +
    '   "iso": "SS"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Spain",\n' +
    '   "iso": "ES"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Sri Lanka",\n' +
    '   "iso": "LK"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Sudan",\n' +
    '   "iso": "SD"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Suriname",\n' +
    '   "iso": "SR"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Svalbard and Jan Mayen",\n' +
    '   "iso": "SJ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Swaziland",\n' +
    '   "iso": "SZ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Sweden",\n' +
    '   "iso": "SE"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Switzerland",\n' +
    '   "iso": "CH"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Syria",\n' +
    '   "iso": "SY"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Taiwan",\n' +
    '   "iso": "TW"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Tajikistan",\n' +
    '   "iso": "TJ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "United Republic of Tanzania",\n' +
    '   "iso": "TZ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Thailand",\n' +
    '   "iso": "TH"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "East Timor",\n' +
    '   "iso": "TL"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Togo",\n' +
    '   "iso": "TG"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Tokelau",\n' +
    '   "iso": "TK"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Tonga",\n' +
    '   "iso": "TO"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Trinidad and Tobago",\n' +
    '   "iso": "TT"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Tunisia",\n' +
    '   "iso": "TN"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Turkey",\n' +
    '   "iso": "TR"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Turkmenistan",\n' +
    '   "iso": "TM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Turks and Caicos Islands",\n' +
    '   "iso": "TC"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Tuvalu",\n' +
    '   "iso": "TV"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Uganda",\n' +
    '   "iso": "UG"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Ukraine",\n' +
    '   "iso": "UA"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "United Arab Emirates",\n' +
    '   "iso": "AE"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "United Kingdom",\n' +
    '   "iso": "GB"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "USA",\n' +
    '   "iso": "USA"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "United States Minor Outlying Islands",\n' +
    '   "iso": "UM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Uruguay",\n' +
    '   "iso": "UY"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Uzbekistan",\n' +
    '   "iso": "UZ"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Vanuatu",\n' +
    '   "iso": "VU"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Venezuela",\n' +
    '   "iso": "VE"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Vietnam",\n' +
    '   "iso": "VN"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Virgin Islands, British",\n' +
    '   "iso": "VG"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Virgin Islands, U.S.",\n' +
    '   "iso": "VI"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Wallis and Futuna",\n' +
    '   "iso": "WF"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Western Sahara",\n' +
    '   "iso": "EH"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Yemen",\n' +
    '   "iso": "YE"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Zambia",\n' +
    '   "iso": "ZM"\n' +
    ' },\n' +
    ' {\n' +
    '   "name": "Zimbabwe",\n' +
    '   "iso": "ZW"\n' +
    ' }\n' +
    ']'
}
