import IconWordCloud from "../components/Misc/icons/visualizations/overview/wordcloud/iconWordcloud";
import IconTemporalCloud from "../components/Misc/icons/visualizations/overview/temporalcloud/temporalcloud";
import TemporalCloudContainer
    from "../components/VisualizationModules/overview/visualizations/TemporalCloud/TemporalCloudContainer";
import WordCloudContainer
    from "../components/VisualizationModules/overview/visualizations/Wordcloud/WordcloudContainer";
import IconPoiChart from "../components/Misc/icons/visualizations/zoomAndFilter/poiChart/poiChart";
import PoiChartContainer
    from "../components/VisualizationModules/zoomAndFilter/visualizations/PoiChart/PoiChartContainer";
import IconTemporalOverviewChart
    from "../components/Misc/icons/visualizations/zoomAndFilter/temporalOverviewChart/temporalOverviewChart";
import TemporalOverviewChartContainer
    from "../components/VisualizationModules/zoomAndFilter/visualizations/TemporalOverviewChart/TemporalOverviewChartContainer";
import IconPublicationListChart
    from "../components/Misc/icons/visualizations/zoomAndFilter/publicationListChart/publicationListChart";
import PublicationListContainer
    from "../components/VisualizationModules/zoomAndFilter/visualizations/PublicationList/PublicationListContainer";
import IconTopicLine from "../components/Misc/icons/visualizations/overview/topicline/iconTopicLine";
import LineChartContainer
    from "../components/VisualizationModules/overview/visualizations/LineChart/LineChartContainer";
import uuid from "react-uuid";
import IconSlicedChart from "../components/Misc/icons/visualizations/zoomAndFilter/slicedChart/slicedChart";
import SlicedChartContainer
    from "../components/VisualizationModules/zoomAndFilter/visualizations/SlicedChart/SlicedChartContainer";
import FACET from "../dataProvider/vissights/utility/facet-extraction-utility";
import IconGeoChart from "../components/Misc/icons/visualizations/zoomAndFilter/geoChart/geoChart";
import GeoChartContainer
    from "../components/VisualizationModules/zoomAndFilter/visualizations/GeoChart/GeoChartContainer";
import IconFacetLineChart from "../components/Misc/icons/visualizations/zoomAndFilter/facetLineChart/facetLineChart";
import FacetLineChartContainer
    from "../components/VisualizationModules/zoomAndFilter/visualizations/FacetLineChart/FacetLineChartContainer";
import IconBarChart from "../components/Misc/icons/visualizations/zoomAndFilter/barChart/barChart";
import BarPieDonutChartContainer
    from "../components/VisualizationModules/zoomAndFilter/visualizations/BarPieDonutChart/BarPieDonutChartContainer";
import IconFacetRiverChart from "../components/Misc/icons/visualizations/zoomAndFilter/facetRiverChart/facetRiverChart";
import TemporalSpreadChartContainer
    from "../components/VisualizationModules/zoomAndFilter/visualizations/TemporalSpreadChart/TemporalSpreadChartContainer";
import IconBubbleChart from "../components/Misc/icons/visualizations/zoomAndFilter/bubbleChart/bubbleChart";
import BubbleChartContainer
    from "../components/VisualizationModules/zoomAndFilter/visualizations/BubbleChart/BubbleChartContainer";
import IconParallelCoorcinatesChart
    from "../components/Misc/icons/visualizations/zoomAndFilter/parallelCoordinatesChart/geoChart";
import ParallelCoordinatesChartContainer
    from "../components/VisualizationModules/zoomAndFilter/visualizations/ParallelCoordinatesChart/ParallelCoordinatesChartContainer";
import OverviewParallelCoordinatesChartContainer
    from "../components/VisualizationModules/overview/visualizations/OverviewParallelCoordinatesChart/OverviewParallelCoordinatesChartContainer";
import BagOfWordsChartContainer
    from "../components/VisualizationModules/zoomAndFilter/visualizations/BagOfWordsChart/BagOfWordsChartContainer";
import FlowChartGeneratorContainer
    from "../components/VisualizationModules/zoomAndFilter/visualizations/FlowChartGenerator/FlowChartGeneratorContainer";
import IconPrismaGenerator from "../components/Misc/icons/visualizations/zoomAndFilter/prismaGenerator/prismaGenerator";

// Overview Visualizations

const TemporalCloud = {
    visType: 'Temporal Cloud',
    name: 'TemporalCloud',
    module: 'overview',
    dashboardConfig:
        {minW: 60, minH: 30, standardW: 20, standardH: 60, smallW: 10, smallH: 12}
    ,
    iconComponent: <IconTemporalCloud/>,
    visComponent: <TemporalCloudContainer/>,
    topControls: [{
        datasets: ['topicOverview'],
        // refers to the initially active dataset
        activeDataSet: 'topicOverview',
        // just leave empty if we dont use aggregations on this kind of visual layout.
        aggregations: ['growth', 'max', 'total'],
        // this refers also to the initial aggregation
        activeAggregation: 'growth'
    }],
    usesRightNav: true
}

const WordCloud = {
    visType: 'Word Cloud',
    name: 'WordCloud',
    module: 'overview',
    iconComponent: <IconWordCloud/>,
    visComponent: <WordCloudContainer/>,
    dashboardConfig:
        {minW: 60, minH: 30, standardW: 15, standardH: 15, smallW: 10, smallH: 10, largeW: 20, largeH: 40}
    ,
    topControls: [{
        datasets: ['searchHistory', 'topicOverview', 'monitoring'],
        // refers to the initially active dataset
        activeDataSet: 'searchHistory',
        // just leave empty if we dont use aggregations on this kind of visual layout.
        aggregations: [],
        activeAggregation: ''
    }],
    // refers to the right-nav inside the visualization container
    usesRightNav: false
}

const TopicLineChart = {
    visType: 'Topic Line Chart',
    name: 'TopicLineChart',
    module: 'overview',
    dashboardConfig:
        {minW: 60, minH: 30, standardW: 30, standardH: 30, smallW: 20, smallH: 15}
    ,
    iconComponent: <IconTopicLine/>,
    visComponent: <LineChartContainer/>,
    topControls: [{
        datasets: ['topicOverview'],
        // refers to the initially active dataset
        activeDataSet: 'topicOverview',
        // just leave empty if we dont use aggregations on this kind of visual layout.
        aggregations: ['growth', 'max', 'total'],
        // this refers also to the initial aggregation
        activeAggregation: 'growth'
    }],
    usesRightNav: true
}

const OverviewParallelCoordinates = {
    visType: 'Overview Parallel Coordinates',
    name: 'OverviewParallelCoordinates',
    module: 'overview',
    dashboardConfig:
        {minW: 15, minH: 15, initialNumber: 0, standardW: 15, standardH: 15, largeW: 20, largeH: 20}
    ,
    iconComponent: <IconParallelCoorcinatesChart/>,
    visComponent: <OverviewParallelCoordinatesChartContainer/>,
    minDataModelsRequirement: [],
    topControls: [{
        datasets: [],
        // refers to the initially active dataset
        activeDataSet: '',
        // just leave empty if we dont use aggregations on this kind of visual layout.
        aggregations: [],
        activeAggregation: ''
    }],
    // refers to the right-nav inside the visualization container
    usesRightNav: true
}

export const configOverview = [TemporalCloud, WordCloud, TopicLineChart, OverviewParallelCoordinates];

const PublicationList = {
    visType: 'Publication List',
    name: 'PublicationList',
    module: 'zoomAndFilter',
    facetRequirements: [FACET.TOPIC],
    dashboardConfig:
        {minW: 60, minH: 30}
    ,
    iconComponent: <IconPublicationListChart/>,
    visComponent: <PublicationListContainer/>,
    minDataModelsRequirement: [],
    topControls: [{
        datasets: [],
        // refers to the initially active dataset
        activeDataSet: '',
        // just leave empty if we dont use aggregations on this kind of visual layout.
        aggregations: [],
        activeAggregation: ''
    }],
    // refers to the right-nav inside the visualization container
    usesRightNav: false
}

const TemporalOverviewChart = {
    visType: 'Temporal Overview',
    name: 'TemporalOverviewChart',
    module: 'zoomAndFilter',
    dashboardConfig:
        {minW: 60, minH: 30}
    ,
    iconComponent: <IconTemporalOverviewChart/>,
    visComponent: <TemporalOverviewChartContainer/>,
    minDataModelsRequirement: ['year'],
    topControls: [{
        datasets: [],
        // refers to the initially active dataset
        activeDataSet: '',
        // just leave empty if we dont use aggregations on this kind of visual layout.
        aggregations: [],
        activeAggregation: ''
    }],
    // refers to the right-nav inside the visualization container
    usesRightNav: false
}

const FacetLineChart = {
    visType: 'Facet Line Chart',
    name: 'FacetLineChart',
    module: 'zoomAndFilter',
    dashboardConfig:
        {minW: 60, minH: 30}
    ,
    iconComponent: <IconFacetLineChart/>,
    visComponent: <FacetLineChartContainer/>,
    minDataModelsRequirement: [],
    topControls: [{
        facets: FACET.types,
        initialActiveFacet: FACET.TOPIC,
        datasets: [],
        // refers to the initially active dataset
        activeDataSet: '',
        aggregations: ['growth', 'max', 'total'],
        activeAggregation: 'growth'
    }],
    // refers to the right-nav inside the visualization container
    usesRightNav: true
}

const SlicedChart = {
    visType: 'Sliced Chart',
    name: 'SlicedChart',
    module: 'zoomAndFilter',
    dashboardConfig:
        {minW: 60, minH: 30}
    ,
    iconComponent: <IconSlicedChart/>,
    visComponent: <SlicedChartContainer/>,
    minDataModelsRequirement: [],
    topControls: [{
        facets: FACET.types,
        initialActiveFacet: FACET.TOPIC,
        datasets: [],
        // refers to the initially active dataset
        activeDataSet: '',
        // just leave empty if we dont use aggregations on this kind of visual layout.
        aggregations: [],
        activeAggregation: ''
    }],
    // refers to the right-nav inside the visualization container
    usesRightNav: false
}

const BarChart = {
    visType: 'Bar Chart',
    name: 'BarChart',
    module: 'zoomAndFilter',
    dashboardConfig:
        {minW: 60, minH: 30}
    ,
    iconComponent: <IconBarChart/>,
    visComponent: <BarPieDonutChartContainer/>,
    minDataModelsRequirement: [],
    topControls: [{
        facets: FACET.types,
        initialActiveFacet: FACET.TOPIC,
        datasets: [],
        // refers to the initially active dataset
        activeDataSet: '',
        aggregations: [],
        activeAggregation: '',
        visTypes: ['Bar', 'Pie', 'Donut']
    }],
    // refers to the right-nav inside the visualization container
    usesRightNav: true
}

const TemporalSpreadChart = {
    visType: 'Temporal Spread Chart',
    name: 'TemporalSpreadChart',
    module: 'zoomAndFilter',
    dashboardConfig:
        {minW: 60, minH: 30}
    ,
    iconComponent: <IconFacetRiverChart/>,
    visComponent: <TemporalSpreadChartContainer/>,
    minDataModelsRequirement: [],
    topControls: [{
        facets: FACET.types,
        initialActiveFacet: FACET.TOPIC,
        datasets: [],
        // refers to the initially active dataset
        activeDataSet: '',
        // just leave empty if we dont use aggregations on this kind of visual layout.
        aggregations: [],
        activeAggregation: ''
    }],
    // refers to the right-nav inside the visualization container
    usesRightNav: true
}

const BubbleChart = {
    visType: 'Bubble Chart',
    name: 'BubbleChart',
    module: 'zoomAndFilter',
    dashboardConfig:
        {minW: 60, minH: 30}
    ,
    iconComponent: <IconBubbleChart/>,
    visComponent: <BubbleChartContainer/>,
    minDataModelsRequirement: [],
    topControls: [{
        facets: FACET.types,
        initialActiveFacet: FACET.AUTHOR,
        datasets: [],
        // refers to the initially active dataset
        activeDataSet: '',
        // just leave empty if we dont use aggregations on this kind of visual layout.
        aggregations: ['Quantity', 'Normalization'],
        activeAggregation: 'Quantity'
    }],
    // refers to the right-nav inside the visualization container
    usesRightNav: false
}

const GeographicalChart = {
    visType: 'Geographical Chart',
    name: 'GeographicalChart',
    module: 'zoomAndFilter',
    facetRequirements: [FACET.YEAR, FACET.COUNTRY],
    dashboardConfig:
        {minW: 60, minH: 30}
    ,
    iconComponent: <IconGeoChart/>,
    visComponent: <GeoChartContainer/>,
    minDataModelsRequirement: [],
    topControls: [{
        // facets: ,
        datasets: [],
        // refers to the initially active dataset
        activeDataSet: '',
        // just leave empty if we dont use aggregations on this kind of visual layout.
        aggregations: [],
        activeAggregation: ''
    }],
    // refers to the right-nav inside the visualization container
    usesRightNav: false
}

const PoiChart = {
    visType: 'Poi Chart',
    name: 'PoiChart',
    module: 'zoomAndFilter',
    dashboardConfig:
        {minW: 60, minH: 30}
    ,
    iconComponent: <IconPoiChart/>,
    visComponent: <PoiChartContainer/>,
    minDataModelsRequirement: [],
    topControls: [{
        datasets: [],
        // refers to the initially active dataset
        activeDataSet: '',
        // just leave empty if we dont use aggregations on this kind of visual layout.
        aggregations: [],
        activeAggregation: ''
    }],
    // refers to the right-nav inside the visualization container
    usesRightNav: false
}

const BagOfWordsChart = {
    visType: 'Bag of Words Chart',
    name: 'BagOfWordsChart',
    module: 'zoomAndFilter',
    dashboardConfig:
        {minW: 60, minH: 30}
    ,
    iconComponent: <IconTopicLine/>,
    visComponent: <BagOfWordsChartContainer/>,
    minDataModelsRequirement: [],
    topControls: [{
        datasets: [],
        // refers to the initially active dataset
        activeDataSet: '',
        // just leave empty if we dont use aggregations on this kind of visual layout.
        aggregations: [],
        activeAggregation: ''
    }],
    // refers to the right-nav inside the visualization container
    usesRightNav: true
}

const ParallelCoordinates = {
    visType: 'Parallel Coordinates',
    name: 'ParallelCoordinates',
    module: 'zoomAndFilter',
    dashboardConfig:
        {minW: 60, minH: 30}
    ,
    iconComponent: <IconParallelCoorcinatesChart/>,
    visComponent: <ParallelCoordinatesChartContainer/>,
    minDataModelsRequirement: [],
    topControls: [{
        datasets: [],
        // refers to the initially active dataset
        activeDataSet: '',
        // just leave empty if we dont use aggregations on this kind of visual layout.
        aggregations: [],
        activeAggregation: ''
    }],
    // refers to the right-nav inside the visualization container
    usesRightNav: true
}




const FlowChartGenerator = {
    visType: 'Flow Chart Generator',
    name: 'FlowChartGenerator',
    module: 'zoomAndFilter',
    dashboardConfig:
        {minW: 40, minH: 30}
    ,
    iconComponent: <IconPrismaGenerator/>,
    visComponent: <FlowChartGeneratorContainer/>,
    minDataModelsRequirement: [],
    topControls: [{
        datasets: [],
        // refers to the initially active dataset
        activeDataSet: '',
        // just leave empty if we dont use aggregations on this kind of visual layout.
        aggregations: [],
        activeAggregation: ''
    }],
    // refers to the right-nav inside the visualization container
    usesRightNav: false
}

export const configZoomAndFilter = [PublicationList, TemporalOverviewChart, FacetLineChart, SlicedChart, BarChart, TemporalSpreadChart, BubbleChart, GeographicalChart, PoiChart, ParallelCoordinates]


/*
{
            id: uuid(),
            visType: "Temporal Cloud",
            visComponent: <TemporalCloudContainer/>,
            topControls: configOverview[0].topControls,
            usesRightNav: true,
            dashboardConfig: {minW: 60, minH: 30, standardW: 20, standardH: 60, smallW: 10, smallH: 12},
            module: 'overview'
        }
 */



// this is the currently used initial charts config.
export const initialChartsWholeDataModel =
    [{
        id: uuid(),
        visType: "Temporal Overview",
        visComponent: <TemporalOverviewChartContainer/>,
        topControls: configZoomAndFilter[0].topControls,
        usesRightNav: false,
        dashboardConfig: {minW: 60, minH: 30, standardW: 20, standardH: 60, smallW: 10, smallH: 12},
        module: 'zoomAndFilter'
    },
        {
            id: uuid(),
            visType: "Word Cloud",
            visComponent: <WordCloudContainer/>,
            topControls: configOverview[1].topControls,
            usesRightNav: false,
            dashboardConfig: {minW: 60, minH: 30, standardW: 20, standardH: 60, smallW: 10, smallH: 12},
            module: 'overview'
        }]


// this is the currently used initial charts config.
export const initialChartsTemporalDataModel =
    [{
        id: uuid(),
        visType: "Temporal Overview",
        visComponent: <TemporalOverviewChartContainer/>,
        topControls: configZoomAndFilter[0].topControls,
        usesRightNav: false,
        dashboardConfig: {minW: 60, minH: 30},
        module: 'zoomAndFilter'
    },
        {
            id: uuid(),
            visType: "Temporal Cloud",
            visComponent: <TemporalCloudContainer/>,
            topControls: configOverview[0].topControls,
            usesRightNav: true,
            dashboardConfig: {minW: 60, minH: 30},
            module: 'overview'
        }]

// this is the currently used initial charts config.
export const initialChartsSpatioTemporalDataModel =
    [{
        id: uuid(),
        visType: "Geographical Chart",
        visComponent: <GeoChartContainer/>,
        topControls: configZoomAndFilter[7].topControls,
        usesRightNav: false,
        dashboardConfig: {minW: 60, minH: 30},
        module: 'zoomAndFilter'
    },
        {
            id: uuid(),
            visType: "Temporal Cloud",
            visComponent: <TemporalCloudContainer/>,
            topControls: configOverview[0].topControls,
            usesRightNav: true,
            dashboardConfig: {minW: 60, minH: 30},
            module: 'overview'
        }]


// we merge both and export it.


export const availableVisualizationsOverview = configOverview;

export const availableVisualizationsZoomAndFilter = configZoomAndFilter;

export const availableVisualizationsWholeDataModel = [...configOverview, ...configZoomAndFilter];

export const availableVisualizationsSpatioTemporalDataModel = [...configOverview, GeographicalChart, TemporalOverviewChart];

export const availableVisualizationsTemporalDataModel = [...configOverview, TemporalOverviewChart];


/** GRAPHICAL SEARCH **/

// we define an amount that switches between only list on double click on poi. Or list + temporal overview
export const criticalAmount = 20;

export const injectedPublicationList = {
    id: uuid(),
    visType: configZoomAndFilter[0].visType,
    visComponent: configZoomAndFilter[0].visComponent,
    topControls: configZoomAndFilter[0].topControls,
    usesRightNav: configZoomAndFilter[0].usesRightNav,
    module: configZoomAndFilter[0].module,
    dashboardConfig: configZoomAndFilter[0].dashboardConfig,
    graphicalSearchInfos: undefined,
    useMultiSelectionData: undefined
}

export const injectedTemporalOverview = {
    id: uuid(),
    visType: configZoomAndFilter[1].visType,
    visComponent: configZoomAndFilter[1].visComponent,
    topControls: configZoomAndFilter[1].topControls,
    usesRightNav: configZoomAndFilter[1].usesRightNav,
    module: configZoomAndFilter[1].module,
    dashboardConfig: configZoomAndFilter[1].dashboardConfig,
    graphicalSearchInfos: undefined,
}

