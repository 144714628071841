import { useKeycloak } from "@react-keycloak/web";
import {Button} from "react-bootstrap";


const PrivateRoute = ({ children }) => {
    const { keycloak } = useKeycloak();

    const isLoggedIn = keycloak.authenticated;

    return isLoggedIn ? children :
        <div>
            <h1>Please login to continue.</h1>

            <Button variant="outline-success" size="lg"
           onClick={() => keycloak.login()}>
                Login
            </Button>

        </div>;
};

export default PrivateRoute;