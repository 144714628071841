import React from 'react';
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {Badge} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faX} from "@fortawesome/free-solid-svg-icons";
import {topicsAtom} from "../../../../dataProvider/vissights/overview/overview";
import {sliderValuesAtomFamily} from "../../LeftNavigation/LeftNavigationState";
import {useLocalAsGlobalSearchDataAtom} from "../../../../dataProvider/vissights/search/search";


function DataLinkingFilters(props) {
    const topics = useRecoilValue(topicsAtom);
    // stores the stuff we display here.
    const [useLocalAsGlobalSearchData, setUseLocalAsGlobalSearchData] = useRecoilState(useLocalAsGlobalSearchDataAtom);
    const resetSliderValues = useResetRecoilState(sliderValuesAtomFamily(props.filterSetId));

    const removeDataLinkingFilter = () => {
        setUseLocalAsGlobalSearchData(false);
        // resetSliderValues();
    }


    const LinkingFilters = () => {
        return (

                <>
                    <FontAwesomeIcon style={{marginRight: 2, marginTop: 4, marginLeft: 10, height: 15 , cursor: 'pointer'}}
                                     icon={faLink}

                    />
                    <Badge variant="primary" key="link-filter-badge"
                           style={{
                               marginRight: 3,
                               cursor: 'pointer'
                           }}>
                    <span key="link-filter-span" style={{marginRight: 5}}>#
                        Data Linking
                    </span>
                    </Badge>
                    <FontAwesomeIcon icon={faX} style={{height: 12, marginTop: 3, marginLeft: 2, cursor: "pointer"}}
                                     onClick={() => {
                                         removeDataLinkingFilter(); // pass the facet, the id of filter
                                     }}/>
                </>
            )

    }


    return (
        <LinkingFilters/>
    );
}

export default DataLinkingFilters;
