import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {atom, useRecoilState} from "recoil";
import {Button, Col, Form, Row} from "react-bootstrap";
import uuid from "react-uuid";
import * as _ from "lodash";
import {displayCreateNewMonitoringItemModalAtom} from "../state/WordCloudState";
import {monitoringDataAtom} from "../../../../../../dataProvider/vissights/overview/overview";
import keycloak from "../../../../../../dataProvider/vissights/authentication/keycloak";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

// call the fetchTopicOverview selector and store it as default value for the data atom
export const displayCreatePoiModalAtom = atom({
    key: "displayCreatePoiModalAtom-Key",
    default: false
});

function CreateNewMonitoringItemModal() {
    const [displayModal, setDisplayModal] = useRecoilState(displayCreateNewMonitoringItemModalAtom);
    const [itemName, setItemName] = useState('');
    const [monitoringItems, setMonitoringItems] = useRecoilState(monitoringDataAtom)
    const itemCreationRef = React.useRef(null);

    const hide = () => {
        setDisplayModal(false);
    }

    const addNewItem = () => {
        const userId = keycloak?.tokenParsed?.sub;
        const newUUID = uuid();
        const clone = _.cloneDeep(monitoringItems);
        clone.push({id: newUUID, text: itemName, amount: (Math.random() * 30) + 6});
        localStorage.setItem(userId, JSON.stringify([{monitoringItems: clone}]))
        setMonitoringItems(clone);
    }

    useEffect(() => {
        itemCreationRef.current.focus();
    }, [])

    const removeMonitoringItemById = (rId) => {
        const userId = keycloak?.tokenParsed?.sub;
        const clone = _.cloneDeep(monitoringItems);
        const newItems = clone.filter((d) => d.id !== rId);
        localStorage.setItem(userId, JSON.stringify([{monitoringItems: newItems}]));
        // const localStorageItems = localStorage.getItem(userId)
        setMonitoringItems(newItems);
    }


    return (
        <Modal show={displayModal} onHide={hide} className="modal-create-poi">
            <Modal.Body>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    addNewItem();
                    setDisplayModal(false);
                }}>
                    <div>
                        <input type="text" ref={itemCreationRef} style={{
                            width: 240,
                        }} onChange={(e) => setItemName(e.target.value)}/>

                        <Button
                            style={{
                                marginTop: 5,
                                marginLeft: '10%',
                            }}
                            onClick={() => {
                                addNewItem()
                                setDisplayModal(false)
                            }} variant="outline-primary">
                            Create Monitoring Item
                        </Button>
                    </div>
                </Form>
                <div style={{marginTop: 15}}>
                    {
                        monitoringItems.map((d) => {
                            return (
                                <Row key={"Row-" + d.id} style={{marginTop: 5}}>
                                    <Col key={"Col-text-" + d.id} xs="10"><b>{d.text}</b></Col>
                                    <Col key={"Col-remove-" + d.id} xs="2">
                                        <FontAwesomeIcon icon={faTrash} style={{cursor: "pointer"}}
                                                         onClick={() => removeMonitoringItemById(d.id)}
                                        />
                                    </Col>
                                </Row>
                            );
                        })
                    }
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default CreateNewMonitoringItemModal;