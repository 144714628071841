import React, {useEffect} from 'react'
import 'react-grid-layout/css/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col, Spinner} from "react-bootstrap";
import {useHistory} from 'react-router-dom'
import MainNavigation from "../GlobalNavigations/MainNavigation/MainNavigation";
import ErrorBoundary from "../Misc/ErrorBoundary/ErrorBoundary";
import DashboardLayout from "./DashboardLayout";
import {dbConfig} from "../../dataProvider/vissights/vissightsConfig";
import {recursiveDeepClone} from "../../utility";
import {useRecoilState, useSetRecoilState} from "recoil";
import {currentThemeAtom, themeOptionsAtom} from "../../styles/colors";
import {availableDatabases} from "../../dataProvider/vissights/overview/overview";
import {searchDatabase} from "../../dataProvider/vissights/search/search";
import {allowedVisualizationsAtom} from "../GlobalNavigations/RightNavigation/state/RightNavigationState";
import {sizeConfigAtom} from "../../config/sizings/sizings";

function DashboardWithConfig(props) {
    let history = useHistory();
    const setThemeOptions = useSetRecoilState(themeOptionsAtom);
    const setCurrentTheme = useSetRecoilState(currentThemeAtom);
    // we use the available dbs from the backend.
    const [availDbs, setAvailDbs] = useRecoilState(availableDatabases);
    const setDb = useSetRecoilState(searchDatabase);
    const setAllowedVisualizations = useSetRecoilState(allowedVisualizationsAtom);
    const setSizeConfig = useSetRecoilState(sizeConfigAtom);


    // we have a separate config that also stores the icons. We check which ones are available from config.json and only display them.
    const getCorrespondingDbFromString = (dbName) => {
        const returnValue = dbConfig.filter((d) => d.name === dbName);
        return returnValue[0];
    }

    // as the app is wrapped with the configHOC we can derive the config from config.js and map it to state
    useEffect(() => {
        // console.log(props.Sconfig)
        // initialize the config loaded from the config.json file which can be manipulated also in production build...
        if(props.loadedConfig !== undefined){
            //  set initial database
            setDb(getCorrespondingDbFromString(props.loadedConfig.db));
            const clone = recursiveDeepClone(availDbs);
            // ensure only the available dbs are chosen from the config.
            const avail = clone.filter((d) => props.loadedConfig.visibleDBs.includes(d.name))
            setAvailDbs(avail);
            // set theme
            setDb(getCorrespondingDbFromString(props.loadedConfig.db));
            setCurrentTheme(props.loadedConfig.initialTheme);
            setThemeOptions(props.loadedConfig.availableThemes);
            setAllowedVisualizations(props.loadedConfig.availableVisualizations)
            setSizeConfig(props.loadedConfig.sizeConfig)
        }
    }, [props.loadedConfig])


    return (
        <Container fluid style={{textAlign: "center"}}>
            <React.Suspense style={{opacity: 0.5}}
                            fallback={<Spinner
                                style={{
                                    marginTop: '5vh',
                                    width: 50,
                                    height: 50,
                                    textAlign: 'center',
                                    marginLeft: 'auto'
                                }}
                                animation="border"/>}>
                <Row className='mr-0 ml-0'>
                    <Col className='pl-0 pr-0'>
                        <MainNavigation/>
                    </Col>
                </Row>
            </React.Suspense>
            <ErrorBoundary history={history}>
                <div>
                    <React.Suspense
                        fallback={<Spinner
                            style={{
                                marginTop: '30vh',
                                width: 150,
                                height: 150,
                                textAlign: 'center',
                                marginLeft: 'auto'
                            }}
                            animation="border"
                        />
                        }>
                        <DashboardLayout/>
                    </React.Suspense>
                </div>
            </ErrorBoundary>
        </Container>
    )
}

export default DashboardWithConfig
