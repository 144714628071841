import {Button, Col, Row, Badge, Form} from "react-bootstrap";
import React, {useEffect} from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {} from "../state/sliced-chart-state";
import Dropdown from 'react-multilevel-dropdown';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faCog} from "@fortawesome/free-solid-svg-icons";
import {searchDatabase, searchTermAtom} from "../../../../../../dataProvider/vissights/search/search";
import {moduleToDisplayAtom} from "../../../../../Dashboards/DashboardLayout";
import domtoimage from "dom-to-image";
import uuid from "react-uuid";
import * as _ from "lodash";
import {singleVisualizationScreenshotsAtom} from "../../../../reporting/state/ReportingState";
import {displaySavedScreenshotModalAtom} from "../../../../../Misc/Modals/SavedScreenshot";
import {activeFacetAtomFamily} from "../../../../overview/visualizations/state";
import Switch from "react-switch";
import {
    maxRowsAtomFamily,
    normalizeAtomFamily
} from "../../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";

function SlicedChartTopControls(props) {
    const componentId = props.id;
    // infos we need for screenshot.
    const db = useRecoilValue(searchDatabase);
    const searchTerm = useRecoilValue(searchTermAtom)
    const module = useRecoilValue(moduleToDisplayAtom);
    const setDisplayScreenshotModal = useSetRecoilState(displaySavedScreenshotModalAtom);
    const [singleVisualizationScreenshots, setSingleVisualizationScreenshots] = useRecoilState(singleVisualizationScreenshotsAtom);
    const config = props.topControlConfig[0];
    const [activeFacet, setActiveFacet] = useRecoilState(activeFacetAtomFamily(componentId));
    const [normalization, setNormalization] = useRecoilState(normalizeAtomFamily(componentId));
    const [maxRows, setMaxRows] = useRecoilState(maxRowsAtomFamily(componentId));
    // initialize the active facet. It was checked in vis container component to be existent
    useEffect(()=> {
        setActiveFacet(config.initialActiveFacet)
    },[props.topControlConfig]);
    const createVisualizationScreenshot = () => {
        const node = document.getElementById(componentId);
        domtoimage.toPng(node)
            .then((dataUrl) => {
                const newUUID = uuid();
                // store the image, the module, and the current database
                const autoGeneratedText = 'The following chart was taken in the Zoom and Filter Module' +
                    'searching for' + searchTerm + ' in the database: ' + db.name + '. ' +
                    'It shows a temporal overview.';
                const metaDataObj = {id: newUUID, dataUrl, module, db: db.name, autoGeneratedText}
                const clone = _.cloneDeep(singleVisualizationScreenshots)
                clone.push({id: newUUID, data: metaDataObj});
                setSingleVisualizationScreenshots(clone);
            }).then(() => setDisplayScreenshotModal(true));
    }

    const changeFacetToDisplay = (newDataset) => {
        setActiveFacet(newDataset);
    }


    const ContainerTopControls = () => {
        let dataSets = config.facets;
        // only display datasets if we can choose between more than 1...
        dataSets = dataSets.length > 1 ? dataSets : []
        return (
            <Row>
                <Col xs={12}>
                    <div className="grid-item-container-top-control">
                        <div className="float-left" style={{ display: 'flex'}}>
                            <Dropdown position="right"
                                      title={<FontAwesomeIcon style={{width: 20, height: 20}} icon={faCog}/>}
                            >
                                <Dropdown.Item onClick={() => createVisualizationScreenshot()}>
                                    <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}}
                                                     icon={faCamera}/> Screenshot Chart
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <span style={{marginRight: 10}}>Normalization </span>
                                    <Switch onColor="#86d3ff"
                                                            offHandleColor="#2693e6"
                                                            onHandleColor="#2693e6"
                                                            handleDiameter={20}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            height={15}
                                                            width={40}
                                                            onChange={(checked) => {
                                                                setNormalization(checked);
                                                            }}
                                                            checked={normalization}/>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <span style={{marginRight: 10}}>Max Rows: </span>
                                    <input type='number' min='0' value={maxRows} max='30' onChange={(e) => setMaxRows(e.target.value)} style={{width: 35}}/>
                                </Dropdown.Item>
                            </Dropdown>

                            <div style={{height: 10}}>
                                <span style={{marginRight: 10}}>Rows: </span>
                                <input style={{width: 50, height: 20, marginTop: 10}} type='number' min='0' value={maxRows} max='30' onChange={(e) => setMaxRows(e.target.value)} />
                            </div>



                            <div style={{marginLeft: 15, marginTop: 6}}>
                                {dataSets.map((d) =>
                                    <Badge key={d} style={{marginRight: 5, cursor: 'pointer'}}
                                           variant={d === activeFacet ? "primary" : "light"}
                                           onClick={(e) => {
                                               changeFacetToDisplay(d);
                                           }}>
                                        {d}
                                    </Badge>
                                )}
                            </div>
                        </div>
                        <Button type="button" className="float-right" variant="light" value="Search" onClick={(e) => {
                            e.preventDefault();
                            props.deleteContainer(props.id);
                        }}>X</Button>
                    </div>
                </Col>
            </Row>
        );
    }
    return (
        <ContainerTopControls/>
    );
}

export default SlicedChartTopControls;