import React from 'react'
import {RecoilRoot} from 'recoil'
import './styles/App.scss';
import 'react-grid-layout/css/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {hot} from 'react-hot-loader/root';
import { Spinner} from "react-bootstrap";
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {HashRouter} from 'react-router-dom'
import RecoilLogger from "recoil-logger";
import DashboardWithConfig from "./components/Dashboards/DashboardWithConfig";
import _ from "lodash";
import ConfigLoaderHOC from "./config/ConfigLoaderHOC/ConfigLoader";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import keycloak from "./dataProvider/vissights/authentication/keycloak";


function App(props) {
    //If your app injects on an element with ID of 'app' initOptions={{onLoad: 'login-required'}}
    const app = document.getElementById('app');
    return (
            <DndProvider backend={HTML5Backend}>
                <RecoilRoot>
                    <ReactKeycloakProvider authClient={keycloak} >
                        <RecoilLogger/>
                        <HashRouter>
                            <div className="App">
                                {!_.isEmpty(props.loadedConfig) ?
                                    <DashboardWithConfig loadedConfig={props.loadedConfig}/>
                                    :
                                    <React.Suspense
                                        fallback={<Spinner
                                            style={{
                                                marginTop: '30vh',
                                                width: 150,
                                                height: 150,
                                                textAlign: 'center',
                                                marginLeft: 'auto'
                                            }}
                                            animation="border"/>
                                        }>
                                    </React.Suspense>
                                }
                            </div>
                        </HashRouter>
                    </ReactKeycloakProvider>
                </RecoilRoot>
            </DndProvider>
    )
}

export default ConfigLoaderHOC(hot(App))
