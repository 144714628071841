import SpringerIcon from "../../components/Misc/icons/dbs/springer";
import EgIcon from "../../components/Misc/icons/dbs/eg";
import DblpIcon from "../../components/Misc/icons/dbs/dblp";
import PubbizIcon from "../../components/Misc/icons/dbs/pubbiz";
import CrossrefIcon from "../../components/Misc/icons/dbs/crossref";
import TestIcon from "../../components/Misc/icons/dbs/Test";
import EgTestIcon from "../../components/Misc/icons/dbs/egTest";
import React from "react";

export const dbConfig = [
    {
        name: 'springer',
        displayName: 'Springer',
        icon: <SpringerIcon/>,
    },
    {
        name: 'dblp',
        displayName: 'DBLP',
        icon: <DblpIcon/>,
    },
    {
        name: 'dblp_v2',
        displayName: 'DBLP_v2',
        icon: <DblpIcon/>,
    },
    {
        name: 'eg',
        displayName: 'EG',
        icon: <EgIcon/>,
    },
    {
        name: 'crossref',
        displayName: 'CrossRef',
        icon: <CrossrefIcon/>,
    },
    {
        name: 'pubbiz',
        displayName: 'Pubbiz',
        icon: <PubbizIcon/>,
    },
    {
        name: 'Test',
        displayName: 'Test',
        icon: <TestIcon/>,
    },
    /*
    {
        name: 'lld',
        displayName: 'lld',
        icon: <EgTestIcon/>,
    },
     */
    {
        name: 'egTest',
        displayName: 'egTest',
        icon: <EgTestIcon/>,
    }
]

// const dbsToUse = ['dblp, eg, springer'];

// console.log(dbConfig);





export const vissightsConfig = {
        dbConfig: dbConfig,
        // as the API delivers all kind of not working rubbish we filter that stuff out.
        // by blacklisting not working and work in progress stuff we do not need to apply changes when new working databases are created.
        searchUrlPrefix: 'https://api.vissights.net/semaservice-web-api-vissights/v1/',
        baseQuery: "https://api.morpheus.fbmd.h-da.de/",
        authBaseQuery: "https://auth.morpheus.fbmd.h-da.de/",
        apiVersion: "v1",
        dbName: "springer",
        querySuffix: '&q=',
        dbSuffix: '?db=',
        themeOptions: ['light', 'dark'],
        defaultTheme: 'light',
        // devUrl: 'http://localhost:4000/graphql',
        // productionUrl: 'https://sina.media.h-da.de/graphql'
        // for v2 of api which is not working atm.
        // searchUrlPrefix: 'https://api.vissights.net/semaservice-web-api-vissights/v2/',
        // searchUrlAddendum: '/publications/search?q='}
}
