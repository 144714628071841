import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import {atom, useRecoilState, useRecoilValue} from "recoil";


export const displaySettingsModalAtom = atom({
    key: "displaySettingsModalAtom-Key",
    default: false,
});

function SettingsModal() {
    const [displaySettingsModal, setDisplaySettingsModal] = useRecoilState(displaySettingsModalAtom);


    const hide = () => {
        setDisplaySettingsModal(false);
    }

    return (
        <Modal show={displaySettingsModal} onHide={hide} style={{width: '20%',height: 300, marginLeft: '40%', marginTop: '10%'}}>
            <Modal.Body style={{height: 200}}>
                Settings

                Load/Refresh amount publication data / year to graphQL.

            </Modal.Body>
        </Modal>
    )
}

export default SettingsModal;