import React, {useEffect, useRef} from "react";
import * as d3 from 'd3';
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {
    normalizeAtomFamily,
    visualizationPinAtomFamily,
    visualizationScaleAtomFamily,
    visualizationTranslationXAtomFamily
} from "../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import {
    activeFillColorForSlices, activeStrokeColorForSlices,
    standardFillColorForSlices,
    standardStrokeColorForSlices
} from "../../../../../styles/colors";
import {activeFacetAtomFamily} from "../../../overview/visualizations/state";

function SlicedChart(props) {
    const ref = useRef();
    const componentId = props.id;
    const containerWidth = props.width;
    const containerHeight = props.height;
    const toggleFacetFilter = props.toggleFacetFilter;
    const toggleContextMenu = props.toggleContextMenu;
    const data = props.data;
    let [scale, setNewScale] = useRecoilState(visualizationScaleAtomFamily(componentId));
    let [translationX, setTranslationX] = useRecoilState(visualizationTranslationXAtomFamily(componentId));
    const [myPinId, setNewPinId] = useRecoilState(visualizationPinAtomFamily(componentId));
    const resetPinId = useResetRecoilState(visualizationPinAtomFamily(componentId));
    const normalization = useRecoilValue(normalizeAtomFamily(componentId));
    const activeFacet = useRecoilValue(activeFacetAtomFamily(componentId));
    let zoom;
    // Display text only for on wide enough Width
    const minWidthtext = 80;
    // gap between the slices
    const standardGap = 2;
    // colors
    // bind all colors
    const standardFillColorForSlice = standardFillColorForSlices();
    const standardStrokeColorForSlice = standardStrokeColorForSlices();
    const activeFillColorForSlice = activeFillColorForSlices();
    const activeStrokeColorForSlice = activeStrokeColorForSlices();

    /*
    useEffect(() => {
        createChart();
    },[]);
*/

    useEffect(() => {
        removeChart();
        createChart();
    })


    useEffect(()  => {
        drawAxis();
        drawChart();
    })

    const createChart = () => {
        // rebind zoom on changes.
        zoom = d3.zoom().scaleExtent([1, 5])
            .translateExtent([[0, 0], [containerWidth, 0]])
            .extent([[0, 0], [containerWidth, containerHeight]])
            .on('zoom',
                () => {
                    handleZoom(d3.event.transform);
                }
            )
        bindZoom(zoom);

        if (scale === 1 && translationX === 0) {
            calculateAppropriateZoom(data, zoom);
            drawChart();
        }

        drawChart();
        drawAxis();
    }


    // calculates an appropriate zoom when we start
    const calculateAppropriateZoom = (data, zoom) => {
        if (data.length > 0 && containerHeight > 0 ) {
            const w = containerWidth;
            const [minYear, maxYear] = d3.extent(data, (d) => d.year);
            const numYears = maxYear - minYear + 1;

            // if there is enough space to display texts.
            let newScale = 1;
            let translation = 0;
            // if not we must zoom in to the last years.
            if (w / numYears < minWidthtext) {
                const totalWidth = minWidthtext * numYears + 1;
                newScale = totalWidth / w;
                translation = w - totalWidth;

                // scale = 5;
                // translation = (w * scale) * (-1);
                scale = newScale;
                translationX = translation;

                const transform = d3.zoomIdentity
                    .translate(translation, 0)
                    .scale(scale);
                d3.select(ref.current)
                    .transition()
                    .duration(1200)
                    .call(zoom.transform, transform);
            } else {
               // storeScaleAndTranslation(this.scale, this.translationX);
                setNewScale(scale);
                setTranslationX(translationX);
            }
        }
    }

    const handleZoom = (event) => {
        const newTranslationX = event.x;
        const newScale = event.k;
        d3.select(ref.current)
            .select('g')
            .attr('transform', `translate(${newTranslationX},0)`);

        setTranslationX(newTranslationX);
        setNewScale(newScale);
    }

    const bindZoom = (zoom) => {
        d3.select(ref.current)
            .call(zoom)
            .on('dblclick.zoom', null);
    }

    const _scalesXY = () => {
        const w = containerWidth;
        const h = containerHeight;

        // tslint:disable-next-line:prefer-const
        let [minYear, maxYear] = d3.extent(data, (e) => e.year);

        const maxPos = d3.max(data, (e) => e.pos);

        if (normalization) {
            minYear = 1960;
        }
        return {
            x: d3.scaleBand()
                .domain(d3.range(minYear, maxYear + 1))
                .range([0, w * scale]),
            y: d3.scaleBand()
                .domain(d3.range(maxPos + 1))
                .range([22, h])
        };
    }

    const drawAxis = () => {
            const scalesXY = _scalesXY();
            const w = containerWidth;
            let tickValues = scalesXY.x.domain();
            if (w * scale < tickValues.length * (32 + 8) / 5) {
                tickValues = tickValues.filter((d) => d % 10 === 0);
            } else if (w * scale < tickValues.length * (32 + 8) / 2) {
                tickValues = tickValues.filter((d) => d % 5 === 0);
            } else if (w * scale < tickValues.length * (32 + 8)) {
                tickValues = tickValues.filter((d) => d % 2 === 0);
            }

            const xAxis = d3.axisTop()
                .scale(scalesXY.x)
                .tickValues(tickValues)
                .tickSize(1)
                .tickPadding(4);

            d3.select(ref.current)
                .select('g')
                .select('.axis')
                .attr('transform', 'translate(0,20)')
                .call(xAxis);
    }

    const pinElementById = (pin) => {
        if (pin === myPinId) {
            resetPinId();
        } else {
            setNewPinId(pin)
        }
    }

    const setFacetFilter = (facet, value) => {
        toggleFacetFilter(facet, value)
    }

    const drawChart = () => {
        const scales = _scalesXY();
        // small gap between the slices
        const gap = standardGap;
        const xSize = scales.x.bandwidth();
        const ySize = scales.y.bandwidth();

        // define whether to show or not to show text.
        const showText = scales.x.bandwidth() > minWidthtext;

        const opacity = (d) => {
            const date = new Date();
            const year = date.getFullYear();
            if (d.year > (year - 1)) {
                if (myPinId === d.id) {
                    return 0.8;
                } else {
                    return 0.4;
                }
            } else {
                return myPinId === '-1' || myPinId === d.id ? 1 : 0.3;
            }
        };

        d3.select(ref.current)
            .attr('width', containerWidth)
            .attr('height', containerHeight)


        const rect = d3.select(ref.current)
            .select('g')
            .select('.points')
            .selectAll('rect')
            .data(data, (d) => d.key);

        // mouse events
        const onMouseOver = (d) => {
            const idFilter = (e) => e.id === d.id;
            const keyFilter = (e) => e.key === d.key;

            d3.select(ref.current)
                .select('g')
                .select('.points')
                .selectAll('rect')
                .filter(idFilter)
                .attr('opacity', 1)
                .attr('cursor', 'pointer')
                .attr('fill', activeFillColorForSlice)
                .attr('stroke', activeStrokeColorForSlice)
                .attr('shape-rendering', 'crispEdges');
            d3.select(ref.current)
                .select('g')
                .select('.texts')
                .selectAll('text')
                .filter(idFilter)
                .attr('opacity', 1);
            d3.select(ref.current)
                .select('g')
                .select('.tips')
                .selectAll('text')
                .filter(keyFilter)
                .attr('opacity', 1)
                .text(d.name);
            d3.select(ref.current)
                .select('g')
                .select('.amount')
                .selectAll('text')
                .filter(keyFilter)
                .attr('opacity', 1)
                .text(d.count);
        };

        const onMouseOut = (d) => {
            const idFilter = (e) => e.id === d.id;
            const keyFilter = (e) => e.key === d.key;
            d3.select(ref.current)
                .select('g')
                .select('.points')
                .selectAll('rect')
                .filter(idFilter)
                .attr('opacity', opacity)
                .attr('stroke', standardStrokeColorForSlice)
                .attr('shape-rendering', 'crispEdges')// .style(styleRect)
                .attr('fill', (e) => e.color === undefined ? standardFillColorForSlice : e.color);
            d3.select(ref.current)
                .select('g')
                .select('.texts')
                .selectAll('text')
                .filter(idFilter)
                .attr('opacity', opacity);
            d3.select(ref.current)
                .select('g')
                .select('.tips')
                .selectAll('text')
                .filter(keyFilter)
                .text('');
            d3.select(ref.current)
                .select('g')
                .select('.amount')
                .selectAll('text')
                .filter(keyFilter)
                .text('');

            if (showText) {
                d3.select(ref.current).select('g').select('.texts').selectAll('text').filter(keyFilter)
                    .text(shortenSliceText(d.name, (xSize - 12)));
            }
        };

        rect.enter()
            .append('rect')
            .attr('opacity', 0)
            .on('click', (d) => pinElementById(d.id))
            .on('dblclick', (d) => setFacetFilter(activeFacet, d.id))
            .on('contextmenu', (d) => toggleContextMenu(d.name, ref.current));

        rect
            .on('mouseover', onMouseOver)
            .on('mouseout', onMouseOut)
            .attr('x', (d) => scales.x(d.year) + 0.5 * (xSize - xSize * d.value))
            .attr('width', (d) => d.value * (xSize - gap))
            .transition()
            .attr('opacity', opacity)
            .attr('transform', 'translate(0,0)')
            .attr('y', (d) => scales.y(d.pos))
            .attr('height', ySize - gap)
            .attr('cursor', 'pointer')
            .attr('fill', standardFillColorForSlice)
            .attr('shape-rendering', 'crispEdges')
            .attr('fill', (d) => d.color === undefined ? standardFillColorForSlice : d.color)
            .attr('stroke', standardStrokeColorForSlice);

        //rect.exit()
          //  .transition()
          //  .attr('opacity', 0)
         //   .remove();


        // TEXT
        const text = d3.select(ref.current)
            .select('g')
            .select('.texts')
            .selectAll('text')
            .data(data, (d) => d.key);

        text.enter()
            .append('text')
            .attr('class', 'whiteOnDarkMode')
            .attr('cursor', 'pointer')
            .attr('text-anchor', 'middle')
            .attr('dy', '.3em')
            .attr('opacity', 0)
            .on('click', (d) => pinElementById(d.id));

        text.text((d) => showText ? shortenSliceText(d.name, (xSize - 12)) : '')
            .on('mouseover', onMouseOver)
            .on('mouseout', onMouseOut)
            .attr('x', (d) => scales.x(d.year) + 0.5 * xSize)
            .transition()
            .attr('opacity', opacity)
            .attr('transform', 'translate(0,0)')
            .attr('y', (d) => scales.y(d.pos) + 0.5 * ySize);

        text.exit().transition()
            .attr('opacity', 0)
            .remove();


        // TOOLTIP
        const tip = d3.select(ref.current)
            .select('g')
            .select('.tips')
            .selectAll('text')
            .data(data, (d) => d.key);
        tip.enter()
            .append('text')
            .attr('class', 'whiteOnDarkMode')
            .attr('cursor', 'pointer')
            .attr('text-anchor', 'middle')
            .attr('font-size', '10')
            .attr('dy', '.3em')
            .attr('opacity', 0)
            .on('click', (d) => pinElementById(d.id));

        tip.text((d) => d.name)
            .on('mouseover', onMouseOver)
            .on('mouseout', onMouseOut)
            .attr('font-size', '10')
            .attr('x', (d) => scales.x(d.year) + 0.5 * xSize)
            .transition()
            .attr('opacity', 0)
            .attr('transform', 'translate(0,0)')
            .attr('y', (d) => scales.y(d.pos) + 0.85 * ySize);

        tip.exit()
            .transition()
            .attr('opacity', 0)
            .remove();

        // AMOUNT
        const amount = d3.select(ref.current)
            .select('g').select('.amount').selectAll('text')
            .data(data, (d) => d.key);
        amount.enter()
            .append('text')
            .attr('class', 'whiteOnDarkMode')
            .attr('cursor', 'pointer')
            .attr('text-anchor', 'middle')
            .attr('font-size', '10')
            .attr('dy', '.3em')
            .attr('opacity', 0)
            .on('mouseover', onMouseOver)
            .on('mouseout', onMouseOut)
            .on('click', (d) => pinElementById(d.id));
         // .on('dblclick', onClick);
        amount.text((d) => d.amount)
            .attr('font-size', '10')
            .attr('x', (d) => scales.x(d.year) + 0.5 * xSize)
            .transition()
            .attr('opacity', opacity)
            .attr('transform', 'translate(0,0)')
            .attr('y', (d) => scales.y(d.pos) + 0.2 * ySize);

        amount.exit()
            .transition()
            .attr('opacity', 0)
            .remove();
    }

   const removeAxis = () => {
        const chart = d3.select(ref.current)
            .select('g');
        chart.select('.axis').selectAll()
            .remove();
    }

    const removeChart = () => {
        const chart = d3.select(ref.current)
            .select('g');
        chart.select('.points').selectAll('rect')
            .remove();
        chart.select('.texts').selectAll('text')
            .remove();
        chart.select('.tips').selectAll('text')
            .remove();
        chart.select('.amount').selectAll('text')
            .remove();
    }

    // shortens text
    const shortenSliceText = (name, width) => {
        let shortText = name;
        const length = name.length;
        if (length > width / 6) {
            shortText = shortText.slice(0, width / 8);
            shortText = shortText.slice(0, shortText.lastIndexOf(' '));
        }
        return shortText;
    }

    return (
        <svg ref={ref}>
            <g className="mainGroup">
                <g className="axis"/>
                <g className="points"/>
                <g className="texts"/>
                <g className="tips"/>
                <g className="amount"/>
            </g>
        </svg>
    );
}

export default SlicedChart;


