import {atom, atomFamily, selectorFamily, selector} from "recoil";
import {
    amountRawDataSelector
} from "../../../../../../../dataProvider/vissights/search/search";
import {calculateSignalStrength} from "../../../../../../../config/signals";
import * as _ from "lodash";
import {preProcessedFilteredSearchDataSelector} from "../../../../../../../dataProvider/vissights/search/filters/filters";

export const chartDataTemporalOverviewSelectorFamily = selectorFamily({
    key: 'chartDataTemporalOverviewSelectorFamily-Key',
    get: (facet) => async ({get}) => {
        const dataModel = await get(preProcessedFilteredSearchDataSelector);
        const searchAnswerLength = get(amountRawDataSelector);
        const data = dataModel[facet];
        //console.log('here')
        let lookupClone = _.cloneDeep(data.lookup);
        const dataToPreprocess = _.cloneDeep(data);
        dataToPreprocess.lookup = lookupClone;
        dataToPreprocess.map((d) => {
            dataToPreprocess.lookup[parseInt(d.name,10)]['signal'] = calculateSignalStrength(d, searchAnswerLength)
            return d;
        })
        let preprocessedDataForChart = [];
        //  fill in the gaps ---> years which are not defined must be set on amount 0
        let maxiYear;
        let minYear;
        // if there is no data we cannot to anything!
        if (dataToPreprocess.length > 0) {
            if (dataToPreprocess.length >= 1) {
                maxiYear = dataToPreprocess[0].name;
                minYear = dataToPreprocess[dataToPreprocess.length - 1].name;
            } else if (dataToPreprocess.length === 1) {
                maxiYear = dataToPreprocess[0].name;
                minYear = dataToPreprocess[0].name;
            }
            const allYearsData = [];
            for (let i = minYear; i <= maxiYear; i++) {
                allYearsData.push({
                    year: parseInt(i, 10),
                    amount: dataToPreprocess.lookup[i] ? dataToPreprocess.lookup[i].amount : 0,
                    signal: dataToPreprocess.lookup[i] ? dataToPreprocess.lookup[i].signal : 0,
                });
            }
           preprocessedDataForChart = allYearsData;
        }
        return preprocessedDataForChart;
    },
});

export const temporalOverviewChartDataAtomFamily = atomFamily({
    key: "temporalOverviewChartDataAtomFamily-Key",
    default: selectorFamily({
        key: 'temporalOverviewChartDataAtomFamily/Default',
        get: id =>  ({get}) => {
            return chartDataTemporalOverviewSelectorFamily('Years');
        },
    }),
});


export const temporalOverviewChartDataPrevAtomFamily = atomFamily({
    key: "temporalOverviewChartDataPrevAtomFamily-Key",
    default: selectorFamily({
        key: 'temporalOverviewChartDataPrevAtomFamily/Default',
        get: id =>  ({get}) => {
            return chartDataTemporalOverviewSelectorFamily('Years');
        },
    }),
});




export const benchmarksTemporalOverviewSelector = selector({
    key: 'benchmarksTemporalOverviewSelector-Key',
    get: ({get}) => {
        /** CALCULATE THE BENCHMARKS (Calculator Icon) **/
        const preprocessedDataForChart =  get(chartDataTemporalOverviewSelectorFamily('Years'));
        const benchmarks = [];
        if(preprocessedDataForChart.length > 0){
            const max = preprocessedDataForChart.reduce((prev, current) => (prev.amount > current.amount) ? prev : current);
            const yearMaxAmount = max.year;
            const maxAmount = max.amount;
            const amountYears = preprocessedDataForChart.length;
            const sum = preprocessedDataForChart.reduce((a, b) => a + b.amount, 0);
            const avg = (sum / preprocessedDataForChart.length) || 0;
            benchmarks['yearMaxAmount'] = yearMaxAmount;
            benchmarks['maxAmount'] = maxAmount;
            benchmarks['amountYears'] = amountYears;
            benchmarks['sum'] = sum;
            benchmarks['avg'] = avg;
        }
        return benchmarks;
    },
});

export const benchmarksTemporalOverviewAtom = atom({
    key: "benchmarksTemporalOverviewAtom-Key",
    default: benchmarksTemporalOverviewSelector
});

// menu settings
export const alwaysDisplayNumbersAtomFamily = atomFamily({
    key: "alwaysDisplayNumbersAtom-Key",
    default: false
});

export const alwaysDisplayTextLabelsAtomFamily = atomFamily({
    key: "alwaysDisplayTextLabelsAtomFamily-Key",
    default: false
});


export const displayNeighborsAtomFamily = atomFamily({
    key: "displayNeighborsAtom-Key",
    default: false
});

export const displayColorsAtomFamily = atomFamily({
    key: "displayColorsAtomFamily-Key",
    default: false
});

export const displayLinesAtomFamily = atomFamily({
    key: "displayLinesAtomFamily-Key",
    default: false
});

export const displayLinearRegressionAtomFamily = atomFamily({
    key: "displayLinearRegressionAtomFamily-Key",
    default: false
});

export const displayLOESSRegressionAtomFamily = atomFamily({
    key: "displayLOESSRegressionAtomFamily-Key",
    default: false
});

export const bandWidthLOESSRegressionAtomFamily = atomFamily({
    key: "bandWidthLOESSRegressionAtomFamily-Key",
    default: 0.5
});















    // find the maximum amount.
    /*
const max = preprocessedDataForChart.reduce((prev, current) => (prev.amount > current.amount) ? prev : current);

const yearMaxAmount = max.year;
const maxAmount = max.amount;

const amountYears = preprocessedDataForChart.length;

const sum = preprocessedDataForChart.reduce((a, b) => a + b.amount, 0);
const avg = (sum / preprocessedDataForChart.length) || 0;

const benchmarks = [];

benchmarks['yearMaxAmount'] = yearMaxAmount;
benchmarks['maxAmount'] = maxAmount;
benchmarks['amountYears'] = amountYears;
benchmarks['sum'] = sum;
benchmarks['avg'] = avg;
*/
