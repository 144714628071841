import {atomFamily, selectorFamily} from "recoil";
import {
    activeFacetAtomFamily
} from "../../../../overview/visualizations/state";
import {calculateSignalStrengthOld, genericSort, recursiveDeepClone} from "../../../../../../utility";
import {
    preProcessedFilteredSearchDataSelector
} from "../../../../../../dataProvider/vissights/search/filters/filters";
import {amountRawDataSelector} from "../../../../../../dataProvider/vissights/search/search";
import {
    chartDataPreFacetLineChartSelectorFamily,
    rightControlFacetLineChartDataAtomFamily
} from "../../FacetLineChart/state/FacetLineChartState";


export const chartDataBarPieDonutChartSelectorFamily = selectorFamily({
    key: 'chartDataBarPieDonutChartSelectorFamily-Key',
    get: (id) => ({get}) => {
        const facet = get(activeFacetAtomFamily(id));
        const dataModel = get(preProcessedFilteredSearchDataSelector);
        const searchAnswerLength = get(amountRawDataSelector);
        let data = dataModel[facet];
        let clone = recursiveDeepClone(data);

        clone.sort(genericSort('amount', 'desc'));
        // slice it
        clone = clone.slice(0, 100);
        clone.map((d) => d.signal = calculateSignalStrengthOld(d, searchAnswerLength));
        clone.map((d, i) => d.y = d.amount);
        return clone;
    },
});

export const chartDataBarPieDonutChartAtomFamily = atomFamily({
    key: "chartDataBarPieDonutChartAtomFamily",
    default: selectorFamily({
        key: 'chartDataBarPieDonutChartSelectorFamily/Default',
        get: id => () => {
            return chartDataBarPieDonutChartSelectorFamily(id);
        },
    }),
});

export const chartDataActiveBarPieDonutChartAtomFamily = atomFamily({
    key: "chartDataActiveBarPieDonutChartAtomFamily",
    default: selectorFamily({
        key: 'chartDataActiveBarPieDonutChartAtomFamily/Default',
        get: id => ({get}) => {
            // const data = get(chartDataBarPieDonutChartAtomFamily(id));
            // return data.filter((d) => d.active);
            const rightControlItems = get(rightControlDataBarPieDonutChartAtomFamily(id));
            const rightControlItemsActive = rightControlItems.filter((d) => d.active);
            let rightControlItemsActiveIds = [];

            rightControlItemsActive.map((d) => {
                rightControlItemsActiveIds.push(d.id)
            })

            const chartData = get(chartDataBarPieDonutChartSelectorFamily(id));

            let clone = recursiveDeepClone(chartData);

            const lookUpClone = clone.lookup;


            clone = clone.filter((d) => rightControlItemsActiveIds.includes(d.id))


            clone.map((d) => d.color = Math.random() * 360)

            clone.lookup = lookUpClone;
            // console.log(clone);

            return clone;

        }
    }),
});


export const rightControlDataBarPieDonutChartSelectorFamily = selectorFamily({
    key: 'rightControlDataBarPieDonutChartSelectorFamily-Key',
    get: (id) => ({get}) => {
        const facet = get(activeFacetAtomFamily(id));
        const dataModel = get(preProcessedFilteredSearchDataSelector);
        let data = dataModel[facet];
        let clone = recursiveDeepClone(data);
        clone.sort(genericSort('amount', 'desc'));
        // slice it
        clone = clone.slice(0, 100);
        // activate 15 items
        clone.map((d, i) => i < 15 ? d.active = true : d.active = false);
        return clone;
    }
});


export const rightControlDataBarPieDonutChartAtomFamily = atomFamily({
    key: "rightControlDataBarPieDonutChartAtomFamily",
    default: selectorFamily({
        key: 'rightControlDataBarPieDonutChartAtomFamily/Default',
        get: id => () => {
            return rightControlDataBarPieDonutChartSelectorFamily(id);
        },
    }),
});

export const visualizationTypeToDisplayAtomFamily = atomFamily({
    key: "visualizationTypeToDisplayAtomFamily-Key",
    default: (id) => 'Bar' // Pie, Donut
});

/*
selectorFamily({
        key: 'visualizationTypeToDisplayAtomFamily/Default',
        get: id => ({get}) => {
            const data = get(chartDataBarPieDonutChartAtomFamily(id));
            return data.filter((d) => d.active);
        }
    }),
 */
