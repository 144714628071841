import {Button, Col, Row, Badge} from "react-bootstrap";
import React from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    activatedMultiSelectionDataAtomFamily,
    parallelCoordinatesInitialDimensionsAtomFamily
} from "../state/parallel-coordinates-chart-state";
import Dropdown from 'react-multilevel-dropdown';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faCog, faEye, faLink, faListAlt} from "@fortawesome/free-solid-svg-icons";
import {
    localSearchDataAtom,
    searchDatabase,
    searchTermAtom, useLocalAsGlobalSearchDataAtom
} from "../../../../../../dataProvider/vissights/search/search";
import {moduleToDisplayAtom} from "../../../../../Dashboards/DashboardLayout";
import domtoimage from "dom-to-image";
import uuid from "react-uuid";
import * as _ from "lodash";
import {singleVisualizationScreenshotsAtom} from "../../../../reporting/state/ReportingState";
import {displaySavedScreenshotModalAtom} from "../../../../../Misc/Modals/SavedScreenshot";
import {
    activeColorSchemeAtomFamily, activePathSchemeAtomFamily,
    colorSchemesAtom, pathSchemeAtom
} from "../../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import {recursiveDeepClone} from "../../../../../../utility";
import {visualizationItemsAtom} from "../../../../../Dashboards/GridLayout/state/grid-state";
import {
    configZoomAndFilter
} from "../../../../../../config/visualizations";


function ParallelCoordinatesChartTopControls(props) {
    const componentId = props.id;
    // infos we need for screenshot.
    const db = useRecoilValue(searchDatabase);
    const searchTerm = useRecoilValue(searchTermAtom)
    const module = useRecoilValue(moduleToDisplayAtom);
    const setDisplayScreenshotModal = useSetRecoilState(displaySavedScreenshotModalAtom);
    const [singleVisualizationScreenshots, setSingleVisualizationScreenshots] = useRecoilState(singleVisualizationScreenshotsAtom);
    const config = props.topControlConfig[0];
    // const [activeDataset, setActiveDataset] = useRecoilState(activeFacetAtomFamily(componentId))
    const colorSchemes = useRecoilValue(colorSchemesAtom);
    const setActiveColorScheme = useSetRecoilState(activeColorSchemeAtomFamily(componentId));
    const [dimensions, setDimensions] = useRecoilState(parallelCoordinatesInitialDimensionsAtomFamily(componentId));
    const pathDrawingMethods = useRecoilValue(pathSchemeAtom);
    const setActivePathDrawingMethod = useSetRecoilState(activePathSchemeAtomFamily(componentId));
    const setItems = useSetRecoilState(visualizationItemsAtom);
    const multiSelectionData = useRecoilValue(activatedMultiSelectionDataAtomFamily(componentId))
    const [useLocalAsGlobalSearchData, setUseLocalAsGlobalSearchData] = useRecoilState(useLocalAsGlobalSearchDataAtom);
    const [localSearchData, setLocalSearchData] = useRecoilState(localSearchDataAtom);



    const displayResultsAsList = () => {
        // console.log('display results', props.id)
        setItems((item) => [
            ...item,
            {
                id: uuid(),
                visType: configZoomAndFilter[0].visType,
                visComponent: configZoomAndFilter[0].visComponent,
                topControls: configZoomAndFilter[0].topControls,
                usesRightNav: configZoomAndFilter[0].usesRightNav,
                module: configZoomAndFilter[0].module,
                dashboardConfig: configZoomAndFilter[0].dashboardConfig,
                useMultiSelectionData: props.id,
                graphicalSearchInfos: undefined,
            }
        ]);
    }





    /*
     const doubleClick = (d) => {
        const handleActivate = (tree) => {
            // we get the names of all pois involved.
            if (!tree.poi) return;
            const poiFilter = [];
            let t = tree;
            while (t.poi) {
                poiFilter.push(t.poi.name);
                t = t.parent;
            }
            // we clone all pois
            const poiDataClone = _.cloneDeep(poiData);
            // activate the pois that are included.
            poiDataClone.map((d) =>  poiFilter.includes(d.name) ? d.active = true : false)
            setPoiList(poiDataClone);
        }
        // call the above defined method to activate all pois involved.
        handleActivate(d.tree);
        // dependant on the amount we inject different charts to the layout.
         if(d.text <= criticalAmount) {
             setItems((item) => [
                 ...item,
                 injectedPublicationList
             ]);
         } else {
             setItems((item) => [
                 ...item,
                 injectedPublicationList,
                 injectedTemporalOverview
             ]);
         }
    }
     */

    const createVisualizationScreenshot = () => {
        const node = document.getElementById(componentId);
        domtoimage.toPng(node)
            .then((dataUrl) => {
                const newUUID = uuid();
                // store the image, the module, and the current database
                const autoGeneratedText = 'The following chart was taken in the Zoom and Filter Module' +
                    'searching for' + searchTerm + ' in the database: ' + db.name + '. ' +
                    'It shows a temporal overview.';
                const metaDataObj = {id: newUUID, dataUrl, module, db: db.name, autoGeneratedText}
                const clone = _.cloneDeep(singleVisualizationScreenshots)
                clone.push({id: newUUID, data: metaDataObj});
                setSingleVisualizationScreenshots(clone);
            }).then(() => setDisplayScreenshotModal(true));
    }

    const changeActiveStateOfDimension = (name) => {
        const clone = recursiveDeepClone(dimensions);
        const newDimensions = clone.map((d) => {
            if (d.name === name) {
                d.active = !d.active;
            }
            return d
        });

        // we must check that at least two dimensions are active!
        const correctAmountDimensions = (newDimensions.filter((d) => d.active)).length >= 2;
        if(correctAmountDimensions ){
            setDimensions(newDimensions);
        } else {
           alert('At least two dimensions must be active');
        }
    }


    const localDataGlobally = () => {
         setLocalSearchData(multiSelectionData);
         setUseLocalAsGlobalSearchData(!useLocalAsGlobalSearchData);

    }

    const ContainerTopControls = () => {
        return (
            <Row>
                <Col xs={12}>
                    <div className="grid-item-container-top-control">
                        <div className="float-left" style={{display: 'flex'}}>
                            <Dropdown position="right"
                                      title={<FontAwesomeIcon style={{width: 20, height: 20}} icon={faCog}/>}
                            >
                                <Dropdown.Item>
                                    <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}}
                                                     icon={faEye}/> View Settings
                                    <Dropdown.Submenu position="right">
                                        <Dropdown.Item position="right">
                                            Color Schemes
                                            <Dropdown.Submenu position="right">
                                                {
                                                    colorSchemes.map((d) => {
                                                        return (
                                                            <Dropdown.Item
                                                                key={d}
                                                                position="right"
                                                                           onClick={() => setActiveColorScheme(d)}>
                                                                {d}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </Dropdown.Submenu>
                                        </Dropdown.Item>
                                        <Dropdown.Item position="right">
                                            Path Drawing
                                            <Dropdown.Submenu position="right">
                                                {
                                                    pathDrawingMethods.map((d) => {
                                                        return (
                                                            <Dropdown.Item
                                                                key={d}
                                                                position="right"
                                                                           onClick={() => setActivePathDrawingMethod(d)}>
                                                                {d}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </Dropdown.Submenu>
                                        </Dropdown.Item>
                                    </Dropdown.Submenu>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => createVisualizationScreenshot()}>
                                    <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}}
                                                     icon={faCamera}/> Screenshot Chart
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                        <Button type="button" className="float-right" variant="light" value="Search" onClick={(e) => {
                            e.preventDefault();
                            props.deleteContainer(props.id);
                        }}>X</Button>
                    </div>
                    <div className="float-left" style={{marginLeft: 15, marginTop: 6, display: 'flex'}}>
                        {
                            dimensions.map((d) => <Badge key={d.name} style={{marginRight: 5, cursor: 'pointer'}}
                                                         variant={d.active ? "primary" : "light"}
                                                         onClick={() => {
                                                             changeActiveStateOfDimension(d.name)
                                                         }}>
                                {d.name}
                            </Badge>)
                        }
                    </div>
                    <div className="float-left" style={{marginLeft: 55, marginTop: 6, display: 'flex'}}>
                        <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20 , cursor: 'pointer'}}
                                         icon={faListAlt}
                                         onClick={() => displayResultsAsList(multiSelectionData)}

                        />
                        <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20 , cursor: 'pointer'}}
                                         icon={faLink}
                                         onClick={() => localDataGlobally()}

                        />
                    </div>
                </Col>
            </Row>
        );
    }

    return (
        <ContainerTopControls/>
    );
}

export default ParallelCoordinatesChartTopControls;