import React, {useEffect} from 'react';
import {atom, useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import OverviewDashboard from "../VisualizationModules/overview/OverviewDashboard";
import ZoomAndFilterDashboard from "../VisualizationModules/zoomAndFilter/ZoomAndFilterDashboard";
import {windowHeightAtom, windowWidthAtom,} from "../../config/sizings/sizings";
import ReportingDashboard from "../VisualizationModules/reporting/ReportingDashboard";
import routes from "../../config/routes";
// import {createBrowserHistory} from 'history';
import {Route, Router, Switch, useHistory} from 'react-router-dom'
import {currentThemeAtom} from "../../styles/colors";
import SavedScreenshotModal, {displaySavedScreenshotModalAtom} from "../Misc/Modals/SavedScreenshot";
import {displayContextMenuAtom} from "../Misc/context-menu/state";
import ContextMenuModal from "../Misc/context-menu/ContextMenuModal";
import SettingsModal, {displaySettingsModalAtom} from '../Misc/Modals/GlobalModals/SettingsModal/SettingsModal';
import hotkeys from 'hotkeys-js';
import ProfileDashboard from "../VisualizationModules/profile/ProfileDashboard";
import PrivateRoute from "../../dataProvider/vissights/authentication/PrivateRoute/PrivateRoute";
import ProjectsDashboard from "../VisualizationModules/projects/ProjectsDashboard";
// createBrowserHistory({ basename: '' });
// very important atom that defines the current module which is displayed.
// Based on this module the sizings are calculated.
export const moduleToDisplayAtom = atom({
    key: "moduleToDisplayKey",
    default: 'overview',
});


function DashboardLayout() {
    const [windowWidth, setWindowWidth] = useRecoilState(windowWidthAtom);
    const setWindowHeight = useSetRecoilState(windowHeightAtom);
    const currentTheme = useRecoilValue(currentThemeAtom);
    const displaySavedScreenshotModal = useRecoilValue(displaySavedScreenshotModalAtom);
    const [displayContextMenu, setDisplayContextMenu] = useRecoilState(displayContextMenuAtom);
    const [displaySettingsModal, setDisplaySettingsModal] = useRecoilState(displaySettingsModalAtom);
    let history = useHistory();
    //const history = createHashHistory();
    // apply class for dark theme.
    useEffect(() => {
        if (currentTheme === 'light') {
            document.body.classList.remove('dark-mode');
        } else if (currentTheme === 'dark') {
            document.body.classList.add('dark-mode');
        }
    }, [currentTheme])

    // handle global resize event
    useEffect(() => {
        const handleResize = () => {
            // console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize)
    })

    // as there occurs a problem after redirection to home (/), we now always overwrite overview as start module.
    useEffect(() => {
        if(window.location.hash === '#/'){
            history.push(routes.modules.overview)
        }
    },[])



    // show up settings menu
    hotkeys('ctrl+o',  (event, handler) => {
        event.preventDefault()
        setDisplaySettingsModal(true);
    });

    const DashboardView = () => {
        // we differentiate between overview / zoom and filter and their individual layouts...
        return (
            <div className="Dashboard-View" style={{width: windowWidth}}>
                {displaySavedScreenshotModal ? <SavedScreenshotModal/> : null}
                {displayContextMenu ? <ContextMenuModal/> : null}
                <Router history={history}>
                    <Switch>
                        {/* modules */}
                        <Route path={routes.modules.home} exact>
                            <OverviewDashboard/>
                        </Route>
                        <Route path={routes.modules.overview}>
                            <OverviewDashboard/>
                        </Route>
                        <Route path={routes.modules.profile}>
                            <PrivateRoute>
                                <ProfileDashboard/>
                            </PrivateRoute>
                        </Route>
                        <Route path={routes.modules.projects}>
                            <PrivateRoute>
                                <ProjectsDashboard/>
                            </PrivateRoute>
                        </Route>
                        <Route path={routes.modules.zoomAndFilter} >
                            <ZoomAndFilterDashboard style={{width: windowWidth}}/>
                        </Route>
                        <Route path={routes.modules.reporting}>
                            <ReportingDashboard/>
                        </Route>
                    </Switch>
                </Router>
                <SettingsModal></SettingsModal>
            </div>
        )
    };
    return (
        <DashboardView/>
    );
}

export default DashboardLayout;