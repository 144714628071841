import {atom, atomFamily, selectorFamily} from "recoil";
import {filteredDataSelector} from "../../../../../../dataProvider/vissights/search/filters/filters";
import * as d3 from 'd3';
import {recursiveDeepClone} from "../../../../../../utility";
import FACET from "../../../../../../dataProvider/vissights/utility/facet-extraction-utility";
import {topicsAtom} from "../../../../../../dataProvider/vissights/overview/overview";
import {
    visualizationMultiSelectionAtomFamily
} from "../../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";


export const parallelCoordinatesChartDataAtomFamily = atomFamily({
    key: "parallelCoordinatesChartDataAtomFamily-Key",
    default: selectorFamily({
        key: 'parallelCoordinatesChartDataAtomFamily/Default',
        get: id => () => {
            return parallelCoordinatesChartDataSelectorFamily(id);
        },
    }),
});

export const parallelCoordinatesChartDataSelectorFamily = selectorFamily({
    key: 'parallelCoordinatesChartDataSelectorFamily-Key',
    get: id => ({get}) => {
        const rawData = get(filteredDataSelector);
        const data = recursiveDeepClone(rawData);
        data.map((d, i) => {
            d.id = i;
            return d;
        });
        return data;
    }
});



export const parallelCoordinatesChartRightControlDataAtomFamily = atomFamily({
    key: "parallelCoordinatesChartRightControlDataAtomFamily-Key",
    default: selectorFamily({
        key: 'parallelCoordinatesChartRightControlDataAtomFamily/Default',
        get: id => () => {
            return parallelCoordinatesChartRightControlDataSelectorFamily(id);
        },
    }),
});


export const parallelCoordinatesChartRightControlDataSelectorFamily = selectorFamily({
    key: 'parallelCoordinatesChartRightControlDataSelectorFamily-Key',
    get: id => ({get}) => {
        const rawData = get(parallelCoordinatesChartDataAtomFamily(id));
        const data = recursiveDeepClone(rawData);
        // activate first 50 items.
        data.map((d, i) => {
            if (i < 15) {
                d['active'] = true;
            }
            return d;
        });
        return data;
    }
});



export const parallelCoordinatesChartDataActiveSelectorFamily = selectorFamily({
    key: 'parallelCoordinatesChartDataActiveSelectorFamily-Key',
    get: id => ({get}) => {
        const topics = get(topicsAtom);
        const rightControlItems = get(parallelCoordinatesChartRightControlDataAtomFamily(id));
        const rightControlItemsActive = rightControlItems.filter((d) => d.active);
        let rightControlItemsActiveIds = [];

        rightControlItemsActive.map((d) => {
            rightControlItemsActiveIds.push(d.id)
        })

        const chartData = get(parallelCoordinatesChartDataAtomFamily(id));

        let clone = recursiveDeepClone(chartData);

        clone = clone.filter((d) => rightControlItemsActiveIds.includes(d.id))



        // refine topics by their names from ids.
        clone.map((d) => {
            d.topics = [FACET.getTopicNameById(topics, Object.keys(d.topics)[0])]
        })
        return clone;
    }
});

export const initialInactiveDimensionsAtom = atom({
    key: "initialInactiveDimensionsAtom-Key",
    default: [FACET.TYPES_KEY, FACET.AFFILIATIONS_KEY, FACET.VENUES_KEY[0], FACET.VENUES_KEY[1], '__typename', 'active'] //, 'id', 'key']
})

export const parallelCoordinatesInitalDimensionsSelectorFamily = selectorFamily({
    key: 'parallelCoordinatesInitalDimensionsSelectorFamily-Key',
    get: id => ({get}) => {
        const raw = get(parallelCoordinatesChartDataAtomFamily(id));
        const initialInactiveDimensions = get(initialInactiveDimensionsAtom);
        const data = recursiveDeepClone(raw);
        // const data = allData.filter((d) => d.active);

        const dimensions = d3.keys(data[0])
            .filter((d) => {
                return d !== '__typename'
            }).filter((d) => {
                return d !== 'active'
            }).filter((d) => {
                return d !== 'id'
            }).filter((d) => {
                return d !== 'key'
            }); // , 'id', 'key'

        const actualDimensions = [];
        // const dimensions = d3.keys(data[0]).filter((d) =>  ! initialInactiveDimensions.includes(d))
        dimensions.map((d, i) => {
            actualDimensions.push({name: d, active: !initialInactiveDimensions.includes(d)})
        });
        return actualDimensions;
    }
});

export const parallelCoordinatesInitialDimensionsAtomFamily = atomFamily({
    key: "parallelCoordinatesInitialDimensionsAtomFamily-Key",
    default: selectorFamily({
        key: 'parallelCoordinatesInitialDimensionsAtomFamily/Default',
        get: id => ({get}) => {
            return get(parallelCoordinatesInitalDimensionsSelectorFamily(id));
        },
        dangerouslyAllowMutability: true
    }),
});



/*
export const parallelCoordinatesActiveDimensionsSelectorFamily = selectorFamily({
    key: 'parallelCoordinatesActiveDimensionsSelectorFamily-Key',
    get: id => ({get}) => {
        const raw = get(parallelCoordinatesInitalDimensionsSelectorFamily(id));
        const allData = recursiveDeepClone(raw);
        return allData.filter((d) => d.active);
    }
});
*/

export const parallelCoordinatesActiveDimensionsAtomFamily = atomFamily({
    key: "parallelCoordinatesActiveDimensionsAtomFamily-Key",
    default: selectorFamily({
        key: 'parallelCoordinatesActiveDimensionsAtomFamily/Default',
        get: id => ({get}) => {
            const raw = get(parallelCoordinatesInitialDimensionsAtomFamily(id));
            const allData = recursiveDeepClone(raw);
            return allData.filter((d) => d.active);
        },
        dangerouslyAllowMutability: true
    }),
});


export const activatedMultiSelectionDataAtomFamily = atomFamily({
    key: "activatedMultiSelectionDataAtomFamily-Key",
    default: selectorFamily({
        key: 'activatedMultiSelectionDataAtomFamily/Default',
        get: id => ({get}) => {
            const rawSplitData = get(parallelCoordinatesChartDataActiveSelectorFamily(id));
            const allData = recursiveDeepClone(rawSplitData);
            const multiSelectionIds = get(visualizationMultiSelectionAtomFamily(id));
            let activeData = [];

            // allData.filter((d) => multiselectionIds.includes(d.index))
            allData.map((d, i) => {
                if(multiSelectionIds.includes(i)){
                    activeData.push(d)
                }
            })
            // console.log(activeData);
            return activeData;
        },
        dangerouslyAllowMutability: true
    }),
});


export const parallelCoordinatesChartSplitDataSelectorFamily = selectorFamily({
    key: 'parallelCoordinatesChartSplitDataSelectorFamily-Key',
    get: id => async ({get}) => {
        const raw = await get(parallelCoordinatesChartDataActiveSelectorFamily(id));
        const dimensions = get(parallelCoordinatesActiveDimensionsAtomFamily(id))
        const allData = recursiveDeepClone(raw);
        // const data = allData.filter((d) => d.active);

        //console.log(allData)
        allData.map((d) => {
            delete d.__typename;
            delete d.active;
            // d.year = isNaN(d.year) ? 0 : d.year;
            // d.topics = [FACET.getTopicNameById(topics, Object.keys(d.topics)[0])]
        });

        const dataPub = {};

        /*
        const prepareTopics = (topics) => {
    const sortedData = topics.sort(genericSort('total', 'desc'));
    const cpData = recursiveDeepClone(sortedData);
    cpData.map((d) => {
        d.name = d.text;
        delete d.text;
        delete d.years;
        delete d.malformedData;
        delete d.id;
        delete d.amount;
        delete d.lastMax;
    });
    return cpData;
}

         */

        for (let i = 0; i < dimensions.length; ++i) {
            const next = i < dimensions.length - 1 ? i + 1 : i - 1;
            dataPub[dimensions[i].name] = [];
            let indexI = 0;
            for (const d of allData) {
                // console.log(+d[dimensions[i]]);
                if (dataPub[dimensions[i].name] === 'year') {
                    dataPub[dimensions[i].name].push({
                        from: d[dimensions[i].name] !== null ? d[dimensions[i].name][0] : 0,
                        to: d[dimensions[next].name] !== null ? d[dimensions[next].name][0] : 0,
                        index: indexI,
                        active: false
                        // name: (d.name !== undefined && d.name !== null) ? d.name : 'no val'
                    });
                } else {
                    dataPub[dimensions[i].name].push({
                        from: d[dimensions[i].name] !== [] && d[dimensions[i].name] !== undefined  ? d[dimensions[i].name][0] : 'no value',
                        to: d[dimensions[next].name] !== [] && d[dimensions[next].name] !== undefined ? d[dimensions[next].name][0] : 'no value',
                        index: indexI,
                        active: false
                        // name: (d.name !== undefined && d.name !== null) ? d.name : 'no val'
                    });
                }
                indexI++;
            }
        }
        return dataPub;
    }
});

export const parallelCoordinatesChartSplitDataAtomFamily = atomFamily({
    key: "parallelCoordinatesChartSplitDataAtomFamily-Key",
    default: selectorFamily({
        key: 'parallelCoordinatesChartSplitDataAtomFamily/Default',
        get: id => () => {
            return parallelCoordinatesChartSplitDataSelectorFamily(id);
        },
    }),
});

