import React from 'react';
import {useRecoilState, useRecoilValue} from "recoil";
import {
    activeFilterSetIdAtom,
    amountFilteredDataSelector,
    filterSetsAtom, filterSetsForSubNavigationSelector,
} from "../../../dataProvider/vissights/search/filters/filters";
import * as _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {
    amountRawDataSelector, checkTotalAmount, isStillLoadingDataSelector,
    useLocalAsGlobalSearchDataAtom
} from "../../../dataProvider/vissights/search/search";
import uuid from "react-uuid";
import FacetFilters from "./FacetFilters/FacetFilters";
import PoiFilters from "./PoiFilters/PoiFilters";
import YearSliderFilters from "./YearSliderFilters/YearSliderFilters";
import DataLinkingFilters from "./DataLinkingFilters/DataLinkingFilters";


function SubNavigation() {
    const [activeFilterSetId, setActiveFilterSetId] = useRecoilState(activeFilterSetIdAtom);
    const [filterSets, setFilterSets] = useRecoilState(filterSetsAtom);
    // const [facetFilters, toggleFacetFilters] = useRecoilState(facetFiltersAtomFamily(activeFilterSetId));
    const resultAmount = useRecoilValue(amountRawDataSelector);
    const filteredResultsAmount = useRecoilValue(amountFilteredDataSelector);
    // stores the stuff we display here.
    const formattedFilterSets = useRecoilValue(filterSetsForSubNavigationSelector);
    const [useLocalAsGlobalSearchData, setUseLocalAsGlobalSearchData] = useRecoilState(useLocalAsGlobalSearchDataAtom);
    const isStillLoadingData = useRecoilValue(isStillLoadingDataSelector);
    const fullAmount = useRecoilValue(checkTotalAmount);

    // add a new filter set with a new unique id.
    const addNewFilterSet = () => {
        // console.log('add filter set')
        // check if there is a filter set that is empty. If we already have an empty filter set we switch to it.
        let useExistingFilterSet = false;
        formattedFilterSets.forEach((d) => {
            if (d.facetFilters.length <= 0 && d.isSliderActive === false && d.poiFilters.length <= 0) {
                useExistingFilterSet = true;
                setActiveFilterSetId(d.filterSetId);
            }
            //  console.log(d)
        })
        // if the existing filter sets are not empty we create a new filter set.
        if (!useExistingFilterSet) {
            const newUUID = uuid();
            // console.log(filterSets);
            setFilterSets((item) => [
                ...item,
                newUUID
            ]);
            setActiveFilterSetId(newUUID)
            // resetSliderValues();
        }
    }

    const removeFilterSetById = (idToRemove) => {
        const clone = _.cloneDeep(filterSets);
        const filtered = clone.filter((d) => d !== idToRemove);
        // set the active filter set to the last one in the list.
        if (filtered.length >= 1) {
            setActiveFilterSetId(filtered[filtered.length - 1])
        } else {
            const newUUID = uuid();
            filtered.push(newUUID)
            setActiveFilterSetId(newUUID)
        }
        setFilterSets(filtered);
    }

    const changeActiveFilterSet = (id) => {
        setActiveFilterSetId(id);
    }

    return (
        <div className="SubNav" style={{textAlign: 'left', display: 'flex'}}>

            <span style={{paddingRight: 3}}>
                Results:
            </span>

            {
            resultAmount !== filteredResultsAmount ?
                <b>{filteredResultsAmount}/{resultAmount}</b>
                :
                <b>{resultAmount} </b>
            }


            {isStillLoadingData ?
                <div>
                    <span style={{marginLeft: 5}}><b>{' '}/ {fullAmount}</b></span>
                    <div style={{marginLeft: 5}} className="lds-ring">
                        <div></div>
                    </div>
                </div> : null}

            <span style={{marginLeft: 5}}>Filters:</span>

            {
                formattedFilterSets.map((filter, index) => {
                    console.log(filter)
                    // eslint-disable-next-line no-mixed-operators
                    const activeFilterSetWrapper = filter.filterSetId === activeFilterSetId;
                    return (
                        <div
                            key={filter.filterSetId + '-wrapper'}
                            style={{
                                marginLeft: 5,
                                paddingLeft: 5,
                                marginRight: 5,
                                display: 'flex'
                            }}>
                            <div
                                key={filter.filterSetId + 'activeFilterSetWrapper-wrapper'}
                                className={activeFilterSetWrapper ? 'activeFilterSetWrapper' : 'inActiveFilterSetWrapper'}
                                onClick={() => {
                                    changeActiveFilterSet(filter.filterSetId)
                                }}>
                                {
                                    filter.isSliderActive && filter.manualSliderManipulation ?
                                        <YearSliderFilters filter={filter} filterSetId={filter.filterSetId}/>
                                        : null
                                }
                                {
                                    filter.facetFilters.map((d, j) => {
                                        return (
                                            <FacetFilters key={'facetFilter' + j} d={d} j={j} filterSetId={filter.filterSetId}/>
                                        )
                                    })
                                }
                                {
                                    filter.poiFilters.map((d, j) => {
                                        return (
                                            <PoiFilters d={d} j={j} filterSetId={filter.filterSetId}/>
                                        )
                                    })
                                }
                                {
                                    useLocalAsGlobalSearchData ? <DataLinkingFilters/> : null
                                }
                            </div>
                            {
                                ((filter.facetFilters.length > 0) || (filter.poiFilters.length > 0) || (filter.isSliderActive)) ?
                                    <FontAwesomeIcon
                                        style={{marginLeft: 5, marginRight: 5, marginTop: 5, cursor: "pointer"}}
                                        icon={faTrash}
                                        onClick={() => {
                                            removeFilterSetById(filter.filterSetId);
                                        }}
                                    />
                                    :
                                    null
                            }
                            {(index <= formattedFilterSets.length) ?
                                <span style={{marginLeft: 4, marginRight: 4}}>{' '} or {' '}</span> : null}
                        </div>
                    )
                })
            }
            <span>
                <FontAwesomeIcon icon={faPlus} style={{marginTop: 3, marginLeft: 8, cursor: "pointer"}} onClick={() => {
                    addNewFilterSet()
                }}/>
            </span>
        </div>
    );
}

export default SubNavigation;
