import {atom, atomFamily, selector, selectorFamily} from "recoil";
import FACET from "../../../../../../dataProvider/vissights/utility/facet-extraction-utility";

import * as _ from "lodash";
import {preProcessedFilteredSearchDataSelector} from "../../../../../../dataProvider/vissights/search/filters/filters";
import {recursiveDeepClone} from "../../../../../../utility";

export const chartDataGeoChartSelectorFamily = selectorFamily({
    key: 'chartDataGeoChartSelectorFamily-Key',
    get: (id) => ({get}) => {
        const dataModel = get(preProcessedFilteredSearchDataSelector);
        const data = dataModel[FACET.COUNTRY];
        // let lookupClone = _.cloneDeep(data.lookup);
        // const dataToPreprocess = _.cloneDeep(data);
        // dataToPreprocess.lookup = lookupClone;
        return data;
    },
});

export const geoChartDataAtomFamily = atomFamily({
    key: "geoChartDataAtomFamily-Key",
    default: selectorFamily({
        key: 'chartDataGeoChartSelectorFamily/Default',
        get: id => ({get}) => {
            return chartDataGeoChartSelectorFamily(id);
        },
    }),
});

export const geoChartMapDataSelector = selector({
    key: "geoChartMapDataSelector-Key",
    get: async () => {
        const rawData = await fetch('https://enjalot.github.io/wwsd/data/world/world-110m.geojson');
        const jsonData = await rawData.json()
        const jsonDataClone = recursiveDeepClone(jsonData)

        console.log(jsonDataClone)

        await jsonDataClone.features.map((d,i) => {
            if (d.id === 'USA' || d.id === 'US') {
                d.id = 'USA';
                d.properties.name = 'USA';
            }
            else if (d.id === 'GBR') {
                d.id = 'UK';
                d.properties.name = 'United Kingdom';
            } else if(d.id === 'RUS') {
                //d.id = 'Russian Federation';
                d.properties.name = 'Russian Federation';
            }

        });

        console.log(jsonDataClone)

        // ID muss zu unseren Daten passen und wird deshalb als ganzer name verwendet.
        await jsonDataClone.features.map((d) => d.id = d.properties.name);

        return jsonDataClone.features;
    },
});

export const geoChartMapDataAtom = atom({
    key: "geoChartMapDataAtom-Key",
    default: geoChartMapDataSelector
});

// state of top controls
export const displayPublicationLegendAtomFamily = atomFamily({
    key: "displayPublicationLegendAtom-Key",
    default: true
});

export const displayLinkedCountriesLegendAtomFamily = atomFamily({
    key: "displayLinkedCountriesLegendAtom-Key",
    default: false
});


export const displayTemporalOverviewInsideChartAtomFamily = atomFamily({
    key: "displayTemporalOverviewAtom-Key",
    default: true
});


export const displayAmountPublicationBubblesAtomFamily = atomFamily({
    key: "displayAmountPublicationBubblesAtom-Key",
    default: true
});


export const displayTrajectoryLinesAtomFamily = atomFamily({
    key: "displayTrajectoryLinesAtom-Key",
    default: true
});

export const chartZoomAtomFamily = atomFamily({
    key: "chartZoomAtomFamily-Key",
    default: undefined
});


