import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import {atom, useRecoilState, useRecoilValue} from "recoil";
import {Button, Col, FormCheck, Row} from "react-bootstrap";
import {reportingContentAtom} from "../state/ReportingState";
import domtoimage from 'dom-to-image';
import {saveAs} from 'file-saver';
import ReactQuill from "react-quill";
import {asBlob} from 'html-docx-js-typescript';
import jspdf from 'jspdf';
// call the fetchTopicOverview selector and store it as default value for the data atom
export const displayDownloadModalAtom = atom({
    key: "displayTopicDistributionModalAtom-Key",
    default: false,
});

function DownloadReportModal() {
    const [displayDownloadModal, setDisplayDownloadModal] = useRecoilState(displayDownloadModalAtom);
    const reportingContent = useRecoilValue(reportingContentAtom);
    const [checkBoxDownloadPdf, setCheckBoxDownloadPdf] = useState(false);
    const [checkBoxDownloadDocx, setCheckBoxDownloadDocx] = useState(false);
    const [checkBoxDownloadPng, setCheckBoxDownloadPng] = useState(false);
    const [fileName, setFileName] = useState('');

    const hide = () => {
        setDisplayDownloadModal(false);
    }

    const downloadFiles = () => {
        if (checkBoxDownloadPng) {
            saveReportAsPNG(fileName)
        }
        if (checkBoxDownloadPdf) {
            saveReportAsPDF(fileName)
        }
        if (checkBoxDownloadDocx) {
            saveReportAsDocx(fileName)
        }
    }


    const saveReportAsPNG = (fileName) => {
        const node = document.getElementById('report-preview');
        domtoimage.toBlob(node)
            .then((blob) => {
                saveAs(blob, fileName + '.png');
            })
            .catch((error) => {
                console.error('oops, something went wrong!', error);
            });
    }

    const saveReportAsPDF = (fileName) => {
        const node = document.getElementById('report-preview');
        domtoimage.toPng(node)
            .then((dataUrl) => {
                // const pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
                const pdf = new jspdf('p', 'cm', 'a4');
                // pdf.addImage(dataUrl, 'PNG', 0, 0, 29.7, 21.0);
                pdf.addImage(dataUrl, 'PNG', 2, 1, 19, 28.7);
                pdf.save(fileName + '.pdf');
            })
            .catch((error) => {
                console.error('oops, something went wrong!', error);
            });
    }

    const saveReportAsDocx = (fileName) => {
        // const htmlString = document.querySelector('.ql-editor').innerHTML;
        const htmlString = document.getElementById('report-preview').innerHTML;

        /*
        const headerHTMLString = [];
        const documentOptions = [];
        const footerHTMLString = [];
        await htmlDocx(htmlString, headerHTMLString, documentOptions, footerHTMLString)
         */
        // console.log(htmlString);

        // grab all images and give them a max width, so they do not overflow.
        /*
        const imgs = document.getElementById('report').querySelectorAll('img');
        imgs.forEach((d) => {
          console.log(d);
          d.style.setProperty('width', '300px', 'important')
        })
      */

        asBlob(htmlString).then(data => {
            // console.log('save', data);
            saveAs(data, fileName + '.docx') // save as docx file
        }) // asBlob() return Promise<Blob|Buffer>
            .catch((error) => {
                console.error('oops, something went wrong!', error);
            });

    }


    return (
        <Modal show={displayDownloadModal} onHide={hide} className="modal-download-report" id="downloadModal">
            <Modal.Body style={{width: '100vw !important'}}>

                <Row>
                    <Col xs={1} style={{textAlign: 'left'}}>
                        <h6>Download Settings </h6>
                        <input type="text" onChange={(e) => setFileName(e.target.value)} placeholder="type in a filename..."/>
                        <p>Download as:</p>
                        <span style={{display: 'flex'}}>
                             <FormCheck type="checkbox" defaultChecked={checkBoxDownloadPdf}
                                        onChange={(e) => {
                                            setCheckBoxDownloadPdf(!checkBoxDownloadPdf);
                                        }}
                             /> .pdf
                        </span>
                        <span style={{display: 'flex'}}>
                              <FormCheck type="checkbox" defaultChecked={checkBoxDownloadDocx}
                                         onChange={(e) => {
                                             setCheckBoxDownloadDocx(!checkBoxDownloadDocx);
                                         }}
                              /> .docx
                        </span>
                        <span style={{display: 'flex'}}>
                              <FormCheck type="checkbox" defaultChecked={checkBoxDownloadPng}
                                         onChange={(e) => {
                                             setCheckBoxDownloadPng(!checkBoxDownloadPng);
                                         }}
                              /> .png
                        </span>

                        <Button onClick={() => downloadFiles()}>Download</Button>

                    </Col>
                    <Col xs={7}>
                        Preview
                        <ReactQuill
                            id="report-preview"
                            value={reportingContent}
                            readOnly={true}
                            theme={"bubble"}
                        />
                    </Col>

                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default DownloadReportModal;