import React from 'react';
import {Button} from "react-bootstrap";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, errorInfo: "", history: props.history};

    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        // alert(error, errorInfo);
        // console.log(error)
        this.setState({errorInfo: error})
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div>
                    <h1>Something went wrong.</h1>
                   <p>Reaload the page please. <Button onClick={() => window.location.reload()}>Reaload Application</Button></p>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;