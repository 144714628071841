import {atom, selector} from "recoil";
import {moduleToDisplayAtom} from "../../components/Dashboards/DashboardLayout";


export const windowWidthAtom = atom({
    key: "windowWidthKey",
    default: window.innerWidth
});

export const windowHeightAtom = atom({
    key: "windowHeightKey",
    default: window.innerHeight
});

export const sizeConfigAtom = atom({
    key: "sizeConfigAtomKey",
    default: []
});


// calculates the size of the left nav by module. Module change triggers this one again.
export const leftSideNavWidthSelector = selector({
    key: 'leftSideNavWidthSelectorKey',
    get: ({get}) => {
        const module = get(moduleToDisplayAtom);
        const sizeConfig = get(sizeConfigAtom);

        let leftSideNavigationWidth;
        if (module === 'overview') {
            leftSideNavigationWidth = 0;
        } else if (module === 'zoomAndFilter' || module === 'profile') {
            leftSideNavigationWidth = sizeConfig.leftSideNavWidth;
        } else if (module === 'reporting') {
            leftSideNavigationWidth = 0;
        }
        return Number(leftSideNavigationWidth);
    }
})


// width of facet filters on left side of layout.
export const leftSideNavWidthAtom = atom({
    key: "leftSideNavWidthKey",
    default: leftSideNavWidthSelector
});


// calculates the size of the left nav by module. Module change triggers this one again.
export const subNavigationHeightSelector = selector({
    key: 'subNavigationHeightSelectorKey',
    get: ({get}) => {
        const module = get(moduleToDisplayAtom);
        const sizeConfig = get(sizeConfigAtom);
        let subNavigationHeight;
        if (module === 'overview' || module === 'profile') {
            subNavigationHeight = 0;
        } else if (module === 'zoomAndFilter') {
            subNavigationHeight = sizeConfig.subNavigationHeight;
        } else if (module === 'reporting') {
            subNavigationHeight = 0;
        }
        return Number(subNavigationHeight);
    }
})


// width of facet filters on left side of layout.
export const subNavigationHeightAtom = atom({
    key: "subNavigationHeightKey",
    default: subNavigationHeightSelector
});

export const mainNavigationHeightSelector = selector({
    key: 'mainNavigationHeightSelector',
    get: ({get}) => {
        const sizeConfig = get(sizeConfigAtom);
        return sizeConfig.mainNavigationHeight;
    }
})

// width of facet filters on left side of layout.
export const mainNavigationHeightAtom = atom({
    key: "mainNavigationHeightKey",
    default: mainNavigationHeightSelector
});


// used for toggling the right side nav
export const displayRightSideNavAtom = atom({
    key: "displayRightSideNavAtomKey",
    default: true
});


export const rightSideNavWidthSelector = selector({
    key: 'rightSideNavWidthSelectorsKey',
    get: ({get}) => {
        const display = get(displayRightSideNavAtom);
        const sizeConfig = get(sizeConfigAtom)
        return display ? sizeConfig.rightSideNavWidth : 0
    }
})

// width of right side vis container.
export const rightSideNavWidthAtom = atom({
    key: "rightSideNavWidthAtomKey",
    default: rightSideNavWidthSelector
});


export const dashboardWidthSelector = selector({
    key: 'dashboardWidthSelectorKey',
    get: ({get}) => {
        const module = get(moduleToDisplayAtom);
        const windowWidth = get(windowWidthAtom);
        const leftSideNavigationWidth = get(leftSideNavWidthAtom);
        const rightSideNavigationWidth = get(rightSideNavWidthAtom);
        let dashboardWidth;
        if (module === 'overview') {
            dashboardWidth = windowWidth - rightSideNavigationWidth;
        } else if (module === 'zoomAndFilter' ) {
            dashboardWidth = windowWidth - leftSideNavigationWidth - rightSideNavigationWidth;
        } else if (module === 'reporting' || module === 'profile' || module === 'projects') {
            dashboardWidth = windowWidth;
        }
        return Number(dashboardWidth);
    }
})


export const dashboardWidthAtom = atom({
    key: "dashboardWidthAtomKey",
    // gets calculated in the component
    default: dashboardWidthSelector
});


export const dashboardHeightSelector = selector({
    key: 'dashboardHeightSelectorKey',
    get: ({get}) => {
        const module = get(moduleToDisplayAtom);
        const windowHeight = get(windowHeightAtom);
        const mainNavHeight = get(mainNavigationHeightAtom);
        const subNavHeight = get(subNavigationHeightAtom);
        let dashboardHeight;
        if (module === 'overview') {
            dashboardHeight = windowHeight - mainNavHeight - subNavHeight;
        } else if (module === 'zoomAndFilter' || module === 'profile' || module === 'projects') {
            dashboardHeight = windowHeight - mainNavHeight - subNavHeight;
        } else if (module === 'reporting') {
            dashboardHeight = windowHeight - mainNavHeight;
        }
        return Number(dashboardHeight);
    }
})


export const dashboardHeightAtom = atom({
    key: "dashboardHeightAtomKey",
    default: dashboardHeightSelector
});

// height of facet filters on left side of layout.
export const leftSideNavHeightAtom = atom({
    key: "leftSideNavHeightKey",
    default: dashboardHeightSelector
});
