import Keycloak from "keycloak-js";
const keycloak = new Keycloak({
    // url: "http://localhost:8080/auth",
    //url: "https://sina.media.h-da.de/auth",
    url:  "https://auth.morpheus.fbmd.h-da.de/auth",
    realm: "vissights",
    clientId: process.env.NODE_ENV !== 'production' ? "react-front-end-development" : "react-front-end-production",
    //checkLoginIframe: false,
    //redirectUri: "http://localhost:3000/#/profile",
    //responseMode: 'fragment'
});

export default keycloak;