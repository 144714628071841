import React from 'react';
import {Spinner} from "react-bootstrap";
import {useRecoilValue} from "recoil";
import {leftSideNavHeightAtom} from "../../../config/sizings/sizings";
const LeftNavigationItems = React.lazy(() => import('./LeftNavigationItems/LeftNavigationItems'));

function LeftNavigation() {
    const dashboardHeight = useRecoilValue(leftSideNavHeightAtom);
    return (
        <div style={{
            textAlign: "left",
            borderRight: '1px solid grey',
            height: dashboardHeight,
            overflowY: "auto"
        }}>

            <React.Suspense
                fallback={<Spinner
                    style={{
                        marginTop: '30vh',
                        width: 150,
                        height: 150,
                        textAlign: 'center',
                        marginLeft: 'auto'
                    }}
                    animation="border"/>}>
                <LeftNavigationItems/>
            </React.Suspense>
        </div>
    );
}

export default LeftNavigation;

