import React, {useEffect, useRef} from "react";
import * as d3 from 'd3';
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {contextMenuDataAtom, displayContextMenuAtom} from "./state";
import {searchDatabase, searchTermAtom} from "../../../dataProvider/vissights/search/search";
import {poiAtomFamily} from "../../GlobalNavigations/LeftNavigation/LeftNavigationState";
import uuid from "react-uuid";
import * as _ from "lodash";
import {calculateRandomHueValue} from "../../../styles/colors";
import {activeFilterSetIdAtom} from "../../../dataProvider/vissights/search/filters/filters";
import {vissightsConfig} from "../../../dataProvider/vissights/vissightsConfig";


function ContextMenu() {
    const ref = useRef()
    const contextMenuWidth = 200;
    const contextMenuHeight = 200;
    const setDisplayContextMenu = useSetRecoilState(displayContextMenuAtom);
    const setSearchTerm = useSetRecoilState(searchTermAtom);
    const activeFilterSetId = useRecoilValue(activeFilterSetIdAtom);
    const [poiList, setPoiList] = useRecoilState(poiAtomFamily(activeFilterSetId));
    const contextMenuData = useRecoilValue(contextMenuDataAtom);
    const setCurrDb = useSetRecoilState(searchDatabase);

    const menuStringGenerator = () => {
        const menu = {}
        menu.name = "menu";
        menu.children = [];
        // add the add poi
        // menu.children.push({name: "pois", children: [{name: "add POI", value: 360, action: 'addPoi'}]});
        menu.children.push({name: "gap analysis", children: [{name: "display", value: 80, action: 'displayResults'}]});

        // start search logic
        const dbs = [];
        // map all available databases
        vissightsConfig.dbConfig.forEach((d) => dbs.push({name: d.name, dbData: d, value: 10, action: 'startSearch'}));
        // append databases as submenu point
        menu.children.push({name: "search", children: dbs});
        return menu;
    }

    const addNewPoi = (newPoiName) => {
        const newUUID = uuid();
        const clone = _.cloneDeep(poiList)
        clone.push({id: newUUID, name: newPoiName, active: true, color: calculateRandomHueValue(clone, 35)});
        setPoiList(clone);
    }

    const startSearch = (dbData, query) => {
        setDisplayContextMenu(false);
        setCurrDb(dbData);
        setSearchTerm(query);
    }

    useEffect(() => {
        removeMenu();
        drawMenu();
    });

    const drawMenu = () => {
        const parsedData = menuStringGenerator();

        const partition = (data) => {
            const roots = d3.hierarchy(data)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value);
            return d3.partition()
                .size([2 * Math.PI, roots.height + 1])
                (roots);
        };

        const radius = 50;

        const arc = d3.arc()
            .startAngle(d => d.x0)
            .endAngle(d => d.x1)
            .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
            .padRadius(radius * 1.5)
            .innerRadius(d => d.y0 * radius)
            .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1));

        const root = partition(parsedData);

        root.each(d => d.current = d);

        const svg = d3.select(ref.current);

        svg.attr('height', 200);

        svg.on('contextMenu', () => d3.event.preventDefault());

        const menuGroup = svg
            .selectAll('g');

        // reposition the svg and the circle menus group so that the chart is centered on mouseX and mouseY
        // svg.attr('transform', 'translate(-' + (contextMenuWidth / 2)+ ',-' + (contextMenuHeight / 2) + ')');

        menuGroup.attr('transform', 'translate(' + (contextMenuWidth / 2) + ',' + (contextMenuHeight / 2) + ')');

        // color scale
        const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, parsedData.children.length + 1));

        // label formatting
        const format = d3.format(',d');

        // append the path elements
        const path = menuGroup
            .append('g')
            .selectAll('path')
            .data(root.descendants().slice(1))
            .join('path')
            .attr('fill', d => {
                while (d.depth > 1) d = d.parent;
                return color(d.data.name);
            })
            .attr('fill-opacity', d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
            .attr('d', d => arc(d.current));

        path.filter(d => d.children)
            .style('cursor', 'pointer')
            .on('click', clicked);


        const action = (d, i, n) => {
            if (d.data.action !== undefined) {
                if (d.data.action === 'addPoi') {
                    addNewPoi(contextMenuData.name)
                } else if (d.data.action === 'startSearch') {
                    // give db name and the elements name which is the new query to startSearch
                    startSearch(d.data.dbData, contextMenuData.name)
                }
            }
        };

        // if there are no children, we call the actions defined
        path.filter(d => !d.children)
            .style('cursor', 'pointer')
            .on('click', action);

        // append tooltips (show the nested structure)
        path.append('title')
            .text(d => `${d.ancestors().map((e) => e.data.name).reverse().join('/')}\n${format(d.value)}`);

        // append the textual labels
        const label = menuGroup.append('g')
            .attr('pointer-events', 'none')
            .attr('text-anchor', 'middle')
            .style('user-select', 'none')
            .selectAll('text')
            .data(root.descendants().slice(1))
            .join('text')
            .attr('dy', '0.35em')
            .attr('fill-opacity', d => +labelVisible(d.current))
            .attr('transform', d => labelTransform(d.current))
            .text(d => d.data.name);

        const parent = menuGroup.append('circle')
            .datum(root)
            .attr('r', radius)
            .attr('fill', 'none')
            .attr('pointer-events', 'all')
            .on('click', clicked);

        function clicked(p) {
            parent.datum(p.parent || root);
            root.each(d => d.target = {
                x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                y0: Math.max(0, d.y0 - p.depth),
                y1: Math.max(0, d.y1 - p.depth)
            });

            const t = menuGroup.transition().duration(750);

            // Transition the data on all arcs, even the ones that aren’t visible,
            // so that if this transition is interrupted, entering arcs will start
            // the next transition from the desired position.
            path.transition(t)
                .tween('data', d => {
                    const i = d3.interpolate(d.current, d.target);
                    return t => d.current = i(t);
                })
                .filter(function (d) {
                    return +this.getAttribute('fill-opacity') || arcVisible(d.target);
                })
                .attr('fill-opacity', d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
                .attrTween('d', d => () => arc(d.current));

            label.filter(function (d) {
                return +this.getAttribute('fill-opacity') || labelVisible(d.target);
            }).transition(t)
                .attr('fill-opacity', d => +labelVisible(d.target))
                .attrTween('transform', d => () => labelTransform(d.current));
        }

        function arcVisible(d) {
            return d.y1 <= 2 && d.y0 >= 1 && d.x1 > d.x0;
        }

        function labelVisible(d) {
            return d.y1 <= 2 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
        }

        function labelTransform(d) {
            const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
            const y = (d.y0 + d.y1) / 2 * radius;
            return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
        }


    }

    const removeMenu = () => {
        d3.select(ref.current)
            .selectAll('g')
            .selectAll('g')
            .remove();
    }

    return (
        <svg ref={ref}>
            <g className='circle-menu'>test</g>
        </svg>
    );
}

export default ContextMenu;


