import React, {useEffect, useRef} from "react";
import * as d3 from 'd3';
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {
    forceRedrawAtomFamily,
    normalizeAtomFamily,
    subVisualizationPinAtomFamily,
    visualizationPinAtomFamily,
} from "../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import {
    bubbleChartBarActiveFillColor,
    bubbleChartBarFillColor, bubbleChartBarStrokeColor,
    bubbleChartBubbleActiveFillColor,
    bubbleChartBubbleActiveStrokeColor,
    bubbleChartBubbleInnerRelationalFillColor,
    bubbleChartBubbleInnerRelationalStrokeColor,
    bubbleChartBubbleStrokeColor,
    bubbleChartTextColor,
    calculateBubbleColorBySignalStrength,
} from "../../../../../styles/colors";
import {activeFacetAtomFamily} from "../../../overview/visualizations/state";
import {genericSort, recursiveDeepClone} from "../../../../../utility";
import FACET from "../../../../../dataProvider/vissights/utility/facet-extraction-utility";

function BubbleChart(props) {
    const ref = useRef();
    const componentId = props.id;
    const containerWidth = props.width;
    const containerHeight = props.height;
    const toggleFacetFilter = props.toggleFacetFilter;
    let data = recursiveDeepClone(props.data);
    const prevData = recursiveDeepClone(props.prevData);
    const [pinId, setNewPinId] = useRecoilState(visualizationPinAtomFamily(componentId));
    const resetPinId = useResetRecoilState(visualizationPinAtomFamily(componentId))
    const normalization = useRecoilValue(normalizeAtomFamily(componentId));
    const activeFacet = useRecoilValue(activeFacetAtomFamily(componentId));
    const [pinnedYear, setPinnedYear] = useRecoilState(subVisualizationPinAtomFamily(componentId));
    // const [forceRedraw, setForceRedraw] = useResetRecoilState(forceRedrawAtomFamily(componentId));

    const toggleContextMenu = props.toggleContextMenu;
    let barChartHeight;
    let bubbleChartHeight;
    // data
    let bubbleChartData = recursiveDeepClone(data);
    let barChartData;
    const bubbleChartWidth = containerWidth;
    const barChartWidth = containerWidth;
    let hoveredElement;
    let yearDataForPinnedElement;

    /************* Color imports from color.js **********/
        // bubbles
    const bubbleStrokeColor = bubbleChartBubbleStrokeColor();
    const bubbleActiveStrokeColor = bubbleChartBubbleActiveStrokeColor();
    const bubbleTextColor = bubbleChartTextColor();
    const bubbleActiveFillColor = bubbleChartBubbleActiveFillColor();
    // inner relation colors
    const bubbleInnerRelationFillColor = bubbleChartBubbleInnerRelationalFillColor();
    const bubbleInnerRelationStrokeColor = bubbleChartBubbleInnerRelationalStrokeColor();
    // bars
    const barFillColor = bubbleChartBarFillColor();
    const barActiveFillColor = bubbleChartBarActiveFillColor();
    const barStrokeColor = bubbleChartBarStrokeColor();


    // redraw cycle of the bubble chart
    useEffect(() => {
        barChartHeight = containerHeight / 8;
        if (pinId !== '-1') {
            bubbleChartHeight = containerHeight - barChartHeight;
        } else {
            bubbleChartHeight = containerHeight;
        }
        removeBubbleChart();
        if (containerWidth > 0) {
            drawBubbleChart();
        }
    }, [containerWidth, data, containerHeight, pinId, data]);


    /*
    useEffect(() => {
        drawBubbleChart();
    },[forceRedraw])
*/

    // redraw cycle of the barchart
    useEffect(() => {
        if (pinId !== '-1') {
            drawBarChart();
            drawBarChart();
            drawAxis();
        } else {
            removeBarChart();
        }
    }, [pinId, setNewPinId, data, containerWidth, containerHeight, pinnedYear]);


    const formatBarChartData = () => {
        const findPinData = data.find(d => d.id === pinId);
        let years = []
        if (findPinData !== undefined) {
            years = findPinData.years;
        }

        let yearsObj = {};
        // tslint:disable-next-line:forin
        for (const y in years) {
            yearsObj[y] = {
                year: parseInt(y, 10),
                amount: findPinData.years[y],
            };
        }
        yearsObj = d3.values(yearsObj);
        yearDataForPinnedElement = yearsObj;
        return yearsObj;
    }

    const pinElementById = (pin) => {
        if (pin === pinId) {
            resetPinId();
        } else {
            setNewPinId(pin);
        }
    }

    const setFacetFilter = (facet, value) => {
        toggleFacetFilter(facet, value)
    }

    /*********** Draw x axis *********/
    const drawAxis = () => {
        const xScale = scaleXY().x;

        /************** Calculate ticks **************/
            // calculation of ticks.
        let tickValues = xScale.domain();
        if (barChartWidth < tickValues.length * (32 + 8) / 5) {
            tickValues = xScale.domain().filter((d) => d % 10 === 0);
        } else if (barChartWidth < tickValues.length * (32 + 8) / 2) {
            tickValues = xScale.domain().filter((d) => d % 5 === 0);
        } else if (barChartWidth < tickValues.length * (32 + 8)) {
            tickValues = xScale.domain().filter((d) => d % 2 === 0);
        }

        const xAxis = d3.axisBottom()
            .scale(xScale)
            .tickValues(tickValues)
            .tickSize(1)
            .tickPadding(4);

        d3.select(ref.current)
            .select('.bar-chart-group')
            .select('.axis')
            .attr('transform', 'translate(0,' + (barChartHeight) + ')')
            .call(xAxis);
    }


    const drawBubbleChart = () => {
        //console.log('draw bubbles')
        const onMouseOver = (d) => {
            hoveredElement = d;
            // select all items that have the id of the selected item in their links...
            d3.select(ref.current)
                .selectAll('.bubble-chart-group')
                .selectAll('g')
                .filter((e) => d.data.links.hasOwnProperty(e.data.id))
                .attr('opacity', 1)
                .selectAll('circle')
                .attr('stroke', bubbleActiveStrokeColor)
                .attr('fill', bubbleActiveFillColor)
                .attr('stroke-width', (e) => e.data.id === d.data.id ? 2 : 1)
                .attr('fill', (e) => pinId !== d.data.name && e.data.links.hasOwnProperty(pinId) ? bubbleInnerRelationFillColor : bubbleActiveFillColor)
                .attr('stroke', (e) => pinId !== d.data.name && e.data.links.hasOwnProperty(pinId) ? bubbleInnerRelationStrokeColor : bubbleActiveFillColor);

            // if (pinnedYear !== '-1') {

            const bars = d3.select(ref.current).select('.bar-chart-group').select('g.bars').selectAll('g.bar-group').selectAll('rect');


            bars.attr('fill', (e) => {
                if (d.data.hasOwnProperty('years')) {
                    if (d.data.years.hasOwnProperty(e.year)) {
                        // console.log('make green')
                        return bubbleActiveFillColor;
                    } else {
                        return barFillColor;
                    }
                } else {
                    return barFillColor;
                }
            })
            // }
        }

        const onMouseOut = (d) => {
            d3.select(ref.current)
                .selectAll('.bubble-chart-group')
                .selectAll('g')
                .filter((e) => d.data.links.hasOwnProperty(e.data.id))
                .attr('opacity', () => pinId === '-1' || d.data.links.hasOwnProperty(pinId) || (d.data.id === pinId) ? 1 : 0.3)
                .select('circle')
                .attr('stroke', bubbleStrokeColor)
                .attr('fill', (e) => e.color)
                .attr('stroke-width', (e) => pinId !== '-1' && pinId === e.id ? 2 : 1);
            hoveredElement = '-1';
        };

        d3.select(ref.current)
            .attr('width', bubbleChartWidth)
            .attr('height', containerHeight);


        const min = d3.min(bubbleChartData.map((d) => d.amount));
        const max = d3.max(bubbleChartData.map((d) => d.amount));

        const normalize = d3.scaleLinear()
            .domain([min, max])
            .range([0.6, 1]);

        bubbleChartData.sort(genericSort('amount', 'desc'));

        // define the root node which has the data as child
        const root = d3.hierarchy({children: bubbleChartData})
            .sum((d) => {
                // scaleLinear if normalization is true...
                if (normalization === true) {
                    return normalize(d.amount);
                } else {
                    return d.amount;
                }
            });

        // define a layout for our bubble chart
        const pack = d3.pack()
            .size([bubbleChartWidth, bubbleChartHeight - 40])
            .padding(2)

        // pack our data
        bubbleChartData = pack(root);
        bubbleChartData.children.map((d) => {
            d.color = calculateBubbleColorBySignalStrength(d.signal);
            return d;
        });

        /*
        const bubbleChartDataPrev = pack(root);
        bubbleChartDataPrev.children.map((d) => {
            d.color = calculateBubbleColorBySignalStrength(d.signal);
            return d;
        });
*/

        /****
         * NodeGroup ----------------> the group which is wrapper for all bubbles
         * bubbleGroup ---------------> wrapper for each (circle, text, tooltip)
         ****/

        const nodeGroup = d3.select(ref.current)
            .selectAll('.bubble-chart-group')
            .selectAll('g')
            .data(bubbleChartData.children, (d) => d.id);


        const bubbleGroup = nodeGroup
            .enter()
            .append('g')
            .attr('transform', (d) => `translate(${d.x},${d.y})`)
            .on('mouseover', onMouseOver)
            .on('mouseout', onMouseOut)
            .on('click', (d) => pinElementById(d.data.id))
            .on('dblclick', (d) => setFacetFilter(activeFacet, d.data.id))
            .on('contextmenu', (d) => toggleContextMenu(d.data.name, ref.current))
            .attr('opacity', (e) => pinId === '-1' || e.data.links.hasOwnProperty(pinId) || (e.data.id === pinId) ? 1 : 0.3);


        bubbleGroup
            .append('circle')
            .attr('fill', (d) => d.color)
            /*
            .attr('stroke-fill', (d) => {
                if (pinnedYear !== '-1') {
                    if (d.data.years !== undefined) {
                        if (d.data.years.hasOwnProperty(pinnedYear)) {
                            return "red"
                        }
                    }
                } else {
                    return "green";
                }


            })
*/

            .attr('cursor', 'pointer')
            // .transition()
            // .delay(1000)
            // .duration(800)
            .attr('r', (d) => d.r);


        bubbleGroup
            .append('text')
            .text((d) => {
                const len = d.r / 3.8;
                return (activeFacet === 'Authors' ? shortenBubbleAuthorName(d.data.name, len) : d.data.name).substring(0, len);
            })
            //  .attr('opacity', () => pinId === '-1' || d.data.links.hasOwnProperty(pinId) || (d.data.id === pinId) ? 1 : 0.3)
            .attr('font-weight', (d, i) => {
                if (pinnedYear !== '-1') {
                    if (d.data.years !== undefined) {
                        if (d.data.years.hasOwnProperty(pinnedYear)) {
                            return 'bold'
                        }
                    }
                } else {
                    return '500'
                }
            })
            .attr('dy', '.3em')
            .attr('text-anchor', 'middle')
            .attr('cursor', 'pointer');

        bubbleGroup
            .append('title')
            .text((d) => d.data.name);

    }

    const shortenBubbleAuthorName = (name, radius) => {
        if (name !== undefined) {
            const maxLength = radius / 7;
            // Name ist ein String der kurz genug ist um angezeigt zu werden.
            if (name.length <= maxLength) {
                return name;
            }
            // Aufsplitten des Namens falls möglich
            let parts = name.split(' ');
            let lastName = parts[parts.length - 1];
            // Name ist eine Zahl...
            if (parseInt(lastName, 10)) {
                parts = parts.slice(0, parts.length - 1);
                lastName = parts[parts.length - 1];
            }
            if (lastName.length >= maxLength) {
                return lastName;
            }
            let currentLength = lastName.length;
            let abbrevation = '';
            for (let i = 0; i < parts.length - 1; i++) {
                let part = parts[i];
                if (part.length > 2) {
                    part = `${part[0]}.`;
                }
                currentLength += part.length + 1;
                if (currentLength > maxLength) {
                    break;
                }
                abbrevation += `${part} `;
            }
            abbrevation += lastName;
            return abbrevation;
        } else {
            return;
        }
    }

    const removeBubbleChart = () => {
        d3.select(ref.current)
            .selectAll('.bubble-chart-group')
            .selectAll('g')
            .remove();
    }

    // calculate scaling for bar chart. It is returned as an object and can be accessed as .x or .y
    const scaleXY = () => {
        const w = barChartWidth;
        const h = barChartHeight - 15;
        const data = d3.values(yearDataForPinnedElement);
        const [minYear, maxYear] = d3.extent(data, (e) => e.year);
        const maxAmount = d3.max(data, (e) => e.amount);
        return {
            x: d3.scaleBand()
                .domain(d3.range(minYear, maxYear + 1))
                .range([0, w]),
            y: d3.scaleLinear()
                .domain([0, maxAmount])
                .range([0, h])
        };
    }

    const focusYear = (year) => {
        if (year === pinnedYear) {
            setPinnedYear('-1');
        } else {
            setPinnedYear(year);
        }
    }

    const setFacetFilterYear = (year) => {
        toggleFacetFilter(FACET.YEAR, year)
    }

    /**
     *  BAR CHART
     */
    const drawBarChart = () => {
        const height = barChartHeight;
        const width = barChartWidth;
        const svg = d3.select(ref.current);
        const barChartGroup = svg.select('.bar-chart-group');
        const pin = pinId;

        // transform the y coordinate so that the barchart is underneath the bubble chart
        barChartGroup.attr('transform', 'translate(0,' + (bubbleChartHeight - 20) + ')');

        const opacityOnFocus = (d) => {
            if (pinnedYear !== '-1') {
                return d.year === pinnedYear ? 1 : 0.3;
            } else {
                return 1;
            }
        }

        const xScale = scaleXY().x;
        const yScale = scaleXY().y;
        const yearsData = formatBarChartData();

        if (yearsData.length <= 0) {
            removeBarChart();
            d3.select(ref.current)
                .select('.bar-chart-group')
                .selectAll('g .no-data')
                .remove();

            d3.select(ref.current)
                .select('.bar-chart-group')
                .append('g')
                .attr('class', 'no-data')
                .append('text')
                .attr('class', 'whiteOnDarkMode')
                .text('No temporal data available.');

        } else {
            // remove the no data text.
            d3.select(ref.current)
                .select('.bar-chart-group')
                .selectAll('g .no-data')
                .remove();


            const onMouseOverBar = (d) => {
                hoveredElement = d;
                // console.log(hoveredElement);

                // select all items that have the id of the selected item in their links...

                d3.select(ref.current)
                    .selectAll('.bubble-chart-group')
                    .selectAll('g')
                    .filter((e) => {
                        if (e.data.hasOwnProperty("years")) {
                            return e.data.years.hasOwnProperty(d.year);
                        } else {
                            return;
                        }
                    })
                    // d.data.years.hasOwnProperty(e.year))
                    .attr('opacity', 1)
                    .selectAll('circle')
                    .attr('stroke', bubbleActiveStrokeColor)
                    .attr('fill', bubbleActiveFillColor)
                    .attr('stroke-width', (e) => e.data.years.hasOwnProperty(d.year) ? 2 : 1)
                    .attr('fill', (e) => e.data.years.hasOwnProperty(d.year) ? bubbleInnerRelationFillColor : bubbleActiveFillColor)
                    .attr('stroke', (e) => e.data.years.hasOwnProperty(d.year) ? bubbleInnerRelationStrokeColor : bubbleActiveFillColor);


            };


            const onMouseLeaveBar = (d) => {
                // console.log(d);
                d3.select(ref.current)
                    .selectAll('.bubble-chart-group')
                    .selectAll('g')
                    .filter((e) => {
                        if (e.data.hasOwnProperty("years")) {
                            return e.data.years.hasOwnProperty(d.year)
                        } else {
                            return;
                        }
                    })
                    .attr('opacity', (e) => pinId === '-1' || e.data.links.hasOwnProperty(pinId) || (e.data.id === pinId) ? 1 : 0.3)
                    .select('circle')
                    .attr('stroke', bubbleStrokeColor)
                    .attr('fill', (e) => e.color)
                    .attr('stroke-width', 1);
                hoveredElement = '-1';
            };

            /************ CREATE THE BARS ************/
            const bar = barChartGroup
                .select('.bars')
                .selectAll('g')
                .on('mouseover', onMouseOverBar)
                .on('mouseleave', onMouseLeaveBar)
                .on('click', (d) => focusYear(d.year))
                .on('dblclick', (d) => setFacetFilterYear(d.year))
                .data(yearsData);

            const barGroup = bar
                .enter()
                .append('g')
                .attr('class', 'bar-group')
                .attr('pointer-events', 'all')
                .attr('cursor', 'pointer');

            barGroup
                .append('rect')
                .attr('class', 'bar')
                .attr('fill', barFillColor)
                .attr('stroke', barStrokeColor)
                .attr('stroke-width', 1)
                .attr('shape-rendering', 'crispEdges')
                .attr('pointer-events', 'all');

            barGroup
                .append('text')
                .attr('pointer-events', 'all')
                .attr('dy', '.3em')
                .attr('text-anchor', 'middle');

            bar.select('.bar')
                .attr('x', (d) => xScale(d.year) + 3)
                .attr('y', (d) => (height - yScale(d.amount)))
                .attr('width', xScale.bandwidth() - 6)
                .attr('height', (d) => yScale(d.amount))
                .attr('opacity', (d) => opacityOnFocus(d));

            bar.select('text')
                .attr('x', (d) => xScale(d.year) + xScale.bandwidth() / 2)
                .attr('y', (d) => height - yScale(d.amount))
                .text((d) => d.amount)
                .attr('class', 'whiteOnDarkMode')
                .attr('opacity', (d) => opacityOnFocus(d));
            bar.exit()
                .remove();
        }
    }

    const removeBarChart = () => {
        d3.select(ref.current)
            .select('.bar-chart-group')
            .select('.bars')
            .selectAll('g')
            .remove();

        d3.select(ref.current)
            .select('.bar-chart-group')
            .select('.axis')
            .selectAll('path')
            .remove();


        d3.select(ref.current)
            .select('.bar-chart-group')
            .select('.axis')
            .selectAll('g')
            .remove();
    }

    return (
        <svg ref={ref}>
            <g className="bubble-chart-group"/>
            <g className="bar-chart-group">
                <g className="axis"/>
                <g className="bars"/>
            </g>
            <g className="bench-mark-group"/>
        </svg>
    );

}

export default BubbleChart;


