import React, {useEffect, useRef} from "react";
import * as d3 from 'd3';
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {
    visualizationPinAtomFamily,
} from "../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import {activeFacetAtomFamily} from "../../../overview/visualizations/state";
import {
    calculateDonutSliceColorBySignalStrength,
    donutChartActiveDonutFillColor,
    donutChartActiveDonutStrokeColor,
    donutChartStandardDonutStrokeColor,
    donutSlicePreviewColor,
} from "../../../../../styles/colors";


function DonutChart(props) {
    const componentId = props.id;
    const ref = useRef()
    const containerWidth = props.width;
    const containerHeight = props.height;
    const calculatedData = props.data;
    const toggleFacetFilter = props.toggleFacetFilter;
    // const [hoveredElementId, setHoveredElementId] = useRecoilState(hoveredElementIdAtomFamily(componentId));
    const [pinId, setNewPinId] = useRecoilState(visualizationPinAtomFamily(componentId));
    const resetPinId = useResetRecoilState(visualizationPinAtomFamily(componentId))
    const activeFacet = useRecoilValue(activeFacetAtomFamily(componentId));
    const toggleContextMenu = props.toggleContextMenu;

    useEffect(() => {
        createChart();
    });

    const createChart = () => {
        // rebind zoom on changes.
        removeChart();
        // drawAxisAndOverlay();
        updateChart();
    }


    const pinElementById = (pin) => {
        if (pin === pinId) {
            resetPinId();
        } else {
            setNewPinId(pin)
        }
    }

    const setFacetFilter = (facet, value) => {
        toggleFacetFilter(facet, value)
    }


    /**
     * Important! Because of the pack layout, we have to access the attributes of each obj as d.data.xy
     */

    const updateChart = () => {
        /************* Define variables *************/
        const w = containerWidth;
        const h = containerHeight;
        const data = calculatedData;
        // calculate the radius, which is the minimum of width and height / 2. We substract 5 to make it not stick to the borders.
        const radius = (Math.min(w, h) / 2) - 5;

        d3.select(ref.current)
            .attr('width', containerWidth)
            .attr('height', containerHeight);

        // we do some calculations for the displaying of the current active amount inside the donut
        const currentSum = calculatedData.reduce((acc, curr) => acc + curr.y, 0);
        const currActiveElement = calculatedData.filter((d) => d.id === pinId);
        const currActiveAmount = currActiveElement.map((d) => d.y);
        const currentAmountString = (currActiveAmount !== undefined && pinId !== '-1') ? (currActiveAmount + '/' + currentSum) : '0' + '/' + currentSum;

        /************ Define our shorthand arrow functions, they do simple tasks and definitions for us *********/
            // defines what happens when the mouse is over a bar.
        const onMouseOver = (d, i, n) => {
                // element n[i] refers to the current element
                const currentElement = n[i];
                d3.select(currentElement)
                    .attr('fill', donutChartActiveDonutFillColor)
                    .attr('stroke', donutChartActiveDonutStrokeColor)
                    .attr('opacity', 1);
            };

        // defines what happens when the mouse leaves a bar.
        const onMouseLeave = (d, i, n) => {
            // element n[i] refers to the current element
            const currentElement = n[i];
            d3.select(currentElement)
                .attr('fill', (e) => calculateDonutSliceColorBySignalStrength(e.data.signal))
                .attr('stroke', donutChartStandardDonutStrokeColor);
            chartGroup.selectAll('path')
                .attr('opacity', (e) => calculateOpacity(e));
        };

        const shortenText = (text, maxLength) => {
            let shortText = text;
            const length = text.length;
            if (length > maxLength / 8) {
                shortText = text.slice(0, maxLength / 8);
                shortText = shortText.slice(0, shortText.lastIndexOf(' '));
                return shortText + '..';
            }
            return shortText;
        }


        // checks if a pin is set, and changes the opacity.
        const calculateOpacity = (d) => {
            // wenn es einen aktiven pin gibt.
            if (pinId !== '-1') {
                return d.data.id === pinId ? 1 : 0.4;
            } else {
                return 1;
            }
        };

        // Computes the angle of an arc, converting from radians to degrees.
        const calculateAngle = (d) => {
            const angle = (d.startAngle + d.endAngle) * 90 / Math.PI - 90;
            return angle > 90 ? angle - 180 : angle;
        };

        const chartGroup =
            d3.select(ref.current)
                .select('g');

        // translate to the center of the container
        chartGroup
            .attr('transform', 'translate(' + (containerWidth / 2) + ',' + ((containerHeight) / 2) + ')');

        const pie = d3.pie()
            .value((d) => d.y)
            .padAngle(.01)
            .sort(null);

        const dataReady = pie(data);

        const donutPieces = chartGroup
            .selectAll('path')
            .data(dataReady)
            .enter()
            .append('g');

        // makes more inner radius when we select a slice. So the text gets displayed properly :)
        const arc = pinId === '-1' ?
                d3.arc()
                    // change inner radius to create a donut chart
                    .innerRadius(radius / 6)
                    .outerRadius(radius)
                :
                d3.arc()
                    // change inner radius to create a donut chart
                    .innerRadius(radius / 4)
                    .outerRadius(radius);


        // adds the circle and the arcs.
        donutPieces
            .append('path')
            .attr('d', arc)
            .attr('fill', (d) => d.data.preview ? donutSlicePreviewColor() : calculateDonutSliceColorBySignalStrength(d.data.signal))
            .attr('stroke', donutChartStandardDonutStrokeColor)
            .attr('stroke-width', '1')
            .attr('opacity', (d) => calculateOpacity(d))
            .attr('cursor', 'pointer')
            .on('mouseover', onMouseOver)
            .on('mouseleave', onMouseLeave)
            .on('click', (d) => pinElementById(d.data.id))
            .on('dblclick', (d) => setFacetFilter(activeFacet, d.data.id))
            .on('contextmenu', (d) => toggleContextMenu(d.data.name, ref.current));


        // append text labels
        donutPieces
            .append('text')
            .text((d) => ((d.endAngle - d.startAngle) > 0.2) ? shortenText(d.data.name, donutPieces._groups[0][0].getBBox().width - 100) : '')
            .attr('cursor', 'pointer')
            .attr('text-anchor', 'middle')
            .attr('dy', '.35em')
            .attr('transform', (d) => 'translate(' + arc.centroid(d) + ')rotate(' + calculateAngle(d) + ')')
            .attr('opacity', (d) => calculateOpacity(d));

        // Append tooltips.
        donutPieces
            .append('title')
            .text((d) => d.data.name + ' ' + '(' + d.data.y + ')');

        const centerText = donutPieces
            .append('text')
            .attr('class', 'whiteOnDarkMode')
            .attr('text-anchor', 'middle')
            .attr('font-size', radius / 160 + 'em')
            .attr('cursor', 'pointer')
            .attr('y', 10)
            .text(currentAmountString);

        // append tooltip for text in middle.
        centerText.append('title').text('Total amount displayed: ' + currentSum);

    }

    const removeChart = () => {
        d3.select(ref.current).select('g').selectAll('path').remove();
        d3.select(ref.current).select('g').selectAll('text').remove();
    }

    return (
        <svg ref={ref}>
            <g/>
        </svg>
    );
}

export default DonutChart;


