import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {atom, useRecoilState, useRecoilValue} from "recoil";
import Slider from "rc-slider";
import {Button, Form} from "react-bootstrap";
import uuid from "react-uuid";
import * as _ from "lodash";
import {calculateRandomHueValue, poiFillColor, poiStrokeAndTextColor} from "../../../../../../styles/colors";
import {poiAtomFamily} from "../../../../../GlobalNavigations/LeftNavigation/LeftNavigationState";
import {activeFilterSetIdAtom} from "../../../../../../dataProvider/vissights/search/filters/filters";

// call the fetchTopicOverview selector and store it as default value for the data atom
export const displayCreatePoiModalAtom = atom({
    key: "displayCreatePoiModalAtom-Key",
    default: false
});

function CreatePoiModal() {
    const [displayModal, setDisplayModal] = useRecoilState(displayCreatePoiModalAtom);
    const activeFilterSetId = useRecoilValue(activeFilterSetIdAtom);
    // use recoil state to keep track after the poi has been created.
    const [poiList, setPoiList] = useRecoilState(poiAtomFamily(activeFilterSetId));
    const [localColor, setNewLocalColor] = useState(calculateRandomHueValue(poiList, 35));
    const [localPoiName, setNewLocalPoiName] = useState('');

    const poiCreationRef = React.useRef(null);

    const hide = () => {
        setDisplayModal(false);
    }

    const addNewPoi = () => {
        if (localPoiName.length >= 2) {
            const newUUID = uuid();
            const clone = _.cloneDeep(poiList);
            clone.push({id: newUUID, name: localPoiName, active: false, color: localColor});
            setPoiList(clone);
        }
    }

    useEffect(() => {
        poiCreationRef.current.focus();
    },[])



    return (
        <Modal show={displayModal} onHide={hide} className="modal-create-poi">
            <Modal.Body>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    addNewPoi();
                    setDisplayModal(false);
                }}>
                    <div>
                        <input type="text" ref={poiCreationRef} style={{
                            width: 240,
                            borderColor: poiStrokeAndTextColor(localColor),
                            backgroundColor: poiFillColor(localColor)
                        }} onChange={(e) => setNewLocalPoiName(e.target.value)}/>
                        <Slider style={{width: 240, marginTop: 10, marginBottom: 10}}
                                key={'color-slider-key'}
                                min={0}
                                step={1}
                                max={360}
                                defaultValue={localColor}
                                onAfterChange={(val) => {
                                    setNewLocalColor(val)
                                }}/>
                        <Button
                            style={{
                                marginLeft: '30%',
                                borderColor: poiStrokeAndTextColor(localColor),
                                color: poiStrokeAndTextColor(localColor)
                            }}
                            onClick={() => {
                                addNewPoi()
                                setDisplayModal(false)
                            }} variant="outline-primary">
                            Create POI
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default CreatePoiModal;