import React, {useEffect} from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import GridLayout from 'react-grid-layout';
import {useDrop} from "react-dnd";
import uuid from 'react-uuid';
import {
    elementsDraggableSelector,
    filteredWithLayout, forceStaticGridAtom,
    gridConfigAtom,
    layoutGridAtom,
    visualizationItemsAtom,
} from "./state/grid-state";
import VisualizationContainer from "../VisualizationContainer/VisualizationContainer";
import {
    dashboardHeightAtom,
    dashboardWidthAtom,
} from "../../../config/sizings/sizings";
import {currentThemeAtom} from "../../../styles/colors";

function CustomGrid() {
    const [layout, setLayout] = useRecoilState(layoutGridAtom);
    const setItems = useSetRecoilState(visualizationItemsAtom);
    const itemsToDisplay = useRecoilValue(filteredWithLayout);
    const gridConfig = useRecoilValue(gridConfigAtom);
    const dashboardWidth = useRecoilValue(dashboardWidthAtom)
    const dashboardHeight = useRecoilValue(dashboardHeightAtom);
    const currentTheme = useRecoilValue(currentThemeAtom);
    const isDraggable = useRecoilValue(elementsDraggableSelector);


    useEffect(() => {
        setLayout(layout)
    },[itemsToDisplay])

    const [{isOver}, drop] = useDrop(() => ({
        // The type (or types) to accept - strings or symbols
        accept: 'RightSideDragNavItems',
        drop(dragItem, monitor) {
            addItem(dragItem);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        })
    }));

    // adds item to layout
    const addItem = (d) => {
        const newUUID = uuid();
        // use the config from the vis config file.
        const visType = d.config.visType;
        const visComponent = d.config.visComponent;
        const topControls = d.config.topControls;
        const dashboardConfig = d.config.dashboardConfig;
        const usesRightNav = d.config.usesRightNav;
        const module = d.config.module;
        // create random keys so that it will rerender.... very bad style but only option for us here...
        setItems((item) => [
            ...item,
            {
                id: newUUID,
                visType: visType,
                visComponent: visComponent,
                topControls: topControls,
                usesRightNav: usesRightNav,
                module: module,
                dashboardConfig: dashboardConfig,
                // to keep it consistent we pass an empty array to each. But it is only used when starting graphical search from the poi chart.
                graphicalSearchInfos: undefined,
                useMultiSelectionData: undefined
            }
        ]);
    }

    return (
        <div>
            <div ref={drop}
                 style={{
                     backgroundColor: isOver ? '#a6cdf6' : currentTheme === 'dark' ? '#121212' : 'white',
                     width: dashboardWidth,
                     height: dashboardHeight
                 }}>
                <GridLayout className="layout"
                            cols={gridConfig.cols}
                            rowHeight={gridConfig.rowHeight}
                            width={dashboardWidth}
                            height={dashboardHeight}
                            layout={layout}
                            useCSSTransforms={gridConfig.useCSSTransforms}
                            onLayoutChange={(lay) => {
                                setLayout(lay);
                            }}
                            // prevent draggable behavior on certain scenarios. Currently when we change poi color inside modal.
                            isDraggable={isDraggable}
                >
                    {
                        itemsToDisplay !== undefined ?
                        itemsToDisplay.map((item) =>
                        <div key={item.randomKey} id={item.id}
                             style={{overflow: "hidden", maxHeight: dashboardHeight}}
                             data-grid={{
                                 w: item.w,
                                 h: item.h,
                                 x: item.x,
                                 y: item.y,
                                 minW: item.minW,
                                 minH: item.minH
                             }}>
                            <VisualizationContainer
                                id={item.id}
                                translationX={(dashboardWidth / 120) * item.x}
                                translationY={item.y * 10.718}
                                visComponent={item.visComponent}
                                topControls={item.topControls}
                                usesRightNav={item.usesRightNav}
                                width={(dashboardWidth / 120) * item.w}
                                height={item.h * 10.718}
                                graphicalSearchInfos={item.graphicalSearchInfos}
                                useMultiSelectionData={item.useMultiSelectionData}
                            />
                        </div>
                    )
                    : null

                    }
                </GridLayout>
            </div>
        </div>
    );
}

export default CustomGrid;



