import React from "react";
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import TemporalSpreadChart from "./TemporalSpreadChart";
import TemporalSpreadChartRightControls from "./controls/TemporalSpreadChartRightControls";
import TemporalSpreadChartTopControls from "./controls/TemporalSpreadChartTopControls";
import * as _ from "lodash";
import {
    activeFilterSetIdAtom,
    facetFiltersAtomFamily,
} from "../../../../../dataProvider/vissights/search/filters/filters";
import {sliderValuesAtomFamily} from "../../../../GlobalNavigations/LeftNavigation/LeftNavigationState";
import {
    chartDataTemporalSpreadChartYearsSelectorFamily,
    temporalSpreadChartChartDataAtomFamily
} from "./state/TemporalSpreadChartState";
import * as d3 from "d3";
import {recursiveDeepClone} from "../../../../../utility";

function TemporalSpreadChartContainer(props) {
    const componentId = props.id;
    const topControlConfig = props.topControls[0];
    // const [chartDataActive, setChartData] = useRecoilState(temporalSpreadChartChartDataActiveAtomFamily(componentId));
    // stuff we pass to top controls
    const deleteContainer = props.deleteContainer;
    const activeFilterSetId = useRecoilValue(activeFilterSetIdAtom);
    const [facetFilters, toggleFacetFilters] = useRecoilState(facetFiltersAtomFamily(activeFilterSetId));
    const setSliderValues = useSetRecoilState(sliderValuesAtomFamily(activeFilterSetId));
    const resetSliderValues = useResetRecoilState(sliderValuesAtomFamily(activeFilterSetId));
    const [chartData, setChartData] = useRecoilState(temporalSpreadChartChartDataAtomFamily(componentId));
    const yearData = useRecoilValue(chartDataTemporalSpreadChartYearsSelectorFamily(componentId));



    const toggleFacetFilter = (facet, value) => {
        // console.log(facet, value)
        value = value.toString()
        const clone = _.cloneDeep(facetFilters);
        // check if the facet is already created as another filter in that facet is created or sth like that.
        const isAlreadyCreated = clone.find((d) => {
            return d.facet === facet;
        });
        if (isAlreadyCreated !== undefined) {
            // check if we have to reset the slider.
            if (facet === 'Years') {
                resetSliderValues();
            }
            clone.map((d) => {
                if (d.facet === facet) {
                    // check if value is already set...
                    const valueAlready = d.values.find((e, i) => {
                        return e === value;
                    })
                    // if value is already set we remove it
                    if (valueAlready !== undefined) {
                        d.values = d.values.filter((d) => d !== value);
                    }
                    // if its new we add it.
                    else {
                        // d.value = d.value.push(value);
                        d.values.push(value);
                    }
                }
                return d;
            })
        } else {
            // check if we have to change the slider.
            if (facet === 'Years') {
                setSliderValues([Number(value), Number(value)])
            }
            clone.push({facet: facet, values: [value]});
        }

        //remove no longer used facets
        const filteredClone = clone.filter((d) => d.values.length > 0);
        toggleFacetFilters(filteredClone);
    }

    const calculateData = () => {
        let data = recursiveDeepClone(chartData);
        const yearFacet = yearData;

        const minYear = parseInt(d3.min(yearFacet, (e) => e.id), 10);
        const maxYear = parseInt(d3.max(yearFacet, (e) => e.id), 10);

        data = data.map((f, i) => {
            const r = [];
            for (let year = minYear; year <= maxYear; year++) {
                r.push({
                    x: year,
                    y: f.years[year] || 0,
                    pos: i
                });
            }
            r['id'] = f.id;
            r['name'] = f.name;
            r['amount'] = f.amount;
            r['count'] = f.amount;
            r['pos'] = i;
            return r;
        });
        // this.computeBaseline(data);
        // console.log(' test', data);
        // data.map((d) => d.signal = this.calculateSignalStrengthWithAverageOfAllAmounts(d, totalCurrentAmount));
        // data.map((d) => d.signal = this.calculateSignalStrengthWithTotalAmount(d, totalCurrentAmount));
        data.map((d) => d.signal = 1); // calculateSignalStrengthOld(d, searchAnswerLength));
        data.map((d, i) => d.y = d.amount);
        // activate 8 items
        // data.map((d, i) => i < 8 ? d.active = true : d.active = false);

        return data;

    }


    return (
        <div>
            <TemporalSpreadChartTopControls topControlConfig={topControlConfig}
                                            deleteContainer={deleteContainer}
                                            id={props.id}/>
            <div style={{display: "flex"}}>
                <div style={{width: props.containerDimensions[0]}}>
                    <TemporalSpreadChart data={calculateData(chartData)}
                                         id={props.id}
                                         width={props.containerDimensions[0]}
                                         height={props.containerDimensions[1]}
                                         setSearchTermAndRerouteToZoomAndFilter={props.setSearchTermAndRerouteToZoomAndFilter}
                                         toggleFacetFilter={toggleFacetFilter}
                                         toggleContextMenu = {props.toggleContextMenu}
                    />
                </div>
                <div style={{width: 200, overflowY: "hidden"}}>
                    <TemporalSpreadChartRightControls id={props.id}
                                                      height={props.containerDimensions[1]}
                                                      width={props.containerDimensions[0]}
                                                      topControlConfig={topControlConfig}
                                                      setLocalChartData={setChartData}
                                                      toggleRightControls={props.toggleRightControls}
                                                      displayRightNav={props.displayRightNav}/>
                </div>
            </div>
        </div>
    );
}

export default TemporalSpreadChartContainer;