import React from "react";
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import FacetLineChart from "./FacetLineChart";
import FacetLineChartRightControls from "./controls/FacetLineChartRightControls";
import FacetLineChartTopControls from "./controls/FacetLineChartTopControls";
import {facetLineChartChartDataAtomFamily} from "./state/FacetLineChartState";
import * as _ from "lodash";
import {
    activeFilterSetIdAtom,
    facetFiltersAtomFamily
} from "../../../../../dataProvider/vissights/search/filters/filters";
import {sliderValuesAtomFamily} from "../../../../GlobalNavigations/LeftNavigation/LeftNavigationState";
import * as d3 from "d3";
import {activeAggregationAtomFamily} from "../../../overview/visualizations/state";

function FacetLineChartContainer(props) {
    const componentId = props.id;
    const topControlConfig = props.topControls[0];
    const [chartDataActive, setChartData] = useRecoilState(facetLineChartChartDataAtomFamily(componentId));
    const currentAggregation = useRecoilValue(activeAggregationAtomFamily(componentId));
    // stuff we pass to top controls
    const deleteContainer = props.deleteContainer;
    const activeFilterSetId = useRecoilValue(activeFilterSetIdAtom);
    const [facetFilters, toggleFacetFilters] = useRecoilState(facetFiltersAtomFamily(activeFilterSetId));
    const setSliderValues = useSetRecoilState(sliderValuesAtomFamily(activeFilterSetId));
    const resetSliderValues = useResetRecoilState(sliderValuesAtomFamily(activeFilterSetId));

    const calculateData = (dataToPreprocess, aggregation) => {
        const minYear = parseInt(d3.min(dataToPreprocess, (t) =>  d3.min(Object.keys(t.years))), 10);
        const maxYear = parseInt(d3.max(dataToPreprocess, (t) =>  d3.max(Object.keys(t.years))), 10);
        //console.log('here');
        const allData = []
        dataToPreprocess.map((t) => {
            const r = [];
            for (let year = minYear; year <= maxYear; year++) {
                r.push({
                    x: year,
                    y: t.years[year] || 0
                });
            }
            r['id'] = t.id;
            r['name'] = t.name;
            r['amount'] = t.amount;
            r['color'] = t.color;// Math.random()*360;
            r['active'] = t.active;
            switch (aggregation) {
                case "max":
                    r["amount"] = t.max;
                    break;
                case "growth":
                    r["amount"] = t.growth;
                    break;
            }
            allData.push(r);
            return r;
        });

        // amount is always dependant from the current aggregation
        // as amount reflects the current aggregation we can just sort it by amount
        //  allData.sort(genericSort('amount', 'desc'));

        // slice it
        // allData.slice(0, 10);

        // rename amount to y for the chart...
        allData.map((d, i) => d.y = d.amount);

        // activate 15 items
        // allData.map((d, i) => i < 3 ? d.active = true : d.active = false);

        return allData;
    }






    const toggleFacetFilter = (facet, value) => {
        // console.log(facet, value)
        value = value.toString()
        const clone = _.cloneDeep(facetFilters);
        // check if the facet is already created as another filter in that facet is created or sth like that.
        const isAlreadyCreated = clone.find((d) => {
            return d.facet === facet;
        });
        if (isAlreadyCreated !== undefined) {
            // check if we have to reset the slider.
            if (facet === 'Years') {
                resetSliderValues();
            }
            clone.map((d) => {
                if (d.facet === facet) {
                    // check if value is already set...
                    const valueAlready = d.values.find((e, i) => {
                        return e === value;
                    })
                    // if value is already set we remove it
                    if (valueAlready !== undefined) {
                        d.values = d.values.filter((d) => d !== value);
                    }
                    // if its new we add it.
                    else {
                        // d.value = d.value.push(value);
                        d.values.push(value);
                    }
                }
                return d;
            })
        } else {
            // check if we have to change the slider.
            if (facet === 'Years') {
                setSliderValues([Number(value), Number(value)])
            }
            clone.push({facet: facet, values: [value]});
        }

        //remove no longer used facets
        const filteredClone = clone.filter((d) => d.values.length > 0);
        toggleFacetFilters(filteredClone);
    }





    return (
        <div>
            <FacetLineChartTopControls topControlConfig={topControlConfig}
                                       deleteContainer={deleteContainer}
                                       id={props.id}/>
            <div style={{display: "flex"}}>
                <div style={{width: props.containerDimensions[0]}}>
                    <FacetLineChart data={calculateData(chartDataActive, currentAggregation)}
                                    id={props.id}
                                    width={props.containerDimensions[0]}
                                    height={props.containerDimensions[1]}
                                    setSearchTermAndRerouteToZoomAndFilter={props.setSearchTermAndRerouteToZoomAndFilter}
                                    toggleFacetFilter={toggleFacetFilter}
                                    toggleContextMenu = {props.toggleContextMenu}
                    />
                </div>
                <div style={{width: 200, overflowY: "hidden"}}>
                    <FacetLineChartRightControls id={props.id}
                                                 height={props.containerDimensions[1]}
                                                 width={props.containerDimensions[0]}
                                                 topControlConfig={topControlConfig}
                                                 setLocalChartData={setChartData}
                                                 toggleRightControls={props.toggleRightControls}
                                                 displayRightNav={props.displayRightNav}/>
                </div>
            </div>
        </div>
    );
}

export default FacetLineChartContainer;