import {atom, atomFamily, selector, selectorFamily} from "recoil";
import {
    searchDatabase
} from "../../../../../dataProvider/vissights/search/search";
import {vissightsConfig} from "../../../../../dataProvider/vissights/vissightsConfig";
import {
    filteredDataSelector
} from "../../../../../dataProvider/vissights/search/filters/filters";
import * as _ from "lodash";
import {genericSort} from "../../../../../utility";
import {
    activeSortingMethodAtomFamily,
    titleSortingOrderAtomFamily, weightSortingOrderAtomFamily,
    yearSortingOrderAtomFamily
} from "./controls/state-pub-list-top-control";

export const displayGraphicalSearchResultsAtomFamily = atomFamily({
    key: "displayGraphicalSearchResultsAtomFamily",
    default: false
});


export const graphicalSearchDataAtomFamily = atomFamily({
    key: "graphicalSearchDataAtomFamily-Key",
    default: []
});




export const publicationListChartDataSelectorFamily = selectorFamily({
    key: "publicationListChartDataSelectorFamily-Key",
    get: (id) => ({get}) => {
        // get the filtered data from the search
        const filteredData = get(filteredDataSelector);
        //console.log("pub-list-state")
        // get graphical search data
        const graphicalSearchData = get(graphicalSearchDataAtomFamily(id));
        const displayGraphicalSearchResults =  get(displayGraphicalSearchResultsAtomFamily(id));
        let data
        if(displayGraphicalSearchResults) {
            data = graphicalSearchData;
        } else {
            data = filteredData;
        }
        // clone in order to be able to mutate it.
        const clone = _.cloneDeep(data);
        // get infos from top controls.
        const activeSortingMethod = get(activeSortingMethodAtomFamily(id));
        const yearSortingOrder = get(yearSortingOrderAtomFamily(id));
        const weightSortingOrder = get(weightSortingOrderAtomFamily(id));
        const titleSortingOrder = get(titleSortingOrderAtomFamily(id));
        let order;
        // order is dependant from the active sorting method currently used.
        switch (activeSortingMethod) {
            case 'year':
                order = yearSortingOrder;
                break;
            case 'weight':
                order = weightSortingOrder;
                break;
            case 'title':
                order = titleSortingOrder;
                break;
            default:
                order = yearSortingOrder;
        }
        return clone.sort(genericSort(activeSortingMethod, order)).slice(0,50);
    }
});



// stores the key to lookup
export const publicationListChartDataAtomFamily = atomFamily({
    key: "publicationDetailsKeyAtomKey",
    default: (id) => publicationListChartDataSelectorFamily(id)
});

export const loadPublicationDetailsByKeySelector = selector({
    key: 'loadAdditionalInformationByKeySelectorFamilyKey',
    get: async ({get}) => {
        const key = get(publicationDetailsKeyAtom);
        const db = get(searchDatabase);
        if (key !== '') {
            // TODO: refactor to new API.
            // const detailsUrl = vissightsConfig.searchUrlPrefix + 'data' + vissightsConfig.dbSuffix + db.name + '&key=' + key;
            // let detailsUrl = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/publications/search?db=" + db.name + "&q=" + query + "&limit=1000" + "&include=title,publicationDate,author,publicationType" + fieldsUrlStr;
            // https://api2.morpheus.fbmd.h-da.de/v1/publications/data?db=springer&key=479eec570771491b9f54d6eb959af491
            const detailsUrl = vissightsConfig.baseQuery + vissightsConfig.apiVersion + '/publications/details?db=' + db.name + '&key=' + key;

            //console.log('details load');

            // TODO: if the api is fixed we change back...
            // 'https://api.vissights.net/semaservice-web-api-vissights/v2/' + db + '/publications/details?key=' + key;
            try {
                const pubDetails = await fetch(detailsUrl);
                return pubDetails.json();
            } catch (error) {
                throw error;
            }
        } else {
            return [];
        }
    }
});

// stores the key to lookup
export const publicationDetailsKeyAtom = atom({
    key: "publicationDetailsKeyAtomKey",
    default: ''
});

// stores the data
export const publicationDetailsAtom = atom({
    key: "detailsAtomKey",
    default: loadPublicationDetailsByKeySelector
});

export const simlarPublicationsKeyAtomFamily = atomFamily({
    key: "simlarPublicationsKeyAtomFamily-Key",
    default: ''
});

export const loadSimilarPublicationsByKeySelector = selectorFamily({
    key: 'loadAdditionalInformationByKeySelectorFamilyKey',
    get: (id) => async ({get}) => {
        const key = get(simlarPublicationsKeyAtomFamily(id));
        const db = get(searchDatabase);
        if (key !== '') {
            const detailsUrl = vissightsConfig.searchUrlPrefix + 'data' + vissightsConfig.dbSuffix + db.name + '&key=' + key;
            // TODO: if the api is fixed we change back...
            // 'https://api.vissights.net/semaservice-web-api-vissights/v2/' + db + '/publications/details?key=' + key;
            try {
                const pubDetails = await fetch(detailsUrl);
                return pubDetails.json()
            } catch (error) {
                throw error;
            }
        } else {
            return [];
        }
    }
});

export const displaySimilarPublicationsModal = atom({
    key: "displaySimilarPublicationsModal-Key",
    default: false
})

// stores the data
export const similarPublicationsDataAtomFamily = atomFamily({
    key: "similarPublicationsDataAtomFamily-Key",
    default: (id) => loadSimilarPublicationsByKeySelector(id)
});



