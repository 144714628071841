import React from "react";
import PublicationListTopControls from "./controls/PublicationListTopControls";
import * as d3 from "d3";
import PublicationListStatic from "./PublicationListStatic";


function PublicationListContainer(props) {
    // const setModuleToDisplay = props.setModuleToDisplay;
    const graphicalSearchInfos = props.graphicalSearchInfos;
    // const
    let color;
    let hexColor;

    if (graphicalSearchInfos !== undefined) {
        color = d3.hsl(graphicalSearchInfos.color, 0.92, 0.38);
        hexColor = color.formatHex();
    } else {
        hexColor = 'black';
    }

    return (
        <div>
            <div style={{border: '2px solid' + hexColor}}>
                <PublicationListTopControls topControlConfig={props.topControlConfig}
                                            deleteContainer={props.deleteContainer}
                                            id={props.id}/>
            </div>
            <PublicationListStatic width={props.containerDimensions[0]}
                             height={props.containerDimensions[1]}
                             id={props.id}
                             graphicalSearchInfos={graphicalSearchInfos}
                             useMultiSelectionData={props.useMultiSelectionData}
                             toggleContextMenu={props.toggleContextMenu}
            />
        </div>
    );
}

export default PublicationListContainer;