import {atom, atomFamily, selectorFamily} from "recoil";
import {
    monitoringDataAtom,
    overviewDataAtom,
    searchHistoryDataAtom
} from "../../../../../../dataProvider/vissights/overview/overview";
import {activeDatasetAtomFamily} from "../../state";

export const chartDataWordCloudSelectorFamily = selectorFamily({
    key: 'chartDataWordCloudSelectorFamilyKey',
    get: (dataset) => ({get}) => {
        const searchHistory = get(searchHistoryDataAtom).slice(0, 12);
        const overviewData = get(overviewDataAtom).slice(0, 12);
        const monitoringData = get(monitoringDataAtom);
        console.log(overviewData)

        if (dataset === 'searchHistory') {
            return searchHistory;
        } else if (dataset === 'topicOverview') {
            return overviewData;
        } else if (dataset === 'monitoring') {
            return monitoringData;
        } else {
            return searchHistory;
        }
    },
});

export const wordCloudChartDataAtomFamily = atomFamily({
    key: "wordCloudChartDataAtomFamilyKey",
    default: selectorFamily({
        key: 'wordCloudChartDataAtomFamily/Default',
        get: id => ({get}) => {
            const dataset = get(activeDatasetAtomFamily(id));
            console.log(dataset)
            return chartDataWordCloudSelectorFamily(dataset.toString());
        },
    }),
});


export const displayCreateNewMonitoringItemModalAtom = atom({
    key: "displayCreateNewMonitoringItemModalAtom-Key",
    default: false,
});



