import React, {useEffect, useRef} from "react";
import {useRecoilState, useResetRecoilState} from "recoil";
import {
    visualizationPinAtomFamily,
} from "../../../../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import * as d3 from "d3";

function TreeMapChart(props) {
    const componentId = props.id;
    const ref = useRef()
    let containerWidth = props.width;
    const containerHeight = props.height;
    // const toggleFacetFilter = props.toggleFacetFilter;
    const data = props.data;
    // const prevData = props.prevData;
    const [pinId, setPinId] = useRecoilState(visualizationPinAtomFamily(componentId));
    // const resetTreeMapChartData = useResetRecoilState(treeMapChartDataAtomFamily(componentId))

    useEffect(() => {
        if (data.length > 1) {
            if (!props.displayRightNav) {
                props.toggleRightControls()
            }
        }
    }, [data])

    useEffect(() => {
        // removeChart();
        if (data.length > 0) {
            removeChart();
            drawChart();
        } else {
            // alert('not enough data to display')
            removeChart();
        }
    });

    const drawChart = () => {
        const width = containerWidth;
        const height = containerHeight;
        const dataTree = {name: "root", children: data}
        const layouts = ['treemapBinary', 'treemapDice', 'treemapSlice', 'treemapSliceDice', 'treemapSquarify'];


        const tile = (node, x0, y0, x1, y1) => {
            d3.treemapBinary(node, 0, 0, width, height);
            for (const child of node.children) {
                child.x0 = x0 + child.x0 / width * (x1 - x0);
                child.x1 = x0 + child.x1 / width * (x1 - x0);
                child.y0 = y0 + child.y0 / height * (y1 - y0);
                child.y1 = y0 + child.y1 / height * (y1 - y0);
            }
        }

        const treemap = data => d3.treemap()
            //.tile(d3[layouts[0]])
            .tile(tile)
            (d3.hierarchy(data)
                .sum((d) => +d.amount)
                // .sum(d => d.value)
                .sort((a, b) => b.amount - a.amount));


        const name = d => d.ancestors().reverse().map(d => d.data.name).join("/")

        const format = d3.format(",d")

        const x = d3.scaleLinear().rangeRound([0, width]);
        const y = d3.scaleLinear().rangeRound([0, height]);


        const svg = d3.select(ref.current);

        // defines what happens when the mouse is over a bar.
        const onMouseOver = (d, i, n) => {
            const currElement = n[i];
            //console.log(currElement)
            d3.select(currElement)
                //.style("fill", "orange")
                .style('fill', "#fac07b")
                .attr('stroke-width', 4);
        };

        const onMouseOut = (d, i, n) => {
            const currElement = n[i];
            d3.select(currElement)
                .attr('stroke-width', d.index !== pinId ? 1 : 4)
                .style('fill', (d) => d.data.children ? "#97DEFF" : "#fac07b");
        };

        svg
            .attr('width', width)
            .attr('height', height);

        let group = svg.append("g")
            .call(render, treemap(dataTree));

        function render(group, root) {
            const node = group
                .selectAll("g")
                .data(root.children.concat(root)) // .children.concat(root)
                // .data(root.children)
                .join("g");

            node.filter(d => d === root ? d.parent : d.children)
                .on("click", (d, i, n) => {
                    d === root ? zoomout(root) : zoomin(d)
                });

            node.append("title")
                //.text((d) => d.name)
                .text((d) => d.data.name + ': ' + d.data.amount);
            //.style("fill", (d) => d.data.nestedTopicData === true ? "#fac07b" : "#97DEFF")
            node.append("rect")
                // .attr("id", d => (d.leafUid = DOM.uid("leaf")).id)
                .attr("fill", d => d === root ? "#97DEFF" : d.children ? "#97DEFF" : "#fac07b")
                .on("click", (d, i, n) => d.children === undefined ? zoomout(root) : zoomin(d))
                .on('mouseover', onMouseOver)
                .on('mouseleave', onMouseOut)
                .attr("cursor", (d) => d.data.children ? "zoom-in" : "zoom-out")
                .attr("stroke", "#fff");

            //node.append("clipPath")
            //.attr("id", d => (d.clipUid = DOM.uid("clip")).id)
            // .append("use")
            //.attr("xlink:href", d => d.leafUid.href);

            const displayAmount = (d) => {
                if (d.children === undefined) {
                    return d.data.amount + "%";
                } else {
                    return d.data.amount;
                }
            }

            const shortenTextNew = (d, width) => {

                if (width >= 300) {
                    return d.name;
                } else if (width >= 150) {
                    return d.name.length <= 15 ? d.name : (d.name.slice(0, 15).toString() + '...');
                } else if (width >= 100) {
                    return d.name.length <= 10 ? d.name : (d.name.slice(0, 10).toString() + '...');
                } else {
                    return d.name.length <= 4 ? d.name : (d.name.slice(0, 4).toString() + '...');
                }
                /*
                if (width >= 350) {
                    return d.name;
                } else if (width <= 350) {
                    return d.name.length <= 20 ? d.name : (d.name.slice(0, 15).toString() + '...');
                } else if (width <= 100) {
                    return d.name.length <= 10 ? d.name : (d.name.slice(0, 10).toString() + '...');
                } else if (width <= 80 && width >= 40) {
                    return d.name.length <= 6 ? d.name : (d.name.slice(0, 8).toString() + '...');
                } else {
                    return d.name.length <= 2 ? d.name : (d.name.slice(0, 2).toString() + '...');
                }
                 */
            }


            node.append("text")
                .on("click", (d) => d.children === undefined ? zoomout(root) : zoomin(d))
                .attr("cursor", (d) => d.data.children ? "zoom-in" : "zoom-out")
                // .attr("clip-path", d => d.clipUid)
                .attr("font-weight", d => d === root ? "bold" : null)
                .selectAll("tspan")
                // .data(d => (d === root ? name(d) : d.data.name).split(/(?=[A-Z][^A-Z])/g).concat(format(d.value)))
                .data(d => (d === root ? name(d) + ": " + displayAmount(d) : shortenTextNew(d.data, (x(d.x1) - x(d.x0))) + ": " + displayAmount(d)).split(/(?=[A-Z][^A-Z])/g))
                .join("tspan")
                .attr("x", 3)
                .attr("y", (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9}em`)
                .attr("fill-opacity", (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
                .attr("font-weight", (d, i, nodes) => i === nodes.length - 1 ? "normal" : null)
                .text(d => d);

            group.call(position, root);
        }

        function position(group, root) {
            group.selectAll("g")
                .attr("transform", d => d === root ? `translate(0,-30)` : `translate(${x(d.x0)},${y(d.y0)})`)
                .select("rect")
                .attr("width", d => d === root ? width : x(d.x1) - x(d.x0))
                .attr("height", d => d === root ? 30 : y(d.y1) - y(d.y0));
        }

        // When zooming in, draw the new nodes on top, and fade them in.
        function zoomin(d) {
            const group0 = group.attr("pointer-events", "none");

            const group1 = group = svg.append("g").call(render, d);

            x.domain([d.x0, d.x1]);
            y.domain([d.y0, d.y1]);

            svg.transition()
                .duration(750)
                .call(t => group0.transition(t).remove()
                    .call(position, d.parent))
                .call(t => group1.transition(t)
                    .attrTween("opacity", () => d3.interpolate(0, 1))
                    .call(position, d));
        }

        // When zooming out, draw the old nodes on top, and fade them out.
        function zoomout(d) {
            const group0 = group.attr("pointer-events", "none");
            const group1 = group = svg.insert("g", "*").call(render, d.parent);

            x.domain([d.parent.x0, d.parent.x1]);
            y.domain([d.parent.y0, d.parent.y1]);

            svg.transition()
                .duration(750)
                .call(t => group0.transition(t).remove()
                    .attrTween("opacity", () => d3.interpolate(1, 0))
                    .call(position, d))
                .call(t => group1.transition(t)
                    .call(position, d.parent));
        }
    }


    const removeChart = () => {
        const svg = d3.select(ref.current);
        svg.selectAll('g').remove();
        // mainGroup.selectAll('rect').remove();
        // mainGroup.selectAll('text').remove();
    }


    return (
        <svg ref={ref}>
        </svg>
    );

}

export default TreeMapChart;


