import React from "react";

import FlowChartGeneratorTopControls from "./controls/FlowChartGeneratorTopControls";
import FlowChartGenerator from "./FlowChartGenerator";


function FlowChartGeneratorContainer(props) {
    const componentId = props.id;
    // const dataModel = useRecoilValue(preProcessedFilteredSearchDataSelector);
    // stuff we pass to top controls
    const topControlConfig = props.topControls;
    const deleteContainer = props.deleteContainer;


    return (
        <div>
            <FlowChartGeneratorTopControls topControlConfig={topControlConfig}
                                              deleteContainer={deleteContainer}
                                              id={componentId}
            />
            <div style={{display: "flex"}}>
                <div style={{width: props.containerDimensions[0]}}>
                    <FlowChartGenerator id={componentId}
                              width={props.containerDimensions[0]}
                              height={props.containerDimensions[1]}

                    />
                </div>
            </div>
        </div>
    );
}

export default FlowChartGeneratorContainer;
