import {atom} from "recoil";
import { recoilPersist } from 'recoil-persist'
const { persistAtom } = recoilPersist()

export const reportingContentAtom = atom({
    key: "reportingContentAtom-Key",
    default: "",
    effects_UNSTABLE: [persistAtom]
});

export const singleVisualizationScreenshotsAtom = atom({
    key: "singleVisualizationScreenshotsAtom-Key",
    default: [],
    effects_UNSTABLE: [persistAtom],
});

export const dashboardScreenshotsAtom = atom({
    key: "dashboardScreenshotsAtom-Key",
    default: [],
    effects_UNSTABLE: [persistAtom],
});