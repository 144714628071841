import {Badge, Button, Col, Row} from "react-bootstrap";
import React from "react";
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {activeAggregationAtomFamily, useNormalizationAtomFamily} from "../../state";
import {temporalCloudChartDataAtomFamily} from "../state/TemporalCloudState";
import Dropdown from "react-multilevel-dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faCog} from "@fortawesome/free-solid-svg-icons";
import domtoimage from "dom-to-image";
import uuid from "react-uuid";
import * as _ from "lodash";
import {searchDatabase} from "../../../../../../dataProvider/vissights/search/search";
import {singleVisualizationScreenshotsAtom} from "../../../../reporting/state/ReportingState";
import {displaySavedScreenshotModalAtom} from "../../../../../Misc/Modals/SavedScreenshot";

function TemporalCloudTopControls(props) {
    const componentId = props.id;
    // all available aggregations are coming from the visualizations file.
    const config = props.topControlConfig;
    // we use an atom Family which is connected to the current componentId
    const [activeTopControl, setActiveTopControl] = useRecoilState(activeAggregationAtomFamily(componentId))
    const resetTopControl = useResetRecoilState(temporalCloudChartDataAtomFamily(componentId))
    const [useNormalization, setUseNormalization] = useRecoilState(useNormalizationAtomFamily(componentId));
    const db = useRecoilValue(searchDatabase);
    const [singleVisualizationScreenshots, setSingleVisualizationScreenshots] = useRecoilState(singleVisualizationScreenshotsAtom);
    const setDisplayScreenshotModal = useSetRecoilState(displaySavedScreenshotModalAtom);

    const ChangeAggregationToDisplay = (aggregation) => {
        setActiveTopControl(aggregation);
        // we must reset to set it again to the selector
        resetTopControl();
    }

    const changeNormalizationState = () => {
        setUseNormalization(!useNormalization);
    }

    const createVisualizationScreenshot = () => {
        const node = document.getElementById(componentId);
        domtoimage.toPng(node)
            .then((dataUrl) => {
                const newUUID = uuid();
                // store the image, the module, and the current database
                const autoGeneratedText = 'The following chart was taken in the overview Module';
                   // 'searching for' + searchTerm + ' in the database: ' + db.name + '. ' +
                  //  'It shows a temporal overview.';
                const metaDataObj = {id: newUUID, dataUrl, module, db: db.name, autoGeneratedText}
                const clone = _.cloneDeep(singleVisualizationScreenshots)
                clone.push({id: newUUID, data: metaDataObj});
                setSingleVisualizationScreenshots(clone);
            }).then(() => setDisplayScreenshotModal(true));
    }

    const ContainerTopControls = () => {
        // only display datasets if we can choose between more than 1...
        const aggregations = config.aggregations;
        return (
            <Row>
                <Col xs={12}>
                    <div className="grid-item-container-top-control">
                        <div className="float-left" style={{ display: 'flex'}}>
                            <Dropdown position="right"
                                      title={<FontAwesomeIcon style={{width: 20, height: 20}} icon={faCog}/>}
                            >
                                <Dropdown.Item onClick={() => createVisualizationScreenshot()}>
                                    <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}}
                                                     icon={faCamera}/> Screenshot Chart
                                </Dropdown.Item>
                            </Dropdown>

                            <div style={{marginLeft: 15, marginTop: 6}}>
                                {aggregations.map((d) =>
                                    <Badge key={d} style={{marginRight: 5, marginTop: '-15px !important', cursor: 'pointer'}}
                                           variant={d === activeTopControl ? "primary" : "light"}
                                           onClick={(e) => {
                                               e.preventDefault();
                                               ChangeAggregationToDisplay(d);
                                           }}>
                                        {d}
                                    </Badge>
                                )}
                            </div>
                            <div style={{marginLeft: 15, marginTop: 6}}>
                            <Badge style={{marginRight: 5, marginTop: '-15px !important', cursor: 'pointer'}}
                                   variant={useNormalization ? "primary" : "light"}
                                   onClick={(e) => {
                                       e.preventDefault();
                                       changeNormalizationState();
                                   }}>
                               normalize
                            </Badge>
                            </div>
                        </div>
                        <Button type="button" className="float-right" variant="light" value="Search" onClick={(e) => {
                            e.preventDefault();
                            props.deleteContainer(props.id);
                        }}>X</Button>
                    </div>
                </Col>
            </Row>
        );
    }
    return (
        <ContainerTopControls/>
    );
}

export default TemporalCloudTopControls;