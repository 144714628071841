import {Button, Col, Row, FormCheck} from "react-bootstrap";
import React, {useEffect} from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    displayAmountPublicationBubblesAtomFamily,
    displayLinkedCountriesLegendAtomFamily,
    displayPublicationLegendAtomFamily,
    displayTemporalOverviewInsideChartAtomFamily,
    displayTrajectoryLinesAtomFamily
} from "../state/geo-chart-state";
import Dropdown from 'react-multilevel-dropdown';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faCog, faEye, faTools} from "@fortawesome/free-solid-svg-icons";
import {searchDatabase, searchTermAtom} from "../../../../../../dataProvider/vissights/search/search";
import {moduleToDisplayAtom} from "../../../../../Dashboards/DashboardLayout";
import domtoimage from "dom-to-image";
import uuid from "react-uuid";
import * as _ from "lodash";
import {singleVisualizationScreenshotsAtom} from "../../../../reporting/state/ReportingState";
import {displaySavedScreenshotModalAtom} from "../../../../../Misc/Modals/SavedScreenshot";

function GeoChartTopControls(props) {
    const componentId = props.id;
    // infos we need for screenshot.
    const db = useRecoilValue(searchDatabase);
    const searchTerm = useRecoilValue(searchTermAtom)
    const module = useRecoilValue(moduleToDisplayAtom);
    const setDisplayScreenshotModal = useSetRecoilState(displaySavedScreenshotModalAtom);
    const [singleVisualizationScreenshots, setSingleVisualizationScreenshots] = useRecoilState(singleVisualizationScreenshotsAtom);
    const [displayPublicationLegend, setDisplayPublicationLegend] = useRecoilState(displayPublicationLegendAtomFamily(componentId));
    const [displayLinkedCountriesLegend, setDisplayLinkedCountriesLegend] = useRecoilState(displayLinkedCountriesLegendAtomFamily(componentId));
    const [displayTemporalOverviewInsideChart, setDisplayTemporalOverviewInsideChart] = useRecoilState(displayTemporalOverviewInsideChartAtomFamily(componentId));
    const [displayAmountPublicationBubbles, setDisplayAmountPublicationBubbles] = useRecoilState(displayAmountPublicationBubblesAtomFamily(componentId));
    const [displayTrajectoryLines, setDisplayTrajectoryLines] = useRecoilState(displayTrajectoryLinesAtomFamily(componentId));


    const createVisualizationScreenshot = () => {
        const node = document.getElementById(componentId);
        domtoimage.toPng(node)
            .then((dataUrl) => {
                const newUUID = uuid();
                // store the image, the module, and the current database
                const autoGeneratedText = 'The following chart was taken in the Zoom and Filter Module' +
                    'searching for' + searchTerm + ' in the database: ' + db.name + '. ' +
                    'It shows a temporal overview.';
                const metaDataObj = {id: newUUID, dataUrl, module, db: db.name, autoGeneratedText}
                const clone = _.cloneDeep(singleVisualizationScreenshots)
                clone.push({id: newUUID, data: metaDataObj});
                setSingleVisualizationScreenshots(clone);
            }).then(() => setDisplayScreenshotModal(true));
    }

    const ContainerTopControls = () => {
        return (
            <Row>
                <Col xs={1}>
                    <Dropdown position="right"
                              title={<FontAwesomeIcon style={{width: 20, height: 20}} icon={faCog}/>}
                    >
                        <Dropdown.Item>
                            <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}} icon={faEye}/> View
                            Settings
                            <Dropdown.Submenu position="right">
                                <Dropdown.Item position="right">
                                    Legends
                                    <Dropdown.Submenu position="right">
                                        <Dropdown.Item position="right">
                                            <FormCheck type="checkbox" defaultChecked={displayPublicationLegend}
                                                       onChange={(e) => {
                                                           e.stopPropagation();
                                                           setDisplayPublicationLegend(!displayPublicationLegend)
                                                       }}
                                            />
                                            Publication Legend
                                        </Dropdown.Item>
                                        <Dropdown.Item position="right">
                                            <FormCheck type="checkbox" defaultChecked={displayLinkedCountriesLegend}
                                                       onChange={(e) => {
                                                           e.stopPropagation();
                                                           setDisplayLinkedCountriesLegend(!displayLinkedCountriesLegend)
                                                       }}
                                            />
                                            Linked Countries Legend
                                        </Dropdown.Item>
                                    </Dropdown.Submenu>
                                </Dropdown.Item>
                                <Dropdown.Item position="right">
                                    Relations
                                    <Dropdown.Submenu position="right">
                                        <Dropdown.Item position="right">
                                            <FormCheck type="checkbox" defaultChecked={displayAmountPublicationBubbles}
                                                       onChange={(e) => {
                                                           e.stopPropagation();
                                                           setDisplayAmountPublicationBubbles(!displayAmountPublicationBubbles)
                                                       }}
                                            />
                                            Amount Bubbles
                                        </Dropdown.Item>
                                        <Dropdown.Item position="right">
                                            <FormCheck type="checkbox" defaultChecked={displayTrajectoryLines}
                                                       onChange={(e) => {
                                                           e.stopPropagation();
                                                           setDisplayTrajectoryLines(!displayTrajectoryLines)
                                                       }}
                                            />
                                            Relation Lines
                                        </Dropdown.Item>
                                    </Dropdown.Submenu>
                                </Dropdown.Item>
                                <Dropdown.Item position="right">
                                    <FormCheck type="checkbox" defaultChecked={displayTemporalOverviewInsideChart}
                                               onChange={(e) => {
                                                   e.stopPropagation();
                                                   setDisplayTemporalOverviewInsideChart(!displayTemporalOverviewInsideChart)
                                               }}
                                    />
                                    Temporal Overview
                                </Dropdown.Item>
                            </Dropdown.Submenu>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => createVisualizationScreenshot()}>
                            <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}}
                                             icon={faCamera}/> Screenshot Chart
                        </Dropdown.Item>
                    </Dropdown>
                </Col>
                <Col xs={11}>
                    <div className="grid-item-container-top-control">
                        <Button type="button" className="float-right" variant="light" value="Delete" onClick={(e) => {
                            e.preventDefault();
                            props.deleteContainer(props.id);
                        }}>X</Button>
                    </div>
                </Col>
            </Row>
        );
    }
    return (
        <ContainerTopControls/>
    );
}

export default GeoChartTopControls;