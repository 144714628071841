import {atom, atomFamily, selectorFamily} from "recoil";
import {
    filteredDataSelector
} from "../../../../../../dataProvider/vissights/search/filters/filters";

export const poiChartDataAtomFamily = atomFamily({
    key: "poiChartDataAtomFamily-Key",
    default: selectorFamily({
        key: 'poiChartDataAtomFamily-test/Default',
        get: id => () => {
            return poiChartDataSelectorFamily(id);
        },
    }),
});


export const poiChartDataSelectorFamily = selectorFamily({
    key: 'poiChartDataSelectorFamily-Key',
    get: id =>  ({get}) => {
        return get(filteredDataSelector);
    },
});


export const subTreePoiChartAtomFamily = atomFamily({
        key: "subTreePoiChartAtomFamily-Key",
        default: [],
        dangerouslyAllowMutability: true
});

export const forceRerenderAtomFamily = atomFamily({
    key: "forceRerenderAtomFamily-Key",
    default: false
});

export const latestRemovedPoiAtomFamily = atomFamily({
    key: "latestRemovedPoiAtomFamily-Key",
    default: '-1'
});


