import {atom, atomFamily, selectorFamily} from "recoil";
import {filteredDataSelector} from "../../../../../../dataProvider/vissights/search/filters/filters";
import {topicsAtom} from "../../../../../../dataProvider/vissights/overview/overview";
import {recursiveDeepClone} from "../../../../../../utility";
import * as _ from 'lodash'

export const chartDataBagOfWordsChartSelectorFamily = selectorFamily({
    key: 'chartDataBagOfWordsChartSelectorFamily-Key',
    get: (id) => ({get}) => {
        return []
    }
});

const getSelectionValue = (data) => {
    let wordState = false;
    let phraseState = false;

    data.forEach((d) => {
        if (d.name === 'words') {
            wordState = d.value;
        } else if (d.name === 'phrases') {
            phraseState = d.value;
        }
    })

    if (wordState && phraseState) {
        return 2;
    } else if (phraseState) {
        return 0;
    } else if (wordState) {
        return 1;
    }
}


export const bagOfWordsConnectedDataAtomFamily = atomFamily({
    key: "bagOfWordsDataAtomFamily",
    default: selectorFamily({
        key: 'bagOfWordsDataSelectorFamily/Default',
        get: id => ({get}) => {
            // const connectBagOfWordsScientific = (searchData, topics, selection, topicMinAccuracy, bagOfWords) => {
            const searchData = get(filteredDataSelector);
            const topics = get(topicsAtom);
            const topicMinAccuracy = get(bagOfWordsChartMinPrecisionSliderValueAtomFamily(id));
            const bagOfWordsEconomic = get(bagOfWordsChartEconomicWordsActiveDataAtomFamily(id));
            const bagOfWordsScientific = get(bagOfWordsChartScientificWordsDataAtomFamily(id));
            // As proposed by LH selection: 0 - phrases and words, 1 - only words, 2 - only phrases
            const rawSelection = get(bagOfWordsChartConsiderWordsPhrasesCheckBoxes(id));


            const selection = getSelectionValue(rawSelection);
            // (searchData, topics, selection, topicMinAccuracy, bagOfWordsScientific)
            const bowScientific = connectBagOfWordsScientific(searchData, topics, selection, topicMinAccuracy, bagOfWordsScientific);
            const bowEconomical = connectBagOfWordsEconomic(searchData, topics, selection, topicMinAccuracy, bagOfWordsEconomic);
            // console.log([bowScientific, bowEconomical])
            return [bowScientific, bowEconomical]
        }
    }),
});


// prepare data from active BagOfWords fields
export const bagOfWordsChartDataAtomFamily = atomFamily({
    key: "bagOfWordsChartDataAtomFamily",
    default: selectorFamily({
        key: 'bagOfWordsChartSelectorFamily/Default',
        get: id => ({get}) => {
            const raw = get(bagOfWordsConnectedDataAtomFamily(id));
            const bowData = recursiveDeepClone(raw);
            const sortedDataSci = bowData[0].sort((a, b) => b.count - a.count);
            const sortedDataEco = bowData[1].sort((a, b) => b.count - a.count);
            const yearCountSci = {};
            const yearCountEco = {};

            sortedDataSci.map((d, i) => {
                mergeObject(yearCountSci, getCountOfYears(d.year))
            })

            sortedDataEco.map((d, i) => {
                mergeObject(yearCountEco, getCountOfYears(d.year))
            })


            let yearsSci = Object.keys(yearCountSci);
            let yearsEco = Object.keys(yearCountEco);

            yearsSci = yearsSci.map((d) => parseInt(d, 10));
            yearsEco = yearsEco.map((d) => parseInt(d, 10));

            let amountSci = Object.values(yearCountSci);
            let amountEco = Object.values(yearCountEco);

            amountSci = amountSci.map((d) => parseInt(d, 10));
            amountEco = amountEco.map((d) => parseInt(d, 10));

            // first entry refers to min year
            const minXSci = yearsSci[0];
            const minXEco = yearsEco[0];

            // last entry refers to max year
            const maxXSci = yearsSci[yearsSci.length - 1];
            const maxXEco = yearsEco[yearsEco.length - 1];

            // min and max of both
            const minX = Math.min(minXSci, minXEco);
            const maxX = Math.max(maxXSci, maxXEco);

            const maxYSci = _.max(amountSci);
            const maxYEco = _.max(amountEco);

            const maxY = Math.max(maxYSci, maxYEco);

            const finalDataSci = [];
            for (let year = minX; year <= maxX; year++) {
                finalDataSci.push({
                    x: year,
                    y: yearCountSci[year] || 0
                });
            }
            finalDataSci['minX'] = minX;
            finalDataSci['maxX'] = maxX;
            finalDataSci['maxY'] = maxY;
            finalDataSci['color'] = 'orange';
            finalDataSci['name'] = 'scientificBoW';


            const finalDataEco = [];
            for (let year = minX; year <= maxX; year++) {
                finalDataEco.push({
                    x: year,
                    y: yearCountEco[year] || 0
                });
            }
            finalDataEco['minX'] = minX;
            finalDataEco['maxX'] = maxX;
            finalDataEco['maxY'] = maxY;
            finalDataEco['color'] = 'blue';
            finalDataEco['name'] = 'ecoBoW';

            /*
            dataToPreprocess.map((t) => {
                const r = [];
                for (let year = minYear; year <= maxYear; year++) {
                    r.push({
                        x: year,
                        y: t.years[year] || 0
                    });
                }
                r['id'] = t.id;

            })
            */

            /*
            // first entry refers to min year
            const minXSci = yearsSci[0];
            const minXEco = yearsEco[0];

            // last entry refers to max year
            const maxXSci = yearsSci[yearsSci.length-1];
            const maxXEco = yearsEco[yearsEco.length-1];

            // min and max of both
            const minX = Math.min(minXSci, minXEco);
            const maxX = Math.max(maxXSci, maxXEco);

            const maxYSci = amountSci[amountSci.length-1];
            const maxYEco = amountEco[amountEco.length-1];

*/

            return [finalDataSci, finalDataEco];
        },
        dangerouslyAllowMutability: true
    }),
});

//merges two objects, if key already in object adds value to key
const mergeObject = (obj = {}, objectToMerge) => {
    for (const key of Object.keys(objectToMerge)) {
        if (key in obj) {
            obj[key] += objectToMerge[key];
        } else {
            obj[key] = objectToMerge[key];
        }
    }
    return obj;
}

//counts single years in yearsArray, returns years with corresponding count
const getCountOfYears = (yearArray) => {
    let years = {};
    yearArray.forEach((x) => {
        years[x] = (years[x] || 0) + 1;
    });
    return years;
}

export const bagOfWordsChartScientificWordsDataSelectorFamily = selectorFamily({
    key: 'bagOfWordsChartScientificWordsDataSelectorFamily-Key',
    get: (id) => ({get}) => {
        const words = ["research", "knowledge", "educate", "study", "scientific", "science", "school", "cognition", "calculate", "test"];
        const finalData = [];
        words.forEach((d) => {
            finalData.push({name: d, active: true})
        });
        return finalData;
    }
});

export const bagOfWordsChartScientificWordsDataAtomFamily = atomFamily({
    key: "bagOfWordsChartScientificWordsDataAtomFamily",
    default: selectorFamily({
        key: 'bagOfWordsChartScientificWordsDataSelectorFamily/Default',
        get: id => ({get}) => {
            return get(bagOfWordsChartScientificWordsDataSelectorFamily(id));
        },
        dangerouslyAllowMutability: true,
    }),
});


export const bagOfWordsInitialEconomicWordsAtomFamily = atom({
    key: 'bagOfWordsInitialEconomicWordsAtomFamily-Key',
    default: [{name: "economic", active: true},{name: "business", active: true},{name: "advertisement", active: true},{name: "trading", active: false},{name: "commerce", active: false},{name: "marketable", active: false}]
        // "trade", "business", "advertisement", "trading", "commerce", "marketable", "mercantile", "financial", "profitable", "market", "invest", "sale", "corporate", "money", "success"];
});



export const bagOfWordsChartEconomicWordsDataSelectorFamily = selectorFamily({
    key: 'bagOfWordsChartEconomicWordsDataSelectorFamily-Key',
    get: (id) => ({get}) => {
        const words = get(bagOfWordsInitialEconomicWordsAtomFamily); // ["economic", "trade", "business", "advertisement", "trading", "commerce", "marketable", "mercantile", "financial", "profitable", "market", "invest", "sale", "corporate", "money", "success"];
        const finalData = [];
        words.forEach((d,i) => {
            if(i<=3){
                finalData.push({name: d, active: true})
            }else {
                finalData.push({name: d, active: false})
            }
        });
        return words;
    }
});

export const bagOfWordsChartEconomicWordsDataAtomFamily = atomFamily({
    key: "bagOfWordsChartEconomicWordsDataAtomFamily",
    default: selectorFamily({
        key: 'bagOfWordsChartEconomicWordsDataSelectorFamily/Default',
        get: id => ({get}) => {
            return get(bagOfWordsChartEconomicWordsDataSelectorFamily(id));
        }
    }),
});

export const bagOfWordsChartEconomicWordsActiveDataAtomFamily = atomFamily({
    key: "bagOfWordsChartEconomicWordsActiveDataAtomFamily",
    default: selectorFamily({
        key: 'bagOfWordsChartEconomicWordsActiveDataSelectorFamily/Default',
        get: id => ({get}) => {
            const allWords = get(bagOfWordsChartEconomicWordsDataAtomFamily(id));
            return allWords.filter((d) => d.active);
        }
    }),
});


export const bagOfWordsChartConsiderWordsPhrasesCheckBoxes = atomFamily({
    key: "bagOfWordsChartConsiderWordsPhrasesCheckBoxes",
    default: selectorFamily({
        key: 'bagOfWordsChartConsiderWordsPhrasesCheckBoxes/Default',
        get: id => ({get}) => {
            return [{name: 'words', value: true}, {name: 'phrases', value: true}];
        },
        dangerouslyAllowMutability: true
    }),
});

export const bagOfWordsChartMinPrecisionSliderValueAtomFamily = atomFamily({
    key: "bagOfWordsChartMinPrecisionSliderValue",
    default: selectorFamily({
        key: 'bagOfWordsChartMinPrecisionSliderValue/Default',
        get: id => ({get}) => {
            return 0.01;
        },
        dangerouslyAllowMutability: true
    }),
});


//function for comparing data with bag of words
//selection: 0 - phrases and words, 1 - only words, 2 - only phrases
const connectBagOfWordsScientific = (searchData, topics, selection, topicMinAccuracy, bagOfWordsScientific) => {
    /*let count = 0;*/
    let resultArrayScientific = [];
    if (topics) {
        for (let i = 0; i < searchData.length; i++) {
            let resultTopics = searchData[i].topics;
            /*provides phrases and words from topics of search results */
            for (let objKey in resultTopics) {
                /*console.log("TopicNo:", objKey,"title:",searchData[i].title, " rank:", resultTopics[objKey]);*/
                let res = topics[objKey];
                /*searches through phrases of search result to find matching strings for scientific and economical fields found in bagOfWords.json*/
                if (selection === 0 || selection === 2) {
                    for (let p in res.phrase) {
                        //min value of topic rank/accuracy (res.phrase[p][1] = accuracy value)
                        if (res.phrase[p][1] >= topicMinAccuracy) {
                            /*compares scientific topics in bag of words*/
                            for (let q = 0; q <= bagOfWordsScientific.length - 1; q++) {
                                if (res.phrase[p][0].includes(bagOfWordsScientific[q].name)) {
                                    let wordScientific = bagOfWordsScientific[q].name;
                                    /*checks if word from BoW has already been added to resultArray, returns index of found word or -1 if no index was found*/
                                    if (resultArrayScientific.length > 0) {
                                        let wordIndexScientific = resultArrayScientific.map(e => {
                                            return e.word
                                        }).indexOf(wordScientific);
                                        if (wordIndexScientific === -1) {
                                            resultArrayScientific.push({
                                                word: wordScientific,
                                                count: 1,
                                                title: [searchData[i].title],
                                                /*add years without array (always on index 0)*/
                                                year: [searchData[i].year[0]]
                                            });
                                        } else {
                                            resultArrayScientific[wordIndexScientific].count += 1;
                                            resultArrayScientific[wordIndexScientific].year.push(searchData[i].year[0]);
                                            if (resultArrayScientific[wordIndexScientific].title.indexOf(searchData[i].title) === -1) {
                                                resultArrayScientific[wordIndexScientific].title.push(searchData[i].title);
                                            }
                                        }
                                    } else {
                                        resultArrayScientific.push({
                                            word: wordScientific,
                                            count: 1,
                                            title: [searchData[i].title],
                                            year: [searchData[i].year[0]]
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
                if (selection === 0 || selection === 1) {
                    for (let p in res.word) {
                        //min value of topic rank/accuracy (res.phrase[p][1] = accuracy value)
                        if (res.word[p][1] >= topicMinAccuracy) {
                            /*compares scientific topics in bag of words*/
                            for (let q = 0; q <= bagOfWordsScientific.length - 1; q++) {
                                if (res.word[p][0].includes(bagOfWordsScientific[q].name)) {
                                    let wordScientific = bagOfWordsScientific[q].name;
                                    let wordIndexScientific = resultArrayScientific.map(e => {
                                        return e.word
                                    }).indexOf(wordScientific);
                                    if (resultArrayScientific.length !== 0) {
                                        if (wordIndexScientific === -1) {
                                            resultArrayScientific.push({
                                                word: wordScientific,
                                                count: 1,
                                                title: [searchData[i].title],
                                                year: [searchData[i].year[0]]
                                            });
                                        } else {
                                            resultArrayScientific[wordIndexScientific].count += 1;
                                            resultArrayScientific[wordIndexScientific].year.push(searchData[i].year[0]);
                                            if (resultArrayScientific[wordIndexScientific].title.indexOf(searchData[i].title) === -1) {
                                                resultArrayScientific[wordIndexScientific].title.push(searchData[i].title);
                                            }
                                        }
                                    } else {
                                        resultArrayScientific.push({
                                            word: wordScientific,
                                            count: 1,
                                            title: [searchData[i].title],
                                            year: [searchData[i].year[0]]
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return resultArrayScientific;
}
//function for comparing data with bag of words
//selection: 0 - phrases and words, 1 - only words, 2 - only phrases
const connectBagOfWordsEconomic = (searchData, topics, selection, topicMinAccuracy, bagOfWordsEconomic) => {
    /*let count = 0;*/
    let resultArrayEconomical = [];
    /*Checks if Topics, the Database and the search term is given*/
    if (topics) {
        /*provides topic numbers and corresponding rank for search results*/
        for (let i = 0; i < searchData.length; i++) {
            let resultTopics = searchData[i].topics;
            /*provides phrases and words from topics of search results */
            for (let objKey in resultTopics) {
                /*console.log("TopicNo:", objKey,"title:",searchData[i].title, " rank:", resultTopics[objKey]);*/
                let res = topics[objKey];
                /*ToDo: number of topics variable, use page rank as limit --> userinput*/
                /*searches through phrases of search result to find matching strings for scientific and economical fields found in bagOfWords.json*/
                if (selection === 0 || selection === 2) {
                    for (let p in res.phrase) {
                        //min value of topic rank/accuracy (res.phrase[p][1] = accuracy value)
                        if (res.phrase[p][1] >= topicMinAccuracy) {
                            /*compares economical topics in bag of words*/
                            for (let q = 0; q <= bagOfWordsEconomic.length - 1; q++) {
                                if (res.phrase[p][0].includes(bagOfWordsEconomic[q].name)) {
                                    let wordEconomical = bagOfWordsEconomic[q].name;
                                    let wordIndexEconomical = resultArrayEconomical.map(e => {
                                        return e.word
                                    }).indexOf(wordEconomical);
                                    if (resultArrayEconomical.length !== 0) {
                                        //todo: add year
                                        if (wordIndexEconomical === -1) {
                                            resultArrayEconomical.push({
                                                word: wordEconomical,
                                                count: 1,
                                                title: [searchData[i].title],
                                                year: [searchData[i].year[0]]
                                            });
                                        } else {
                                            resultArrayEconomical[wordIndexEconomical].count += 1;
                                            resultArrayEconomical[wordIndexEconomical].year.push(searchData[i].year[0]);
                                            if (resultArrayEconomical[wordIndexEconomical].title.indexOf(searchData[i].title) === -1) {
                                                resultArrayEconomical[wordIndexEconomical].title.push(searchData[i].title);
                                            }
                                        }
                                    } else {
                                        resultArrayEconomical.push({
                                            word: wordEconomical,
                                            count: 1,
                                            title: [searchData[i].title],
                                            year: [searchData[i].year[0]]
                                        });
                                    }
                                    /*todo:comment out/delete*/
                                    /*console.log("Compared Scientific Topic:", bagOfWordsScientific[q], "\nTopicNo:", objKey, "title:", searchData[i].title, " rank:", resultTopics[objKey], "\nSearchresults:", objKey, res.phrase[p][0]);
                                    console.log("#########","\nBoW:",bagOfWordsScientific[q],"\nPHRASE:", res.phrase[p][0],"\nTOPICno:",objKey,"\nTITLE:",searchData[i].title,"\nNUMBER:",count,"\n#########");
                                    */
                                    /*count++;*/
                                }
                            }
                        }
                    }
                }
                /*searches through words of search result to find matching strings for scientific and economical fields found in bagOfWords.json*/
                if (selection === 0 || selection === 1) {
                    for (let p in res.word) {
                        //min value of topic rank/accuracy (res.phrase[p][1] = accuracy value)
                        if (res.word[p][1] >= topicMinAccuracy) {
                            /*compares economical topics in bag of words*/
                            for (let q = 0; q <= bagOfWordsEconomic.length - 1; q++) {
                                if (res.word[p][0].includes(bagOfWordsEconomic[q].name)) {
                                    let wordEconomical = bagOfWordsEconomic[q].name;
                                    let wordIndexEconomical = resultArrayEconomical.map(e => {
                                        return e.word
                                    }).indexOf(wordEconomical);
                                    if (resultArrayEconomical.length !== 0) {
                                        if (wordIndexEconomical === -1) {
                                            resultArrayEconomical.push({
                                                word: wordEconomical,
                                                count: 1,
                                                title: [searchData[i].title],
                                                year: [searchData[i].year[0]]
                                            });
                                        } else {
                                            resultArrayEconomical[wordIndexEconomical].count += 1;
                                            resultArrayEconomical[wordIndexEconomical].year.push(searchData[i].year[0]);
                                            if (resultArrayEconomical[wordIndexEconomical].title.indexOf(searchData[i].title) === -1) {
                                                resultArrayEconomical[wordIndexEconomical].title.push(searchData[i].title);
                                            }
                                        }
                                    } else {
                                        resultArrayEconomical.push({
                                            word: wordEconomical,
                                            count: 1,
                                            title: [searchData[i].title],
                                            year: [searchData[i].year[0]]
                                        });
                                    }
                                    /*todo:comment out/delete*/
                                    /*console.log("Compared Scientific Topic:", bagOfWordsScientific[q], "\nTopicNo:", objKey, "title:", searchData[i].title, " rank:", resultTopics[objKey], "\nSearchresults:", objKey, res.phrase[p][0]);
                                    console.log("#########","\nBoW:",bagOfWordsScientific[q],"\nPHRASE:", res.phrase[p][0],"\nTOPICno:",objKey,"\nTITLE:",searchData[i].title,"\nNUMBER:",count,"\n#########");
                                    */
                                    /*count++;*/
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return resultArrayEconomical;
}
