export const genericSort = (key, order = 'asc') => {
    return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }
        const varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key];
        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    }
}

export const shortenTextForLegend = (text) => {
    return text.length <= 16 ? text : (text.slice(0, 11).toString() + '...');
}

export const shortenText = (text) => {
    return text.length <= 8 ? text : (text.slice(0, 8).toString() + '...');
}

export const shortenTextForLegendFullContent = (text, value) => {
    if(text !== undefined) {
        const fullStringLength = (text + value).length;
        if (fullStringLength <= 18) {
            return text;
        } else {
            return text.slice(0, 12).toString() + '...';
        }
        // return fullStringLength.length <= 22 ? text : ();
    } else {
        return '';
    }

}

// a method that copys literally everything :)
export const recursiveDeepClone = (inObject) => {
    let outObject, value, key
    if (typeof inObject !== 'object' || inObject === null) {
        return inObject // Return the value if inObject is not an object
    }
    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {}
    for (key in inObject) {
        value = inObject[key]
        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = recursiveDeepClone(value)
    }
    return outObject
};






export const calculateSignalStrengthOld = (d, totalSearchAnswerAmount) => {
    const percentage = (d.amount / totalSearchAnswerAmount) * 100;
    if (percentage > 8) {
        return 2;
    } else if (percentage > 1) {
        return 1;
    } else {
        return 0;
    }
}

export const calculateSignalStrengthWithTotalAmount = (d, total) => {
    const percentage = (d.amount / total) * 100;
    if (percentage > 6) {
        return 2;
    } else if (percentage > 1) {
        return 1;
    }
}

export const calculateSignalStrengthWithAverageOfAllAmounts = (d, total) => {
    const average = total / 100;
    if (d.amount >= average) {
        return 2;
    } else if (d.amount < average) {
        return 1;
    } else {
        return 1;
    }
}