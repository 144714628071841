import React, {useEffect, useRef} from "react";
import * as d3 from 'd3';
import {useRecoilState, useRecoilValue} from "recoil";
import {
    visualizationScaleAtomFamily,
    visualizationTranslationXAtomFamily
} from "../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import {topicLineFacetOutlineColor} from "../../../../../styles/colors";
import {alwaysDisplayTextLabelsAtomFamily} from "../../../zoomAndFilter/visualizations/TemporalOverviewChart/layouts/TemporalOverviewLayout/temporal-overview-chart-state";


function LineChart(props) {
    const componentId = props.id;
    const ref = useRef()
    const containerWidth = props.width;
    const containerHeight = props.height;
    const calculatedData = props.data;
    const [scale, setNewScale] = useRecoilState(visualizationScaleAtomFamily(componentId));
    const [translationX, setTranslationX] = useRecoilState(visualizationTranslationXAtomFamily(componentId));
    // const [hoveredElementId, setHoveredElementId] = useRecoilState(hoveredElementIdAtomFamily(componentId));
    const alwaysDisplayLabels = useRecoilValue(alwaysDisplayTextLabelsAtomFamily(componentId));
    const setSearchTermAndRerouteToZoomAndFilter = props.setSearchTermAndRerouteToZoomAndFilter;
    let zoom = null;


    useEffect(() => {
        createLineChart();
    });

    const createLineChart = () => {
        // rebind zoom on changes.
        zoom = d3.zoom().scaleExtent([1, 5])
            .translateExtent([[0, 0], [containerWidth, 0]])
            .extent([[0, 0], [containerWidth, containerHeight]])
            .on('zoom',
                () => {
                    handleZoom(d3.event.transform);
                }
            );

        bindZoom(zoom);
        //if (scale !== 1 || translationX !== 0) {
        // reloadPreviousZoom(zoom);
        // }
        removeChart();
        drawAxisAndOverlay();


        // TODO: put the initializing stuff like here...
        updateLineChart();
    }

    const onClick = (d) => {
        setSearchTermAndRerouteToZoomAndFilter(d.name);
    }

    const drawAxisAndOverlay = () => {

        const w = containerWidth;
        const h = containerHeight;
        const scales = scaleXY();
        const Sscale = scale;
        const svg = d3.select(ref.current);

        /************** Calculate ticks **************/
        let tickValues = scales.x.domain();
        if (w * Sscale < tickValues.length * (32 + 8) / 5) {
            tickValues = scales.x.domain().filter((d) => d % 10 === 0);
        } else if (w * Sscale < tickValues.length * (32 + 8) / 2) {
            tickValues = scales.x.domain().filter((d) => d % 5 === 0);
        } else if (w * Sscale < tickValues.length * (32 + 8)) {
            tickValues = scales.x.domain().filter((d) => d % 2 === 0);
        }

        /******************* X-AXIS *******************/
        const xAxis = d3.axisBottom()
            .scale(scales.x)
            .tickValues(tickValues)
            .tickSize(1)
            .tickPadding(4);

        svg
            .select('g')
            .select('.axis')
            .attr('transform', `translate(${0},${h - 20})`)
            .transition()
            .call(xAxis);

        /******************* Y-AXIS *******************/
        const yAxis = d3.axisLeft()
            .scale(scales.y)
            .tickSize(1)
            .tickPadding(4);

        svg
            .select('g')
            .select('.yaxis')
            .attr('transform', 'translate(80, 0)')
            .transition()
            .call(yAxis);

        const ytick = yAxis.ticks();
        const lineData = yAxis
            .scale()
            .ticks(ytick[0]);

        /********************* Dash overlay *******************/
        svg
            .select('g')
            .select('.lines')
            .selectAll('path')
            .remove();

        const line = svg
            .select('g')
            .select('.lines')
            .selectAll('path')
            .data(lineData);

        line
            .enter()
            .append('path')
            .attr('d', (d) => {
                const y = scales.y(d);
                return 'M 65,' + y + 'h' + ((w * Sscale) - 40);
            })
            .attr('stroke-dasharray', '0.5%, 0.5%')
            .attr('transform', 'translate(20,0)')
        // .attr('stroke', topicLineOverlayLineStrokeColor());

    }

    const updateLineChart = () => {

        const svg = d3.select(ref.current);

        svg
            .attr('width', containerWidth)
            .attr('height', containerHeight)


        // const data = this.chartDataDisplay;
        /********* SCALING ********/
        const scales = scaleXY();
        const x = (d) => scales.x(d.x);
        const y = (d) => scales.y(d.y);

        const shortenLineText = (t, maxLength) => {
            let shortText = t;
            const length = t.length;
            if (length > maxLength / 8) {
                shortText = t.slice(0, maxLength / 8);
                shortText = shortText.slice(0, shortText.lastIndexOf(' '));
                return shortText + '..';
            }
            return shortText;
        }

        /********* Bind scaled x & ydata to line ********/
        const line = d3.line()
            .x(x)
            .y(y)
            // @Info Hier hat man die Auswahl aus 9 curve functions. MonotoneX ist eigentlich ganz gut.
            // Hier kann man für alternativen schauen: http://bl.ocks.org/d3indepth/b6d4845973089bc1012dec1674d3aff8
            .curve(d3.curveMonotoneX);

        const path = svg
            .selectAll('g')
            .select('.points')
            .selectAll('path')
            .data(calculatedData);

        path
            .enter()
            .append('path')
            .attr('opacity', 1)
            .attr('fill', 'none')
            .attr('stroke-width', 2)
            .attr('cursor', 'pointer')
            .on('click', (d) => onClick(d))
            .attr('stroke', (d) => topicLineFacetOutlineColor(d.color))// (d) => d.hovered === true ? 'red' : 'blue')
            .attr('d', line)
            /***************** Tooltips ************/
            .append('title')
            .text((d) => d.name);

        svg
            .select('g')
            .select('.points')
            .selectAll('path')
            .attr('transform', 'translate(' + scales.x.bandwidth() / 2 + ',' + 0 + ')');


        path.exit()
            .transition()
            .attr('opacity', 0)
            .remove();

        /*************************TEXT FOR EACH LINE, (Topicname at the top position of each line) ************************/
            // Calculate the maximal Y for each line to find out where to place the name on the line
        const maxY = (d) => {
                let max = null;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < d.length; i++) {
                    if (max === null || max.y < d[i].y) {
                        max = d[i];
                    }
                }
                return max;
            };

        const mouseover = (d, i, n) => {
            const currenEl = n[i];
            currenEl.attr('class', 'displayText')
        }

        const text = svg
            .select('g')
            .select('.texts')
            .selectAll('text')
            .data(calculatedData, (d) => d);

        text.enter()
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('opacity', alwaysDisplayLabels ? 1 : 0)
            // .attr('class', 'noDisplayText')
            .attr('class', 'fillWhiteOnDarkMode')
            .attr('alignment-baseline', 'hanging')
            .attr('cursor', 'default')
            .text((d) => shortenLineText(d.name, 120 * scale))
            .attr('x', (d) => x(maxY(d)))
            .attr('y', (d) => y(maxY(d)) + 10);

        d3.select(ref.current)
            .select('g')
            .attr('transform', `translate(${translationX},0)`);

        text
            .exit()
            .transition()
            .attr('opacity', 0)
            .remove();

    }

    const removeChart = () => {
        d3.select(ref.current)
            .selectAll('g')
            .select('.points')
            .selectAll('path')
            .remove();
        d3.select(ref.current)
            .selectAll('g')
            .selectAll('.texts')
            .selectAll('text')
            .remove();
    }

    const scaleXY = () => {
        const w = containerWidth;
        const h = containerHeight;
        const data = calculatedData;
        let currData;
        if (data !== undefined && data.length > 0) {
            currData = data[data.length - 1];
        } else {
            currData = [];
        }
        const [minX, maxX] = d3.extent(currData, (e) => e.x);
        const maxY = d3.max(data, (d) => d3.max(d, (e) => e.y));
        return {
            x: d3.scaleBand()
                .domain(d3.range(minX, maxX + 1))
                .range([80, (w * scale)], 0.5),
            y: d3.scaleLinear()
                .domain([0, maxY])
                .range([h - 20, 1])
        };
    }

    const reloadPreviousZoom = (zoom) => {
        const transform = d3
            .zoomIdentity
            .translate(translationX, 0)
            .scale(scale);

        d3.select(ref.current)
            .transition()
            .duration(200)
            .call(zoom.transform, transform);
    }

    const handleZoom = (event) => {
        // console.log(event);
        const newTranslationX = event.x;
        const newScale = event.k;
        setTranslationX(newTranslationX);
        setNewScale(newScale);
    }

    const bindZoom = (zoom) => {
        d3.select(ref.current)
            .call(zoom)
            .on('dblclick.zoom', null);
    }


    return (
        <svg ref={ref}>
            <g>
                <g className='points'/>
                <g className='axis'/>
                <g className='yaxis'/>
                <g className='lines'/>
                <g className='texts'/>
            </g>
        </svg>
    );
}

export default LineChart;


