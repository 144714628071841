import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import {atom, useRecoilState} from "recoil";
import {
    amountRelatedPhrasesToConsiderAtom,
    amountRelatedTopicsToConsiderAtom,
    assistedSearchAtom,
    displaySearchAdjustmentsModalAtom,
    advancedSearchFieldsAtom,
    availableDataModelsAtom,
    includeSelectionAtom,
    useLazyLoadingAtom,
    initialAmountLoadedAtom,
    firstLazyAmountLoadedAtom, maximumAmountLoadedAtom
} from "../../../../dataProvider/vissights/search/search";
import {Accordion, Button, Col, NavLink, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import Switch from "react-switch";
import * as _ from "lodash";
import Slider from "rc-slider";
import 'rc-slider/assets/index.css'

function SearchAdjustmentsModal() {
    const [displayModal, setDisplayModal] = useRecoilState(displaySearchAdjustmentsModalAtom);
    const [assistedSearch, setAssistedSearch] = useRecoilState(assistedSearchAtom);
    const [useLazyLoading, setUseLazyLoading] = useRecoilState(useLazyLoadingAtom);
    // const [considerRelatedPhrases, setConsiderRelatedPhrasesAtom] = useRecoilState(considerRelatedPhrasesAtom);
    const [amountRelatedPhrases, setAmountRelatedPhrasesAtom] = useRecoilState(amountRelatedPhrasesToConsiderAtom);
    const [amountRelatedTopics, setAmountRelatedTopicsAtom] = useRecoilState(amountRelatedTopicsToConsiderAtom);
    const [advancedSearchFields, setAdvancedSearchFields] = useRecoilState(advancedSearchFieldsAtom);
    const [initialAmountLoaded, setInitialAmountLoaded] = useRecoilState(initialAmountLoadedAtom);
    const [firstLazyAmountLoaded, setFirstLazyAmountLoaded] = useRecoilState(firstLazyAmountLoadedAtom);
    const [maximumAmountLoaded, setMaximumAmountLoaded] = useRecoilState(maximumAmountLoadedAtom);
    // const [specifiedFacetsFields, setSpecifiedFacetsFields] = useRecoilState(specifiedFacetsAtom);
    // we use local state to prevent data loading before clicking the search button in the modal.
    const [tempAssistedSearch, setTempAssistedSearch] = useState(assistedSearch);
    const [tempLazyLoading, setTempLazyLoading] = useState(useLazyLoading);
    const [tempAmountRelatedPhrases, setTempAmountRelatedPhrases] = useState(amountRelatedPhrases);
    const [tempAmountRelatedTopics, setTempAmountRelatedTopics] = useState(amountRelatedTopics);
    const [tempAdvancedSearchFields, setTempAdvancedSearchFields] = useState(advancedSearchFields);
    const [availableDataModels, setAvailableDataModels] = useRecoilState(availableDataModelsAtom);
    // const activeDataModel = useRecoilValue(activeDataModelSelector);
    // we change the availableDataModels in local state... when finished we change it in recoil state.
    const [tempDataModels, setTempDataModels] = useState(availableDataModels)
    const [includeSelection,setIncludeSelection] = useRecoilState(includeSelectionAtom);
    const [tempIncludeSelection,setTempIncludeSelection] = useState(includeSelection);
    const [tempInitialAmountLoaded, setTempInitialAmountLoaded] = useState(initialAmountLoaded);
    const [tempFirstLazyAmountLoaded, setTempFirstLazyAmountLoaded] = useState(firstLazyAmountLoaded);
    const [tempMaximumAmountLoaded, setTempMaximumAmountLoaded] = useState(maximumAmountLoaded);



    const changeActiveDataModel = (name) => {
        const clone = _.cloneDeep(tempDataModels);

        const cloneIncludeSelection = _.cloneDeep(tempIncludeSelection);

        clone.map((d) => {
            if(d.name === name){
                // iterate over the active fields
                //d.value.forEach((e) => {
                    // iterate over the incldue selection
                    cloneIncludeSelection.forEach((e) => {
                         e.value = d.value.includes(e.name);
                    })
                //})
            }
            return d.active = d.name === name;
        })
        setTempIncludeSelection(cloneIncludeSelection);
        setTempDataModels(clone)
    }

    const changeAdvancesSearchFieldsByNameVal = (fieldName) => {
        // clone the existing value
        let clone = _.cloneDeep(tempAdvancedSearchFields);
        clone.map((d) => {
            if (d.name === fieldName) {
                return d.value = !d.value;
            } else return d;
        })
        setTempAdvancedSearchFields(clone)
    }

    const changeIncludeFieldsByNameVal = (fieldName) => {
        // clone the existing value
        let clone = _.cloneDeep(tempIncludeSelection);
        clone.map((d) => {
            if (d.name === fieldName) {
                return d.value = !d.value;
            } else return d;
        })
        setTempIncludeSelection(clone)
    }

    // save the local component changes in the global recoil state if we want to keep them.
    const saveChanges = () => {
        setAvailableDataModels(tempDataModels);
        setAssistedSearch(tempAssistedSearch);
        setAmountRelatedTopicsAtom(tempAmountRelatedTopics);
        setAmountRelatedPhrasesAtom(tempAmountRelatedPhrases);
        setAdvancedSearchFields(tempAdvancedSearchFields);
        setIncludeSelection(tempIncludeSelection);
        setUseLazyLoading(tempLazyLoading);
        setInitialAmountLoaded(tempInitialAmountLoaded);
        setFirstLazyAmountLoaded(tempFirstLazyAmountLoaded);
        setMaximumAmountLoaded(tempMaximumAmountLoaded);
        setDisplayModal(false)
    }

    const discardChanges = () => {
        setDisplayModal(false)
    }

    /** Define UI components in functional components themself to clean up code**/

    const AssistedSearchSwitch = () => {
        return (
            <Row>
                <Col xs={3} className="pt-1">
                    <Switch onColor="#86d3ff"
                            offHandleColor="#2693e6"
                            onHandleColor="#2693e6"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={15}
                            width={40}
                            onChange={(checked) => {
                                setTempAssistedSearch(checked);
                            }}
                            checked={tempAssistedSearch}/>
                </Col>
                <Col xs={8} className="pt-0">
                    Assisted Search
                    <OverlayTrigger
                        key={'assisted-infos'}
                        placement={'right'}
                        overlay={
                            <Tooltip id={`tooltip-${'assisted-search'}`}>
                                Uses the proposed amount of phrases related to the search term.
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon style={{marginLeft: 15}} icon={faInfoCircle}/>
                    </OverlayTrigger>
                </Col>
            </Row>
        )
    }

    const LazyLoadingSwitch = () => {
        return (
            <Row>
                <Col xs={3} className="pt-1">
                    <Switch onColor="#86d3ff"
                            offHandleColor="#2693e6"
                            onHandleColor="#2693e6"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={15}
                            width={40}
                            onChange={(checked) => {
                                setTempLazyLoading(checked);
                            }}
                            checked={tempLazyLoading}/>
                </Col>
                <Col xs={8} className="pt-0">
                    Lazy Loading
                    {/*
                     <OverlayTrigger
                        key={'assisted-infos'}
                        placement={'right'}
                        overlay={
                            <Tooltip id={`tooltip-${'assisted-search'}`}>
                                Uses the proposed amount of phrases related to the search term.
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon style={{marginLeft: 15}} icon={faInfoCircle}/>
                    </OverlayTrigger>
                     */}
                </Col>
            </Row>
        )
    }

    const AssistedSearchAdjustments = () => {
        return (
            <Row className="ml-2 mr-2">
                <Col xs={6}>
                    <p><b>{tempAmountRelatedPhrases + ' '}</b>phrases:</p>
                    <div>
                        <Slider key={'test'} style={{}}
                                min={0}
                                max={5}
                                defaultValue={tempAmountRelatedPhrases}
                                onAfterChange={(val) => setTempAmountRelatedPhrases(val)}
                        />
                    </div>
                </Col>

                <Col xs={6}>
                    <p><b>{tempAmountRelatedTopics + ' '}</b>topics:</p>
                    <div>
                        <Slider key={'test'} style={{}}
                                min={0}
                                max={5}
                                defaultValue={tempAmountRelatedTopics}
                                onAfterChange={(val) => setTempAmountRelatedTopics(val)}
                        />
                    </div>
                </Col>
            </Row>
        )
    }

    const LazyLoadingAdjustments = () => {
        return (
            <Row className="ml-2 mr-2">
                <Col xs={12}>
                    <p><b>{tempInitialAmountLoaded + ' '}</b>Initial amount loaded</p>
                    <div>
                    <Slider key={'initialAmountLoaded'} style={{}}
                            min={0}
                            max={1000}
                            defaultValue={Number(tempInitialAmountLoaded)}
                            onAfterChange={(val) => setTempInitialAmountLoaded(val)}
                    />
                </div>
                </Col>
                <Col xs={12}>
                    <p><b>{tempFirstLazyAmountLoaded + ' '}</b>First lazy amount loaded</p>
                    <div>
                        <Slider key={'firstLazylAmountLoaded'} style={{}}
                                min={Number(tempInitialAmountLoaded)}
                                max={2000}
                                defaultValue={Number(tempFirstLazyAmountLoaded)}
                                onAfterChange={(val) => setTempFirstLazyAmountLoaded(val)}
                        />
                    </div>
                </Col>
            </Row>
        )
    }

    const AdvancedSearch = () => {
        return (
            <>
                <Accordion.Toggle as={NavLink} variant="link" eventKey="1">
                    <h5>
                        Advanced Search
                        <OverlayTrigger
                            key={'advanced-info'}
                            placement={'right'}
                            overlay={
                                <Tooltip id={`tooltip-${'advanced-search'}`}>
                                    You may specify the fields that should be searched in for your phrase
                                </Tooltip>
                            }
                        >
                            <FontAwesomeIcon style={{marginLeft: 15, fontSize: 18}} icon={faInfoCircle}/>
                        </OverlayTrigger>
                    </h5>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    <Row key={'advanced-search-fields-row'}>
                        {tempAdvancedSearchFields.map((d) => {
                            return (
                                <Col xs={6} className="mb-2 pl-3 pr-1" key={d.name + 'wrapper-col'}
                                     style={{display: 'flex'}}>
                                    <Switch onColor="#86d3ff"
                                            offHandleColor="#2693e6"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={15}
                                            key={d.name + 'switch'}
                                            width={40}
                                            onChange={() => {
                                                changeAdvancesSearchFieldsByNameVal(d.name);
                                            }}
                                            checked={d.value}/>
                                    <span key={d.name + 'switch-label'}
                                          style={{marginLeft: 2, marginTop: -4}}>{d.name}</span>
                                </Col>
                            )
                        })}
                    </Row>
                </Accordion.Collapse>
            </>
        )
    }

    const DataModelSelection = () => {
        return (
            <>
                <Accordion.Toggle as={NavLink} variant="link" eventKey="data-model">
                    <h5>Data Models</h5>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="data-model">
                    <div>
                        <Row>
                            {
                                tempDataModels.map((d) => {
                                    return (
                                        <Col xs={12} key={d.name + 'col-wrapper'}>
                                            <div className="radio" key={d.name}>
                                                <label key={d.name + 'label'}>
                                                    <input
                                                        type="radio"
                                                        key={d.name + 'input'}
                                                        value={d.value}
                                                        checked={d.active}
                                                        onChange={(e) => {
                                                            changeActiveDataModel(d.name)
                                                        }}
                                                    />
                                                    {' '}{d.name}
                                                </label>
                                            </div>
                                        </Col>

                                    );
                                })
                            }
                        </Row>
                        <Row>
                            {
                                tempIncludeSelection.map((d) => {
                                    return (
                                        <Col xs={6} className="mb-2 pl-3 pr-1" key={d.name + 'wrapper-col'}
                                             style={{display: 'flex'}}>
                                            <Switch onColor="#86d3ff"
                                                    offHandleColor="#2693e6"
                                                    onHandleColor="#2693e6"
                                                    handleDiameter={20}
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    height={15}
                                                    key={d.name + 'switch'}
                                                    width={40}
                                                    onChange={() => {
                                                        changeIncludeFieldsByNameVal(d.name);
                                                    }}
                                                    checked={d.value}/>

                                            <span key={d.name + 'switch-label'}
                                                  style={{marginLeft: 2, marginTop: -4}}>{d.name}</span>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </Accordion.Collapse>
            </>
        )
    }

    const AcceptDeclineButton = () => {
        return(
            <Row>
                <Col xs={5}>
                    <Button onClick={() => setDisplayModal(false)} variant="danger">
                        Discard
                    </Button>
                </Col>
                <Col xs={2}/>
                <Col xs={5}>
                    <Button onClick={() => saveChanges()} variant="success">
                        Save
                    </Button>
                </Col>
            </Row>
        )
    }

    return (
        <Modal className="modal-search-adjustments" show={displayModal} onHide={discardChanges}>
            <Modal.Body className="modal-body-search-adjustments">
                <AssistedSearchSwitch/>
                { /* we only display adjustments if we have toggles the switch above. */
                    /*
                    tempAssistedSearch ?
                        <AssistedSearchAdjustments/>
                        : null
                     */
                }


                {
                  /*  <LazyLoadingSwitch/> */
                    /* we only display adjustments if we have toggles the switch above. */
                    /*
                    tempLazyLoading ?
                        <LazyLoadingAdjustments/>
                        : null
                  */
                }
                <Accordion defaultActiveKey="1">
                    <AdvancedSearch/>
                    {/* we can comment the model selection out for the moment.. */}
                    <DataModelSelection/>
                </Accordion>
            </Modal.Body>
            <Modal.Footer style={{display: 'block'}} className="modal-footer-search-adjustments">
               <AcceptDeclineButton/>
            </Modal.Footer>
        </Modal>
    )
}

export default SearchAdjustmentsModal;