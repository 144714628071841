import React from "react";
import Modal from "react-bootstrap/Modal";
import {useRecoilState} from "recoil";
import {displayMaxItemsModalAtom} from "./TemporalCloudContainer";
import {Button} from "react-bootstrap";

function MaxItemsModal() {
    const [displayModal, setDisplayModal] = useRecoilState(displayMaxItemsModalAtom)

    const hide = () => {
        setDisplayModal(false);
    }

    return (
            <Modal show={displayModal} onHide={hide} className="modal-max-items">
                <Modal.Body>
                    <p>You may only select 9 items.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{cursor: 'pointer'}} onClick={() => setDisplayModal(false)} variant="primary">Okay</Button>
                </Modal.Footer>
            </Modal>
    )
}

export default MaxItemsModal;
