import {atom} from "recoil";

export const displayContextMenuAtom = atom({
    key: "displayContextMenuAtom-Key",
    default: false
});

// here we load all data for the search
export const contextMenuDataAtom = atom({
    key: "contextMenuDataAtom-Key",
    default: []
});