import {atomFamily} from "recoil";

export const activeAggregationAtomFamily = atomFamily({
    key: "activeAggregationAtomFamilyKey",
    default: (id) => 'growth'
});

export const useNormalizationAtomFamily = atomFamily({
    key: "useNormalizationAtomFamily-Key",
    default: (id) => false
});


export const activeDatasetAtomFamily = atomFamily({
    key: "activeDatasetAtomFamilyKey",
    default: (id) => 'topicOverview'
});

export const activeFacetAtomFamily = atomFamily({
    key: "activeFacetAtomFamily-Key",
    default: (id) => 'Topics' // FACET.TOPIC
});
