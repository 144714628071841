import {Button} from "react-bootstrap";
import React from "react";
import keycloak from "../../../dataProvider/vissights/authentication/keycloak";

 function LoginFirst() {
    return(
        <div>
            <h1>Please login to continue.</h1>
            <Button variant="outline-success" size="lg"
                    onClick={() => keycloak.login()}>
                Login
            </Button>
        </div>
    )
}

export default LoginFirst;