import React from "react";
import Modal from "react-bootstrap/Modal";
import {useRecoilState, useRecoilValue} from "recoil";
import {contextMenuDataAtom, displayContextMenuAtom} from "./state";
import ContextMenu from "./ContextMenu";


function ContextMenuModal() {
    const [displayContextMenu, setDisplayContextMenu] = useRecoilState(displayContextMenuAtom);
    const contextMenuData = useRecoilValue(contextMenuDataAtom);
    const hide = () => {
        setDisplayContextMenu(false);
    }

    return (
        <Modal id="context-menu-modal" show={displayContextMenu} className="context-menu-modal" onHide={hide} style={{marginLeft: contextMenuData.x - 600, marginTop: contextMenuData.y}}>
            <Modal.Body>
            <ContextMenu/>
            </Modal.Body>
        </Modal>
    )
}

export default ContextMenuModal;