import React, {useEffect} from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {visualizationItemsAtom} from "../GridLayout/state/grid-state";
import {assistedSearchAtom, searchTermAtom, searchTermSelector} from "../../../dataProvider/vissights/search/search";
import * as _ from "lodash";
import {
    containerDimensionsAtomFamily,
    displayRightNavAtomFamily,
    rightControlsDimensionsAtomFamily
} from "./state/visualizationContainerState";
import {useHistory} from 'react-router-dom'
import routes from "../../../config/routes";
import * as d3 from "d3";
import {contextMenuDataAtom, displayContextMenuAtom} from "../../Misc/context-menu/state";
import {facetedFilteredDataAvailableFacetsSelector} from "../../../dataProvider/vissights/search/filters/filters";
import {recursiveDeepClone} from "../../../utility";

function removeItemById(arr, id) {
    return arr.filter((el) => el.id !== id);
}

function VisualizationContainer(props) {
    const componentId = props.id;
    let history = useHistory();
    const [items, setItems] = useRecoilState(visualizationItemsAtom);
    // for the exact width and height of a container we do not need recoil. As the layout already uses atoms.
    const [displayRightNav, setDisplayRightNav] = useRecoilState(displayRightNavAtomFamily(componentId)); //useState(props.usesRightNav)
    const [containerDimensions, setContainerDimensions] = useRecoilState(containerDimensionsAtomFamily(componentId))
    const [rightControlsDimensions, setRightControlsDimensions] = useRecoilState(rightControlsDimensionsAtomFamily(componentId))
    const setSearchTerm = useSetRecoilState(searchTermAtom);
    const rightControlWidth = 200;
    const visComponent = props.visComponent;
    const setDisplayContextMenu = useSetRecoilState(displayContextMenuAtom);
    const setContextMenuData = useSetRecoilState(contextMenuDataAtom);
    const possibleFacets = useRecoilValue(facetedFilteredDataAvailableFacetsSelector);

    // we filter out facets that are currently not possible
    const validatePossibleFacets = (topControls) => {
        const clone = recursiveDeepClone(topControls);
        if (topControls[0].facets !== undefined) {
            clone[0].facets = topControls[0].facets.filter((d) => possibleFacets.includes(d));
            // check if the initial active facet still exists. Otherwise overwrite it with an existing one.
            if (!clone[0].facets.includes(clone[0].initialActiveFacet)) {
                clone[0].initialActiveFacet = clone[0].facets[0];
            }
        }
        return clone;
    }


    const setSearchTermAndRerouteToZoomAndFilter = async (term) => {
        console.log("setSearchTermAndRerouteToZoomAndFilter");
        setSearchTerm(term);
        history.push(routes.modules.zoomAndFilter)
    }

    // we define the function on top level and pass it down as prop to each child
    const deleteItem = (id) => {
        const clone = _.cloneDeep(items);
        const newList = removeItemById(clone, id);
        setItems(newList);
    };

    const toggleRightControls = () => {
        // toggle behavior
        setDisplayRightNav(!displayRightNav);
    }

    const toggleContextMenu = (name, ref) => {
        d3.event.preventDefault();
        const mouseXY = d3.mouse(ref);
        setDisplayContextMenu(true);
        setContextMenuData({name: name, x: mouseXY[0] + props.translationX, y: mouseXY[1] + props.translationY})
    }


    useEffect(() => {
        // Update the document title using the browser API
        // as react-grid-layout does not provide the correct values we have to grab them from the corresponding element...
        // this is a common issue they dont want to fix as they think rows and cols would be enough which is bs!
        if (document.getElementById(props.id) && document.getElementById(props.id).clientHeight !== 0 && document.getElementById(props.id).clientWidth !== 0) {
            let height = document.getElementById(props.id).clientHeight - 50;
            let width = document.getElementById(props.id).clientWidth;
            width = displayRightNav ? width - rightControlWidth : width - 10;
            setContainerDimensions([width, height])
            setRightControlsDimensions([displayRightNav ? rightControlWidth : 10, height])
        }
        // if we do not have the element. we calculate ourselfs...
        else {
            let height = props.height - 50;
            let width = props.width - 23;
            width = displayRightNav ? width - rightControlWidth : width - 10;
            setContainerDimensions([width, height])
            setRightControlsDimensions([displayRightNav ? rightControlWidth : 10, height])
        }
    }, [props, displayRightNav, setContainerDimensions, setRightControlsDimensions]);


    // const MyComponent = (Component) => props.visComponent; //stored it in some variable
    return (
        <div className="grid-item-container-content">
            {React.cloneElement(visComponent,
                {
                    containerDimensions: containerDimensions,
                    rightControlDimensions: rightControlsDimensions,
                    displayRightNav: displayRightNav,
                    deleteContainer: deleteItem,
                    topControls: validatePossibleFacets(props.topControls), //validatePossibleFacets(props.topControls),
                    usesRightNav: props.usesRightNav,
                    id: props.id,
                    setSearchTermAndRerouteToZoomAndFilter: setSearchTermAndRerouteToZoomAndFilter,
                    toggleRightControls: toggleRightControls,
                    graphicalSearchInfos: props.graphicalSearchInfos,
                    useMultiSelectionData: props.useMultiSelectionData,
                    toggleContextMenu: toggleContextMenu
                })}
        </div>
    );

}

export default VisualizationContainer;