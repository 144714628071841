import React, {useEffect} from "react";
import TemporalOverviewChartTopControls from "./controls/TemporalOverviewTopControls";
import {useRecoilValue} from "recoil";
import {
    temporalOverviewChartDataAtomFamily
} from "./layouts/TemporalOverviewLayout/temporal-overview-chart-state";
import TreeMapBarChartLayout from "./layouts/TreeMapBarchartLayout/TreeMapBarchartLayout";
import TemporalOverviewLayout from "./layouts/TemporalOverviewLayout/TemporalOverviewLayout";
import TreeMapChartLayout from "./layouts/TreeMapLayout/TreeMapLayout";

function TemporalOverviewChartContainer(props) {
    const componentId = props.id;
    const temporalOverviewData = useRecoilValue(temporalOverviewChartDataAtomFamily(componentId));
    // stuff we pass to top controls
    const topControlConfig = props.topControls;
    const deleteContainer = props.deleteContainer;

    useEffect(() => {
        if (temporalOverviewData.length > 5) {
            if (props.displayRightNav) {
                props.toggleRightControls()
            }
        }
    })

    // check based on the amount of publications and years if it should be displayed as chart or as simple List.
    const checkDisplayFormat = (temporalOverviewData) => {
        if (temporalOverviewData.length === 1) {
            return <TreeMapChartLayout {...props}/>;
        } else if (temporalOverviewData.length > 1 && temporalOverviewData.length < 5) {
            return <TreeMapBarChartLayout {...props}/>;
        } else {
            return <TemporalOverviewLayout {...props}/>;
        }
    }

    return (
        <div>
            <TemporalOverviewChartTopControls topControlConfig={topControlConfig}
                                              deleteContainer={deleteContainer}
                                              id={componentId}/>
            {checkDisplayFormat(temporalOverviewData)}
        </div>
    );
}

export default TemporalOverviewChartContainer;
