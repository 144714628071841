import React from 'react'
import ConfigLoader from "../configloader";

export default function ConfigLoaderHOC(WrappedComponent) {
    return (
        class ConfigHOC extends React.Component {
            constructor() {
                super();
                this.state = {
                    loadedConfig: undefined
                }
            }
            componentDidMount() {
                ConfigLoader.Config((c) => this.setState({loadedConfig: c}));
            }
            render () {
                return <WrappedComponent loadedConfig={this.state.loadedConfig} />
            }
        }
    )
};

/*
function ConfigLoaderHOC() {
    const [Sconfig, setSConfig]  = useState({})
    //code before the pause
    useEffect(() => {
        ConfigLoader.Config((c) => setSConfig(c));
    },[])
    // TODO: refactor this to a hoc that wraps app...
    return (
        <App Sconfig={Sconfig}/>
    )
}

export default ConfigLoaderHOC
*/