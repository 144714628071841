import {Badge, Button, Col, FormCheck, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";

import Dropdown from "react-multilevel-dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faCog, faEye} from "@fortawesome/free-solid-svg-icons";
import {searchDatabase} from "../../../../../../dataProvider/vissights/search/search";
import {singleVisualizationScreenshotsAtom} from "../../../../reporting/state/ReportingState";
import {displaySavedScreenshotModalAtom} from "../../../../../Misc/Modals/SavedScreenshot";
import domtoimage from "dom-to-image";
import uuid from "react-uuid";
import * as _ from "lodash";
import {alwaysDisplayTextLabelsAtomFamily} from "../../TemporalOverviewChart/layouts/TemporalOverviewLayout/temporal-overview-chart-state";
import {activeAggregationAtomFamily, activeFacetAtomFamily} from "../../../../overview/visualizations/state";
import {
    facetLineChartChartDataAtomFamily,
    rightControlFacetLineChartDataAtomFamily
} from "../state/FacetLineChartState";


function FacetLineChartTopControls(props) {
    const componentId = props.id;
    // all available aggregations are coming from the visualizations file.
    const config = props.topControlConfig;
    // we use an atom Family which is connected to the current componentId
    const [activeAggregation, setActiveAggregation] = useRecoilState(activeAggregationAtomFamily(componentId));
    // const resetActiveAggregation = useResetRecoilState(activeAggregationAtomFamily(componentId))
    const [activeFacet, setActiveFacet] = useRecoilState(activeFacetAtomFamily(componentId))
    const db = useRecoilValue(searchDatabase);
    const [singleVisualizationScreenshots, setSingleVisualizationScreenshots] = useRecoilState(singleVisualizationScreenshotsAtom);
    const setDisplayScreenshotModal = useSetRecoilState(displaySavedScreenshotModalAtom);
    const [alwaysDisplayLabels, setAlwaysDisplayLabels] = useRecoilState(alwaysDisplayTextLabelsAtomFamily(componentId));
    const resetChartDataSelector = useResetRecoilState(facetLineChartChartDataAtomFamily(componentId));
    const resetRightControlDataSelector = useResetRecoilState(rightControlFacetLineChartDataAtomFamily(componentId));

    // initialize the active facet. It was checked in vis container component to be existent
    useEffect(()=> {
        setActiveFacet(config.initialActiveFacet)
    },[props.topControlConfig]);

    const createVisualizationScreenshot = () => {
        const node = document.getElementById(componentId);
        domtoimage.toPng(node)
            .then((dataUrl) => {
                const newUUID = uuid();
                // store the image, the module, and the current database
                const autoGeneratedText = 'The following chart was taken in the overview Module';
                // 'searching for' + searchTerm + ' in the database: ' + db.name + '. ' +
                //  'It shows a temporal overview.';
                const metaDataObj = {id: newUUID, dataUrl, module, db: db.name, autoGeneratedText}
                const clone = _.cloneDeep(singleVisualizationScreenshots)
                clone.push({id: newUUID, data: metaDataObj});
                setSingleVisualizationScreenshots(clone);
            }).then(() => setDisplayScreenshotModal(true));
    }

    const changeAggregationToDisplay = (aggregation) => {
        // we must reset as we overwrite the subsciption when manually manipulating the chart Data from right controls.
        resetChartDataSelector();
        resetRightControlDataSelector();
        setActiveAggregation(aggregation);
    }

    const changeFacetToDisplay = (facet) => {
        // we must reset as we overwrite the subsciption when manually manipulating the chart Data from right controls.
        resetRightControlDataSelector();
        resetChartDataSelector();
        setActiveFacet(facet);
    }


    const ContainerTopControls = () => {
        // only display datasets if we can choose between more than 1...
        const aggregations = config.aggregations;
        const facets = config.facets;
        return (
            <Row>
                <Col xs={12}>
                    <div className="grid-item-container-top-control">
                        <div className="float-left" style={{ display: 'flex'}}>

                            <Dropdown position="right"
                                      title={<FontAwesomeIcon style={{width: 20, height: 20}} icon={faCog}/>}
                            >
                                <Dropdown.Item>
                                    <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}} icon={faEye}/>
                                    View Settings
                                    <Dropdown.Submenu position="right">
                                        <Dropdown.Item position="right">
                                            <FormCheck type="checkbox" defaultChecked={alwaysDisplayLabels}
                                                       onChange={(e) => {
                                                           e.stopPropagation();
                                                           e.preventDefault();
                                                           setAlwaysDisplayLabels(!alwaysDisplayLabels);
                                                       }}
                                            /> Display Labels
                                        </Dropdown.Item>
                                    </Dropdown.Submenu>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => createVisualizationScreenshot()}>
                                    <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}}
                                                     icon={faCamera}/> Screenshot Chart
                                </Dropdown.Item>
                            </Dropdown>
                            <div style={{marginLeft: 15, marginTop: 6}}>
                            {aggregations.map((d) =>
                                <Badge key={d} style={{marginRight: 5, cursor: 'pointer'}}
                                       variant={d === activeAggregation ? "primary" : "light"}
                                       onClick={() => {
                                           changeAggregationToDisplay(d);
                                       }}>
                                    {d}
                                </Badge>
                            )}
                            </div>
                            <div style={{marginLeft: 60, marginTop: 6}} >
                                {facets.map((d) =>
                                    <Badge key={d} style={{marginRight: 5, cursor: 'pointer'}}
                                           variant={d === activeFacet ? "primary" : "light"}
                                           onClick={() => {
                                               changeFacetToDisplay(d);
                                           }}>
                                        {d}
                                    </Badge>
                                )}
                            </div>

                        </div>
                        <Button type="button" className="float-right" variant="light" value="Search" onClick={(e) => {
                            e.preventDefault();
                            props.deleteContainer(props.id);
                        }}>X</Button>
                    </div>
                </Col>
            </Row>
        );
    }
    return (
        <ContainerTopControls/>
    );
}

export default FacetLineChartTopControls;