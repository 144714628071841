const routes = {
    modules: {
        home: '/',
        overview: '/overview/',
        zoomAndFilter: '/zoomAndFilter/',
        reporting: '/reporting/',
        profile: '/user/',
        projects: '/projects/'
    }
}

export default routes;