import {atom, atomFamily, selectorFamily, useRecoilValue} from "recoil";
import React from "react";
import {MarkerType} from "react-flow-renderer";
import {recursiveDeepClone} from "../../../../../../utility";

export const displayPreviousStudiesAtomFamily = atomFamily({
    key: "displayPreviousStudiesAtomFamilyKey",
    default: false,
});

export const displayOtherMethodsAtomFamily = atomFamily({
    key: "displayOtherMethodsAtomFamilyKey",
    default: false,
});

export const previousStudiesTemplateDataAtom = atom({
    key: "previousStudiedTemplateDataAtom-Key",
    default: [{
        nodes: [{
            // previous studies
            id: '1',
            data: {
                label: 'Studies included in previous\n' +
                    'version of review (n= )\n' +
                    'Reports of studies included\n' +
                    'in previous version of\n' +
                    'review (n= )'
            },
            position: {x: -700, y: 50},
        }],
        edges: [{
            id: 'e1-2',
            source: '1',
            target: '2',
            type: 'step',
            markerEnd: {type: MarkerType.ArrowClosed}
        }]
    }
    ]
});

export const newStudiesTemplateDataAtom = atom({
    key: "newStudiesTemplateDataAtom-Key",
    default: [
        {
            nodes: [
                {
                    id: '2',
                    // you can also pass a React component as a label
                    data: {
                        label: <div>Total studies included in
                            review (n= )
                            Reports of total included
                            studies (n= )</div>
                    },
                    position: {x: -400, y: 200},
                },
                {
                    id: '3',
                    data: {
                        label: <div>Records identified from*:
                            Databases (n= )
                            Registers (n= )</div>
                    },
                    position: {x: -400, y: -520},
                },
                {
                    id: '4',
                    data: {label: <div>Records screened (n= )</div>},
                    position: {x: -400, y: -200},
                },
                {
                    id: '5',
                    data: {
                        label: <div>Reports sought for retrieval
                            (n= )</div>
                    },
                    position: {x: -400, y: -120},
                },
                {
                    id: '6',
                    data: {
                        label: <div>Reports assessed for
                            eligibility (n= )</div>
                    },
                    position: {x: -400, y: -30},
                },
                {
                    id: '7',
                    data: {
                        label: <div>New studies included in
                            review (n= )
                            Reports of new included
                            studies (n= )</div>
                    },
                    position: {x: -400, y: 60},
                },

                {
                    id: '8',
                    data: {
                        label: <div>Records removed before
                            screening:
                            Duplicate records
                            removed (n= )
                            Records marked as
                            ineligible by automation
                            tools (n= )
                            Records removed for
                            other reasons (n= )</div>
                    },
                    position: {x: -200, y: -400},
                },

                {
                    id: '9',
                    data: {label: <div>Records excluded (n= )</div>},
                    position: {x: -200, y: -130},
                },

                {
                    id: '10',
                    data: {label: <div>Reports not retrieved</div>},
                    position: {x: -200, y: -30},
                },
                {
                    id: '11',
                    data: {
                        label: <div> Reports excluded:
                            Reason 1 (n= )
                            Reason 2 (n= )
                            Reason 3 (n= ) etc</div>
                    },
                    position: {x: -200, y: 60},
                },
            ],
            edges: [
                {id: 'e3-4', source: '3', target: '4', markerEnd: {type: MarkerType.ArrowClosed}},
                {id: 'e4-5', source: '4', target: '5', markerEnd: {type: MarkerType.ArrowClosed}},
                {id: 'e5-6', source: '5', target: '6', markerEnd: {type: MarkerType.ArrowClosed}},
                {id: 'e6-7', source: '6', target: '7', markerEnd: {type: MarkerType.ArrowClosed}},
                {id: 'e7-2', source: '7', target: '2', markerEnd: {type: MarkerType.ArrowClosed}},
                {id: 'e3-8', source: '3', target: '8', type: 'step', markerEnd: {type: MarkerType.ArrowClosed}},
                {id: 'e4-9', source: '4', target: '9', type: 'step', markerEnd: {type: MarkerType.ArrowClosed}},
                {id: 'e5-10', source: '5', target: '10', type: 'step', markerEnd: {type: MarkerType.ArrowClosed}},
                {id: 'e6-11', source: '6', target: '11', type: 'step', markerEnd: {type: MarkerType.ArrowClosed}}
                // { id: 'e2-3', source: '2', target: '3', animated: true },
            ]
        }
    ]
});

export const otherMethodsTemplateDataAtom = atom({
    key: "otherMethodsTemplateDataAtom-Key",
    default: [{
        nodes: [{
            id: '12',
            data: {
                label: <div>Records identified from:
                    Websites (n= )
                    Organisations (n= )
                    Citation searching (n= ) etc</div>
            },
            position: {x: 400, y: -550},
        },
            {
                id: '13',
                data: {
                    label: <div>Reports sought for retrieval
                        (n= )</div>
                },
                position: {x: 400, y: -150},
            },
            {
                id: '14',
                data: {label: <div>Reports not retrieved (n= )</div>},
                position: {x: 600, y: -50},
            },
            {
                id: '15',
                data: {
                    label: <div>Reports assessed for
                        eligibility (n= )</div>
                },
                position: {x: 400, y: -50},
            },
            {
                id: '16',
                data: {
                    label: <div>Reports excluded:
                        Reason 1 (n= )
                        Reason 2 (n= )
                        Reason 3 (n= ) etc</div>
                },
                position: {x: 600, y: 50},
            }],
        edges: [{id: 'e12-13', source: '12', target: '13', type: 'step', markerEnd: {type: MarkerType.ArrowClosed}},
            {id: 'e13-14', source: '13', target: '14', type: 'step', markerEnd: {type: MarkerType.ArrowClosed}},
            {id: 'e13-15', source: '13', target: '15', type: 'step', markerEnd: {type: MarkerType.ArrowClosed}},
            {id: 'e15-16', source: '15', target: '16', type: 'step', markerEnd: {type: MarkerType.ArrowClosed}},
            {id: 'e15-7', source: '15', target: '7', type: 'step', markerEnd: {type: MarkerType.ArrowClosed}}]

    }],
});

export const templateDataSelectorFamily = selectorFamily({
    key: "templateDataAtomFamilyKey",
    get: (id) => ({get}) => {
        const displayPreviousStudies = get(displayPreviousStudiesAtomFamily(id));
        const displayOtherMethods = get(displayOtherMethodsAtomFamily(id));

        const newStudiesData = get(newStudiesTemplateDataAtom);
        const otherMethodsData = get(otherMethodsTemplateDataAtom);
        const previousStudiesData = get(previousStudiesTemplateDataAtom);

        let combinedData = recursiveDeepClone(newStudiesData)
       //console.log(combinedData)
        if(displayPreviousStudies){
            combinedData[0].nodes.unshift(previousStudiesData[0].nodes[0]);
            combinedData[0].edges.unshift(previousStudiesData[0].edges[0]);
        }

        if(displayOtherMethods){
            combinedData[0].nodes.push(...otherMethodsData[0].nodes); // combinedData[0].nodes, otherMethodsData[0].nodes];
            combinedData[0].edges.push(...otherMethodsData[0].edges); //, otherMethodsData[0].edges];
        }

        return combinedData
    }
});
