import React, {useEffect} from "react";
import WordCloudTopControls from "./controls/WordCloudTopControls";
import WordCloudChart from "./Wordcloud";
import {wordCloudChartDataAtomFamily} from "./state/WordCloudState";
import * as _ from "lodash";
import {useRecoilState, useRecoilValue} from "recoil";

import {monitoringDataAtom} from "../../../../../dataProvider/vissights/overview/overview";
import keycloak from "../../../../../dataProvider/vissights/authentication/keycloak";
import {activeDatasetAtomFamily} from "../state";

function WordCloudContainer(props) {
    const componentId = props.id;
    const setModuleToDisplay = props.setModuleToDisplay;
    const setSearchTerm = props.setSearchTerm;
    // stuff we pass to top controls
    const topControlConfig = props.topControls;
    const deleteContainer = props.deleteContainer;
    const results = useRecoilValue(wordCloudChartDataAtomFamily(componentId));
    const data = _.cloneDeep(results); //.filter((d)  => d.text.length < 60)

    console.log(data)

    const monitoringData = useRecoilValue(monitoringDataAtom);
    const [activeDataset, setActiveDataset] = useRecoilState(activeDatasetAtomFamily(componentId))
    useEffect(() => {
        if(keycloak.authenticated &&  monitoringData.length >= 1 && activeDataset !== "monitoring"){
            setActiveDataset("monitoring");
        }
    },[])

    return (
        <div>
            <WordCloudTopControls topControlConfig={topControlConfig}
                                  deleteContainer={deleteContainer}
                                  id={props.id}
            />
            <div style={{width: props.containerDimensions[0]}}>
                <WordCloudChart
                    id={props.id}
                    results={data}
                    width={props.containerDimensions[0]}
                    height={props.containerDimensions[1]}
                    setModuleToDisplay={setModuleToDisplay}
                    setSearchTerm={setSearchTerm}
                    setSearchTermAndRerouteToZoomAndFilter={props.setSearchTermAndRerouteToZoomAndFilter}
                />
            </div>
        </div>
    );
}

export default WordCloudContainer;