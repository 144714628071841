import React from "react";
import Modal from "react-bootstrap/Modal";
import {atom, useRecoilState} from "recoil";

// call the fetchTopicOverview selector and store it as default value for the data atom
export const displaySavedScreenshotModalAtom = atom({
    key: "displaySavedScreenshotModalAtom-Key",
    default: false,
});

export const currentlySavedScreenshotIdAtom = atom({
    key: "currentlySavedScreenshotIdAtom-Key",
    default: false,
});


function SavedScreenshotModal() {
    const [displayModal, setDisplayModal] = useRecoilState(displaySavedScreenshotModalAtom);

    const hide = () => {
        setDisplayModal(false);
    }

    return (
        <Modal show={displayModal} onHide={hide} className="modal-saved-screenshot">
            <Modal.Body>
                Screenshot was successfully created.
            </Modal.Body>
        </Modal>
    )
}

export default SavedScreenshotModal;