import React, {useEffect, useRef} from "react";
import * as d3 from 'd3';
import {atom, useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {
    activeColorSchemeAtomFamily, activePathSchemeAtomFamily, brushesAtomFamily, visualizationMultiSelectionAtomFamily,
    visualizationPinAtomFamily,
} from "../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import {recursiveDeepClone} from "../../../../../utility";
import * as _ from 'lodash';
import {
    parallelCoordinatesActiveDimensionsAtomFamily
} from "./state/parallel-coordinates-chart-state";


export const mouseXYAtom = atom({
    key: "mouseXYAtom",
    default: 0
});


function ParallelCoordinatesChart(props) {
    const ref = useRef();
    const componentId = props.id;
    const containerWidth = props.width;
    const containerHeight = props.height;
    const toggleFacetFilter = props.toggleFacetFilter;
    let chartData = recursiveDeepClone(props.data);
    let splitData = recursiveDeepClone(props.splitData);
    const [pinId, setNewPinId] = useRecoilState(visualizationPinAtomFamily(componentId));
    const resetPinId = useResetRecoilState(visualizationPinAtomFamily(componentId));
    const [multiSelection, setNewMultiSelection] = useRecoilState(visualizationMultiSelectionAtomFamily(componentId));
    const activeDimensions = useRecoilValue(parallelCoordinatesActiveDimensionsAtomFamily(componentId));
    // const [dimensions, setDimensions] = useRecoilState(parallelCoordinatesInitialDimensionsAtomFamily(componentId));
    // const [normalization, setNormalization] = useRecoilState(visualizationPinAtomFamily(componentId));
    // const activeFacet = useRecoilValue(activeFacetAtomFamily(componentId));
    // const [pinnedYear, setPinnedYear] = useRecoilState(subVisualizationPinAtomFamily(componentId));
    const toggleContextMenu = props.toggleContextMenu;
    // const multiSelectionData = useRecoilValue(activatedMultiSelectionDataAtomFamily(componentId))
    const colorScheme = useRecoilValue(activeColorSchemeAtomFamily(componentId));
    const pathDrawing = useRecoilValue(activePathSchemeAtomFamily(componentId));
    const [brushes, setBrushes] = useRecoilState(brushesAtomFamily(componentId));
    const [mouseXY, setMouseXY] = useRecoilState(mouseXYAtom);

    let currentlyBrushed;
    let activeIndexes = [];
    let fullRedraw = false;

    useEffect(() => {
        updateChart();
    });

    useEffect(() => {
        removeOldGroups();
        // removeAxisBrushes();
        // drawChart();
        removeLines();
        fullRedraw = true;
        drawChart();
    }, [containerWidth, containerHeight, props.data, activeDimensions, props.splitData])

    const updateChart = () => {
        removeLines();
        fullRedraw = false;
        drawChart();
    }

    const removeLines = () => {
        d3.select(ref.current)
            .selectAll('g')
            .selectAll('g .foreground').remove();

        d3.select(ref.current)
            .selectAll('g')
            .selectAll('g .background').remove();
    }

    const removeOldGroups = () => {
        d3.select(ref.current)
            .selectAll('g')
            .remove();
    }

    const removeAxisBrushes = () => {
        d3.select(ref.current)
            .selectAll('g')
            .selectAll('g .dimension')
            .remove();
    }

    const pinElementById = (pin) => {
        if (pin === pinId) {
            resetPinId();
        } else {
            setNewPinId(pin)
        }
    }

    // multiselection
    const toggleMultiSelectionById = (el) => {
        let cloned = recursiveDeepClone(multiSelection);

        if (cloned.includes(el)) {
            cloned = cloned.filter((d) => d !== el)
        } else {
            cloned.push(el)
        }
        setNewMultiSelection(cloned);
    }


    const setFacetFilter = (facet, value) => {
        toggleFacetFilter(facet, value)
    }


    const lineColor = () => {
        const cols = {};
        // tslint:disable-next-line:forin
        for (const i in activeDimensions) {

            /*
             if(d === 'year') {
                y[d] = d3.scaleLinear()
                    .domain(d3.extent(chartData, (p) => +p[d]))
                    .range([containerHeight - 30, 0])
            } else {
                const values = chartData.map((p) => p[d] !== null ? p[d][0] : 'no')
                console.log(values);
                y[d] = d3.scalePoint()
                     .domain(values)
                    .range([containerHeight - 30, 0])

            }
             */


            // @ts-ignore
            const name = activeDimensions[i].name;

            if (name === 'year') {
                cols[name] = d3.scaleSequential()
                    .domain(d3.extent(chartData, (d) => {
                        return +d[name];
                    }))
                    .interpolator(d3[colorScheme]);
            } else {
                const values = chartData.map((p) => p[name] !== undefined ? p[name][0] : 'no')
                cols[name] = d3.scalePoint()
                    .domain(values)
                    .range(d3[colorScheme])
                // .domain(d3.range(0, 100))
                //.domain(d3.extent(chartData, (d) => {
                //      console.log('test', d, +d[name])
                //     return +d[name];
                // }))
                // .interpolator(d3[colorScheme]);
            }
        }
        return cols;
    };


    const drawChart = () => {
        // years have to be handled seperately as the numbers must be formatted to remove ','s
        const yearAxis = d3.axisLeft().tickFormat(d3.format("d"));
        const axis = d3.axisLeft();
        const pathDrawingMethod = pathDrawing;
        const lineColors = lineColor();
        const svg = d3.select(ref.current);

        svg.attr('width', containerWidth)
            .attr('height', containerHeight)
            .on('contextmenu', (d) => toggleContextMenu('d.from', ref.current));
        // For each dimension, I build a linear scale. I store all in a y object
        const y = {};

        activeDimensions.forEach((d, i, n) => {
            if (d.name === 'year') {
                y[d.name] = d3.scaleLinear()
                    .domain(d3.extent(chartData, (p) => +p[d.name]))
                    // .tickFormat(d3.timeFormat("%Y"))
                    .range([containerHeight - 30, 0])//
            } else {
                /*
                const shortenTextTest = (text) => {
                    if(text !== undefined && isString(text)) {
                        return text.length <= 8 ? text : (text.slice(0, 8).toString() + '...');
                    }
                   else return 'no value';
                }
                */
                const values = chartData.map((p) => p[d.name] !== undefined ? p[d.name][0] : 'no value')
                y[d.name] = d3.scalePoint()
                    .domain(values)
                    // .domain(d3.range(0, values.length))
                    //.rangePoints([containerHeight, 0]);
                    .range([containerHeight - 30, 0])

                // custom invert function
                y[d.name].invert = (() => {
                    // const domain = y[d.name].domain()
                    // const range = y[d.name].range()

                    /*
                                        const yPos = mouseXY[1];
                                        const domain = y[d.name].domain();
                                        const range = y[d.name].range();
                                        const rangePoints = d3.range(range[0], range[1], y[d.name].step())
                                        const xPos = domain[d3.bisect(rangePoints, yPos) -1];
                                        console.log(xPos, yPos);
                    */


                    const scale = d3.scaleQuantize().domain([0, containerHeight - 30]).range(values);

                    return function (y) {
                        // console.log(y, scale(y))
                        return scale(y)
                    }
                })()
                /*
                 y[d.name].invert = (() => {
                    const xPos = d3.event.pageX;
                    const domain = y[d.name].domain();
                    const range = y[d.name].range();
                    const rangePoints = d3.range(range[0], range[1], y[d.name].step())
                    const yPos = domain[d3.bisect(rangePoints, xPos) -1];
                    return yPos;
                })()
                 */
            }
            /*
             x: d3.scaleBand()
                .domain(d3.range(minX, maxX))
                .range([0, (w * scale) - 50]),
             */
        });

        const actualDimensions = [];

        activeDimensions.map((d) => actualDimensions.push(d.name))

        // console.log(actualDimensions)

        const x = d3.scalePoint()
            .range([0, containerWidth])
            .padding(0.1)
            .domain(actualDimensions);

        const chartGroup = svg
            .append('g')
            .attr('transform', 'translate(' + 10 + ',' + 20 + ')');

        /**
         *  Implementation of differenct pathes e.g. bezier..
         *  CC0 Licence and extracted from arielmant0 @ https://observablehq.com/@arielmant0/bezier-parallel-coordinate-plots
         */

        const paths = {
            straight_path(d, dim, next) {
                return d3.line()([[x(dim), y[dim](d.from)], [x(next), y[next](d.to)]]);
            },
            bezier_path(d, dim, next) {
                return d3.linkHorizontal()
                    .x(([a]) => a)
                    .y(([, b]) => b)
                    ({source: [x(dim), y[dim](d.from)], target: [x(next), y[next](d.to)]})
            },
            bezier_path_down(d, dim, next) {
                const p = d3.path();
                p.moveTo(x(dim), y[dim](d.from));
                p.quadraticCurveTo(x(dim), y[next](d.to), x(next), y[next](d.to));
                return p.toString();
            },
            bezier_path_up(d, dim, next) {
                const p = d3.path();
                p.moveTo(x(dim), y[dim](d.from));
                p.quadraticCurveTo(x(next), y[dim](d.from), x(next), y[next](d.to));
                return p.toString();
            }
        };

        const foreground = chartGroup
            .append('g')
            .attr('class', 'foreground');

        const background = chartGroup
            .append('g')
            .attr('class', 'background');

        foreground
            .selectAll('g')
            .selectAll('path')
            .remove();

        background
            .selectAll('g')
            .selectAll('path')
            .remove();

        const click = (d) => {
            //  pinElementById(d.index)
            // console.log(d)
            toggleMultiSelectionById(d.index);
        };

        // defines what happens when the mouse is over a bar.
        const onMouseOver = (d, i, n) => {
            const currElement = n[i];
            d3.select(currElement).attr('stroke-width', 6);
        };

        const onMouseOut = (d, i, n) => {
            const currElement = n[i];
            // d3.select(currElement).attr('stroke-width', d.index !== pinId ? 1 : 4);
            d3.select(currElement).attr('stroke-width', !multiSelection.includes(d.index) ? 2 : 3);
        };

        const addLinesBackground = (dim, next, data) => {
            foreground
                .append('g')
                .attr('class', dim)
                .selectAll('path')
                .data(data)
                .enter()
                .append('path')
                .on('mouseover', onMouseOver)
                .on('mouseout', onMouseOut)
                .on('click', click)
                // .attr('class', (d, i) => 'path-' + i)
                // here we will differentiate the methods
                .attr('d', (d) => d.active !== true ? paths[pathDrawingMethod](d, dim, next) : null)
                // TODO: Reimplement color schemes.
                // .attr('stroke', (d) => d.index !== pinId ? lineColors[dim](d.from) : '#DC143C')
                // .attr('stroke', (d) => d.index !== pinId ? 'blue' : '#DC143C')
                .attr('stroke', (d) => multiSelection.includes(d.index) ? '#dc143c' : '#adcfe6')
                // .attr('stroke-width', (d) => d.index === pinId ? 4 : 1)
                .attr('stroke-width', (d) => multiSelection.includes(d.index) ? 3 : 2)
                .attr('stroke-opacity', (d) => {

                    if (multiSelection.includes(d.index)) {
                        return 1;
                    } else {
                        return 0.2;
                    }

                })
                /*
                .attr('stroke-opacity', (d) => {
                    if (pinId !== -1) {
                        if (d.index === pinId) {
                            return 1;
                        } else {
                            return 0.2;
                        }
                    } else {
                        return 1;
                    }
                })
                 */
                .attr('fill', 'none')
                .attr('cursor', 'pointer')
                .append('title')
                .text((d) => d.name);
        };

        const addLinesForeground = (dim, next, data) => {
            background
                .append('g')
                .attr('class', dim)
                .selectAll('path')
                .data(data)
                .enter()
                .append('path')
                .on('mouseover', onMouseOver)
                .on('mouseout', onMouseOut)
                .on('click', click)
                // .attr('class', (d, i) => 'path-' + i)
                // here we will differentiate the methods
                .attr('d', (d) => d.active ? paths[pathDrawingMethod](d, dim, next) : null)
                // .attr('stroke', (d) => d.index !== pinId ? '#eeb33f' : '#DC143C')
                .attr('stroke', (d) => !multiSelection.includes(d.index) ? '#eeb33f' : '#DC143C')
                .attr('stroke-width', (d) => multiSelection.includes(d.index) ? 3 : 1)
                .attr('stroke-opacity', (d) => {
                    if (multiSelection.includes(d.index)) {
                        if (multiSelection.includes(d.index)) {
                            return 1;
                        } else {
                            return 0.2;
                        }
                    } else {
                        return 1;
                    }
                })
                /*
                .attr('stroke-opacity', (d) => {
                    if (pinId !== -1) {
                        if (d.index === pinId) {
                            return 1;
                        } else {
                            return 0.2;
                        }
                    } else {
                        return 1;
                    }
                })
                 */
                .attr('fill', 'none')
                .attr('cursor', 'pointer')
                .append('title')
                .text((d) => d.name);
        };

        // filter function for brushes. Calculates the intersection of available line indexes.
        if (brushes !== undefined) {
            activeIndexes.splice(0, activeIndexes.length);
            // const calcActive = () => {
            brushes.forEach((g, j) => {
                for (const [key, value] of Object.entries(splitData)) {
                    if (key === g.dimension) {
                        const testArray = [];
                        // @ts-ignore
                        value.forEach((h) => {
                            if (brushes[j].extent[1] <= h.from && h.from <= brushes[j].extent[0]) {
                                // console.log(h);
                                testArray.push(h.index);
                            }
                        });
                        activeIndexes.push(testArray);
                    }
                }
            });

            for (const [key, value] of Object.entries(splitData)) {
                // @ts-ignore
                value.map((l) => {
                    l.active = false;
                })
            }

            //console.log(this.activeIndexes);

            const intersection = _.intersection.apply(_, activeIndexes)

            //console.log(intersection);

            for (const [key, value] of Object.entries(splitData)) {
                // @ts-ignore
                value.map((l) => {
                    intersection.forEach((a, b) => {
                        if (l.index === a) {
                            l.active = true;
                        }
                    })
                })
            }

        }

        for (let i = 0; i < activeDimensions.length; ++i) {
            const next = i < activeDimensions.length - 1 ? i + 1 : i - 1;
            addLinesBackground(activeDimensions[i].name, activeDimensions[next].name, splitData[activeDimensions[i].name]);
            addLinesForeground(activeDimensions[i].name, activeDimensions[next].name, splitData[activeDimensions[i].name]);
        }

        // we dont want to fire the store event too often, so we made it async
        const brushDef = () => {
            const actives = [];

            /*
            function scalePointPosition() {
                const xPos = d3.mouse(this)[0];
                const domain = x.domain();
                const range = x.range();
                const rangePoints = d3.range(range[0], range[1], x.step())
                const yPos = domain[d3.bisect(rangePoints, xPos) -1];
                console.log(yPos);
            }
            */

            chartGroup.selectAll('.brush')
                .on('mousemove', (d, i, n) => {
                    // console.log(d3.mouse(n[i]));
                    setMouseXY(d3.mouse(n[i]))
                })
                .filter((d, i, n) => {
                    const currElement = n[i];
                    y[d.name].brushSelectionValue = d3.brushSelection(currElement);
                    return d3.brushSelection(currElement);
                })
                .each((d, i, n) => {
                    const currElement = n[i];
                    // Get extents of brush along each active selection axis (the Y axes)
                    if (d.name === 'year') {
                        actives.push({
                            dimension: d.name,
                            extent: d3.brushSelection(currElement).map(y[d.name].invert)
                        });
                    } else {
                        actives.push({
                            dimension: d.name,
                            extent: d3.brushSelection(currElement).map(y[d.name].invert)
                        });

                    }
                });
            currentlyBrushed = actives;
        };

        const brushEnd = () => {
            // console.log(this.currentlyBrushed);
            // this.store.dispatch(new StoreCurrentBrushes(this.currentlyBrushed, this.componentId))
            setBrushes(currentlyBrushed);
        };

        const drawAxis = () => {
            // Add a group element for each dimension.
            const g = chartGroup.selectAll('.dimension')
                .data(activeDimensions)
                .enter()
                .append('g')
                .attr('class', 'dimension')
                .attr('transform', (d) => 'translate(' + x(d.name) + ')');

            // Add an axis and title.
            g.append('g')
                .attr('class', 'axis')
                .each((d, i, n) => {
                    const currElement = n[i];
                    // years have to be handled separately as the numbers must be formatted to remove ','s
                    if (d.name === 'year') {
                        d3.select(currElement)
                            .call(yearAxis.scale(y[d.name]));
                    } else


                        d3.select(currElement)
                            .call(axis.scale(y[d.name]));
                });
            // add axis headings
            g
                .append('text')
                .attr('text-anchor', 'middle')
                .attr('y', -9)
                .attr('class', "whiteOnDarkMode")
                .text((d) => d.name);

            // Add brush for each axis.
            g.append('g')
                .attr('class', 'brush')
                .each((d, i, n) => {
                    const currElement = n[i];
                    d3.select(currElement)
                        .call(currElement.brush = d3.brushY()
                            .extent([[-10, 0], [10, containerHeight]])
                            .on('brush', brushDef)
                            .on('end', brushEnd)
                        )
                })
                .selectAll('rect')
                .attr('x', -8)
                .attr('width', 16);
        };

        // also redraw axis when true
        if (fullRedraw === true) {
            removeAxisBrushes();
            drawAxis();
        }
    }


    return (
        <svg ref={ref}>
            <g/>
        </svg>
    );

}

export default ParallelCoordinatesChart;


