import {genericSort} from "../../../utility";

// Facets
const TYPES = 'Types';
const YEARS = 'Years';
const TOPICS = 'Topics';
const AUTHORS = 'Authors';
const COUNTRIES = 'Countries';
const AFFILIATIONS = 'Affiliations';
const PREFIX = 'Prefix';
const VENUES = 'Venues';

// Facets Keys
const TYPES_KEY = 'type';
const YEARS_KEY = 'year';
const TOPICS_KEY = 'topics';
const AUTHORS_KEY = 'author';
const COUNTRIES_KEY = 'country';
const AFFILIATIONS_KEY = 'affiliation';
const PREFIX_KEY = 'key';
const VENUES_KEY = ['booktitle', 'journal'];


 const FACET = {
    TYPE: TYPES,
    YEAR: YEARS,
    TOPIC: TOPICS,
    AUTHOR: AUTHORS,
    COUNTRY: COUNTRIES,
    AFFILIATION: AFFILIATIONS,
    PREFIX,
    VENUE: VENUES,
    types: [YEARS, TYPES, TOPICS, AUTHORS, COUNTRIES, AFFILIATIONS, PREFIX, VENUES],
    typesKeys: [TYPES_KEY, YEARS_KEY, TOPICS_KEY, AUTHORS_KEY, COUNTRIES_KEY, AFFILIATIONS_KEY, PREFIX_KEY, VENUES_KEY[0], VENUES_KEY[1]],
     // all keys seperated. So we can use them in other files.
     TYPES_KEY,
     YEARS_KEY,
     TOPICS_KEY,
     AUTHORS_KEY ,
     COUNTRIES_KEY,
     AFFILIATIONS_KEY,
     PREFIX_KEY,
     VENUES_KEY,


    facetName(facet, id, topics) {
        return facet === TOPICS ? this.getTopicNameById(topics, id) : id;
    },

    eachGeneric(type, e, f) {
        if (!e.hasOwnProperty(type) || e[type] === null) return;
        e[type].forEach((v) => {
            f(v, 1);
        });
    },

    eachTopics(e, f) {
        if (!e.hasOwnProperty(TOPICS_KEY)) return;
        const ts = e[TOPICS_KEY];
        for (const id in ts) {
            if (ts.hasOwnProperty(id)) f(id, 1);
        }
    },

    eachAffiliation(e, f) {
        this.eachGeneric(AFFILIATIONS_KEY, e, (v) => {
            const idx = v.lastIndexOf(',');
            if (idx > 0) v = v.substring(0, idx);
            f(v, 1);
        });
    },

    eachPrefix(e, f) {
        this.eachGeneric(PREFIX_KEY, e, (v) => {
            const idx = v.indexOf('/', v.indexOf('/') + 1);
            if (idx !== -1) v = v.substring(0, idx);
            f(v, 1);
        });
    },

    eachVenues(e, f) {
        // if (e.journal !== null && e.booktitle !== null) {
            VENUES_KEY.forEach((key) => {
                this.eachGeneric(key, e, f);
            });
        // }
    },

    eachFacet(facet, e, f) {
        switch (facet) {
            case TYPES:
                return this.eachGeneric(TYPES_KEY, e, f);
            case YEARS:
                return this.eachGeneric(YEARS_KEY, e, f);
            case TOPICS:
                return this.eachTopics(e, f);
            case AUTHORS:
                return this.eachGeneric(AUTHORS_KEY, e, f);
            case COUNTRIES:
                return this.eachGeneric(COUNTRIES_KEY, e, f);
            case AFFILIATIONS:
                return this.eachAffiliation(e, f);
            //case PREFIX:
            //    return this.eachPrefix(e, f);
            case VENUES:
                return this.eachVenues(e, f);
            default:
                return this.eachGeneric(e, f);
        }
    },

    extractFacet(data, facet, topics) {
        const m = {};
        if(data !== null){
            data.forEach((entry) => {
                const l = [];
                this.eachFacet(facet, entry, (id, amount) => {
                    l.push(id);
                    if (m.hasOwnProperty(id)) {
                        m[id].amount += amount;
                    } else {
                        m[id] = {id, name: this.facetName(facet, id, topics), amount, links: {}, years: {}};
                    }
                    this.eachFacet(YEARS, entry, (year) => {
                        m[id].years[year] = (m[id].years[year] || 0) + amount;
                    });
                });
                l.forEach((a) => {
                    l.forEach((b) => {
                        m[a].links[b] = (m[a].links[b] || 0) + 1;
                    });
                });
            });
            const loo = Object.values(m);
            loo['lookup'] = m;
            return loo;
        }
    },

    getTopicNameById(topics, topicid) {
        // console.log('getTopicNameById')
        if (topics.length === 0 || topicid === undefined) return topicid;
        const [[pTerm, pValue]] = topics[topicid].phrase;
        if (pValue > 0.05) {
            return pTerm;
        }
        const [[w1Term, w1Value], [w2Term, w2Value]] = topics[topicid].word;
        if (w1Value > w2Value * 2) {
            return w1Term;
        }
        if (w2Term.indexOf(w1Term) !== -1) {
            return w1Term;
        }
        if (w1Term.indexOf(w2Term) !== -1) {
            return w2Term;
        }
        return `${w1Term}, ${w2Term}`;
    },

    extractAllFacets(data, topics) {
        const facets = {};
        this.types.forEach((facet) => {
            facets[facet] = this.extractFacet(data, facet, topics);
            if(facet !== 'Years'){
                return facets[facet].sort(genericSort('amount', 'desc'));
            }else {
                return facets[facet].sort(genericSort('name', 'desc'));
            }
        });
        return facets;
    }
}
export default FACET