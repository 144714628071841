import {Button, Col, Row, FormCheck, Badge} from "react-bootstrap";
import React from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    alwaysDisplayNumbersAtomFamily,
    bandWidthLOESSRegressionAtomFamily,
    benchmarksTemporalOverviewAtom,
    displayLinearRegressionAtomFamily,
    displayLinesAtomFamily,
    displayLOESSRegressionAtomFamily,
    displayNeighborsAtomFamily, temporalOverviewChartDataAtomFamily
} from "../layouts/TemporalOverviewLayout/temporal-overview-chart-state";
import Dropdown from 'react-multilevel-dropdown';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalculator, faCamera, faCog, faCross, faEye, faTools, faTrash} from "@fortawesome/free-solid-svg-icons";
import Slider from "rc-slider";
import {searchDatabase, searchTermAtom} from "../../../../../../dataProvider/vissights/search/search";
import {moduleToDisplayAtom} from "../../../../../Dashboards/DashboardLayout";
import domtoimage from "dom-to-image";
import uuid from "react-uuid";
import * as _ from "lodash";
import {singleVisualizationScreenshotsAtom} from "../../../../reporting/state/ReportingState";
import {displaySavedScreenshotModalAtom} from "../../../../../Misc/Modals/SavedScreenshot";
import {
    visualizationPinAtomFamily
} from "../../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";

function TemporalOverviewChartTopControls(props) {
    const componentId = props.id;
    const benchmarks = useRecoilValue(benchmarksTemporalOverviewAtom);
    const [alwaysDisplayNumbers, setAlwaysDisplayNumbers] = useRecoilState(alwaysDisplayNumbersAtomFamily(componentId));
    // const setDisplayColors = useSetRecoilState(displayColorsAtom);
    const [displayNeighbors, setDisplayNeighbors] = useRecoilState(displayNeighborsAtomFamily(componentId));
    const [displayLines, setDisplayLines] = useRecoilState(displayLinesAtomFamily(componentId));
    const [displayLinearRegression, setDisplayLinearRegression] = useRecoilState(displayLinearRegressionAtomFamily(componentId));
    const [displayLOESSRegression, setDisplayLOESSRegression] = useRecoilState(displayLOESSRegressionAtomFamily(componentId));
    const [bandWidthLOESSRegression, setBandWidthLOESSRegression] = useRecoilState(bandWidthLOESSRegressionAtomFamily(componentId));
    // infos we need for screenshot.
    const db = useRecoilValue(searchDatabase);
    const searchTerm = useRecoilValue(searchTermAtom)
    const module = useRecoilValue(moduleToDisplayAtom);
    const setDisplayScreenshotModal = useSetRecoilState(displaySavedScreenshotModalAtom);
    const [singleVisualizationScreenshots, setSingleVisualizationScreenshots] = useRecoilState(singleVisualizationScreenshotsAtom);
    const temporalOverviewData = useRecoilValue(temporalOverviewChartDataAtomFamily(componentId));
    const [pinId, setPinId] = useRecoilState(visualizationPinAtomFamily(componentId));

    const createLabelForPublications = (amount, year) => {
        //console.log(amount)
        const plural = amount > 1 ? 's' : ''
        return   + amount + ' Publication' + plural + ' in ' + year;
    }


    const createVisualizationScreenshot = () => {
        const node = document.getElementById(componentId);
        domtoimage.toPng(node)
            .then((dataUrl) => {
                const newUUID = uuid();
                // store the image, the module, and the current database
                const autoGeneratedText = 'The following chart was taken in the Zoom and Filter Module' +
                    'searching for' + searchTerm + ' in the database: ' + db.name + '. ' +
                    'It shows a temporal overview.';
                const metaDataObj = {id: newUUID, dataUrl, module, db: db.name, autoGeneratedText}
                const clone = _.cloneDeep(singleVisualizationScreenshots)
                clone.push({id: newUUID, data: metaDataObj});
                setSingleVisualizationScreenshots(clone);
            }).then(() => setDisplayScreenshotModal(true));
    }

    const ContainerTopControls = () => {
        return (
            <Row>
                <Col xs={1}>
                    <Dropdown position="right"
                              title={<FontAwesomeIcon style={{width: 20, height: 20}} icon={faCog}/>}
                    >

                        {
                            // only show benchmarks if there is data to display.
                            benchmarks.length > 0 ?
                                <Dropdown.Item position="right"
                                               onClick={() => console.log("")}
                                >
                                    <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}}
                                                     icon={faCalculator}/> {/* <IconSigma/>*/} Benchmarks
                                    <Dropdown.Submenu position="right">
                                        <Dropdown.Item>
                                            {benchmarks['sum'] + ' in ' + benchmarks['amountYears'] + ' years'}
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            {'Maximum: ' + benchmarks['maxAmount'] + ' in ' + benchmarks['yearMaxAmount']}
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            {'Average: ' + benchmarks['avg'].toFixed(2)}
                                        </Dropdown.Item>
                                    </Dropdown.Submenu>
                                </Dropdown.Item>
                                : null

                        }


                        <Dropdown.Item>
                            <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}} icon={faTools}/> Tools
                            <Dropdown.Submenu position="right">
                                <Dropdown.Item>
                                    Regressions
                                    <Dropdown.Submenu position="right">
                                        <Dropdown.Item>
                                            <FormCheck type="checkbox" defaultChecked={displayLinearRegression}
                                                       onChange={(e) => {
                                                           e.stopPropagation();
                                                           e.preventDefault();
                                                           setDisplayLinearRegression(!displayLinearRegression)
                                                       }}
                                            /> Linear Regression

                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <FormCheck type="checkbox" defaultChecked={displayLOESSRegression}
                                                       onChange={(e) => {
                                                           e.stopPropagation();
                                                           e.preventDefault();
                                                           setDisplayLOESSRegression(!displayLOESSRegression)
                                                       }}
                                            /> Loess Regression
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            Bandwidth:
                                            <Slider key={'bandwidth-slider-key'}
                                                    min={0}
                                                    step={0.1}
                                                    max={1}
                                                    defaultValue={bandWidthLOESSRegression}
                                                    onAfterChange={(val) => {
                                                        setBandWidthLOESSRegression(val)
                                                    }}
                                            />
                                        </Dropdown.Item>
                                    </Dropdown.Submenu>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    Advanced
                                    <Dropdown.Submenu position="right">
                                        <Dropdown.Item>
                                            <FormCheck type="checkbox" defaultChecked={displayLines}
                                                       onChange={(e) => {
                                                           e.stopPropagation();
                                                           e.preventDefault();
                                                           setDisplayLines(!displayLines)
                                                       }}
                                            /> Lines
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <FormCheck type="checkbox" defaultChecked={displayNeighbors}
                                                       onChange={(e) => {
                                                           e.stopPropagation();
                                                           e.preventDefault();
                                                           setDisplayNeighbors(!displayNeighbors)
                                                       }}
                                            /> Neighborhood
                                        </Dropdown.Item>
                                    </Dropdown.Submenu>
                                </Dropdown.Item>
                            </Dropdown.Submenu>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}} icon={faEye}/> View
                            Settings
                            <Dropdown.Submenu position="right">
                                <Dropdown.Item position="right">
                                    <FormCheck type="checkbox" defaultChecked={alwaysDisplayNumbers}
                                               onChange={(e) => {
                                                   e.stopPropagation();
                                                   e.preventDefault();
                                                   setAlwaysDisplayNumbers(!alwaysDisplayNumbers);
                                               }}
                                    /> Display Numbers
                                </Dropdown.Item>
                            </Dropdown.Submenu>
                        </Dropdown.Item>

                        {/*
                             <Dropdown.Item>
                               <IconReporting/> Reporting
                            <Dropdown.Submenu position="right">

                            </Dropdown.Submenu>
                        </Dropdown.Item>
                            */}
                        <Dropdown.Item onClick={() => createVisualizationScreenshot()}>
                            <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}}
                                             icon={faCamera}/> Screenshot Chart
                        </Dropdown.Item>
                    </Dropdown>
                </Col>
                <Col xs={2} style={{marginTop: 8}}>

                    {
                        /*
                         <Badge key={1} style={{marginRight: 5, cursor: 'pointer'}}
                           variant="primary"
                           >
                        LDA topics
                    </Badge>
                    <Badge key={1} style={{marginRight: 5, cursor: 'pointer'}}
                           variant="light"
                    >
                        CTM topics
                    </Badge>
                    <Badge key={1} style={{marginRight: 5, cursor: 'pointer'}}
                           variant="light"
                    >
                        LSI topics
                    </Badge>
                         */
                    }

                </Col>
                <Col xs={5}>
                    <>{temporalOverviewData.length < 2 ? createLabelForPublications(temporalOverviewData[0].amount, temporalOverviewData[0].year)  : null }</>
                </Col>
                <Col xs={4}>
                    <div className="grid-item-container-top-control">
                        <Button type="button" className="float-right" variant="light" value="Delete" onClick={(e) => {
                            e.preventDefault();
                            props.deleteContainer(props.id);
                        }}>X</Button>
                    </div>
                </Col>
            </Row>
        );
    }
    return (
        <ContainerTopControls/>
    );
}

export default TemporalOverviewChartTopControls;