import React, {useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {dashboardWidthAtom} from "../../../config/sizings/sizings";
import Reporting from "./Reporting";
import {moduleToDisplayAtom} from "../../Dashboards/DashboardLayout";

function ReportingDashboard() {
    const dashboardWidth = useRecoilValue(dashboardWidthAtom);
    const setModuleToDisplay = useSetRecoilState(moduleToDisplayAtom);
    useEffect(() => {
        setModuleToDisplay('reporting')
    })
    return (
        <div className="ReportingDashboard" style={{display: "flex"}}>
            <div style={{width: dashboardWidth}}>
                <Reporting/>
            </div>
        </div>
    );
}

export default ReportingDashboard;