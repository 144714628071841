import React, {useEffect, useRef} from "react";
import * as d3 from 'd3';
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {
    visualizationPinAtomFamily,
    visualizationScaleAtomFamily,
    visualizationTranslationXAtomFamily
} from "../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import {activeFacetAtomFamily} from "../../../overview/visualizations/state";
import {
    barChartActiveBarFillColor,
    barChartActiveBarStrokeColor,
    barChartOverlayLineStrokeColor,
    barChartStandardBarStrokeColor, barPreviewColor, calculateBarColorBySignalStrength
} from "../../../../../styles/colors";


function BarChart(props) {
    const componentId = props.id;
    const ref = useRef()
    const containerWidth = props.width;
    const containerHeight = props.height;
    const calculatedData = props.data;
    const toggleFacetFilter = props.toggleFacetFilter;
    const [scale, setNewScale] = useRecoilState(visualizationScaleAtomFamily(componentId));
    const [translationX, setTranslationX] = useRecoilState(visualizationTranslationXAtomFamily(componentId));
    // const [hoveredElementId, setHoveredElementId] = useRecoilState(hoveredElementIdAtomFamily(componentId));
    const [pinId, setNewPinId] = useRecoilState(visualizationPinAtomFamily(componentId));
    const resetPinId = useResetRecoilState(visualizationPinAtomFamily(componentId))
    const activeFacet = useRecoilValue(activeFacetAtomFamily(componentId));
    const toggleContextMenu = props.toggleContextMenu;
    let zoom = null;
    /****** Bind the colors from the color.ts file to variables. *****/
    const standardBarStroke = barChartStandardBarStrokeColor();
    const activeBarStrokeColor = barChartActiveBarStrokeColor();
    const activeBarFillColor = barChartActiveBarFillColor();

    useEffect(() => {
        createChart();
    });

    const createChart = () => {
        // rebind zoom on changes.
        zoom = d3.zoom().scaleExtent([1, 5])
            .translateExtent([[0, 0], [containerWidth, 0]])
            .extent([[0, 0], [containerWidth, containerHeight]])
            .on('zoom',
                () => {
                    handleZoom(d3.event.transform);
                }
            );

        bindZoom(zoom);
        updateChart();
    }

    const updateChart = () => {
        removeChart();
        drawAxisAndOverlay();
        drawChart();
    }

    const removeChart = () => {
        d3.select(ref.current)
            .select('g')
            .select('.points')
            .selectAll('g')
            .remove();
    }

    const pinElementById = (pin) => {
        if (pin === pinId) {
            resetPinId();
        } else {
            setNewPinId(pin)
        }
    }

    const setFacetFilter = (facet, value) => {
        toggleFacetFilter(facet, value)
    }

    const drawAxisAndOverlay = () => {
        const w = containerWidth;
        const scales = scaleXY();

        /********** Y-AXIS ************/
        const yAxis = d3.axisLeft()
            .scale(scales.y)
            .tickSize(1)
            .tickPadding(4);

        d3.select(ref.current)
            .select('g')
            .select('.yaxis')
            .attr('transform', 'translate(40, 0)')
            .transition()
            .call(yAxis);

        const ytick = yAxis.ticks();
        const lineData = yAxis
            .scale()
            .ticks(ytick[0]);

        /********************* Dash overlay *******************/
        d3.select(ref.current)
            .select('g')
            .select('.lines')
            .selectAll('path')
            .remove();

        const line =  d3.select(ref.current)
            .select('g')
            .select('.lines')
            .selectAll('path')
            .data(lineData);

        line
            .enter()
            .append('path')
            .attr('d', (d) => {
                const y = scales.y(d);
                return 'M 50,' + (y) + 'h' + w * scale;
            })
            .attr('stroke-dasharray', '0.5%, 0.5%')
            .attr('stroke', barChartOverlayLineStrokeColor());

    }


    // draw the bar chart
    const drawChart = () => {
        const h = containerHeight;
        const data = calculatedData;
        const scales = scaleXY();
        const xScale = scales.x;
        xScale.paddingInner(0.01);

        d3.select(ref.current)
            .attr('width', containerWidth)
            .attr('height', containerHeight)


        // BAR
        const barGroup = d3.select(ref.current)
            .select('g')
            .select('.points');

        barGroup
            .attr('transform', 'translate(45, 0)');

        // defines what happens when the mouse is over a bar.
        const onMouseOver = (d, i, n) => {
            const currentElement = n[i];
            d3.select(currentElement)
                .attr('fill', activeBarFillColor)
                .attr('stroke', activeBarStrokeColor)
                .attr('opacity', 1);
        };

        // defines what happens when the mouse leaves a bar.
        const onMouseLeave = (d, i, n) => {
            const currentElement = n[i];
            d3.select(currentElement)
                .attr('fill', (e) => calculateBarColorBySignalStrength(e.signal))
                .attr('stroke', standardBarStroke);
            barGroup.selectAll('rect')
                .attr('opacity', (e) => calculateOpacity(e));
        };

        // shortens the text of the bars
        const shortenBarText = (text, maxLength) => {
            let shortText = text;
            const length = text.length;
            if (length > maxLength / 8) {
                shortText = text.slice(0, maxLength / 8);
                shortText = shortText.slice(0, shortText.lastIndexOf(' '));
                return shortText + '..';
            }
            return shortText;
        }

        // checks if a pin is set, and changes the opacity.
        const calculateOpacity = (d) => {
            // wenn es einen aktiven pin gibt.
            if (pinId !== '-1') {
                return d.id === pinId ? 1 : 0.4;
            } else {
                return 1;
            }
        };

        // Append groups for each Bar/Text pair
        const bars = barGroup
            .selectAll('rect')
            .data(data, (d) => d.id)
            .enter()
            .append('g');

        bars
            .append('rect')
            .attr('opacity', (d) => calculateOpacity(d))
            .attr('cursor', 'pointer')
            .attr('fill', (d) => d.preview ? barPreviewColor() : calculateBarColorBySignalStrength(d.signal))
            .attr('stroke', standardBarStroke)
            .on('mouseover', onMouseOver)
            .on('mouseleave', onMouseLeave)
            .on('click', (d) => pinElementById(d.id))
            .on('dblclick', (d) => setFacetFilter(activeFacet, d.id))
            .on('contextmenu', (d) => toggleContextMenu(d.name, ref.current))
            .attr('x', (d, i) => xScale(i))
            .attr('width', xScale.bandwidth())
            // .attr('y', containerHeight)
            // .attr('height', 0)
            // .transition()
            // .duration(400)
            .attr('y', (d) => scales.y(d.y))
            .attr('height', (d) => (h) - scales.y(d.y));



        bars
            .append('text')
            .text((d, i) => bars._groups[0][i].getBBox().height > 40 ? shortenBarText(d.name, xScale.bandwidth(i) / 2) : '')
            .attr('pointer-events', 'none')
            .attr('text-anchor', 'middle')
            .attr('opacity', 0)
            .attr('dy', '.3em')
            .attr('opacity', (d) => calculateOpacity(d))
            .attr('x', (d, i) => xScale(i) + xScale.bandwidth(i) / 2)
            .attr('y', (d) => scales.y(d.y) + 10);

        // Append amount text label
        bars
            .append('text')
            // we use the bbox height to define if we should display any text.
            .text((d, i) => ((bars._groups[0][i].getBBox().height > 40) && (bars._groups[0][i].getBBox().width > 20)) ? '(' + d.y + ')' : '')
            .attr('pointer-events', 'none')
            .attr('text-anchor', 'middle')
            .attr('opacity', 0)
            .attr('dy', '.3em')
            .attr('opacity', (d) => calculateOpacity(d))
            .attr('x', (d, i) => xScale(i) + xScale.bandwidth(i) / 2)
            .attr('y', (d) => scales.y(d.y) + 30);


        d3.select(ref.current)
            .select('g')
            .attr('transform', `translate(${translationX},0)`);

        // Append tooltips.
        bars
            .append('title')
            .text((d) => d.name + ' ' + '(' + d.amount + ')');
    }



    const scaleXY = () => {
        const w = containerWidth;
        const h = containerHeight;
        const data = calculatedData;
        // minimum ist immer 0 auf der x achse. Steht für Element 0. Im alten System wurde das durch ein Merkmal x gemacht. Wir verwenden einfach die Laufvariable...
        const minX = 0;
        // geht bis Element x
        const maxX = calculatedData.length;
        // const [minX, maxX] = d3.extent(currData, (e) => e.x);
        const maxY = d3.max(data, (d) => d3.max(calculatedData, (e) => e.y));
        return {
            x: d3.scaleBand()
                .domain(d3.range(minX, maxX))
                .range([0, (w * scale) - 50]),
            y: d3.scaleLinear()
                .domain([0, maxY])
                .range([h, 0])
        };
    }

    const reloadPreviousZoom = (zoom) => {
        const transform = d3
            .zoomIdentity
            .translate(translationX, 0)
            .scale(scale);

        d3.select(ref.current)
            .transition()
            .duration(200)
            .call(zoom.transform, transform);
    }

    const handleZoom = (event) => {
        const newTranslationX = event.x;
        const newScale = event.k;
        setTranslationX(newTranslationX);
        setNewScale(newScale);

    }

    const bindZoom = (zoom) => {
        d3.select(ref.current)
            .call(zoom)
            .on('dblclick.zoom', null);
    }


    return (
        <svg ref={ref}>
            <g>
                <g className='points'/>
                <g className='yaxis'/>
                <g className='lines'/>
            </g>
        </svg>
    );
}

export default BarChart;


