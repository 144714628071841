import React from 'react';
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {
    facetFiltersAtomFamily
} from "../../../../dataProvider/vissights/search/filters/filters";
import {Badge} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faX} from "@fortawesome/free-solid-svg-icons";
import FACET from "../../../../dataProvider/vissights/utility/facet-extraction-utility";
import {topicsAtom} from "../../../../dataProvider/vissights/overview/overview";
import {recursiveDeepClone} from "../../../../utility";
import {sliderValuesAtomFamily} from "../../LeftNavigation/LeftNavigationState";


function FacetFilters(props) {
    const topics = useRecoilValue(topicsAtom);
    // stores the stuff we display here.
    const [facetFilters, setFacetFilters] = useRecoilState(facetFiltersAtomFamily(props.filterSetId));
    const resetSliderValues = useResetRecoilState(sliderValuesAtomFamily(props.filterSetId));

    const removeElementFromFilterSet = (facet, filter) => {
        // console.log(filter, filterset, filterSetIndex)
        // d.facet, e, filterSetIndex
        // const filterSetToUpdate = formattedFilterSets[filterSetIndex]
        // console.log(facetFilters)
        let clone = recursiveDeepClone(facetFilters);

        clone.forEach((d) => {
            if (d.facet === facet) {
                // filter out the value that was passed
                d.values = d.values.filter((e) => e !== filter);
                return d;
            }
            return d;
        });

        setFacetFilters(clone);
        resetSliderValues();
    }


    const FacetFilters = () => {
        // console.log("test",props);
        const d = props.d;
        const j = props.j;
        return (
            d.values.map((e, i) =>
                <>
                    <Badge variant="primary" key={'facet-badge' + d.facet + j}
                           style={{
                               marginRight: 3,
                               cursor: 'pointer'
                           }}>
                    <span key={i} style={{marginRight: 5}}>#
                        {d.facet === FACET.TOPIC ? FACET.getTopicNameById(topics, e) : e}
                    </span>
                    </Badge>
                    <FontAwesomeIcon icon={faX} style={{height: 12, marginTop: 3, marginLeft: 2, cursor: "pointer"}}
                                     onClick={() => {
                                         removeElementFromFilterSet(d.facet, e); // pass the facet, the id of filter
                                     }}/>
                </>
            )
        )
    }


    return (
        <FacetFilters/>
    );
}

export default FacetFilters;
