import {Button, Col, Row, Badge, SplitButton, FormCheck} from "react-bootstrap";
import React from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {} from "../state/overview-parallel-coordinates-chart-state";
import Dropdown from 'react-multilevel-dropdown';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faCog, faEye, faFillDrip} from "@fortawesome/free-solid-svg-icons";
import {searchDatabase, searchTermAtom} from "../../../../../../dataProvider/vissights/search/search";
import {moduleToDisplayAtom} from "../../../../../Dashboards/DashboardLayout";
import domtoimage from "dom-to-image";
import uuid from "react-uuid";
import * as _ from "lodash";
import {singleVisualizationScreenshotsAtom} from "../../../../reporting/state/ReportingState";
import {displaySavedScreenshotModalAtom} from "../../../../../Misc/Modals/SavedScreenshot";
import {
    activeColorSchemeAtomFamily, activePathSchemeAtomFamily,
    colorSchemesAtom, pathSchemeAtom
} from "../../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";

function OverviewParallelCoordinatesChartTopControls(props) {
    const componentId = props.id;
    // infos we need for screenshot.
    const db = useRecoilValue(searchDatabase);
    const searchTerm = useRecoilValue(searchTermAtom)
    const module = useRecoilValue(moduleToDisplayAtom);
    const setDisplayScreenshotModal = useSetRecoilState(displaySavedScreenshotModalAtom);
    const [singleVisualizationScreenshots, setSingleVisualizationScreenshots] = useRecoilState(singleVisualizationScreenshotsAtom);
    const colorSchemes = useRecoilValue(colorSchemesAtom);
    const setActiveColorScheme = useSetRecoilState(activeColorSchemeAtomFamily(componentId));

    const pathDrawingMethods = useRecoilValue(pathSchemeAtom);
    const setActivePathDrawingMethod = useSetRecoilState(activePathSchemeAtomFamily(componentId));

    const config = props.topControlConfig[0];
    // const [activeDataset, setActiveDataset] = useRecoilState(activeFacetAtomFamily(componentId))

    const createVisualizationScreenshot = () => {
        const node = document.getElementById(componentId);
        domtoimage.toPng(node)
            .then((dataUrl) => {
                const newUUID = uuid();
                // store the image, the module, and the current database
                const autoGeneratedText = 'The following chart was taken in the Zoom and Filter Module' +
                    'searching for' + searchTerm + ' in the database: ' + db.name + '. ' +
                    'It shows a temporal overview.';
                const metaDataObj = {id: newUUID, dataUrl, module, db: db.name, autoGeneratedText}
                const clone = _.cloneDeep(singleVisualizationScreenshots)
                clone.push({id: newUUID, data: metaDataObj});
                setSingleVisualizationScreenshots(clone);
            }).then(() => setDisplayScreenshotModal(true));
    }


    const ContainerTopControls = () => {
        return (
            <Row>
                <Col xs={12}>
                    <div className="grid-item-container-top-control">
                        <div className="float-left" style={{display: 'flex'}}>
                            <Dropdown position="right"
                                      title={<FontAwesomeIcon style={{width: 20, height: 20}} icon={faCog}/>}
                            >
                                <Dropdown.Item>
                                    <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}}
                                                     icon={faEye}/> View Settings
                                    <Dropdown.Submenu position="right">
                                        <Dropdown.Item position="right">
                                            Color Schemes
                                            <Dropdown.Submenu position="right">
                                                {
                                                    colorSchemes.map((d) => {
                                                        return (
                                                            <Dropdown.Item position="right" onClick={() => setActiveColorScheme(d)}>
                                                                {d}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </Dropdown.Submenu>
                                        </Dropdown.Item>
                                        <Dropdown.Item position="right">
                                            Path Drawing
                                            <Dropdown.Submenu position="right">
                                                {
                                                    pathDrawingMethods.map((d) => {
                                                        return (
                                                            <Dropdown.Item position="right" onClick={() => setActivePathDrawingMethod(d)}>
                                                                {d}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </Dropdown.Submenu>
                                        </Dropdown.Item>
                                    </Dropdown.Submenu>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => createVisualizationScreenshot()}>
                                    <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}}
                                                     icon={faCamera}/> Screenshot Chart
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                        <Button type="button" className="float-right" variant="light" value="Search" onClick={(e) => {
                            e.preventDefault();
                            props.deleteContainer(props.id);
                        }}>X</Button>
                    </div>
                </Col>
            </Row>
        );
    }

    return (
        <ContainerTopControls/>
    );
}

export default OverviewParallelCoordinatesChartTopControls;