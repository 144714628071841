import React, {useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {
    dashboardHeightAtom,
    dashboardWidthAtom,
} from "../../../config/sizings/sizings";
import {moduleToDisplayAtom} from "../../Dashboards/DashboardLayout";
import keycloak from "../../../dataProvider/vissights/authentication/keycloak";
import Card from 'react-bootstrap/Card';
import {Button, Col, Row} from "react-bootstrap";
import {vissightsConfig} from "../../../dataProvider/vissights/vissightsConfig";
import routes from "../../../config/routes";
import {useHistory} from "react-router-dom";

function ProfileDashboard() {
    const setModuleToDisplay = useSetRecoilState(moduleToDisplayAtom);
    // const leftSideNavigationWidth = useRecoilValue(leftSideNavWidthAtom);
    const dashboardWidth = useRecoilValue(dashboardWidthAtom);
    const dashboardHeight = useRecoilValue(dashboardHeightAtom);
    let history = useHistory();
    useEffect(() => {
        setModuleToDisplay('profile');
    }, [])

    // <ProfileLeftNaviagation width={Number(leftSideNavigationWidth)} height={Number(dashboardHeight)}> </ProfileLeftNaviagation>

    {
        /*
         <Switch>

    <Route path={routes.modules.home} exact>
        <OverviewDashboard/>
    </Route>
    <Route path={routes.modules.overview}>
        <OverviewDashboard/>
    </Route>
    <Route path={routes.modules.profile}>
        <PrivateRoute>
            <ProfileDashboard/>
        </PrivateRoute>
    </Route>
    <Route path={routes.modules.projects}>
        <PrivateRoute>
            <ProjectsDashboard/>
        </PrivateRoute>
    </Route>
    <Route path={routes.modules.zoomAndFilter} >
        <ZoomAndFilterDashboard style={{width: windowWidth}}/>
    </Route>
    <Route path={routes.modules.reporting}>
        <ReportingDashboard/>
    </Route>
</Switch>
         */


    }

    return (
        <div className="ProfileDashboard" style={{display: "flex", height: Number(dashboardHeight)}}>
            <div style={{width: Number(dashboardWidth)}}>
                <h1>Welcome back <b>{keycloak.tokenParsed.preferred_username}</b></h1>
                <Row style={{marginTop: 50}}>
                    <Col xs={2}/>
                    <Col xs={2}>
                        <Card style={{width: '18rem'}}>
                            <Card.Body>
                                <Card.Title>Profile Settings</Card.Title>
                                <Card.Text>
                                    You may adjust your account settings here.
                                </Card.Text>
                                <Button variant="outline-success"
                                        href={vissightsConfig.authBaseQuery + "auth/realms/vissights/account"}
                                        target="_blank">Go to Profile Settings</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={2}>
                        <Card style={{width: '18rem'}}>
                            <Card.Body>
                                <Card.Title>My Projects</Card.Title>
                                <Card.Text>
                                    You may go on working on your projects here.
                                </Card.Text>
                                <Button onClick={() => history.push(routes.modules.projects)}
                                        variant="outline-success">Go to Projects</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={2}/>
                </Row>
            </div>
        </div>
    )
}

export default ProfileDashboard;

