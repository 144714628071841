import React from 'react';
import {useDrag} from "react-dnd";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useRecoilValue} from "recoil";
import {amountEachVisTypeActiveSelector} from "../state/RightNavigationState";
import {rightSideNavWidthSelector} from "../../../../config/sizings/sizings";


function RightNavigationItem(props) {
    const config = props.config;
    // we call a selector that returns an array with values on how often a chart is currently in the dashboard
    const amountVisType = useRecoilValue(amountEachVisTypeActiveSelector);
    const rightNavWidth = useRecoilValue(rightSideNavWidthSelector)


    let [{isDragging}, drag, dragPreview] = useDrag(() => ({
        // "type" is required. It is used by the "accept" specification of drop targets.
        type: 'RightSideDragNavItems',
        item: {config},
        // The collect function utilizes a "monitor" instance (see the Overview for what this is)
        // to pull important pieces of state from the DnD system.
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }))

    const RightNavigationItem = () => {
        return (
            <OverlayTrigger
                key={props.visType + '-tooltip'}
                placement={'left'}
                overlay={
                    <Tooltip id={`tooltip-${'vis-type'}`}>
                        {props.visType in amountVisType ?
                            <div>{props.visType + ': ' + amountVisType[props.visType]}</div> :
                            <div>{props.visType}</div>}
                    </Tooltip>
                }
            >
                <div key={props.config} ref={dragPreview} style={{
                    opacity: isDragging ? 0.5 : 1,
                    height: 72,
                    marginBottom: 3,
                    marginRight: 5,
                    position: 'relative'
                }}>

                    <div style={{ display: rightNavWidth > 0 ? 'block' : 'none'}} className={props.visType in amountVisType ? 'rightNavItemActive' : 'rightNavItem'} ref={drag}>
                        {amountVisType[props.visType] > 0 ?
                            <div className="legend-right-circle">{amountVisType[props.visType]}</div>
                        : null}
                        {props.config.iconComponent}
                    </div>
                </div>
            </OverlayTrigger>
        );
    }
return (
    <RightNavigationItem/>
);
}

export default RightNavigationItem;



