import {atomFamily, selectorFamily} from "recoil";
import * as _ from "lodash";
import {
    activeAggregationAtomFamily,
    activeFacetAtomFamily
} from "../../../../overview/visualizations/state";
import {genericSort, recursiveDeepClone} from "../../../../../../utility";
import {
    preProcessedFilteredSearchDataSelector
} from "../../../../../../dataProvider/vissights/search/filters/filters";


/*
 // calculate the needed parameters
    data.map((d) => this.calculateMaxTotalGrowth(d));


    const minYear = parseInt(d3.min(data, (t) =>  d3.min(Object.keys(t.years))), 10);
    const maxYear = parseInt(d3.max(data, (t) =>  d3.max(Object.keys(t.years))), 10);

   data = data.map((t) => {
      const r = [];

      for (let year = minYear; year <= maxYear; year++) {

        r.push({
          x: year,
          y: t.years[year] || 0
        });
      }
      r['id'] = t.id;
      r['name'] = t.name;
      r['amount'] = t.amount;
      r['color'] = Math.random() * 360;
      switch (aggregation) {
        case 'max':
          r['amount'] = t.max;
          break;
        case 'growth':
          r['amount'] = t.growth;
          break;
      }
      return r;
    });



    // amount is always dependant from the current aggregation


    // as amount reflects the current aggregation we can just sort it by amount
    data.sort(this.genericSort('amount', 'desc'));

    // slice it
    data = data.slice(0, amount);

    // rename amount to y for the chart...
    data.map((d, i) => d.y = d.amount);

    // activate 15 items
    data.map((d, i) => i < 3 ? d.active = true : d.active = false);
    return [facetTypes, data];

  }

 */


export const chartDataRawFacetLineChartSelectorFamily = selectorFamily({
    key: 'chartDataRawFacetLineChartSelectorFamily-Key',
    get: (id) => ({get}) => {
        const aggregation = get(activeAggregationAtomFamily(id));
        const facet = get(activeFacetAtomFamily(id));
        const dataModel = get(preProcessedFilteredSearchDataSelector);
        const data = dataModel[facet];
        let lookupClone = _.cloneDeep(data.lookup);
        const dataToPreprocess = _.cloneDeep(data);


        dataToPreprocess.lookup = lookupClone;
        // dataToPreprocess.map((d) => calculateMaxTotalGrowth(d));
        // amount is always dependant from the current aggregation
        // as amount reflects the current aggregation we can just sort it by amount

        dataToPreprocess.sort(genericSort(aggregation, 'desc'));
        return dataToPreprocess;
    }
});

export const rightControlDataFacetLineChartSelectorFamily = selectorFamily({
    key: 'rightControlDataFacetLineChartSelectorFamily-Key',
    get: (id) => ({get}) => {
        const aggregation = get(activeAggregationAtomFamily(id));
        const facet = get(activeFacetAtomFamily(id));
        const dataModel = get(preProcessedFilteredSearchDataSelector);
        const data = dataModel[facet];
        const dataToPreprocess = _.cloneDeep(data);


        dataToPreprocess.map((d) => calculateMaxTotalGrowth(d));

        dataToPreprocess.map((d) => {
            switch(aggregation) {
                case "max":
                    d["amount"] = d.max;
                    break;
                case "growth":
                    d["amount"] = d.growth;
                    break;
                default:
                    break;
            }
        });

        // dataToPreprocess.map((d) => calculateMaxTotalGrowth(d));
        // amount is always dependant from the current aggregation
        // as amount reflects the current aggregation we can just sort it by amount
        dataToPreprocess.sort(genericSort(aggregation, 'desc'));
        // activate 3 items if there is no attribute active already.
        dataToPreprocess.map((d, i) => i < 3 ? d.active = true : d.active = false);
        return dataToPreprocess;
    }
});


export const rightControlFacetLineChartDataAtomFamily = atomFamily({
    key: "rightControlFacetLineChartDataAtomFamily",
    default: selectorFamily({
        key: 'rightControlFacetLineChartDataAtomFamily/Default',
        get: id => () => {
            return rightControlDataFacetLineChartSelectorFamily(id);
        },
    }),
});

export const chartDataPreFacetLineChartSelectorFamily = selectorFamily({
    key: 'chartDataPreFacetLineChartSelectorFamily-Key',
    get: (id) => ({get}) => {
        return get(chartDataRawFacetLineChartSelectorFamily(id))
    }
});


export const facetLineChartChartDataAtomFamily = atomFamily({
    key: "facetLineChartChartDataAtomFamily",
    default: selectorFamily({
        key: 'facetLineChartChartDataAtomFamily/Default',
        get: id => ({get}) => {
            const rightControlItems = get(rightControlFacetLineChartDataAtomFamily(id));
            const rightControlItemsActive = rightControlItems.filter((d) => d.active);
            let rightControlItemsActiveIds = [];

            rightControlItemsActive.map((d) => {
                rightControlItemsActiveIds.push(d.id)
            })

            const chartData = get(chartDataPreFacetLineChartSelectorFamily(id));

            let clone = recursiveDeepClone(chartData);

            const lookUpClone = clone.lookup;

            clone = clone.filter((d) => rightControlItemsActiveIds.includes(d.id))

            clone.map((d) => d.color = Math.random() * 360)

            clone.lookup = lookUpClone;

            return clone;
        },
    }),
});

/*
export const chartDataFacetLineChartSelectorFamily = selectorFamily({
    key: 'chartDataActiveFacetLineChartSelectorFamily-Key',
    get: (id) => ({get}) => {
        const dataToPreprocess = recursiveDeepClone(get(facetLineChartChartDataAtomFamily(id)))
        dataToPreprocess.map((d) => calculateMaxTotalGrowth(d));
        return dataToPreprocess;
    }
});
*/

/*
  const minYear = parseInt(d3.min(dataToPreprocess, (t) =>  d3.min(Object.keys(t.years))), 10);
        const maxYear = parseInt(d3.max(dataToPreprocess, (t) =>  d3.max(Object.keys(t.years))), 10);

        const allData = []
        dataToPreprocess.map((t) => {
            const r = [];
            for (let year = minYear; year <= maxYear; year++) {
                r.push({
                    x: year,
                    y: t.years[year] || 0
                });
            }
            r['id'] = t.id;
            r['name'] = t.name;
            r['amount'] = t.amount;
            r['color'] = Math.random()*360;
            r['active'] = t.active;
            switch (aggregation) {
                case "max":
                    r["amount"] = t.max;
                    break;
                case "growth":
                    r["amount"] = t.growth;
                    break;
            }
            allData.push(r);
            return r;
        });

        // amount is always dependant from the current aggregation
        // as amount reflects the current aggregation we can just sort it by amount
        allData.sort(genericSort('amount', 'desc'));

        // slice it
        allData.slice(0, 10);

        // rename amount to y for the chart...
        allData.map((d, i) => d.y = d.amount);

        // activate 15 items
        allData.map((d, i) => i < 3 ? d.active = true : d.active = false);
 */

/*
export const facetLineChartChartDataActiveAtomFamily = atomFamily({
    key: "facetLineChartChartDataActiveAtomFamily",
    default: selectorFamily({
        key: 'facetLineChartChartDataActiveAtomFamily/Default',
        get: id => ({get}) => {
            const data = get(facetLineChartChartDataAtomFamily(id));
            return data.filter((d) => d.active);
        }
    }),
});
*/

const calculateMaxTotalGrowth = (element) => {
    const date = new Date();
    const year = date.getFullYear();
    const yearmin = year - 6;
    const yearmax = year - 1;
    let max = 0;
    let lastFiveAmount = 0;
    let lastFiveMax = 0;

    for (const key in element.years) {
        if (element.years[key] !== undefined) {
            const v = element.years[key];
            if (v !== undefined) {
                if (max < v) {
                    max = v;
                }
                if (parseInt(key, 10) > yearmin - 1) {
                    lastFiveAmount = lastFiveAmount + v;
                    if (lastFiveMax < v) {
                        lastFiveMax = v;
                    }
                }
            }
        }
    }
    element.max = max;
    element.lastAmount = lastFiveAmount;
    element.lastMax = lastFiveMax;
    let yma = element.years[yearmax];
    let ymi = element.years[yearmin];
    if (yma === undefined) {
        yma = 0;
    }
    if (ymi === undefined) {
        ymi = 0;
    }
    element.growth = yma - ymi;
}