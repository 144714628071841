import React from "react";
import SlicedChart from "./SlicedChart";
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {
    activeFilterSetIdAtom,
    facetFiltersAtomFamily
} from "../../../../../dataProvider/vissights/search/filters/filters";
import * as _ from "lodash";
import {sliderValuesAtomFamily} from "../../../../GlobalNavigations/LeftNavigation/LeftNavigationState";
import SlicedChartTopControls from "./controls/SlicedChartTopControls";
import {slicedChartDataAtomFamily} from "./state/sliced-chart-state";

function SlicedChartContainer(props) {
    const componentId = props.id;
    // const dataModel = useRecoilValue(preProcessedFilteredSearchDataSelector);
    const activeFilterSetId = useRecoilValue(activeFilterSetIdAtom);
    const [facetFilters, toggleFacetFilters] = useRecoilState(facetFiltersAtomFamily(activeFilterSetId));
    const setSliderValues = useSetRecoilState(sliderValuesAtomFamily(activeFilterSetId));
    const resetSliderValues = useResetRecoilState(sliderValuesAtomFamily(activeFilterSetId));
    // const data = useRecoilValue(temporalOverviewChartDataAtomFamily(componentId));
    const data = useRecoilValue(slicedChartDataAtomFamily(componentId));
    // stuff we pass to top controls
    const topControlConfig = props.topControls;
    const deleteContainer = props.deleteContainer;

    const toggleFacetFilter = (facet, value) => {
        value = value.toString()
        const clone = _.cloneDeep(facetFilters);
        // check if the facet is already created as another filter in that facet is created or sth like that.
        const isAlreadyCreated = clone.find((d) => {
            return d.facet === facet;
        });
        if (isAlreadyCreated !== undefined) {
            // check if we have to reset the slider.
            if (facet === 'Years') {
                resetSliderValues();
            }
            clone.map((d) => {
                if (d.facet === facet) {
                    // check if value is already set...
                    const valueAlready = d.values.find((e, i) => {
                        return e === value;
                    })
                    // if value is already set we remove it
                    if (valueAlready !== undefined) {
                        d.values = d.values.filter((d) => d !== value);
                    }
                    // if its new we add it.
                    else {
                        // d.value = d.value.push(value);
                        d.values.push(value);
                    }
                }
                return d;
            })
        } else {
            // check if we have to change the slider.
            if (facet === 'Years') {
                setSliderValues([Number(value), Number(value)])
            }
            clone.push({facet: facet, values: [value]});
        }

        //remove no longer used facets
        const filteredClone = clone.filter((d) => d.values.length > 0);
        toggleFacetFilters(filteredClone);
    }

    return (
        <div>
            <SlicedChartTopControls topControlConfig={topControlConfig}
                                    deleteContainer={deleteContainer}
                                    id={props.id}/>
            <div style={{display: "flex"}}>
                <div style={{width: props.containerDimensions[0]}}>
                    <SlicedChart id={props.id}
                                 data={data}
                                 width={props.containerDimensions[0]}
                                 height={props.containerDimensions[1]}
                                 toggleFacetFilter={toggleFacetFilter}
                                 toggleContextMenu = {props.toggleContextMenu}
                    />
                </div>
            </div>
        </div>
    );
}

export default SlicedChartContainer;
