import * as d3 from 'd3';
import {atom} from "recoil";
import { recoilPersist } from 'recoil-persist'
import {vissightsConfig} from "../dataProvider/vissights/vissightsConfig";
const { persistAtom } = recoilPersist()

export const currentThemeAtom = atom({
    key: "currentTheme-Key",
    default: 'light',
    effects_UNSTABLE: [persistAtom],
});

export const themeOptionsAtom = atom({
    key: "themeOptionsAtom-Key",
    default: vissightsConfig.themeOptions
});




// define custom colors
/* primary color --- normally the first color of the logo */
const primary = '#31BEFF';
const primaryAccentOne = '#64CEFF';
const primaryAccentTwo = '#97DEFF';
const primaryAccentThree = '#00AEFF';

/* secondary color --- normaly the second color of the logo */
const secondary = '#f8941f';
const secondaryAccentOne = '#c7997a';
const secondaryAccentTwo = '#fac07b';

/* borders --- mostly gray / black schemes used for borders / strokes and lines */
const delimitation = '#000000';
const delimitationAccentOne = '#d3d3d3';
const delimitationAccentTwo = '#b7b4a9';
// const delimitationAccentThree = '#444444';
const delimitationAccentFour = '#777777';
const delimitationAccentFive = '#eee';
// e.g dash-strokes for overlay lines
// const delimitationAccentSix = '#bec2cb';

// for example previews use those colors.
const highlight = '#ff0000';
const highlightAccentOne = '#c76167';
const highlightAccentTwo = '#ff8080';

// inner relations (e.G. Bubble Chart)
const innerRelations = '#fa8005';
const innerRelationsAccentOne = '#FF6E00';


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// ----------> Overview Charts <---------

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


/**
 * Word Cloud
 */

/* There are tons of alternative color scales that can be used. check: https://github.com/d3/d3-scale-chromatic for more information
Moreover, we could use our own default scales when it comes to concerns of corporate identity questions etc. */

export function wordColorScheme() {
    return d3.scaleOrdinal(d3.schemeCategory10);
}

export function wordColorSchemeAlternative1(){
    return d3.scaleOrdinal(d3.schemeSet1);
}

export function wordColorSchemeAlternative2(){
    return d3.scaleOrdinal(d3.schemeTableau10);
}

/**
 * Temporal Cloud Chart
 */

export function temporalCloudChartLineColor() {
    return delimitation;
}

export function temporalCloudChartTextColor() {
    return delimitationAccentTwo;
}


/**
 * Topic Line Chart
 */
// CHART
export function topicLineLineStrokeColor(color) {
    // -1 refers to not set -> default
    return color === -1 ? secondaryAccentOne : d3.hsl(color, 0.92, 0.38).toString(); // #b4b7a9
}

// LEGEND
export function topicLineFacetBackgroundColor(color) {
    // -1 refers to not set -> default
    return color === -1 ? secondaryAccentTwo : d3.hsl(color, .20, .88).toString(); // #b4b7a9
}

export function topicLineFacetOutlineColor(color) {
    // -1 refers to not set -> default
    return color === -1 ? secondaryAccentOne : d3.hsl(color, 0.92, 0.38).toString(); // #b4b7a9
}

export function topicLineFacetOutlineColorHovered() {
    return highlight;
}

export function topicLineOverlayLineStrokeColor() {
    return delimitationAccentOne;
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// ----------> Zoom & Filter Charts <---------

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


/**
 *  TreeMapBarChart
 */

export function standardFillColorForSquares() {
    return primaryAccentTwo;
}

export function squareFillColorHovered() {
    return secondaryAccentTwo;
}


export function standardStrokeColorForSquares() {
    return primaryAccentThree;
}

export function squareStrokeColorHovered() {
    return secondary;
}

















/**
 *  Bar Chart
 */

export function barChartStandardBarStrokeColor() {
    return delimitationAccentOne;
}

export function barChartOverlayLineStrokeColor() {
    return delimitationAccentOne;
}

export function barChartActiveBarFillColor() {
    return secondaryAccentTwo;
}

export function barChartActiveBarStrokeColor() {
    return highlight;
}

export function calculateBarColorBySignalStrength(value) {
    switch (value) {
        case 0:
            return primaryAccentTwo;
        case 1:
            return primaryAccentOne;
        case 2:
            return primary;
        default:
            return primaryAccentTwo;
    }
}

export function barPreviewColor() {
    return highlightAccentOne;
}


/**
 * Bubble Chart
 */
export function calculateBubbleColorBySignalStrength(value) {
    switch (value) {
        case 0:
            return primaryAccentTwo;
        case 1:
            return primaryAccentOne;
        case 2:
            return primary;
        default:
            return primaryAccentTwo;
    }
}

export function bubbleChartBubbleStrokeColor() {
    return delimitationAccentTwo;
}

export function bubbleChartBubbleActiveStrokeColor() {
    return secondaryAccentOne;
}

export function bubbleChartBubbleActiveFillColor() {
    return secondaryAccentTwo;
}

export function bubbleChartBubbleInnerRelationalFillColor() {
    return innerRelations;
}

export function bubbleChartBubbleInnerRelationalStrokeColor() {
    return innerRelationsAccentOne;
}

export function bubbleChartBarFillColor() {
    return primaryAccentTwo;
}

export function bubbleChartBarStrokeColor() {
    return primaryAccentThree;
}

export function bubbleChartBarActiveFillColor() {
    return secondaryAccentTwo;
}

export function bubbleChartTextColor() {
    return delimitation;
}


/**
 *  PIE CHART
 */
export function pieChartStandardPieStrokeColor() {
    return delimitationAccentOne;
}

export function pieChartActivePieFillColor() {
    return secondaryAccentTwo;
}

export function pieChartActivePieStrokeColor() {
    return highlight;
}

export function calculatePieSliceColorBySignalStrength(value) {
    switch (value) {
        case 0:
            return primaryAccentTwo;
        case 1:
            return primaryAccentOne;
        case 2:
            return primary;
        default:
            return primaryAccentTwo;
    }
}

export function pieSlicePreviewColor() {
    return highlightAccentOne;
}

/**
 *  DONUT CHART
 */
export function donutChartStandardDonutStrokeColor() {
    return delimitationAccentOne;
}

export function donutChartActiveDonutFillColor() {
    return secondaryAccentTwo;
}

export function donutChartActiveDonutStrokeColor() {
    return highlight;
}

export function calculateDonutSliceColorBySignalStrength(value) {
    switch (value) {
        case 0:
            return primaryAccentTwo;
        case 1:
            return primaryAccentOne;
        case 2:
            return primary;
        default:
            return primaryAccentTwo;
    }
}

export function donutSlicePreviewColor() {
    return highlightAccentOne;
}

/**
 * GEO OVERVIEW CHART
 */

export function standardFillColorCountry() {
    return delimitationAccentFive;
}

export function standardStrokeColorCountry() {
    return delimitationAccentTwo;
}

export function activeStrokeColorCountry() {
    return highlight;
}

// Color scale that is used within d3 range
export function countryFillGradient() {
    // hsl(200, 15%, 87%) => D9E0E3    | hsl(200, 91%, 38%) => 097EB9
    return ['hsl(198,15%,87%)', 'hsl(200, 91%, 38%)'];
}

// Color scale that is used within d3 range
export function bubbleFillGradient() {
    // hsl(200, 15%, 87%) => D9E0E3    | hsl(200, 91%, 38%) => 097EB9
    // return ['hsla(38, 100%, 75%, 0.84)', 'hsla(0,100%,75%,0.84)'];
    return [secondaryAccentTwo, highlightAccentTwo];
}

// Color scale that is used within d3 range
export function bubbleStrokeGradient() {
    // hsl(200, 15%, 87%) => D9E0E3    | hsl(200, 91%, 38%) => 097EB9
    // return ['hsla(38, 100%, 50%, 0.9)', 'hsla(0, 100%, 50%, 0.84)'];
    return [secondary, highlight];
}

export function standardCountryBarChartBarFillColor() {
    return primaryAccentTwo;
}

export function standardCountryBarChartBarStrokeColor() {
    return primaryAccentThree;
}

export function activeCountryBarChartBarFillColor() {
    return secondaryAccentTwo;
}

export function activeCountryBarChartBarStrokeColor() {
    return secondaryAccentOne;
}


/**
 * Facet Line
 */

// CHART
export function facetLineLineStrokeColor(color) {
    // default color -> -1
    return color === -1 ? secondaryAccentOne : d3.hsl(color, 0.92, 0.38).toString();
}

export function facetLegendBackgroundColor(color) {
    return d3.hsl(color, .30, .84).toString();
}

// LEGEND
export function facetLineLegendFillColor() {
    return primaryAccentOne; // #b4b7a9
}

export function facetLineOverlayLineStrokeColor() {
    return delimitationAccentOne;
}

/**
 *  Poi Chart
 */

// We use HSL colors
// Returns the Hue value for the Main-Circle. Filling and Stroke/Text are calculated by the poiFill and poiStrokeAndText Color Methods at runtime.
export function mainCircleColorHue() {
    return 45;
}

/** The next 2 functions convert the numeric user input from the range
 * slider in the create-poi-dialog component to a HSL color. tTe user may only change hue.
 */
// describes the background/ fill color inside circles. Low saturation and high lightness
export function poiFillColor(color) {
    return color === -1 ? secondaryAccentTwo : d3.hsl(color, .30, .84).toString();
}

// describes the color of the text inside circles and the strokes. High saturation and low lightness
export function poiStrokeAndTextColor(color) {
    return color === -1 ? secondaryAccentOne : d3.hsl(color, 0.92, 0.38).toString();
}

// This function takes the previously used colors and calculates a new random number whichs hue value is +X / -X away from others
export function calculateRandomHueValue(data, minColorDifference) {
    // Store all already used colors inside one array.
    const allTakenColors = [];
    // if(this.data !== undefined){
    // TODO: Habe hier erstmal zweite bedingung gestellt.. da wir jetzt unendlich viele pois haben können, theoretisch...
    if (data.length > 0 && data.length < 5) {
        data.map((d) => allTakenColors.push(Number(d.color)));
    }
    // if it is the first color we just return a random value.
    else {
        return Math.random() * 360;
    }
    // The while loop generates randomNumbers until we find one number which is different enough.
    let flag = true;
    while (flag) {
        // generate a random number 0-359
        let randomNumber = Math.random() * 360;
        let diffFitsNot = true;
        const diffArray = [];
        const diffFitsNotArray = [];
        // randwerte werden errechnet und einfach in das diffArray reingeballert...
        const upperBoundary = 360 - minColorDifference;
        const lowerBoundary = minColorDifference;
        if (randomNumber > upperBoundary || randomNumber < lowerBoundary) {
            diffArray.push(360 - randomNumber);
        }
        // calculate for each color its absolute difference to the new value (there is no positive or negative values for absolute numbers...)
        allTakenColors.forEach((d) => diffArray.push(Math.round((Math.abs(randomNumber - d)))));

        diffArray.forEach((d) => {
            // check for each value if its smaller than the minimum amount of difference.
            diffFitsNot = d <= minColorDifference;
            // create a new array with true or false values, dependant on if there is
            diffFitsNotArray.push(diffFitsNot);
        });

        // //circlepack(diffFitsNotArray);
        // if !!not!! all differences where smaller than the minColorDifference than run again
        if (diffFitsNotArray.includes(true)) {
            randomNumber = Math.round(Math.random() * 360);
        }
        // else stop and return the new random number which is at least the amount of minColorDifference away from each other value already picked
        else {
            flag = false;
            return Math.round(randomNumber);
        }
    }
    // }

}

export function poiAddButtonFillColor() {
    return '#eaeaea';
}

export function poiAddButtonStrokeColor() {
    return '#b7b4a9';
}


/**
 * Sliced Chart
 */

// Based on the numeric input of signal strength, we calculate the color.
// returned colors are hex values as strings.
export function calculateInitialSliceColorsBySignalStrength(value) {
    switch (value) {
        case 0:
            return primaryAccentTwo;
        case 1:
            return primaryAccentOne;
        case 2:
            return primary;
        default:
            return primaryAccentTwo;
    }
}

export function standardFillColorForSlices() {
    return primaryAccentTwo;
}

export function standardStrokeColorForSlices() {
    return primaryAccentThree;
}

export function activeFillColorForSlices() {
    return secondaryAccentTwo;
}

export function activeStrokeColorForSlices() {
    return secondaryAccentOne;
}

/**
 * Temporal Overview
 */
export function standardFillColorForBars() {
    return primaryAccentTwo;
}

export function calculateStandardFillColorForBarsBySignalStrength(value) {
    switch (value) {
        case 0:
            return primaryAccentTwo;
        case 1:
            return primaryAccentOne;
        case 2:
            return primary;
        default:
            return primaryAccentTwo;
    }
}

export function standardStrokeColorForBars() {
    return primaryAccentThree;
}

export function activeFillColorForBars() {
    return secondaryAccentTwo;
}

export function activeStrokeColorForBars() {
    return highlight;
}

export function temporalOverviewOverlayLineColor() {
    return delimitationAccentTwo;
}

export function overlayAverageLineColor() {
    return delimitationAccentFour;
}

export function pathStrokeColor() {
    return delimitationAccentTwo;
}

/**
 *  Temporal Spread / (old) Facet River chart
 */

export function calculateRiverFillColorForAreaBySignalStrength(value) {
    switch (value) {
        case 0:
            return primaryAccentTwo;
        case 1:
            return primaryAccentOne;
        case 2:
            return primary;
        default:
            return primaryAccentTwo;
    }
}

export function standardStrokeColorForRiverArea() {
    return '#cadae2';
}

export function hoverFillColorForRiverArea() {
    return '#FAC07B';
}

export function hoverStrokeColorForRiverArea() {
    return d3.hsl(0, 0.92, 0.38).toString();
    // return '#c7997a';
}

export function temporalSpreadFacetBackgroundColorHover() {
    return 'red';
}

export function temporalSpreadFacetOutlineColorHover() {
    return 'red';
}

export function riverPreviewColor() {
    return highlightAccentOne;
}
