import {atomFamily, selectorFamily} from "recoil";
import * as _ from "lodash";
import {genericSort} from "../../../../../../utility";
import {overviewDataAtom} from "../../../../../../dataProvider/vissights/overview/overview";
import {activeAggregationAtomFamily, useNormalizationAtomFamily} from "../../state";

export const chartDataTemporalCloudSelector = selectorFamily({
    key: 'chartDataTemporalCloudSelectorKey',
    get: (id) => ({get}) => {
        const rawData = get(overviewDataAtom);
        const aggregation = get(activeAggregationAtomFamily(id));
        const clonedRawData = _.cloneDeep(rawData);
        const normalization = get(useNormalizationAtomFamily(id));
        const aggregationName = normalization ? aggregation + "Normalized" : aggregation;
        const chartData = clonedRawData.sort(genericSort(aggregationName, 'desc'));
        chartData.map((d, i) => {
            // amount refers to which value gets displayed in the chart.
            d.amount =  d[aggregationName];
            // we initially activate the first 0-8 el -> 9 active items
            i > 8 ? d.active = false : d.active = true;
            return d;
        })
        return chartData
    },
});

export const temporalCloudChartDataAtomFamily = atomFamily({
    key: "temporalCloudChartDataAtomFamilyKey",
    default: selectorFamily({
        key: 'temporalCloudChartDataAtomFamilyKey/Default',
        get: id => () => {
            return chartDataTemporalCloudSelector(id);
        },
    }),
});
