import {Button, Col, Row, Badge} from "react-bootstrap";
import React, {useEffect} from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {} from "../state/bubble-chart-state";
import Dropdown from 'react-multilevel-dropdown';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faCog} from "@fortawesome/free-solid-svg-icons";
import {searchDatabase, searchTermAtom} from "../../../../../../dataProvider/vissights/search/search";
import {moduleToDisplayAtom} from "../../../../../Dashboards/DashboardLayout";
import domtoimage from "dom-to-image";
import uuid from "react-uuid";
import * as _ from "lodash";
import {singleVisualizationScreenshotsAtom} from "../../../../reporting/state/ReportingState";
import {displaySavedScreenshotModalAtom} from "../../../../../Misc/Modals/SavedScreenshot";
import {activeFacetAtomFamily} from "../../../../overview/visualizations/state";
import {
    normalizeAtomFamily,
    visualizationPinAtomFamily
} from "../../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";

function BubbleChartTopControls(props) {
    const componentId = props.id;
    // infos we need for screenshot.
    const db = useRecoilValue(searchDatabase);
    const searchTerm = useRecoilValue(searchTermAtom)
    const module = useRecoilValue(moduleToDisplayAtom);
    const setDisplayScreenshotModal = useSetRecoilState(displaySavedScreenshotModalAtom);
    const [singleVisualizationScreenshots, setSingleVisualizationScreenshots] = useRecoilState(singleVisualizationScreenshotsAtom);
    const config = props.topControlConfig[0];
    const [activeFacet, setActiveFacet] = useRecoilState(activeFacetAtomFamily(componentId))
    const [pinId, setNewPinId] = useRecoilState(visualizationPinAtomFamily(componentId));
    const [normalization, setNormalization] = useRecoilState(normalizeAtomFamily(componentId));

    useEffect(() => {
        setActiveFacet(config.initialActiveFacet)
    },[])

    const createVisualizationScreenshot = () => {
        const node = document.getElementById(componentId);
        domtoimage.toPng(node)
            .then((dataUrl) => {
                const newUUID = uuid();
                // store the image, the module, and the current database
                const autoGeneratedText = 'The following chart was taken in the Zoom and Filter Module' +
                    'searching for' + searchTerm + ' in the database: ' + db.name + '. ' +
                    'It shows a temporal overview.';
                const metaDataObj = {id: newUUID, dataUrl, module, db: db.name, autoGeneratedText}
                const clone = _.cloneDeep(singleVisualizationScreenshots)
                clone.push({id: newUUID, data: metaDataObj});
                setSingleVisualizationScreenshots(clone);
            }).then(() => setDisplayScreenshotModal(true));
    }

    const changeFacetToDisplay = (newDataset) => {
        setNewPinId('-1');
        setActiveFacet(newDataset);
    }

    const ContainerTopControls = () => {
        let dataSets = config.facets;
        // only display datasets if we can choose between more than 1...
        dataSets = dataSets.length > 1 ? dataSets : []
        return (
            <Row>
                <Col xs={12}>
                    <div className="grid-item-container-top-control">
                        <div className="float-left" style={{display: 'flex'}}>
                            <Dropdown position="right"
                                      title={<FontAwesomeIcon style={{width: 20, height: 20}} icon={faCog}/>}
                            >
                                <Dropdown.Item onClick={() => createVisualizationScreenshot()}>
                                    <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}}
                                                     icon={faCamera}/> Screenshot Chart
                                </Dropdown.Item>
                            </Dropdown>
                            <div style={{marginLeft: 15, marginTop: 6}}>
                                <Badge style={{marginRight: 25, cursor: 'pointer'}}
                                       variant={normalization ? "primary" : "light"}
                                       onClick={() => {
                                           setNormalization(!normalization);
                                       }}>
                                    normalization
                                </Badge>
                            </div>
                            <div style={{marginLeft: 15, marginTop: 6}}>
                                {dataSets.map((d) =>
                                    <Badge key={d} style={{marginRight: 5, cursor: 'pointer'}}
                                           variant={d === activeFacet ? "primary" : "light"}
                                           onClick={(e) => {
                                               changeFacetToDisplay(d);
                                           }}>
                                        {d}
                                    </Badge>
                                )}
                            </div>
                        </div>
                        <Button type="button" className="float-right" variant="light" value="Search" onClick={(e) => {
                            e.preventDefault();
                            props.deleteContainer(props.id);
                        }}>X</Button>
                    </div>
                </Col>
            </Row>
        );
    }

    return (
        <ContainerTopControls/>
    );
}

export default BubbleChartTopControls;