import React, {useState} from "react";
import {genericSort, recursiveDeepClone, shortenTextForLegendFullContent} from "../../../../../../utility";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheckSquare,
    faChevronLeft,
    faChevronRight,
    faSortAlphaDown,
    faSortAlphaUp,
    faSortAmountDown,
    faSortAmountUp,
    faSquare
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {overviewParallelCoordinatesChartDataAtomFamily} from "../state/overview-parallel-coordinates-chart-state";


function OverviewParallelCoordinatesChartRightControls(props) {
    const componentId = props.id;
    // const [chartData, setChartData] = useRecoilState(temporalCloudChartDataAtom);
    const [activeSortingMethod, setActiveSortingMethod] = useState('amount');
    const [amountSortingOrder, setAmountSortingOrder] = useState('desc');
    const [azSortingOrder, setAzSortingOrder] = useState('asc');
    const toggleRightControls = props.toggleRightControls;
    const displayRightNav = props.displayRightNav;
    const [chartData, setChartData] = useRecoilState(overviewParallelCoordinatesChartDataAtomFamily(componentId))
    // const [hoveredElementId, setHoveredElementId] = useRecoilState(hoveredElementIdAtomFamily(componentId));

    const ContainerRightControls = () => {
        const changeActiveStateRightNavItem = (id, val) => {
            const clone = recursiveDeepClone(chartData);
            const newChartData = clone.map((d) => {
                // check if its the right item to manipulate...
                if (d.id === id) {
                    d.active = !val;
                }
                return d;
            })
            setChartData(newChartData)
        }

        const unCheckAll = () => {
            const clone = recursiveDeepClone(chartData);
            clone.map((d) => d.active = false);
            setChartData(clone);
        }

        const checkAll = () => {
            const clone = recursiveDeepClone(chartData);
            clone.map((d) => d.active = true);
            setChartData(clone);
        }


        const changeAmountSorting = (order) => {
            const clone = recursiveDeepClone(chartData);
            // if we are already sorting by amount we change the order.
            if (activeSortingMethod === 'amount') {
                clone.sort(genericSort('amount', order));
                // formattedData.sort(genericSort('amount', 'desc'));
                setAmountSortingOrder(order);
            }
            // otherwise we come here from az sorting and only want to acitvate amount sorting.
            else {
                clone.sort(genericSort('amount', amountSortingOrder));
            }
            setActiveSortingMethod('amount');
            setChartData(clone);
        }

        const changeAzSorting = (order) => {
            const clone = recursiveDeepClone(chartData);
            // console.log(clone);
            // if we are already sorting by amount we change the order.
            if (activeSortingMethod === 'az') {
                clone.sort(genericSort('text', order));
                // formattedData.sort(genericSort('amount', 'desc'));
                setAzSortingOrder(order);
            }
            // otherwise we come here from az sorting and only want to acitvate amount sorting.
            else {
                clone.sort(genericSort('text', azSortingOrder));
            }
            setActiveSortingMethod('az');
            setChartData(clone);
        }

        return (
            <div style={{display: 'flex'}}>
                <div className="right-controls-toggle"
                     style={{height: props.height, width: 10, backgroundColor: '#343a4a'}}
                     onClick={() => {
                         toggleRightControls()
                     }}>
                    <div className="right-controls-toggle">
                        {
                            displayRightNav ?
                                <FontAwesomeIcon icon={faChevronRight} style={{color: 'white'}}/>
                                :
                                <FontAwesomeIcon icon={faChevronLeft} style={{color: 'white'}}/>
                        }

                    </div>

                </div>
                <div className="grid-item-container-right-controls"
                     style={{width: props.width - 20, height: props.height, overflowY: 'scroll'}}>
                    <div style={{borderBottom: '1px solid darkgrey', padding: 0}}>
                        <Row>
                            <Col xs={1}>
                                <FontAwesomeIcon icon={faSquare}
                                                 onClick={() => {
                                                     unCheckAll()
                                                 }}
                                />
                            </Col>
                            <Col xs={1}>
                                <FontAwesomeIcon icon={faCheckSquare}
                                                 onClick={() => {
                                                     checkAll()
                                                 }}
                                />
                            </Col>

                            <Col xs={2}>
                                <div
                                    className={activeSortingMethod === 'amount' ? 'activeSortingIcon' : 'inactiveSortingIcon'}
                                    style={{
                                    width: 22,
                                    paddingLeft: 2,
                                }}>
                                    {
                                        amountSortingOrder === 'desc' ?

                                            <FontAwesomeIcon icon={faSortAmountDown}
                                                             onClick={() => {
                                                                 changeAmountSorting('asc')
                                                             }}
                                            /> :

                                            <FontAwesomeIcon icon={faSortAmountUp}
                                                             onClick={() => {
                                                                 changeAmountSorting('desc')
                                                             }}
                                            />

                                    }
                                </div>

                            </Col>
                            <Col xs={2}>
                                <div
                                    className={activeSortingMethod === 'az' ? 'activeSortingIcon' : 'inactiveSortingIcon'}
                                    style={{
                                    width: 22,
                                    paddingLeft: 2,
                                }}>
                                    {
                                        azSortingOrder === 'asc' ?
                                            <FontAwesomeIcon icon={faSortAlphaDown}
                                                             onClick={() => {
                                                                 changeAzSorting('desc')
                                                             }}
                                            /> :
                                            <FontAwesomeIcon icon={faSortAlphaUp}
                                                             onClick={() => {
                                                                 changeAzSorting('asc')
                                                             }}
                                            />
                                    }
                                </div>
                            </Col>
                        </Row>

                    </div>
                    {
                        chartData.map((d) => {
                            return (
                                <div key={d.id}
                                     onClick={(e) => {
                                         e.preventDefault();
                                         changeActiveStateRightNavItem(d.id, d.active);
                                     }}>
                                    {d.active ?
                                        <b>{shortenTextForLegendFullContent(d.text, d.amount)}</b> : shortenTextForLegendFullContent(d.text, d.amount)}
                                    <span style={{float: 'right'}}>
                                    {d.amount}
                               </span>
                                    <br/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
    return (
        <ContainerRightControls/>
    );
}

export default OverviewParallelCoordinatesChartRightControls;
