import React, {useEffect, useRef} from "react";
import * as d3 from 'd3';

function TemporalCloud(props) {
    const width = props.width;
    const height = props.height;
    const calculatedData = props.calulatedData;
    const setSearchTermAndRerouteToZoomAndFilter = props.setSearchTermAndRerouteToZoomAndFilter;

    const createTemporalCloudChart = () => {
        // TODO: put the initializing stuff like here...
        updateTemporalCloudChart(calculatedData);
    }

    const calculateTextScale = (amount, width, minAmount, maxAmount) => {
        const scale = d3.scaleLinear()
            .domain([minAmount, maxAmount])
            .range([0.5, 2]);
        const s = scale(amount);
        return s < 0.5 ? 0.5 : s;
    }

    const onClick = (d) => {
       setSearchTermAndRerouteToZoomAndFilter(d.name);
    }

    const updateTemporalCloudChart = (data) => {
        const svg = d3.select(ref.current);

        svg
            .attr('width', width)
            .attr('height', height)

        svg
            .selectAll('g')
            .remove();

        const w = width;
        const h = height;

        const shortenText = (name, width) => name.length < width / 10 ? name : name.slice(0, 30) + '...';

        const [minAmount, maxAmount] = d3.extent(data, (e) => e.amount);

        const scale = d3.scaleLinear()
            .domain([minAmount, maxAmount])
            .range([0.33, 1]);

        const maxY = d3.max(data, (d) => d3.max(d, (e) => e.y));


        // calculate the positions
        data.map((d, i) => {
            let [cX, cY] = [0.5 * w, 0.5 * h];
            if (i === 1) {
                [cX, cY] = [w * 5 / 6, h / 2];
            }
            if (i === 2) {
                [cX, cY] = [w / 2, h / 6];
            }
            if (i === 3) {
                [cX, cY] = [w / 6, h / 2];
            }
            if (i === 4) {
                [cX, cY] = [w / 2, h * 5 / 6];
            }
            if (i === 5) {
                [cX, cY] = [w * 5 / 6, h / 6];
            }
            if (i === 6) {
                [cX, cY] = [w / 6, h / 6];
            }
            if (i === 7) {
                [cX, cY] = [w / 6, h * 5 / 6];
            }
            if (i === 8) {
                [cX, cY] = [w * 5 / 6, h * 5 / 6];
            }

            const amountScale = scale(d.amount);
            const [sizeX, sizeY] = [amountScale * w / 6, amountScale * h / 6];
            const [minX, maxX] = d3.extent(d, (e) => {
                return e.x;
            });
            // const maxY = d3.max(d, (e) => { return e.y; });
            const scaleX = d3.scaleBand()
                .domain(d3.range(minX, maxX + 1))
                .range([-sizeX, sizeX]);
            const scaleY = d3.scaleLinear()
                .domain([0, maxY])
                .range([0, -sizeY * 2]);

            d.x = cX;
            d.y = cY + sizeY;
            d.width = sizeX * 2;
            d.height = sizeY * 2;

            d.forEach((e) => {
                e.scaledX = scaleX(e.x);
                e.scaledY = scaleY(e.y);
            });
        });

        // define the line
        const line = d3.line()
            .x((d) => d.scaledX)
            .y((d) => d.scaledY)
            // we could use a variety of other curve functions from d3
            .curve(d3.curveLinear);

        svg
            .selectAll('g')
            .data(data, (d) => d.id)
            .enter()
            .append('g')
            .attr('opacity', 1)
            .attr('transform', (d) => 'translate(' + d.x + ',' + d.y + ')')
            .append('title')
            .text((d) => d.name);

        // define the rect and its position which is used as box fot the sparklines
        svg
            .selectAll('g')
            .append('rect')
            .attr('opacity', 0)
            .attr('x', (d) => -0.5 * d.width)
            .attr('y', (d) => -d.height)
            .attr('width', (d) => d.width)
            .attr('height', (d) => d.height)
            .attr('cursor', 'pointer')
            .on('click', (d) => onClick(d));

        svg
            .selectAll('g')
            .append('text') // calculateTextScale
            .attr('pointer-events', 'none')
            .attr('font-size', '24px')
            .attr('font-weight', 'bold')
            .attr('stroke-width', 0)
            .attr('fill', 'darkgrey')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'auto')
            // .transition()
            .attr('transform', (d, i) => 'scale(' + calculateTextScale(d.amount, d.width, minAmount, maxAmount) + ')')
            .text((d) => shortenText(d.name, d.width));


        // append the lines
        svg
            .selectAll('g')
            .append('path')
            .attr('class', 'temporal-cloud-lines')
            .attr('stroke-width', 1.5)
            .attr('fill', 'none')
            .attr('stroke', 'darkgrey')
            .transition()
            .attr('d', line);

        // define how to remove
        svg
            .selectAll('g')
            .exit()
            .remove();
    }

    /*
        const destroyTemporalCloudChart = () => {
            const svg = d3.select(ref.current);
            svg
                .selectAll('g')
                .remove();

            // remove old placeholder for (if not enough space).
            svg
                .selectAll('text')
                .remove();
        }
    */


    const ref = useRef()
    useEffect(() => {
        createTemporalCloudChart();
    });

    return (
        <div>
            <svg ref={ref}/>
        </div>
    );
}

export default TemporalCloud;

