// we aggregate each vis type with its amount for the right controls. -> active state and amount
import {atom, selector} from "recoil";
import * as _ from "lodash";
import {visualizationItemsAtom} from "../../../Dashboards/GridLayout/state/grid-state";
import {
    availableVisualizationsOverview,
    availableVisualizationsZoomAndFilter,
} from "../../../../config/visualizations";
import {
    facetedFilteredDataAvailableFacetsSelector,
} from "../../../../dataProvider/vissights/search/filters/filters";
import {moduleToDisplayAtom} from "../../../Dashboards/DashboardLayout";

// takes the data from config json file to recoil state.
export const allowedVisualizationsAtom = atom({
    key: "allowedVisualizationsAtom-Key",
    default: [],
});

export const availableVisualizationsZoomAndFilterSelector = selector({
    key: "availableVisualizationsZoomAndFilterSelector-Key",
    get: ({get}) => {
        // prevent fireing a request to the search when not absolutely necessary by checking if it is even displayed...
        if(get(moduleToDisplayAtom) !== "overview"){
            // const activeDataModel = get(activeDataModelSelector);
            let availableVis = availableVisualizationsZoomAndFilter;
            let availableFacets = get(facetedFilteredDataAvailableFacetsSelector);
            /*
            if(activeDataModel[0].name === "Whole"){
                availableVis = availableVisualizationsWholeDataModel;
            } else if (activeDataModel[0].name === "Spatio Temporal") {
                availableVis = availableVisualizationsSpatioTemporalDataModel
            }else if (activeDataModel[0].name === "Temporal") {
                availableVis = availableVisualizationsTemporalDataModel
            }
             */
            // first filter out prohibitted visualizations from config.
            const allowedVisAtom = get(allowedVisualizationsAtom);
            const allowedVis = [];

            allowedVisAtom.forEach((d) => {
                if(d.available === true){
                    allowedVis.push(d.name);
                }
            });

            availableVis = availableVis.filter((d) => allowedVis.includes(d.name));
            // filter out visualizations that cannot be displayed without the according facets.
            let possibleVis = [];
            availableVis.map((d) => {
                // if there is no requirements we can use the vis directly
                if(d.facetRequirements !== undefined){
                    let includesAllNeededFacets = true;
                    d.facetRequirements.forEach((e) => {
                        if(!availableFacets.includes(e)){
                            includesAllNeededFacets = false;
                        }
                    })
                    if(includesAllNeededFacets) {
                        possibleVis.push(d);
                    }
                } else {
                    possibleVis.push(d);
                }
            })
            return possibleVis;
            // console.log(possibleVis);
        } else {
            return [];
        }

    }
});

export const availableVisualizationsOverviewSelector = selector({
    key: "availableVisualizationsOverviewSelector-Key",
    get: ({get}) => {
        let availableVis = availableVisualizationsOverview;
        const allowedVisAtom = get(allowedVisualizationsAtom);
        const allowedVis = [];

        allowedVisAtom.map((d) => {
            if(d.available === true){
                allowedVis.push(d.name);
            }
        })

        availableVis = availableVis.filter((d) => allowedVis.includes(d.name))
        return availableVis;
    }
});

export const amountEachVisTypeActiveSelector = selector({
    key: "amountEachVisTypeActiveSelectorKey",
    get: ({get}) => {
        const visualizationItems = get(visualizationItemsAtom);
        const clone = _.cloneDeep(visualizationItems);
        const visTypes = [];
        clone.map((d) =>  visTypes.push(d.visType));
        const amountVisType = visTypes.reduce((allNames, name) => {
            if (name in allNames) {
                allNames[name]++;
            }
            else {
                allNames[name] = 1;
            }
            return allNames;
        }, {});
        return amountVisType;
    }
});