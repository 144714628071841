import React, {useEffect, useRef} from "react";
import * as d3 from 'd3';
import * as cloud from 'd3-cloud';
import * as _ from "lodash";
import {useRecoilValue} from "recoil";
import {activeDatasetAtomFamily} from "../state";
import {useKeycloak} from "@react-keycloak/web";
import LoginFirst from "../../../../Misc/authentication/LoginFirst";

function WordCloudChart(props) {
    const componentId = props.id;
    const ref = useRef()
    const activeTopControl = useRecoilValue(activeDatasetAtomFamily(componentId));
    let containerWidth = props.width;
    let containerHeight = props.height;
    // we derive the basic functions directly as props instead of directly binding recoil values in the chart.
    const setSearchTermAndRerouteToZoomAndFilter = props.setSearchTermAndRerouteToZoomAndFilter;
    const results = props.results;
    const minFontSize = 22;
    const maxFontSize = 60;
    const font = 'Arial';
    const fontFamily = 'Arial';
    const padding = 12;
    const { keycloak } = useKeycloak();

    const isLoggedIn = keycloak.authenticated;

    // return isLoggedIn ? children :

    useEffect(() => {
        createLayout();
    },[containerWidth, containerHeight, results])


    // uses d3-cloud npm package: Licensed under BSD-3-Clause
    const createLayout = () => {
        const scales = scaleFontSize();
        let w = containerWidth;
        let h = containerHeight;
        let layoutWidth = 0;
        let layoutHeight = 0;
        const clone = _.cloneDeep(results);
        const layout = cloud()
            .size([w, h])
            .words(clone)
            .rotate(0)
            // font must be the same as the font family in the draw function
            .font(font)
            .fontSize((d) => scales.size(d.amount))
            .padding(padding)
            .on('end', () => {
                layoutWidth = layout.size()[0];
                layoutHeight = layout.size()[1];
                let words = layout.words();
                createWordcloudChart(layoutWidth, layoutHeight, words);
            });
        layout.start();
    }

    const createWordcloudChart = (layoutWidth, layoutHeight, words) => {
        // TODO: put the initializing stuff like here...
        updateWordcloudChart(layoutWidth, layoutHeight, words);
    }


    const checkStringLength = (text) =>{
        return text.length > 20 ? text.slice(0, 20)  +  '...'  : text;
    }


    const updateWordcloudChart = (layoutWidth, layoutHeight, words) => {
        // TODO: everything that changes on render must be put here :)

        const scales = scaleFontSize();
        const colorScale = scaleColor()
        // const colorScheme = colorScheme;

        // const ref = myRef;
        const svg = d3.select(ref.current);
        svg
            .attr('width', containerWidth)
            .attr('height', containerHeight)

        svg.select('g')
            .remove();


        // generate the wrapping groups for words and tooltips.
        const parentGroup = svg
            // .attr('class', 'wordcloud')
            .append('g')
            // .select('.cloud-words')
            // translate, so the words dont get cut of left and top. This sets them to the center.
            .attr('transform', 'translate(' + layoutWidth / 2 + ',' + layoutHeight / 2 + ')')
            .selectAll('text')
            .data(words)
            .enter()
            .append('g')
            .on('click', (d) => onClick(d))
            .attr('class', (d, i) => 'text-group-' + i);

        parentGroup
            .append('text')
            .attr('class', (d, i) => 'word-' + i)
            .attr('font-size', (d) => scales.size(d.amount))
            // font must be the same as the font in the draw function
            .attr('font-family', fontFamily)
            .attr('text-anchor', 'middle')
            .attr('cursor', 'pointer')
            .attr('fill', (d) => colorScale.color(d.amount))
            .transition()
            .duration(1200)
            .attr('transform', (d) => 'translate(' + [d.x, d.y] + ')rotate(' + 0 + ')')
            .text((d) => checkStringLength(d.text));

        // console.log("asdasd")
        // Append Tooltips to the wrapping group
        parentGroup
            .append('title')
            .attr('class', (d, i) => 'tooltip-' + i)
            .text((d) => {
                if (d.text !== ' ') {
                    if (activeTopControl === 'searchHistory') {
                        const end = d.amount > 1 ? 'requests' : 'request';
                        return d.text + ': ' + d.amount + ' ' + end;
                    } else {
                        const end = d.amount > 1 ? 'papers' : 'paper';
                        return d.text + ': ' + d.amount + ' ' + end;
                    }
                }
            });
    }

    const onClick = (d) => {
        setSearchTermAndRerouteToZoomAndFilter(d.text);
    }

    const scaleFontSize = () => {
        const [minAmount, maxAmount] = d3.extent(results, (e) => {
            return e.amount;
        });
        return {
            size: d3.scaleLinear()
                .domain([minAmount, maxAmount])
                .range([minFontSize, maxFontSize])
        };
    }

    const scaleColor = () => {
        const [minAmount, maxAmount] = d3.extent(results, (e) => {
            return e.amount;
        });
        return {

            color: d3.scaleOrdinal(d3.schemeCategory10)
                .domain([minAmount, maxAmount])
            // .interpolator(d3.interpolateViridis)
        };
    }

    return (
        !isLoggedIn && activeTopControl === "monitoring" ? <LoginFirst/> : <svg ref={ref}/>
    );
}

export default WordCloudChart;

