import {atomFamily, selectorFamily, selector} from "recoil";
import {
    preProcessedFilteredSearchDataSelector
} from "../../../../../../../dataProvider/vissights/search/filters/filters";
import FACET from "../../../../../../../dataProvider/vissights/utility/facet-extraction-utility";
import {genericSort, recursiveDeepClone} from "../../../../../../../utility";

export const chartDataTreeMapBarChartSelectorFamily = selectorFamily({
    key: 'chartDataTreeMapBarChartSelectorFamily-Key',
    get: id => async ({get}) => {
        const dataModel = await get(preProcessedFilteredSearchDataSelector);
        let returnDataTopics = dataModel[FACET.TOPIC];
        const newReturnDataTopics = recursiveDeepClone(returnDataTopics);
        let returnDataYears = dataModel[FACET.YEAR];

        const maxYear = parseInt(returnDataYears[0].id);
        const minYear = parseInt(returnDataYears[returnDataYears.length - 1].id);


        const finalData = {children: []};
        // iterate for each year and build the data structure from scratch
        for (let i = minYear; i <= maxYear; i++) {
            // iterate over the topics and look into their years. Take all that occure in the year out.
            let topicDataForCurrentYear = []
            newReturnDataTopics.forEach((d) => {
                let appearanceYears = Object.keys(d.years);
                let appearanceAmount = Object.values(d.years);
                // check if the topic appears in the year.
                d["year"] = i;
                if (appearanceYears.includes(i.toString())) {
                    // grab the concrete value for the topic occurance in the year.
                    d.amount = d.years[i];
                    topicDataForCurrentYear.push(d)
                }
            })

            // get publication amount for the year
            let dataForCurrYear = returnDataYears.filter((d) => d.id === i.toString())

            topicDataForCurrentYear.sort(genericSort('amount', 'desc'));

            if (topicDataForCurrentYear.length > 0) {
                finalData["children"].push({
                    year: i,
                    amount: dataForCurrYear[0].amount,
                    children: [{type: "lda-topics", children: topicDataForCurrentYear}] //, {type: "ctm-topics", children: topicDataForCurrentYear}]
                })
            }
        }
        return finalData;
    },
});


export const treeMapBarChartDataAtomFamily = atomFamily({
    key: "treeMapBarChartDataAtomFamily-Key",
    default: selectorFamily({
        key: 'treeMapBarChartDataAtomFamily/Default',
        get: id => ({get}) => {
            return chartDataTreeMapBarChartSelectorFamily(id);
        },
    }),
});


export const treeMapBarChartRightControlDataAtomFamily = atomFamily({
    key: "treeMapBarChartRightControlDataAtomFamily-Key",
    default: selectorFamily({
        key: 'treeMapBarChartRightControlDataAtomFamily/Default',
        get: id => () => {
            return treeMapBarChartRightControlDataSelectorFamily(id);
        },
    }),
});

export const treeMapBarChartRightControlDataSelectorFamily = selectorFamily({
    key: 'treeMapBarChartRightControlDataSelectorFamily-Key',
    get: id => ({get}) => {
        const rawData = get(treeMapBarChartDataAtomFamily(id));
        let data = recursiveDeepClone(rawData);
        // get all children -> topics from nested structure.
        let topicData = data.children.map((d) =>  d.children[0].children)

        let flatTopicData = topicData.flat(1);
        const composedTopicData = [];

        const dataForOneTopicId = new Map();

         flatTopicData.forEach((d) => {
             dataForOneTopicId.set(d.id, (flatTopicData.filter((e) => e.id === d.id)));
        })



        for (const [key, value] of dataForOneTopicId) {
            const sum = value.reduce((accumulator, object) => accumulator + object.amount, 0);
            composedTopicData.push({"id": key, "name": value[0].name, "amount": sum})
        }

        composedTopicData.sort(genericSort('amount', 'desc'));

        // const sortedTopicData = flatTopicData.sort((d) => d.amount)
        // console.log(sortedTopicData);
        // activate first 50 items.
        composedTopicData.map((d, i) => {
             if (i < 40) {
                 d['active'] = true;
             }
             return d;
         });
        return composedTopicData// data;
    }
});

export const treeMapBarChartDataActiveSelectorFamily = selectorFamily({
    key: 'treeMapBarChartDataActiveSelectorFamily-Key',
    get: id => ({get}) => {
        const rightControlItems = get(treeMapBarChartRightControlDataAtomFamily(id));
        const rightControlItemsActive = rightControlItems.filter((d) => d.active);
        let rightControlItemsActiveIds = [];

        rightControlItemsActive.map((d) => {
            rightControlItemsActiveIds.push(d.id)
        })

        const chartData = get(treeMapBarChartDataAtomFamily(id));

        let clone = recursiveDeepClone(chartData);

        // get only children arrays with active data.
        const filteredChildren = clone.children.map((d) => d.children[0].children.filter((f) => rightControlItemsActiveIds.includes(f.id)))// map((e) => console.log(e))); //  e)


        const finalData = {children: []};

        clone.children.forEach((d,i) => {
            d.children[0].children = filteredChildren[i];
            finalData.children.push(d);
        })

        // .children[0].children.filter((d) => rightControlItemsActiveIds.includes(d.id))

        return finalData;
    }
});