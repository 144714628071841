import {atomFamily, selectorFamily} from "recoil";
import * as d3 from 'd3';
import {genericSort, recursiveDeepClone} from "../../../../../../utility";
import {overviewDataAtom} from "../../../../../../dataProvider/vissights/overview/overview";

export const overviewParallelCoordinatesChartDataAtomFamily = atomFamily({
    key: "overviewParallelCoordinatesChartDataAtomFamily-Key",
    default: selectorFamily({
        key: 'overviewParallelCoordinatesChartDataAtomFamily/Default',
        get: id => () => {
            return overviewParallelCoordinatesChartDataSelectorFamily(id);
        },
    }),
});




export const overviewParallelCoordinatesChartDataSelectorFamily = selectorFamily({
    key: 'overviewParallelCoordinatesChartDataSelectorFamily-Key',
    get: id => ({get}) => {
        const rawData = get(overviewDataAtom);
        let data = recursiveDeepClone(rawData);

        data = data.sort(genericSort('amount', 'desc'));

        // activate first 50 items.
        data.map((d, i) => {
            if(i < 50) {
                d['active'] = true;
            }
            return d;
        })
        return data;
    }
});


export const overviewParallelCoordinatesChartSplitDataSelectorFamily = selectorFamily({
    key: 'overviewParallelCoordinatesChartSplitDataSelectorFamily-Key',
    get: id => ({get}) => {
        const rawData = get(overviewParallelCoordinatesChartDataAtomFamily(id));

        let clone = recursiveDeepClone(rawData)
        clone = clone.filter((d) => d.active);

        const data = prepareTopics(recursiveDeepClone(clone));



        const dataPub = {};
        const dimensions = d3.keys(data[0])
            .filter((d) => {
                return d !== 'name' && d !== 'active' && d !== '__typename'
            });


        for (let i = 0; i < dimensions.length; ++i) {
            const next = i < dimensions.length - 1 ? i + 1 : i - 1;
            dataPub[dimensions[i]] = [];
            let indexI = 0;
            for (const d of data) {
                dataPub[dimensions[i]].push({
                    from: +d[dimensions[i]],
                    to: +d[dimensions[next]],
                    index: indexI,
                    active: false,
                    name: d.name
                });
                indexI++;
            }
        }
        return dataPub;
    }
});


const prepareTopics = (topics) => {
    const sortedData = topics.sort(genericSort('total', 'desc'));
    const cpData = recursiveDeepClone(sortedData);
    cpData.map((d) => {
        d.name = d.text;
        delete d.text;
        delete d.years;
        delete d.yearsNormalized;
        delete d.malformedData;
        delete d.id;
        delete d.amount;
        delete d.lastMax;
    });
    return cpData;
}



export const overviewParallelCoordinatesChartSplitDataAtomFamily = atomFamily({
    key: "overviewParallelCoordinatesChartSplitDataAtomFamily-Key",
    default: selectorFamily({
        key: 'parallelCoordinatesChartSplitDataAtomFamily/Default',
        get: id => () => {
            return overviewParallelCoordinatesChartSplitDataSelectorFamily(id);
        },
    }),
});



