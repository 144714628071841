import {atomFamily, selectorFamily} from "recoil";
import * as _ from "lodash";
import {genericSort} from "../../../../../../utility";
import {overviewDataAtom} from "../../../../../../dataProvider/vissights/overview/overview";
import {activeAggregationAtomFamily, useNormalizationAtomFamily} from "../../state";


export const chartDataLineChartSelector = selectorFamily({
    key: 'chartDataLineChartSelectorKey',

    get: (id) => ({get}) => {
        const rawData = get(overviewDataAtom);
        const aggregation = get(activeAggregationAtomFamily(id));
        const clonedRawData = _.cloneDeep(rawData);
        const normalization = get(useNormalizationAtomFamily(id));

        const aggregationName = normalization ? aggregation + "Normalized" : aggregation;

        const chartData = clonedRawData.sort(genericSort(aggregationName, 'desc'));
        // console.log('test')
        chartData.map((d, i) => {
            // amount refers to which value gets displayed in the chart.
            d.amount =  d[aggregationName];
            d.color = d["color"];
            // we initially activate the first 0-8 el -> 9 active items
            i > 8 ? d.active = false : d.active = true;
            return d;
        })
        return chartData
    },

});

export const lineChartChartDataAtomFamily = atomFamily({
    key: "lineChartChartDataAtomFamily",
    default: selectorFamily({
        key: 'lineChartChartDataAtomFamilyKey/Default',
        get: id => () => {
            return chartDataLineChartSelector(id);
        },
    }),
});
