import React, {useEffect} from 'react';
import {Accordion, Button, Col, NavLink, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import 'react-quill/dist/quill.snow.css';
import ReactQuill, {Quill} from 'react-quill'; // ES6
import * as _ from "lodash";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    dashboardScreenshotsAtom,
    reportingContentAtom,
    singleVisualizationScreenshotsAtom
} from "./state/ReportingState";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faTrash} from "@fortawesome/free-solid-svg-icons";
import {dashboardHeightAtom} from "../../../config/sizings/sizings";
import ImageResize from 'quill-image-resize-module-react'
import DownloadReportModal, {displayDownloadModalAtom} from "./modals/DownloadReportModal";
Quill.register('modules/imageResize', ImageResize)

function Reporting() {
    const [displayDownloadModal, setDisplayDownloadModal] = useRecoilState(displayDownloadModalAtom);

    const modules = {
        imageResize: {
            modules: ['Resize', 'DisplaySize', 'Toolbar'] //'Resize', 'DisplaySize', 'Toolbar']
        },
        // imageResize: true, // for image resize
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            [{header: 1}, {header: 2}], // custom button values
            [{list: 'ordered'}, {list: 'bullet'}],
            [{script: 'sub'}, {script: 'super'}], // superscript/subscript
            [{indent: '-1'}, {indent: '+1'}], // outdent/indent
            [{direction: 'rtl'}], // text direction
            [{size: ['small', false, 'large', 'huge']}], // custom dropdown
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            [{color: []}, {background: []}], // dropdown with defaults from theme
            [{font: []}],
            [{align: []}],
            ['clean'], // remove formatting button
            ['link', 'image'], // link and image, video
        ]
    };
    let [reportingContent, setReportingContent] = useRecoilState(reportingContentAtom);
    const [singleVisualizationScreenshots, setSingleVisualizationScreenshots] = useRecoilState(singleVisualizationScreenshotsAtom);
    const [dashboardScreenshots, setDashboardScreenshots] = useRecoilState(dashboardScreenshotsAtom);
    const dashboardHeight = useRecoilValue(dashboardHeightAtom);


    const phrases = []
    const utc = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
    phrases.push('The following report was created on ' + utc + '.')
    phrases.push('On ' + utc + ' the following report was created.')


    const removeSingleVis = (id) => {
        let clone = _.cloneDeep(singleVisualizationScreenshots);
        // filter by id to remove
        clone = clone.filter((d) => d.id !== id);
        setSingleVisualizationScreenshots(clone);
    }

    const removeDashboardVis = (id) => {
        let clone = _.cloneDeep(dashboardScreenshots);
        // filter by id to remove
        clone = clone.filter((d) => d.id !== id);
        setDashboardScreenshots(clone);
    }

    useEffect(() => {

    })

    // addTextToEditor
    /*
    const addParagraphToHtmlString = (event, paragraph) => {
        // stop the propagation to prevent an infinite loop of content changes.
        event.stopPropagation();
        const selection = this.editorTest.getSelection(); // get position of cursor (index of selection)
        // check if we have a cursor
        if (selection === null) {
            this.editorTest.clipboard.dangerouslyPasteHTML(this.lastKnownCursorPosition, paragraph); // insert text at the end
        } else {
            this.editorTest.clipboard.dangerouslyPasteHTML(selection.index, paragraph); // insert text into the cursor position
        }
        // this.store.dispatch(new AddParagraphToReportHtmlString(paragraph, this.componentId))
    }

     */


    const addParagraphToReport = (d) => {
        reportingContent += d
        setReportingContent(reportingContent)
    }


    return (
        <div className="ReportingDashboard" style={{textAlign: 'left', height: dashboardHeight}}>


            <Row>
                <Col xs="3" style={{overflowY: 'auto', height: dashboardHeight - 50}}>
                    <b>Automatic text generation & figures</b>
                    <Accordion defaultActiveKey="1" style={{border: "1px solid lightgrey"}}>
                        <Accordion.Toggle as={NavLink} variant="link" eventKey="1"
                                          style={{border: "1px solid lightgrey"}}>
                            General
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <div>
                                {
                                    phrases.map((d, i) => {
                                        return (
                                            <p key={i}>
                                                {d}

                                                <OverlayTrigger
                                                    key={'add-paragraph-info'}
                                                    placement={'right'}
                                                    overlay={
                                                        <Tooltip id={`tooltip-${'add-paragraph-info'}`}>
                                                            Adds this paragraph to the report.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon style={{margin: 4, cursor: 'pointer', float: 'right'}}
                                                                     icon={faArrowRight}
                                                                     onClick={() => addParagraphToReport(d)}
                                                    />
                                                </OverlayTrigger>
                                            </p>
                                        )
                                    })
                                }

                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                    <br/> <br/>
                    <Accordion defaultActiveKey="2">
                        <Accordion.Toggle as={NavLink} variant="link" eventKey="2"
                                          style={{border: "1px solid lightgrey"}}>
                            Figures
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Accordion defaultActiveKey="55">
                                <Accordion.Toggle as={NavLink} variant="link" eventKey="3"
                                                  style={{marginLeft: 10, border: "1px solid lightgrey"}}>
                                    Dashboards
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <div>
                                        {
                                            dashboardScreenshots.map((item) => {
                                                return (
                                                    <div key={item.id + 'screenshot-img-wrapper'}
                                                         style={{marginLeft: 10, marginBottom: 10, display: 'flex'}}>
                                                        <img style={{maxWidth: '95%'}} key={item.id}
                                                             src={item.data.dataUrl}
                                                             alt={item.id}/>
                                                        <FontAwesomeIcon style={{marginLeft: 2, cursor: 'pointer'}}
                                                                         icon={faTrash}
                                                                         onClick={() => removeDashboardVis(item.id)}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Accordion.Collapse>
                                <Accordion.Toggle as={NavLink} variant="link" eventKey="4"
                                                  style={{marginLeft: 10, border: "1px solid lightgrey"}}>
                                    Single Visualizations
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <div>
                                        {
                                            singleVisualizationScreenshots.map((item) => {
                                                return (
                                                    <div key={item.id + 'img-wrapper'}
                                                         style={{marginLeft: 10, marginBottom: 10, display: 'flex'}}>
                                                        <img style={{maxWidth: '95%'}} key={item.id}
                                                             src={item.data.dataUrl}
                                                             alt={item.id}/>
                                                        <FontAwesomeIcon style={{marginLeft: 2, cursor: 'pointer'}}
                                                                         icon={faTrash}
                                                                         onClick={() => removeSingleVis(item.id)}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Accordion.Collapse>
                            </Accordion>
                        </Accordion.Collapse>
                    </Accordion>
                </Col>
                <Col xs="9">
                    <b>Report</b>
                    <ReactQuill
                        theme="snow"
                        modules={modules}
                        style={{height: dashboardHeight - 110}}
                        value={reportingContent}
                        onChange={setReportingContent}/>
                    {displayDownloadModal ? <DownloadReportModal/> : null}
                </Col>

                <Col xs={3}/>
                <Col xs={9} style={{marginTop: 10}}> <Button onClick={() => setDisplayDownloadModal(true)}>Download
                    Report</Button> </Col>
            </Row>
        </div>
    );
}

export default Reporting;


