import {atom, selector, selectorFamily, waitForAny} from "recoil";
import FACET from "../utility/facet-extraction-utility";
import {topicsAtom} from "../overview/overview";
import {genericSort, recursiveDeepClone} from "../../../utility";
// import {client} from '../../../index';
// import {SPATIO_TEMPORAL_MODEL, TEMPORAL_MODEL, TOPICS_MODEL, WHOLE_MODEL} from "../graphQL/graphqlQueries";
import {dbConfig, vissightsConfig} from "../vissightsConfig";
import {recoilPersist} from 'recoil-persist';

const {persistAtom} = recoilPersist()

const getCorrespondingDbFromString = (dbName) => {
    const returnValue = dbConfig.filter((d) => d.name === dbName);
    return returnValue[0];
}

export const searchTermAtom = atom({
    key: "searchTermKey",
    default: '',
    effects_UNSTABLE: [persistAtom]
});


export const searchTermSelector = selector({
    key: "searchTermSelector-Key",
    get: async ({get}) => {
        const searchTerm = get(searchTermAtom);
        const useAssisted = get(assistedSearchAtom);
        if (useAssisted) {
            return await get(assistedSearchQuerySelectorFamily(searchTerm));
        } else {
            return searchTerm
        }
    }
});

// stuff we need for assisted Search
export const assistedSearchAtom = atom({
    key: "assistedSearchKey",
    default: false
});

// stores the current database
export const searchDatabase = atom({
    key: "searchDatabaseKey",
    default: "",// getCorrespondingDbFromString(vissightsConfig.initialDatabase)//getCorrespondingDbFromString(c.db))// vissightsConfig.initialDatabase
    effects_UNSTABLE: [persistAtom]
});

// The initialization of the advanced search fields.
export const advancedSearchFieldsAtom = atom({
    key: "advancedSearchFieldsKey",
    default: [
        {name: 'title', value: true},
        {name: 'year', value: true},
        {name: 'author', value: true},
        {name: 'abstract', value: false},
        {name: 'full-text', value: false}
    ]
});

export const activeDataModelSelector = selector({
    key: "activeDataModelKey",
    get: ({get}) => {
        const availableDataModels = get(availableDataModelsAtom);
        return availableDataModels.filter((d) => d.active);
    }
});

export const availableDataModelsAtom = atom({
    key: "availableDataModelsAtomKey",
    default: [
        {
            name: 'Whole',
            value: ["key", 'affiliation', "author", 'title', 'topics', 'type', "year", "country"],
            active: true
        },
        {name: 'Spatio Temporal', value: ["key", "year", "country"], active: false},
        {name: 'Temporal', value: ["key", "year"], active: false}
    ],
});

// amount of phrases that are considered in assisted search
export const amountRelatedPhrasesToConsiderAtom = atom({
    key: "amountRelatedPhrasesToConsiderKey",
    // must be stored as a number array for the range slider...
    default: 3,
});

// amount of topics that are considered in assisted search (experimental)
export const amountRelatedTopicsToConsiderAtom = atom({
    key: "amountRelatedTopicsToConsiderKey",
    // must be stored as a number array for the range slider...
    default: 0,
});

const createQueryFilterUrlString = (fields, urlParam) => { //  '&search-field='
    let fieldStr = ' ';
    // filter for only active fields.
    const fieldsToUse = fields.filter((d) => d.value);
    // we must store only names in an array
    const arrayOfFieldsToUse = [];
    fieldsToUse.forEach((d) => arrayOfFieldsToUse.push(d.name));

    for (let i = 0; i < arrayOfFieldsToUse.length; i++) {
        if (arrayOfFieldsToUse[i] !== '') {
            // first iteration
            if (i === 0) {
                // check if it has only one entry
                if (i + 1 === arrayOfFieldsToUse.length) {
                    fieldStr = urlParam + arrayOfFieldsToUse[i];
                } else {
                    fieldStr = urlParam + arrayOfFieldsToUse[i] + ',';
                }
            } else {
                if (i + 1 === arrayOfFieldsToUse.length) {
                    fieldStr += arrayOfFieldsToUse[i];
                } else {
                    fieldStr += arrayOfFieldsToUse[i] + ',';
                }
            }
        }
    }
    //console.log(fieldStr)
    return fieldStr;
}

// The initialization of the advanced search fields.
export const includeSelectionAtom = atom({
    key: "includeSelectionAtom-Key",
    default: [
        // {name: 'abstract', value: false},
        {name: 'affiliation', value: true},
        {name: 'author', value: true},
        //{name: 'contentType', value: false},
        //{name: 'copyright', value: false},
        {name: 'country', value: true},
        //{name: 'doc_dir', value: false},
        //{name: 'doi', value: false},
        //{name: 'ee', value: false},
        //{name: 'endingPage', value: false},
        //{name: 'genre', value: false},
        //{name: 'identifier', value: false},
        //{name: 'journalId', value: false},
        //{name: 'key', value: true},
        //{name: 'language', value: false},
        //{name: 'number', value: false},
        //{name: 'openAccess', value: false},
        //{name: 'publicationDate', value: false},
        //{name: 'publicationName', value: false},
        //{name: 'publisher', value: false},
        //{name: 'startingPage', value: false},
        {name: 'title', value: true},
        {name: 'topics', value: true},
        {name: 'type', value: true},
        //{name: 'url', value: false},
        //{name: 'volume', value: false},
        {name: 'year', value: true}
    ]
});

export const displaySearchAdjustmentsModalAtom = atom({
    key: "displaySearchAdjustmentsModalKey",
    default: false
});

// returns the query for assisted search
export const assistedSearchQuerySelectorFamily = selectorFamily({
    key: "assistedSearchQuerySelectorFamily-Key",
    get: searchTerm => async ({get}) => {
        const db = get(searchDatabase);
        // const searchTerm = get(searchTermAtom);
        // get the amounts of topics / phrases that should be used for advances search.
        const amountPhrases = get(amountRelatedPhrasesToConsiderAtom);
        const amountTopics = get(amountRelatedTopicsToConsiderAtom);
        const topics = get(topicsAtom);

        let query;
        if (searchTerm === '' || searchTerm === []) {
            query = 'Visual Analytics';
        } else {
            query = searchTerm;
        }

        // advanced search fields act like a filter for specifying in which fields shall be searched in the db.
        const advancedSearchFields = get(advancedSearchFieldsAtom);

        // const dataModelToLoad = activeDataModel[0].value;

        const fieldsUrlStr = createQueryFilterUrlString(advancedSearchFields, '&search-field=');


        // let url = vissightsConfig.baseQuery + 'search' + vissightsConfig.apiVersion + '/publications/search?db=' + db.name + '&q=' + query + fieldsUrlStr;

        // url = encodeURI(url)

        // we load asynchronously only the temporal data. This will be returned first to be available to display the first chart
        let initialUrlQuery = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/publications/search?db=" + db.name + "&include=topics" + "&limit=200" + "&q=" + query + fieldsUrlStr;
        let newUrlQuery;

        await fetch(initialUrlQuery).then(async (d) => {
            const newData = await d.json();
            console.log(newData, topics, query, amountPhrases, amountTopics)
            // return newUrlQuery = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/publications/search?db=" + db.name + includeUrlStr + ',key' + "&q=" + createQueryUrlForAssistedSearch(newData, topics, query, amountPhrases, amountTopics)  // + fieldsUrlStr;
            newUrlQuery = createQueryUrlForAssistedSearch(newData, topics, query, amountPhrases, amountTopics)
        });
        return newUrlQuery;
    }
});


export const checkTotalAmount = selector({
    key: "checkTotalAmount-Key",
    get: async ({get}) => {
        const db = get(searchDatabase);
        const advancedSearchFields = get(advancedSearchFieldsAtom);
        const fieldsUrlStr = createQueryFilterUrlString(advancedSearchFields, '&search-field=');
        const includeSelection = get(includeSelectionAtom);
        const includeUrlStr = createQueryFilterUrlString(includeSelection, '&include=');
        const searchTerm = await get(searchTermSelector);
        const maximumAmountToReturn = get(maximumAmountLoadedAtom);

        let url = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/publications/count?db=" + db.name + includeUrlStr + ',key' + "&limit=200" + "&q=" + searchTerm + fieldsUrlStr;
        console.log(encodeURI(url))
        // console.log(encodeURI(url));
        // url = encodeURI(url)
       const totalAmount =  await fetch(encodeURI(url)).then((d) => {
            return d.json();
        });
        return totalAmount <= maximumAmountToReturn ? totalAmount : maximumAmountToReturn;
    }
});

// checks if data amount equals total amount. Returns boolean value
export const isStillLoadingDataSelector = selector({
    key: "isStillLoadingDataSelector-Key",
    get: ({get}) => {
        const searchAmount = get(searchDataSelector);
        const totalAmount = get(checkTotalAmount);
        return !(searchAmount.length === totalAmount);
    }
});


// here we load first n elements for the search
export const searchForTerm = selectorFamily({
    key: "searchForTerm",
    get: queryData => ({get}) => {
        const db = get(searchDatabase);
        const advancedSearchFields = get(advancedSearchFieldsAtom);
        const fieldsUrlStr = createQueryFilterUrlString(advancedSearchFields, '&search-field=');
        const includeSelection = get(includeSelectionAtom);
        const includeUrlStr = createQueryFilterUrlString(includeSelection, '&include=');
        let url = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/publications/search?db=" + db.name + includeUrlStr + ',key' + "&limit=" + queryData.amount + "&offset=" + queryData.offset + "&q=" + queryData.searchTerm + fieldsUrlStr;
        console.log(encodeURI(url))

        return fetch(encodeURI(url)).then((d) => {
            return d.json();
        });
    }
});

export const useLocalAsGlobalSearchDataAtom = atom({
    key: "useLocalAsGlobalSearchDataAtomKey",
    default: false
})

export const localSearchDataAtom = atom({
    key: "localSearchDataAtomKey",
    default: []
})

export const useLazyLoadingAtom = atom({
    key: "useLazyLoadingAtom-Key",
    default: true
})

export const initialAmountLoadedAtom = atom({
    key: "initialAmountLoadedAtom-Key",
    default: 100
})

export const firstLazyAmountLoadedAtom = atom({
    key: "firstLazyAmountLoadedAtom-Key",
    default: 1000
})

export const maximumAmountLoadedAtom = atom({
    key: "maximumAmountLoadedAtom-Key",
    default: 10000
})

// here we load first n elements for the search
export const lazyLoadingConfigSelectorFamily = selectorFamily({
    key: "lazyLoadingConfigSelectorFamily-Key",
    get: lazyConfig => ({get}) => {
        const lazyLoadingConfig = [];

        let iteration = 1;
        // we create packages of 500 for lazy loading. This must be conducted as the api only allows 500 full-text answers
        for (let i = 500; i <= lazyConfig.amount; i = i + 500) {
            lazyLoadingConfig.push({
                name: "lazy-iteration-" + iteration,
                amount: 500,
                offset: i - 500,
                searchTerm: lazyConfig.searchTerm
            })
            // add one to iteration
            iteration++;
        }
        return lazyLoadingConfig;
    }
});

// returns all search data
export const searchDataSelector = selector({
    key: "searchDataSelector-Key",
    get: async ({get}) => {
        const useLocalAsGlobalSearchData = get(useLocalAsGlobalSearchDataAtom);
        const localSearchData = get(localSearchDataAtom);
        // const useLazyLoading = get(useLazyLoadingAtom);
        const maximumAmountLoaded = get(maximumAmountLoadedAtom);
        //  const useAssistedSearch = get(assistedSearchAtom);
        let dataToReturn = [];

        // search term selector pre checks is we use assisted search and returns suitable query
        let searchTerm = get(searchTermSelector);
        let totalAnswerAmountForQuery;
        //if (useAssistedSearch) {
        //searchTerm = await get(assistedSearchQuerySelector);
        //   totalAnswerAmountForQuery = await get(checkTotalAmount);
        //} else {
        totalAnswerAmountForQuery = await get(checkTotalAmount);
        //}

        // we initially query for the full amount of answers for our query.

        // only use lazy loading if we have over 400 elements
        const useLazyLoading = totalAnswerAmountForQuery > 400;
        console.log("full amount of results", totalAnswerAmountForQuery);

        // case lazyloading
        if (useLazyLoading) {

            const lazyLoadingConfigSelector = get(lazyLoadingConfigSelectorFamily({
                amount: totalAnswerAmountForQuery,
                searchTerm: searchTerm
            }))

            const lazyLoadingConfig = recursiveDeepClone(lazyLoadingConfigSelector);

            console.log(lazyLoadingConfig)

            //const lazyLoadingConfig = [
            //    {name: "initial", amount: initialAmountLoaded, offset: 0, searchTerm: searchTerm},
            //    {
            //        name: "first-lazy",
            //        amount: firstLazyAmountLoaded,
            //        offset: Number(initialAmountLoaded),
            //        searchTerm: searchTerm
            //    },
            //    {
            //        name: "second-lazy",
            //        amount: maximumAmountLoaded,
            //        offset: (firstLazyAmountLoaded + initialAmountLoaded),
            //        searchTerm: searchTerm
            //    }
            //]
//

            // let searchData = get(waitForAny(
            //
            //     for(let i = 0; i < totalAnswerAmountForQuery;  i=i+500){
            //     searchForTerm({
            //         amount: d.amount,
            //         offset: d.offset,
            //         searchTerm: d.searchTerm
            //     })
            // }));

            let searchData = get(waitForAny(lazyLoadingConfig.map((d) => d.data = searchForTerm({
                amount: d.amount,
                offset: d.offset,
                searchTerm: d.searchTerm
            }))));


            let returnData = [];


            searchData.forEach((d, i) => {
                if (d.state === 'hasValue') {
                    returnData.push(d.contents)
                }
            })

            // if(tempReturnData.length === 1){
            //     returnData = tempReturnData;
            // } else if(tempReturnData.length >= lazyLoadingConfig.length / 3 && tempReturnData.length <= lazyLoadingConfig.length / 2) {
            //     returnData = tempReturnData;
            // } else if(tempReturnData.length ===  lazyLoadingConfig.length){
            //     returnData = tempReturnData;
            // } else {
            //     return;
            // }


            dataToReturn = returnData.flat();

            // checks if first lazy load answer is there
            // if (searchData[1].state === 'hasValue') {
            //     dataToReturn = searchData[0].contents.concat(searchData[1].contents);
            // }
            // // checks if second lazy load answer is there
            // else if (searchData[2].state === 'hasValue') {
            //     dataToReturn = searchData[0].contents.concat(searchData[1].contents).concat(searchData[2].contents);
            //
            // }
            // // else return data
            // else {
            //     dataToReturn = searchData[0].contents;
            // }

        } else {
            dataToReturn = await get(searchForTerm({amount: maximumAmountLoaded, offset: 0, searchTerm: searchTerm}))
        }

        // implemented for IV2022 PC Paper
        if (useLocalAsGlobalSearchData) {
            let localSearchDataKeys = [];
            localSearchData.map((d) => localSearchDataKeys.push(d.key))
            dataToReturn = dataToReturn.filter((d) => {
                return localSearchDataKeys.includes(d.key);
            })
        }
        return dataToReturn;
    }
});

export const searchDataAtom = atom({
    key: "searchDataAtom-Key",
    default: searchDataSelector
})

// here we preprocess the data
export const preProcessedRawDataSelector = selector({
    key: "preProcessedRawDataSelectorKey",
    get: ({get}) => {
        // const rawData =  get(searchDataSelector);
        // create facet data from the filtered data.
        return get(facetedRawDataSelector);
    }
});

export const amountRawDataSelector = selector({
    key: "amountRawDataSelectorKey",
    get: ({get}) => {
        const rawData = get(searchDataSelector);
        return rawData.length;
    }
});

export const facetedRawDataSelector = selector({
    key: "facetedRawDataSelectorKey",
    get: ({get}) => {
        const topics = get(topicsAtom);
        const rawSearchData = get(searchDataSelector);
        return FACET.extractAllFacets(rawSearchData, topics);
    }
});

// creates the new url for the second query.
const createQueryUrlForAssistedSearch = (data, topics, query, amountPhrases, amountTopics) => {
    let topicfacets = {};
    // we only extract the topic facets
    // TODO: this should be done in the backend... Send a query that advances search is used. + topics + phrases amount to consider -> create new query with "OR" relations...
    topicfacets = FACET.extractFacet(data, FACET.TOPIC, topics);
    topicfacets = topicfacets.sort(genericSort('amount', 'desc'));
    // case we have no data
    if (topicfacets.length === 0) {
        return [];
    }
    // get dominant topic -> as its already sorted above we just use the first entry -> 0
    let dominantTopicId = topicfacets[0].id;

    // formulate new query with semantically similar phrases
    const correspondingPhrasesNGram = topics[dominantTopicId].phrase;
    const correspondingTopicsNGram = topics[dominantTopicId].word;

    let newQuery = query;

    for (let i = 0; i < amountPhrases; i++) {
        newQuery += ' OR ' + '\'' + correspondingPhrasesNGram[i][0] + '\'';
    }

    for (let i = 0; i < amountTopics; i++) {
        newQuery += ' OR ' + correspondingTopicsNGram[i][0];
    }

    return newQuery; // + fieldsUrlStr;
}


/** We do not use currently as we follow a data model approach e.g whole, temporal, geospatial model **/

// we use the facets defined in the utility to create the filterable list. Later we only use selected facets.
const createAvailableFacetsForAdvancedSearch = () => {
    let defaultFacetFilters = [];
    FACET.typesKeys.forEach((d) => {
        defaultFacetFilters.push({name: d, value: true})
    })
    return defaultFacetFilters;
}

// stuff we need for assisted Search
export const specifiedFacetsAtom = atom({
    key: "specifiedFacetsKey",
    default: createAvailableFacetsForAdvancedSearch()
});
