import React, {useEffect, useRef, useState} from "react";
import {useRecoilState, useResetRecoilState} from "recoil";
import {
    visualizationPinAtomFamily, visualizationScaleAtomFamily, visualizationTranslationXAtomFamily,
} from "../../../../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import * as d3 from "d3";
import FACET from "../../../../../../../../dataProvider/vissights/utility/facet-extraction-utility";
import {
    squareFillColorHovered, squareStrokeColorHovered,
    standardFillColorForSquares,
    standardStrokeColorForSquares
} from "../../../../../../../../styles/colors";

function TreeMapBarChart(props) {
    const componentId = props.id;
    const ref = useRef()
    const containerWidth = props.width;
    const containerHeight = props.height;
    const toggleFacetFilter = props.toggleFacetFilter;
    const data = props.data;
    // const prevData = props.prevData;
    const [pinId, setPinId] = useRecoilState(visualizationPinAtomFamily(componentId));
    const resetPinId = useResetRecoilState(visualizationPinAtomFamily(componentId))
    const [scale, setNewScale] = useRecoilState(visualizationScaleAtomFamily(componentId));
    const resetScale = useResetRecoilState(visualizationScaleAtomFamily(componentId));
    const [translationX, setTranslationX] = useRecoilState(visualizationTranslationXAtomFamily(componentId));
    const resetTranslationX = useResetRecoilState(visualizationTranslationXAtomFamily(componentId));
    const [mouseOverId, setMouseOverId] = useState("-1");
    const minWidthtext = 80;
    const minWidthAmountText = 40;
    let zoom;

    const options = {
        key: 'amount',
        country: null
    }

    useEffect(() => {
        removeChart();
        drawChart();
    }, [props.data, props.width, props.height, mouseOverId, pinId]);

    useEffect(() => {
       // if (data.length > 1) {
            if (!props.displayRightNav) {
                props.toggleRightControls()
            }
       // }
    }, [data])


    const handleZoom = (event) => {
        const newTranslationX = event.x;
        const newScale = event.k;
        setTranslationX(newTranslationX);
        setNewScale(newScale);
    }

    const bindZoom = (zoom) => {
        d3.select(ref.current)
            .call(zoom)
            .on('dblclick.zoom', null);
    }

    useEffect(() => {
        zoom = d3.zoom().scaleExtent([1, 5])
            .translateExtent([[0, 0], [containerWidth, 0]])
            .extent([[0, 0], [containerWidth, containerHeight]])
            .on('zoom',
                () => {
                    handleZoom(d3.event.transform);
                }
            )
        bindZoom(zoom);
        d3.select(ref.current)
            .select('.mainGroup')
            .attr('transform', `translate(${translationX},0)`);
        removeChart();
        drawChart();
    }, [containerWidth, containerHeight, translationX]);


    const pinElementById = (d) => {
        //console.log("new pin")
        if (d.data.id === pinId) {
            setPinId('-1')
        } else {
            setPinId(d.data.id);
        }
    }

    const drawChart = () => {
        const svg = d3.select(ref.current);
        svg
            .attr('width', containerWidth)
            .attr('height', containerHeight);

        const mainGroup = svg.select('.mainGroup');

        // set the dimensions and margins of the graph
        const width = containerWidth,
            height = containerHeight;

        const root = d3.hierarchy(data);//.sum((d) => { console.log( d[data.key]); return d[data.key]})

        const yearData = root.children;

        yearData.sort((a, b) => a.data.year - b.data.year);

        const x0 = d3.scaleBand()
            .domain(yearData.map((d) => d.data.year).sort())
            .range([10, (width - 10) * scale])
            .padding(0.1);

        // instead of static type declaration we get the types from the data directly.
        const typesOfTopics = data.children[0].children.map((d) => d.type);

        const x1 = d3.scaleBand()
            .domain(typesOfTopics)
            .rangeRound([0, x0.bandwidth()])
            .paddingInner(0)

        const x0Axis = d3.axisBottom()
            .scale(x0)
            .tickSize(0);

        const x1Axis = d3.axisBottom()
            .scale(x1)
            .tickPadding(5);

        mainGroup.append('g')
            .attr('class', 'x0 axis')
            .attr('transform', 'translate(0,' + (height - 20) + ')')
            .call(x0Axis)

        /*
          dimensions.forEach((d) => {
            y[d] = d3.scaleLinear()
                .domain(d3.extent(chartData, (p) => +p[d]))
                .range([containerHeight - 20, 0])
        });
         */

        const y = d3.scaleLinear()
            .domain([0, d3.max(yearData, (d) => d3.max(d.children,
                (e) => e.value)) * scale
            ])
            // .domain([0, d3.max((data.children), (d) => d.amount)])
            // .nice()
            // .nice()d3.extent(currData, (e) => e.x);
            //.domain(d3.extent(yearData.map((d) => d.children[0].children.map((e) =>{ console.log(e.data.amount); return e.data.amount} )))) //, (d) => { console.log(d.children[0].children); return d.children[0].children.forEach((e) => e.value) } )) //;, (e) => e.value))
            .range([height - 20, 10])

        const yAxis = d3.axisLeft()
            //.tickSize(-width)
            //.tickFormat(tickFormat)
            // .tickSize(1)
            .tickPadding(4)
            .scale(y.copy().range([height - 10, 20]));

        // const gy = mainGroup.append('g')
        //     .attr('class', 'y axis')
        //     .attr('transform', 'translate(' + 30 + ',' + 0 + ')')
        //     .call(yAxis)

        const years = mainGroup.selectAll('.year')
            .data(yearData, (d) => d.data.year)
            .enter()
            .append('g')
            .attr('class', 'year')
            .attr('transform', (d) => 'translate(' + x0(d.data.year) + ',0)')


        years.append('g')
            .attr('class', 'x1 axis')
            //.attr('transform', 'translate(0,' + height + ')')
            .attr('transform', 'translate(0,' + (height - 20) + ')')
            .call(x1Axis)

        /*
        years
            .append('text')
            .text((d) => d.data.amount + "Publications in " + d.data.year)
            .attr('transform', (d) => 'translate(50,20)')
*/

        function sum(d) {
            return d.amount;
            //  return !options.country || options.country === d.country ? d[options.key] : 0
        }

        update();

        function update() {
            root.sum(sum);

            const t = d3.transition();


            const typeData = d3.merge(yearData.map((d) => d.children));

            // console.log("typeData", typeData)
            // y.domain([0,10000]).nice();

            // the domain must be way higher than the max value. I dont know why this behaves like this.
            y.domain([0, d3.max(typeData.map((d) => d.value)) * 10]).nice();

            // y.domain([0, d3.max(typeData.map((d) => {console.log(d); return d.value}))]).nice();
            // y.domain(d3.extent(typeData.map((d) => {console.log(d.children.map((e) => e.value)); return d.children.map((e) => e.value)})))// d.children[0].children.map((e) =>{ console.log(e.data.amount); return e.data.amount} ))))


            // We use a copied Y scale to invert the range for display purposes
            // yAxis.scale(y.copy().range([height - 20, 10]))
            // gy
            //     //.transition(t)
            //     .call(yAxis)

            let types = years.selectAll('.type')
                .data((d) => d.children,
                    (d) => d.data.type)
                .each((d) => {
                    // UPDATE
                    // The copied branches are orphaned from the larger hierarchy, and must be
                    // updated separately (see note at L152).
                    // d.treemapRoot.sum(sum);
                   // d.treemapRoot.children.forEach((d) => d.sort((a, b) => b.value - a.value));
                });


            types = types
                .enter()
                .append('g')
                .attr('class', 'type')
                .attr('transform', (d) => 'translate(' + x1(d.data.type) + ',' + height + ')')
                .each((d) => {
                    // ENTER
                    // TODO: Check if we should sort our data too
                    // Note that we can use .each on selections as a way to perform operations
                    // at a given depth of the hierarchy tree.

                    // d.children.forEach((d)  => d.sort((a, b) =>  b.value - a.value))
                   d.treemap = d3.treemap().tile(d3.treemapResquarify); // (d3.treemapBinary)
                    // The treemap layout must be given a root node, so we make a copy of our
                    // child node, which creates a new tree from the branch.
                   // console.log(" d before treemapRoot ", d)

                   d.treemapRoot = d.copy();

                })
                .merge(types)
                .each((d) => {
                    //console.log(d.treemapRoot)
                    // UPDATE + ENTER
                    d.treemap.size([x1.bandwidth(), y(d.value)])(d.treemapRoot)
                })


            // d3.hierarchy gives us a convenient way to access the parent datum. This line
            // adds an index property to each node that we'll use for the transition delay.
            root.each((d) => d.index = d.parent ? d.parent.children.indexOf(d) : 0)

            types
                // .transition(t)
                // .delay((d, i) => d.parent.index * 150 + i * 50)
                .attr('transform', (d) => 'translate(' + x1(d.data.type) + ',' + (height - y(d.value)) + ')')

            types
                .append('text')
                .text((d) => d.parent.data.amount + " Publications") // in " + d.parent.data.year)
                .attr('transform', (d) => 'translate(' + ((x1.bandwidth() / 2) - 40 * scale) + ',-30)')
                .style("font-weight", "bold")
                .attr('class', 'fillWhiteOnDarkMode')

            //  let topicModels = types.selectAll('.topicModel')
            //      // Note that we're using our copied branch.
            //      .data( (d) => d.treemapRoot.children,
            //          (d) => d.data.name)
//
            //  topicModels = topicModels.enter().append('g')
            //      .attr('class', 'topicModel')
            //      .merge(topicModels)

            let tModels = types.selectAll('.tModel')
                .data((d) => d.treemapRoot.children);
                    //(d) => d.data.name)


            // the calculation of the opacity
            const calculateOpacity = (d) => {
                if (pinId !== undefined && pinId !== '-1') {
                    if (pinId === d.data.id) {
                        return 1;
                    } else {
                        return 0.4;
                    }
                } else {
                    const date = new Date();
                    const year = date.getFullYear();
                    if (d.data.id > (year - 1)) {
                        return 0.4;
                    } else {
                        return 1;
                    }
                }
            };


            let enterTModels = tModels
                .enter()
                .append('rect')
                .attr('class', 'tModel')
                .attr('x', (d) => d.value ? d.x0 : x1.bandwidth() / 2)
                .attr('width', (d) => d.value ? d.x1 - d.x0 : 0)
                .attr('y', 0)
                .attr('height', 0)
                .style('fill', (d) => d.data.id !== mouseOverId ? standardFillColorForSquares() : squareFillColorHovered())
                .style('stroke', (d) => d.data.id !== mouseOverId ? standardStrokeColorForSquares() : squareStrokeColorHovered())
                .style('opacity', (d) => calculateOpacity(d))
                .attr('cursor', 'pointer');

            /*
            enterCountries
            .on('mouseover', function (d) {
                svg.classed('hover-active', true)
                countries.classed('hover', function (e) {
                    return e.data.country === d.data.country
                })
            })
            .on('mouseout', function () {
                svg.classed('hover-active', false)
                countries.classed('hover', false)
            })
            .append('title')
            .text(function (d) { return d.data.country })
             */

            enterTModels
                .on('mouseover', (d) => {
                    setMouseOverId(d.data.id);
                    // svg.classed('hover-active', true)
                    // countries.classed('hover', function (e) {
                    //    return e.data.country === d.data.country
                    // })
                })
                .on('mouseout', () => {
                    setMouseOverId("-1");
                    // svg.classed('hover-active', false)
                    // countries.classed('hover', false)
                })

                .on('click', (d) => {pinElementById(d)})
                .on('dblclick', (d) => {toggleFacetFilter([{facet: FACET.TOPIC, value: d.data.id}, {facet: FACET.YEAR, value: d.data.year}])})
                // toggleFacetFilter(d.data.id, d.data.year))
                .append('title')
                .text((d) => d.data.name + ': ' + d.data.amount);

            // shortens text
            const shortenSliceText = (name, width, amount) => {
                let shortText = name;
                const length = name.length;
                if (length > width / 13) {
                    shortText = shortText.slice(0, width / 13) + '...' + '(' + amount + ')';
                    // shortText = shortText.slice(0, shortText.lastIndexOf(' ')) + '...' + '(' + amount + ')';
                    //shortText = shortText.slice(0, shortText.lastIndexOf(' ')) + '...';
                } else {
                    shortText = shortText +  ' (' + amount + ')';
                }
                return shortText;
            }

            tModels
                .enter()
                .append('text')
                .attr('x', (d) => d.value ? d.x0 + 2 : (x1.bandwidth() / 2) + 2)
                .attr('y', (d) => d.y0 + (((d.y1 - d.y0) / 4)))
                .text((d) => (d.x1 - d.x0) > minWidthtext ? shortenSliceText(d.data.name, (d.x1 - d.x0), d.data.amount) : '')
                .attr('cursor', 'pointer')
                .on('click', (d) => {pinElementById(d)})
                .on('dblclick', (d) => {toggleFacetFilter([{facet: FACET.TOPIC, value: d.data.id}, {facet: FACET.YEAR, value: d.data.year}])});

            // amount text
            // tModels
            //     .enter()
            //     .append('text')
            //     .attr('x', (d) => d.value ? d.x0 : x1.bandwidth() / 2)
            //     .attr('y', (d) => d.y0 + ((d.y1 - d.y0) / 2))
            //     .text((d) => (d.x1 - d.x0) > minWidthAmountText ? shortenSliceText(d.data.amount, (d.x1 - d.x0)) : '')
            //     .attr('cursor', 'pointer')
            //     .on('click', (d) => {pinElementById(d)})
            //     .on('dblclick', (d) => {toggleFacetFilter([{facet: FACET.TOPIC, value: d.data.id}, {facet: FACET.YEAR, value: d.data.year}])});




            /*
            const text = d3.select(ref.current)
                .select('.mainGroup')
                .select('.texts')
                .selectAll('text')
                .data(yearData, (d) => d.id);

            text.enter()
                .append('text')
                .attr('class', 'whiteOnDarkMode')
                .attr('cursor', 'pointer')
                .attr('text-anchor', 'middle')
                .attr('dy', '.3em')
                .attr('opacity', 0)
                .on('click', (d) => pinElementById(d.id));

            text.text((d) => showText ? shortenSliceText(d.name, (xSize - 12)) : '')
                .on('mouseover', onMouseOver)
                .on('mouseout', onMouseOut)
                .attr('x', (d) => scales.x(d.year) + 0.5 * xSize)
                .transition()
                .attr('opacity', opacity)
                .attr('transform', 'translate(0,0)')
                .attr('y', (d) => scales.y(d.pos) + 0.5 * ySize);

            text.exit().transition()
                .attr('opacity', 0)
                .remove();
             */

            tModels = tModels.merge(enterTModels);

            tModels
                //.transition(t)
                .attr('x', (d) => d.value ? d.x0 : x1.bandwidth() / 2)
                .attr('width', (d) => d.value ? d.x1 - d.x0 : 0)
                .attr('y', (d) => (d.value ? d.y0 : d.parent.parent.y1 / 2) - 20)
                .attr('height', (d) => d.value ? d.y1 - d.y0 : 0);
        }
        update();
        /*

        const topics = d3.selectAll('g .year').selectAll('.topics')
            // Note that we're using our copied branch.
            .data( (d) => d.data.children[0],
                 (d) => d.name);


        const enterCountries = topics
            .enter()
            .append('rect')
            .attr('class', 'topics')
            // .attr('x',  (d) => d.x0)//d.value ? d.x0 : x1.bandwidth() / 2)
            .attr('x', (d, i) => x0(i))
            .attr('width',  (d) => 20)// d.value ? d.x1 - d.x0 : 0)
            .attr('y', 0)
            .attr('height', 0)
            //.style('fill', function (d) { return color(d.parent.data.continent) })


         */
        // countries = countries.merge(enterCountries)
        // currently not used. We could use xyAxis scale for LDA vs CTM
        // years.append('g')
        //     .attr('class', 'x0 axis')
        //     .attr('transform', 'translate(0,' + height + ')')
        //     //.call(x0Axis)
    }

    const removeChart = () => {
        const svg = d3.select(ref.current);
        const mainGroup = svg
            .select('.mainGroup');
        mainGroup
            .selectAll('g')
            .remove();
    }

    return (
        <svg ref={ref}>
            <g className="mainGroup">
            </g>
        </svg>
    );

}

export default TreeMapBarChart;


