import React, {useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {overviewDataAtom, searchHistoryDataAtom} from '../../../dataProvider/vissights/overview/overview';
import CustomGrid from "../../Dashboards/GridLayout/GridLayout";
import RightNavigation from "../../GlobalNavigations/RightNavigation/RightNavigation";
import {dashboardWidthAtom, rightSideNavWidthAtom} from "../../../config/sizings/sizings";
import {moduleToDisplayAtom} from "../../Dashboards/DashboardLayout";

function OverviewDashboard() {
    const rightSideNavigationWidth = useRecoilValue(rightSideNavWidthAtom);
    const dashboardWidth = useRecoilValue(dashboardWidthAtom);
    // as the data we need for this dashboard should be loaded when the module is loaded we call useRecoil value here.
    useRecoilValue(searchHistoryDataAtom);
    useRecoilValue(overviewDataAtom);
    const setModuleToDisplay = useSetRecoilState(moduleToDisplayAtom);

    useEffect(() => {
        setModuleToDisplay('overview');
    })

    const Dashboard = () => {
        return (
            <div><CustomGrid/></div>
        );
    }
    return (
        <div className="OverviewDashboard" style={{display: "flex"}}>
            <div style={{width: dashboardWidth}}>
                <Dashboard/>
            </div>
            <div style={{ width: rightSideNavigationWidth}}>
                <RightNavigation/>
            </div>
        </div>
    );
}

export default OverviewDashboard;

