import React from 'react';

import {Badge} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faX} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState, useResetRecoilState} from "recoil";
import {manualSliderControlAtomFamily, sliderValuesAtomFamily} from "../../LeftNavigation/LeftNavigationState";


function YearSliderFilter(props) {
    const resetManualSliderChange = useResetRecoilState(manualSliderControlAtomFamily(props.filterSetId))
    const [sliderValues, setSliderValues] = useRecoilState(sliderValuesAtomFamily(props.filterSetId));
    const resetSliderValues = useResetRecoilState(sliderValuesAtomFamily(props.filterSetId))

    // const correctSliderValues = useRecoilValue()

    const deactivateElementFromFilterSet = () => {
        resetManualSliderChange();
        resetSliderValues();

    }


    const SliderValues = () => {
        const filter = props.filter;
        return (
            <>
                <Badge variant="primary" key={'sliderValus'}
                       style={{marginRight: 5, cursor: 'pointer'}}
                >
                    {filter.sliderValues[0]} - {filter.sliderValues[1]}
                </Badge>
                <FontAwesomeIcon icon={faX} style={{height: 12, marginTop: 3, marginLeft: 2, cursor: "pointer"}}
                                 onClick={() => {
                                     deactivateElementFromFilterSet(); // pass the facet, the id of filter
                                 }}/>
            </>

        )
    }


    return (
        <SliderValues/>
    );
}

export default YearSliderFilter;
