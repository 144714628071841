import {atomFamily, selectorFamily} from "recoil";
import {
    amountRawDataSelector
} from "../../../../../../dataProvider/vissights/search/search";
import * as _ from "lodash";
import * as d3 from 'd3';
import {
    maxRowsAtomFamily,
    normalizeAtomFamily
} from "../../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import {activeFacetAtomFamily} from "../../../../overview/visualizations/state";
import {preProcessedFilteredSearchDataSelector} from "../../../../../../dataProvider/vissights/search/filters/filters";

export const slicedChartDataAtomFamily = atomFamily({
    key: "slicedChartDataAtomFamily-Key",
    default: selectorFamily({
        key: 'slicedChartDataSelectorFamily-test/Default',
        get: id => () => {
            return slicedChartDataSelectorFamily(id);
        },
    }),
});


export const slicedChartDataSelectorFamily = selectorFamily({
    key: 'slicedChartDataSelectorFamily-Key',
    get: id =>  ({get}) => {
        const dataModel = get(preProcessedFilteredSearchDataSelector);
        const searchAnswerLength = get(amountRawDataSelector);
        const normalization = get(normalizeAtomFamily(id));
        const maxRows = get(maxRowsAtomFamily(id));
        const facet = get(activeFacetAtomFamily(id))

        const data = dataModel[facet];
        let lookupClone = _.cloneDeep(data.lookup);
        const dataToPreprocess = _.cloneDeep(data);
        dataToPreprocess.lookup = lookupClone;
       // dataToPreprocess.map((d) => {
            // dataToPreprocess.lookup[parseInt(d.name,10)]['signal'] = calculateSignalStrength(d, searchAnswerLength)
        //    dataToPreprocess.lookup[parseInt(d.name,10)]['signal'] = calculateSignalStrength(d, searchAnswerLength)
       //     return d;
      //  })

        let preprocessedDataForChart = [];
        const rows = maxRows;
        const normalize = normalization;
        const date = new Date();
        const yearNow = date.getFullYear();

        const years = {};
        dataToPreprocess.forEach((f) => {
            // const amountFive = 0;
            for (const year in f.years) {
                if (!(parseInt(year, 10) > (yearNow - 1) )) {
                    if (!f.years.hasOwnProperty(year)) continue;
                    let yearData = years[year];
                    if (yearData === undefined) {
                        yearData = [];
                        years[year] = yearData;
                    }
                    yearData.push({
                        year: parseInt(year, 10),
                        id: f.id,
                        name: f.name,
                        count: f.years[year],
                    });
                }
            }
        });


        // -> object list
        let list = [];

        for (const year in years) {
            if (!years.hasOwnProperty(year)) continue;
            let yearData = years[year];

            // sort lists for each year to figure out ranking position
            yearData.sort((a, b) => {
                return a.count !== b.count ? b.count - a.count : a.name.localeCompare(b.name);
            });
            yearData = yearData.slice(0, rows); // limit to rows
            yearData.forEach((d, i) => {
                d.pos = i;
                d.key = `${d.year}-${i}`;
            });

            if (normalize) {
                const maxCount = d3.max(yearData, (e) => {
                    return e.count;
                });
                yearData.forEach((d) => {
                    d.value = d.count / maxCount;
                });
            }

            list = list.concat(yearData);
        }
        if (!normalize) {
            const totalMaxCount = d3.max(list, (e) => {
                return e.count;
            });
            list.forEach((d) => {
                d.value = d.count / totalMaxCount;
            });
        }


        return list;
    },
});

