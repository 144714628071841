import React from "react";
import PoiChartTopControls from "./controls/topControls";
import PoiChart from "./PoiChart";
import {useRecoilState, useRecoilValue, useRecoilValueLoadable} from "recoil";
import {
    poiAtomFamily,
    poiWithDataSelectorFamily
} from "../../../../GlobalNavigations/LeftNavigation/LeftNavigationState";
import {activeFilterSetIdAtom} from "../../../../../dataProvider/vissights/search/filters/filters";
import {forceRerenderAtomFamily, poiChartDataSelectorFamily, subTreePoiChartAtomFamily} from "./state/poi-chart-state";


function PoiChartContainer(props) {
    const componentId = props.id;
    const setModuleToDisplay = props.setModuleToDisplay;
    const setSearchTerm = props.setSearchTerm;
    const activeFilterSetId = useRecoilValue(activeFilterSetIdAtom);
    const poiData = useRecoilValueLoadable(poiWithDataSelectorFamily(activeFilterSetId));
    const data = useRecoilValue(poiChartDataSelectorFamily(componentId));
    const [poiList, setPoiList] = useRecoilState(poiAtomFamily(activeFilterSetId));
    const [subTree, setSubTree] = useRecoilState(subTreePoiChartAtomFamily(componentId));
    const [forceRerender, setForceRerender] = useRecoilState(forceRerenderAtomFamily(componentId));
    // const activeAggregation = props.topControls[0].activeAggregation;
    // const chartData = useRecoilValue(temporalCloudChartDataAtom)
    const calculatedData = [];
    // stuff we pass to top controls
    const topControlConfig = props.topControls;
    const deleteContainer = props.deleteContainer;
    return (
        <div>
            <PoiChartTopControls topControlConfig={topControlConfig}
                                 deleteContainer={deleteContainer}
                                 id={props.id}/>
            <div style={{display: "flex"}}>
                <div style={{width: props.containerDimensions[0]}}>
                    <PoiChart calculatedData={calculatedData}
                              poiData={poiData.contents}
                              data={data}
                              subTree={subTree}
                              setSubTree={setSubTree}
                              forceRerender={forceRerender}
                              setForceRerender={setForceRerender}
                              poiList={poiList}
                              setPoiList={setPoiList}
                              width={props.containerDimensions[0]}
                              height={props.containerDimensions[1]}
                              setModuleToDisplay={setModuleToDisplay}
                              setSearchTerm={setSearchTerm}
                    />
                </div>
            </div>
        </div>
    );
}

export default PoiChartContainer;
