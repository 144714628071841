import {Button, Col, Row} from "react-bootstrap";
import React from "react";


function PoiChartTopControls(props) {
    const ContainerTopControls = () => {
        return (
            <Row>
                <Col xs={12}>
                    <div className="grid-item-container-top-control">
                        <Button type="button" className="float-right" variant="light" value="Search" onClick={(e) => {
                            e.preventDefault();
                            props.deleteContainer(props.id);
                        }}>X</Button>
                    </div>
                </Col>
            </Row>
        );
    }
    return (
        <ContainerTopControls/>
    );
}
export default PoiChartTopControls;