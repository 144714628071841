import React, {useEffect, useRef} from "react";
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {searchTermAtom} from "../../../../../dataProvider/vissights/search/search";
import {
    forceRerenderAtomFamily,
    latestRemovedPoiAtomFamily,
    subTreePoiChartAtomFamily
} from "./state/poi-chart-state";
import {
    mainCircleColorHue,
    poiAddButtonFillColor, poiAddButtonStrokeColor,
    poiFillColor,
    poiStrokeAndTextColor
} from "../../../../../styles/colors";
import * as d3 from 'd3';
import CreateNewPoiModal, {displayCreatePoiModalAtom} from "./modals/CreateNewPoiModal";
import * as _ from "lodash";
import {visualizationItemsAtom} from "../../../../Dashboards/GridLayout/state/grid-state";
import uuid from 'react-uuid';
import {configZoomAndFilter} from '../../../../../config/visualizations';
import DATA from "../../../../../dataProvider/vissights/search/filters/data";
import {
    criticalAmount,
    injectedPublicationList,
    injectedTemporalOverview
} from "../../../../../config/visualizations";

function PoiChart(props) {
    const componentId = props.id;
    const ref = useRef()
    const containerWidth = props.width;
    const containerHeight = props.height;
    const results = props.data;
    const poiData = props.poiData;
    // console.log('gere')
    const poiList = props.poiList;
    const setPoiList = props.setPoiList;
    const currentSearchTerm = useRecoilValue(searchTermAtom);
    const [subTree, setSubTree] = useRecoilState(subTreePoiChartAtomFamily(componentId));
    const [forceRerender, setForceRerender] = useRecoilState(forceRerenderAtomFamily(componentId));
    const resetSubTree = useResetRecoilState(subTreePoiChartAtomFamily(componentId));
    // const [forceRerender, setForceRerender] = useRecoilState(forceRerenderAtomFamily(componentId));
    // const forceRerender = props.forceRerender;
    // const setForceRerender = props.setForceRerender;
    const [displayModal, setDisplayModal] = useRecoilState(displayCreatePoiModalAtom);
    const [latestRemovedPoi, setLatestRemovedPoi] = useRecoilState(latestRemovedPoiAtomFamily(componentId));
    // graphical search
    const [visItems, setItems] = useRecoilState(visualizationItemsAtom);
    // stores the complete Tree for the mainCircle
    let mainTree = [];
    // chart sizings
    let poiChartWidth;
    let poiChartHeight;
    let legendWidth;
    let legendHeight;
    // Everything we need for the big circle
    const mainCircleColor = mainCircleColorHue();
    // Everything we need for the smaller circles of the right legend
    let smallCircleRadius = 0;
    let smallCircleTransformation = [];
    let smallCircleTextXY = [];
    let legendTransformationXY = [];
    let addButtonTransformationXY = [];
    // The delete button transformation consists of 2 parts to a helper transformation was implemented
    let smallCircleDeleteXY = [];
    let helperTransform = [];
    // drag circle from legend to chart - transformation
    let dragCircleTransformation = [];
    // drag circle from chart to chart - transformation
    let dragCircleInsideChartTransformation = [];
    let poiFontSize = 0;
    let mainCircleFontSize = 0;
    // Drag & Drop
    const dropDegrees = [30, 150, 270];
    let dragging = false;
    legendWidth = 200;
    legendHeight = containerHeight;
    poiChartWidth = containerWidth - legendWidth;
    poiChartHeight = containerHeight;
    // No negative radius!
    smallCircleRadius = Math.abs(legendHeight / 9 - 10);
    legendTransformationXY = [containerWidth - legendWidth, (smallCircleRadius + 5)];
    // equals diameter + 10
    smallCircleTransformation = [legendWidth / 2, 2 * smallCircleRadius + 10];
    smallCircleTextXY = [smallCircleTransformation[0], smallCircleTransformation[1]];
    addButtonTransformationXY = [(legendWidth / 2), (poiData.length * (smallCircleRadius * 2)) + 10];
    helperTransform = [smallCircleRadius, -(smallCircleRadius * (2 / 3))];
    smallCircleDeleteXY = [(smallCircleTransformation[0]), smallCircleTransformation[1]];
    // dragCircleTransformation = [(-poiChartWidth) - viewLeftTopTranslation[0], -(smallCircleRadius * 1.5) - viewLeftTopTranslation[1]];
    // dragCircleInsideChartTransformation = [viewLeftTopTranslation[0], viewLeftTopTranslation[1]];
    poiFontSize = smallCircleRadius / 2;
    mainCircleFontSize = poiChartHeight / 10;


    useEffect(() => {
        if (forceRerender === true) {
            removeChart();
            calculateNodes();
            removeLegend();
            drawLegend();
            drawChart();
            setForceRerender(false);
        }
    }, [subTree, forceRerender])


    useEffect(() => {
        removeChart();
        calculateNodes();
        removeLegend();
        drawLegend();
        drawChart();
    }, [props.poiList, props.poiData])


    useEffect(() => {
        drawLegend();
        // removeChart();
        calculateNodes();
        drawChart();
    }, [containerWidth, containerHeight])


    useEffect(() => {
        recalculateCurrentTree(subTree, results);
        calculateNodes();
        resetSubTree();
        setSubTree(subTree);
    }, [currentSearchTerm, results])


    useEffect(() => {
        removeNoLongerExistingNodesFromTree(subTree, latestRemovedPoi)
    }, [latestRemovedPoi])


    useEffect(() => {
        drawChart();
        drawLegend();
        drawChart();
    })

    const rightClick = (d) => {
        const newUUID = uuid();
        // setGraphicalSearchData(d.tree.data);
        const clonedInfos = _.cloneDeep(d);
        setItems((item) => [
            ...item,
            {
                id: newUUID,
                visType: configZoomAndFilter[0].visType,
                visComponent: configZoomAndFilter[0].visComponent,
                topControls: configZoomAndFilter[0].topControls,
                usesRightNav: configZoomAndFilter[0].usesRightNav,
                module: configZoomAndFilter[0].module,
                dashboardConfig: configZoomAndFilter[0].dashboardConfig,
                graphicalSearchInfos: clonedInfos
            }
        ]);
    }

    const doubleClick = (d) => {
        const handleActivate = (tree) => {
            // we get the names of all pois involved.
            if (!tree.poi) return;
            const poiFilter = [];
            let t = tree;
            while (t.poi) {
                poiFilter.push(t.poi.name);
                t = t.parent;
            }
            // we clone all pois
            const poiDataClone = _.cloneDeep(poiData);
            // activate the pois that are included.
            poiDataClone.map((d) => poiFilter.includes(d.name) ? d.active = true : false)
            setPoiList(poiDataClone);
        }
        // call the above defined method to activate all pois involved.
        handleActivate(d.tree);
        // dependent of the amount we inject different charts to the layout.
        const publicationListAlready = visItems.filter((d) => d.visType === 'Publication List').length > 0;
        const temporalOverviewAlready = visItems.filter((d) => d.visType === 'Temporal Overview').length > 0;
        if (d.text <= criticalAmount) {
            if (!publicationListAlready) {
                setItems((item) => [
                    ...item,
                    injectedPublicationList
                ]);
            }
        } else {
            if(!publicationListAlready && !temporalOverviewAlready){
                setItems((item) => [
                    ...item,
                    injectedPublicationList,
                    injectedTemporalOverview
                ]);
            }else if(!publicationListAlready){
                setItems((item) => [
                    ...item,
                    injectedPublicationList
                ]);
            } else if(!temporalOverviewAlready){
                setItems((item) => [
                    ...item,
                    injectedTemporalOverview
                ]);
            }
        }
    }


    // when filter changes the amounts of each node must be recalculated recursively
    const recalculateCurrentTree = (tree, data) => {
        for (let i = tree.length - 1; i >= 0; i--) {
            const _applyFilterPOI = (data, poi) => {
                // no data? return!
                if (!poi.data) return data;
                // if we have data we apply the poi filters.
                return data.filter((entry) => poi.data.find((e) => e === DATA.Key(entry)));
            }
            tree[i].data = _applyFilterPOI(data, tree[i].poi);
            recalculateCurrentTree(tree[i].children, tree[i].data);
        }
    }


    // generate the tree structure
    const calculateNodes = () => {
        // add main node
        const root = {
            text: currentSearchTerm,
            id: 'root',
            color: mainCircleColor,
            tree: {
                data: results,
                children: subTree
            },
            x: 0.5,
            y: 0.5,
            r: 0.5
        };

        mainTree = [root];
        // recursively calculate the inner nodes
        calculateInnerNodesWrapper(root, subTree, mainTree);
        // return nodes;
    }


    // We use a wrapper function to provide async behavior. We want the recursive calculateInnerNodes
    // function to be finished before we store the tree. (We want to store the final tree!)
    const calculateInnerNodesWrapper = (parent, tree, mainTree) => {
        calculateInnerNodes(parent, tree, mainTree);
        // //console.log("call action and redraw chart");
        // console.log('set SubTree', subTree)
        setSubTree([...subTree]);
        setForceRerender(true);
    }

    // calculated recursively the inner nodes of the main circle
    const calculateInnerNodes = (parent, tree, mainTree) => {
        tree.forEach((t, i) => {
            const degree = t.degree;
            const x = parent.x + (parent.r * 0.55 * Math.cos(degree * Math.PI / 180));
            const y = parent.y + (parent.r * 0.55 * Math.sin(degree * Math.PI / 180));
            const r = parent.r * 0.4;
            const poi = t.poi;
            const n = {
                parent,
                text: t.data.length,
                name: poi.name,
                id: `${parent.id}-${i}`,
                color: poi.color,
                tree: t,
                x,
                y,
                r
            };
            mainTree.push(n);
            calculateInnerNodes(n, t.children, mainTree);
        });
    }

    // creates a new tree inside its parent
    const newTree = (parentTree, poi, dropDegree) => {
        if (parentTree.children.length >= 3) {
            return false;
        }
        // filter point
        const tempData = parentTree.data;

        const _applyFilterPOI = (data, poi) => {
            // no data? return!
            if (!poi.data) return data;
            // if we have data we apply the poi filters.
            return data.filter((entry) => poi.data.find((e) => e === DATA.Key(entry)));
        }

        const filteredData = _applyFilterPOI(tempData, poi);

        // find best insert degree:
        let bestInsertDegree = -1;
        dropDegrees.forEach((d) => {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < parentTree.children.length; i++) {
                if (parentTree.children[i].degree === d) {
                    return;
                }
            }
            let distanceDrop = Math.abs(dropDegree - d);
            if (distanceDrop > 180) distanceDrop = 360 - distanceDrop;
            let distanceCurr = Math.abs(dropDegree - bestInsertDegree);
            if (distanceCurr > 180) distanceCurr = 360 - distanceCurr;
            if (bestInsertDegree === -1 || distanceDrop < distanceCurr) {
                bestInsertDegree = d;
            }
        });

        parentTree.children.push({
            parent: parentTree,
            degree: bestInsertDegree,
            data: filteredData,
            poi,
            children: []
        });
        calculateNodes();
        //  calculateInnerNodesWrapper(parentTree, mainTree, )
    }

    // gets called when we drop a poi somewhere. Returns null if not dropped into the chart. Otherwise it calculates the parent and calls newTree function
    const dropPOI = (poi, x, y) => {
        // console.log(poi)
        // find 'parent'
        let parent = null;

        for (let i = mainTree.length - 1; i >= 0; i--) {
            const n = mainTree[i];
            const dX = n.x - x;
            const dY = n.y - y;

            if (dX * dX + dY * dY <= n.r * n.r) {
                parent = n;
                break;
            }
        }
        if (parent === null) return false;
        let dropDegree = 90 + Math.atan2(parent.x - x, y - parent.y) / Math.PI * 180;
        if (dropDegree < 0) {
            dropDegree += 360;
        }
        return newTree(parent.tree, poi, dropDegree);
    }

    // remove a tree from the chart
    const removeTree = (parentTree, tree) => {
        const idx = parentTree.children.indexOf(tree);
        if (idx === -1) return;
        parentTree.children.splice(idx, 1);
        calculateNodes();
    }

    // private function that triggers an action to the state.
    const removePoi = (data) => {
        removeNoLongerExistingNodesFromTree(subTree, data.name);
        const clone = _.cloneDeep(poiList);
        const newPoiList = clone.filter((e) => e.id !== data.id);
        setLatestRemovedPoi(data.name)
        setPoiList(newPoiList);
        calculateNodes();
    }

    // If we delete a point of interest in the legend. We have to search for every entry by its uuid and remove all with the delete uuid.
    const removeNoLongerExistingNodesFromTree = (children, name) => {
        for (let i = children.length - 1; i >= 0; i--) {
            if ((children[i].poi.name) === name) {
                children.splice(i, 1);
            } else {
                removeNoLongerExistingNodesFromTree(children[i].children, name);
            }
        }
        setSubTree([...subTree])
    }

    // scales radius x and y based on chart size.
    const scaleXYR = () => {
        const w = poiChartWidth //!== undefined ? poiChartWidth : containerWidth;
        const h = poiChartHeight //!== undefined ? poiChartHeight : containerHeight;

        const widthBottleNeck = w < h;
        let l = Math.abs((widthBottleNeck ? w : h));
        // prevent negative cases!
        if (l <= 0) {
            l = 0;
        }
        l = l - 2;
        return {
            r: d3.scaleLinear()
                .domain([0, 1])
                .range([0, l]),
            x: d3.scaleLinear()
                .domain([0, 1])
                .range([(0.5 * (w - l)), 0.5 * (w - l) + l]),
            y: d3.scaleLinear()
                .domain([0, 1])
                .range([0.5 * (h - l), 0.5 * (h - l) + l]),
        };

    }

    // draws the legend. The legend is the right/ bottom part of the chart
    const drawLegend = () => {
        // definitions
        const scales = scaleXYR();

        const svg = d3.select(ref.current);

        // the wrapping group of the legend. (right-part of the chart.)
        const legendGroup = svg.select('.legend');

        legendGroup
            .attr('width', legendWidth)
            .attr('height', legendHeight);

        // the drag element that appears when dragging a poi element inside the legend.
        const dragNodeGroup = svg
            .select('.poiDragNode');

        // console.log(poiData)

        // positioning + width / height of the legend itself
        legendGroup
            .attr('width', legendWidth)
            .attr('height', legendHeight)
            .attr('transform', 'translate(' + (legendTransformationXY[0]) + ',' + (legendTransformationXY[1]) + ')')

        // description of the drag behavior --> gets called later
        const drag = d3.drag()
            .on('start', (poiDragged) => {
                // console.log('drag start')
                // position of the mouse
                const mouseXY = d3.mouse(ref.current);
                // ensure that no longer used drag circles are removed
                dragNodeGroup
                    .selectAll('circle')
                    .remove();
                // append the circle that gets displayed
                dragNodeGroup.append('circle')
                    .attr('cursor', 'grabbing')
                    .attr('fill', poiFillColor(poiDragged.color))
                    .attr('stroke', poiStrokeAndTextColor(poiDragged.color))
                    .attr('r', smallCircleRadius / 2)
                    .attr('cx', mouseXY[0])
                    .attr('cy', mouseXY[1]);
            })
            .on('drag', () => {
                // position of the mouse
                const mouseXY = d3.mouse(ref.current);
                // const sourceEvent = d3.event.sourceEvent;
                dragNodeGroup
                    .selectAll('circle')
                    .attr('cursor', 'grabbing')
                    .attr('cx', mouseXY[0])
                    .attr('cy', mouseXY[1]);
            })
            .on('end', (d) => {
                const dragCircle = dragNodeGroup.selectAll('circle');
                // position of the mouse
                const mouseXY = d3.mouse(ref.current);
                dropPOI(d, scales.x.invert(mouseXY[0]), scales.y.invert(mouseXY[1]));
                dragCircle
                    .transition()
                    .duration(200)
                    .attr('r', 0)
                    .remove();
            });

        // pois group mit daten füttern
        const poiNodes = legendGroup
            .selectAll('g')
            .data(poiData)
            .enter()
            .append('g')
            .attr('class', 'poi')

        // Append the POI Circles
        poiNodes.append('circle')
            .attr('fill', (d) => poiFillColor(d.color))
            .attr('cursor', 'pointer')
            .attr('stroke', (d) => poiStrokeAndTextColor(d.color))
            .attr('r', smallCircleRadius)
            .attr('transform', (d, i) => 'translate(' + smallCircleTransformation[0] + ',' + i * smallCircleTransformation[1] + ')')
            .call(drag);

        // Append the text of POIs
        poiNodes.append('text')
            .attr('dy', '.3em')
            .attr('text-anchor', 'middle')
            .attr('transform', 'translate(' + 0 + ',' + 0 + ')')
            .attr('cursor', 'pointer')
            .attr('font-size', poiFontSize + 'px')
            .text((d) => d.name)
            .attr('fill', (d) => poiStrokeAndTextColor(d.color))
            .attr('stroke', (d) => poiStrokeAndTextColor(d.color))
            .attr('transform', (d, i) => 'translate(' + smallCircleTransformation[0] + ',' + i * smallCircleTransformation[1] + ')')
            .call(drag);

        // X - POI For deleteing POIs
        poiNodes.append('path')
            .attr('transform', (d, i) => `translate(${(smallCircleDeleteXY[0] + helperTransform[0])}, ${(i * smallCircleDeleteXY[1] + helperTransform[1])}) rotate(45)`)
            .attr('d', d3.symbol().type(d3.symbolCross).size(3 * smallCircleRadius))
            .attr('cursor', 'pointer')
            .attr('fill', (d) => poiFillColor(d.color))
            .attr('stroke', (d) => poiStrokeAndTextColor(d.color))
            .on('click', (d) => removePoi(d));
        // check whether we should display the + icon dependant of the amount of pois
        if (poiData.length === 4) {
            legendGroup.select('path').attr('d', null);
        } else {
            legendGroup.select('path')
                .attr('cursor', 'pointer')
                .attr('fill', poiAddButtonFillColor())
                .attr('stroke', poiAddButtonStrokeColor())
                .attr('transform', `translate(${addButtonTransformationXY[0]},${addButtonTransformationXY[1]})`)
                .attr('d', d3.symbol().type(d3.symbolCross).size(28 * smallCircleRadius))
                .on('click', () => setDisplayModal(true));
        }

        poiNodes.exit().remove();

    }


// draws the big main circle and its inner nodes.
    const drawChart = () => {
        const calculateFontSize = (textLength, width, test) => {
            const size = width / textLength;
            return size + 'px';
        };

        const svg = d3.select(ref.current);

        svg.attr('width', containerWidth)
            .attr('height', containerHeight);

        const chartGroup = svg.select('#chart');

        const scales = scaleXYR();
        // drag and drop behavior for elements inside main circle
        const drag = d3.drag()
            .on('start', () => {
            })
            .on('drag', (d, i, n) => {
                const node = d.id !== 'root' ? n[i] : null;
                d3.select(node)
                    .remove();
                const mouseXY = d3.mouse(ref.current);
                const dragNode = d3.select(ref.current).selectAll('.dragNodeInChart');
                if (!dragging && d.id !== 'root') {
                    dragging = true;
                    dragNode.append('circle')
                        .attr('cursor', 'grabbing')
                        .attr('fill', poiFillColor(d.color))
                        .attr('stroke', poiStrokeAndTextColor(d.color))
                        .attr('r', smallCircleRadius / 2)
                        .attr('cx', mouseXY[0])
                        .attr('cy', mouseXY[1])
                        .attr('class', 'dragEl');
                    removeTree(d.parent.tree, d.tree);
                }
                dragNode
                    .selectAll('.dragEl')
                    .attr('cx', mouseXY[0])
                    .attr('cy', mouseXY[1]);
            })
            .on('end', (node) => {
                if (node.id === 'root' || !dragging) return;
                dragging = false;
                const mouseXY = d3.mouse(ref.current);
                dropPOI(node.tree.poi, scales.x.invert(mouseXY[0]), scales.y.invert(mouseXY[1]));
                const dragNode = d3.select(ref.current)
                    .selectAll('.dragNodeInChart')
                    .selectAll('circle');
                dragNode.attr('cursor', 'grabbing');
                dragNode
                    .transition()
                    .attr('r', 0)
                    .remove();
            });

        const nodeTree = chartGroup
            .selectAll('g')
            .data(mainTree)
            .enter()
            .append('g')
            .on('mouseover', (d, i, n) => {
                if (dragging || d.tree.children.length < 3) {
                    const currentNode = n[i];
                    d3.select(currentNode)
                        .attr('cursor', 'pointer')
                        .attr('stroke-width', 2);
                }
            })
            .on('mouseout', (d, i, n) => {
                const currentNode = n[i];
                d3.select(currentNode)
                    .attr('cursor', null)
                    .attr('stroke-width', 1);
            }).attr('fill', (d) => poiStrokeAndTextColor(d.color));

        // call the above defined drag behavior for the current element.
        nodeTree.call(drag)
        // .on('dblclick', (d) => changeActiveStateLeftControlPoiFilter(d.name));

        // append the circles
        nodeTree
            .append('circle')
            .attr('class', (d) => d.id === 'root' ? 'mainCircle' : 'smallCircle')
            .attr('fill', (d) => poiFillColor(d.color))
            .attr('stroke', (d) => poiStrokeAndTextColor(d.color))
            .attr('transform', (d) => 'translate(' + scales.x(d.x) + ',' + scales.y(d.y) + ')')
            .attr('r', (d) => scales.r(d.r))
            // .on('click', () => {return;})
            .on('dblclick', (d) => doubleClick(d));

        // append the texts to the nodes
        nodeTree.append('text')
            .attr('text-anchor', 'middle')
            .attr('transform', (d) => 'translate(' + scales.x(d.x) + ',' + scales.y(d.y) + ')')
            .attr('font-weight', 'bold')
            .attr('cursor', 'pointer')
            .attr('dy', '0.3em')
            .attr('font-size', (d) => d.id === 'root' ? (scales.r(d.r) / 5) + 'px' : (scales.r(d.r) / 1.3) + 'px')
            .text((d) => d.text)
            .on('dblclick', (d) => doubleClick(d));

        // nodeTree.exit().remove();

    }

    // removes the chart with d3.remove()
    const removeChart = () => {
        d3.select(ref.current)
            .select('.chart')
            .selectAll('g')
            .remove();
    }

    // removes the legend with d3.remove()
    const removeLegend = () => {
        d3.select(ref.current)
            .select('.legend')
            .selectAll('g')
            .remove();
    }

    return (
        <>
            {displayModal ? <CreateNewPoiModal/> : null}
            <svg ref={ref}>
                <g id="chart" className="chart"/>
                <g id="test" className='dragNodeInChart'/>
                <g className="legend">
                    <path className='add'/>
                </g>
                <g className="poiDragNode"/>
            </svg>
        </>

    );
}

export default PoiChart;


