import {Button, Col, Row} from "react-bootstrap";
import React from "react";
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";

import Dropdown from "react-multilevel-dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faCog} from "@fortawesome/free-solid-svg-icons";
import {searchDatabase} from "../../../../../../dataProvider/vissights/search/search";
import {singleVisualizationScreenshotsAtom} from "../../../../reporting/state/ReportingState";
import {displaySavedScreenshotModalAtom} from "../../../../../Misc/Modals/SavedScreenshot";
import domtoimage from "dom-to-image";
import uuid from "react-uuid";
import * as _ from "lodash";
import {alwaysDisplayTextLabelsAtomFamily} from "../../TemporalOverviewChart/layouts/TemporalOverviewLayout/temporal-overview-chart-state";
import Switch from "react-switch";
import {
    bagOfWordsChartConsiderWordsPhrasesCheckBoxes,
    bagOfWordsChartMinPrecisionSliderValueAtomFamily
} from "../state/BagOfWordsChartState";
import Slider from "rc-slider";
import {displayCreatePoiModalAtom} from "../../PoiChart/modals/CreateNewPoiModal";


function BagOfWordsChartTopControls(props) {
    const componentId = props.id;
    // all available aggregations are coming from the visualizations file.
    const config = props.topControlConfig;
    const db = useRecoilValue(searchDatabase);
    const [singleVisualizationScreenshots, setSingleVisualizationScreenshots] = useRecoilState(singleVisualizationScreenshotsAtom);
    const setDisplayScreenshotModal = useSetRecoilState(displaySavedScreenshotModalAtom);
    const [alwaysDisplayLabels, setAlwaysDisplayLabels] = useRecoilState(alwaysDisplayTextLabelsAtomFamily(componentId));
    const [toggleSwitchWordsPhrases, setToggleSwitchWordsPhrases] = useRecoilState(bagOfWordsChartConsiderWordsPhrasesCheckBoxes(componentId));
    const [minPrecisionSliderValue, setMinPrecisionSliderValue] = useRecoilState(bagOfWordsChartMinPrecisionSliderValueAtomFamily(componentId));

    // TODO: currently we have no separate function to freeze the drag and drop of vis containers. It depends on the one modal for the poi chart.
    const setCurrentlyDisplayingModal = useSetRecoilState(displayCreatePoiModalAtom);


    const createVisualizationScreenshot = () => {
        const node = document.getElementById(componentId);
        domtoimage.toPng(node)
            .then((dataUrl) => {
                const newUUID = uuid();
                // store the image, the module, and the current database
                const autoGeneratedText = 'The following chart was taken in the overview Module';
                // 'searching for' + searchTerm + ' in the database: ' + db.name + '. ' +
                //  'It shows a temporal overview.';
                const metaDataObj = {id: newUUID, dataUrl, module, db: db.name, autoGeneratedText}
                const clone = _.cloneDeep(singleVisualizationScreenshots)
                clone.push({id: newUUID, data: metaDataObj});
                setSingleVisualizationScreenshots(clone);
            }).then(() => setDisplayScreenshotModal(true));
    }

    const changeToggleSwitchValues = (fieldName) => {
        // clone the existing value
        let clone = _.cloneDeep(toggleSwitchWordsPhrases);
        clone.map((d) => {
            if (d.name === fieldName) {
                return d.value = !d.value;
            } else return d;
        })
        setToggleSwitchWordsPhrases(clone)
    }

    const ContainerTopControls = () => {
        return (
            <Row>
                <Col xs={12}>
                    <div className="grid-item-container-top-control">
                        <div className="float-left" style={{display: 'flex'}}>

                            <Dropdown position="right"
                                      title={<FontAwesomeIcon style={{width: 20, height: 20}} icon={faCog}/>}
                            >
                                <Dropdown.Item onClick={() => createVisualizationScreenshot()}>
                                    <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}}
                                                     icon={faCamera}/> Screenshot Chart
                                </Dropdown.Item>
                            </Dropdown>

                            <div style={{display: 'flex'}}>
                                {toggleSwitchWordsPhrases.map((d) => {
                                        return (
                                            <div style={{paddingTop: 6}}>
                                                        <span key={d.name + 'switch-label'}
                                                              style={{marginLeft: 2, marginTop: -8}}> {d.name}
                                                </span>
                                                <Switch onColor="#86d3ff"
                                                        offHandleColor="#2693e6"
                                                        onHandleColor="#2693e6"
                                                        handleDiameter={20}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        height={15}
                                                        key={d.name + 'switch'}
                                                        width={40}
                                                        onChange={() => {
                                                            changeToggleSwitchValues(d.name);
                                                        }}
                                                        checked={d.value}/>
                                            </div>
                                        )
                                    }
                                )}
                            </div>

                            <div style={{display: 'flex'}}
                                 onMouseOver={() => {
                                     setCurrentlyDisplayingModal(true);
                                 }}

                                 onMouseLeave={() => {
                                     setCurrentlyDisplayingModal(false);
                                 }}
                            >
                                <span style={{
                                    paddingLeft: 16,
                                    paddingTop: 6
                                }}> Min. precision: {minPrecisionSliderValue} </span>
                                <Slider style={{width: 240, marginTop: 10, marginBottom: 10}}
                                        key={'color-slider-key'}
                                        min={0}
                                        step={0.01}
                                        max={1}
                                        defaultValue={minPrecisionSliderValue}
                                        onAfterChange={(val) => {
                                            setMinPrecisionSliderValue(val);
                                            setCurrentlyDisplayingModal(false);
                                        }}/>


                            </div>
                        </div>
                        <Button type="button" className="float-right" variant="light" value="Search" onClick={(e) => {
                            e.preventDefault();
                            props.deleteContainer(props.id);
                        }}>X</Button>
                    </div>
                </Col>
            </Row>
        );
    }
    return (
        <ContainerTopControls/>
    );
}

export default BagOfWordsChartTopControls;