import {Badge, Button, Col, FormCheck, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {activeDatasetAtomFamily} from "../../state";
import Dropdown from "react-multilevel-dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faCog, faInfoCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {searchDatabase} from "../../../../../../dataProvider/vissights/search/search";
import {singleVisualizationScreenshotsAtom} from "../../../../reporting/state/ReportingState";
import {displaySavedScreenshotModalAtom} from "../../../../../Misc/Modals/SavedScreenshot";
import domtoimage from "dom-to-image";
import uuid from "react-uuid";
import * as _ from "lodash";
import {displayCreateNewMonitoringItemModalAtom} from "../state/WordCloudState";
import CreateNewMonitoringItemModal from "../modals/CreateNewMonitoringItemModal";


function WordCloudTopControls(props) {
    const componentId = props.id;
    const config = props.topControlConfig[0];
    const [activeDataset, setActiveDataset] = useRecoilState(activeDatasetAtomFamily(componentId))
    const db = useRecoilValue(searchDatabase);
    const [singleVisualizationScreenshots, setSingleVisualizationScreenshots] = useRecoilState(singleVisualizationScreenshotsAtom);
    const setDisplayScreenshotModal = useSetRecoilState(displaySavedScreenshotModalAtom);
    const [displayCreateNewMonitoringItemModal, setDisplayCreateNewMonitoringItemModal] = useRecoilState(displayCreateNewMonitoringItemModalAtom);


    console.log(activeDataset)
    // console.log("here")

    const createVisualizationScreenshot = () => {
        const node = document.getElementById(componentId);
        domtoimage.toPng(node)
            .then((dataUrl) => {
                const newUUID = uuid();
                // store the image, the module, and the current database
                const autoGeneratedText = 'The following chart was taken in the overview Module';
                // 'searching for' + searchTerm + ' in the database: ' + db.name + '. ' +
                //  'It shows a temporal overview.';
                const metaDataObj = {id: newUUID, dataUrl, module, db: db.name, autoGeneratedText}
                const clone = _.cloneDeep(singleVisualizationScreenshots)
                clone.push({id: newUUID, data: metaDataObj});
                setSingleVisualizationScreenshots(clone);
            }).then(() => setDisplayScreenshotModal(true));
    }

    const changeDataSetToDisplay = (newDataset) => {
        setActiveDataset(newDataset)
    }

    const ContainerTopControls = () => {
        let dataSets = config.datasets;
        // only display datasets if we can choose between more than 1...
        dataSets = dataSets.length > 1 ? dataSets : []
        return (
            <Row>
                <Col xs={12}>
                    <div className="grid-item-container-top-control">
                        <div className="float-left" style={{ display: 'flex'}}>
                            <Dropdown position="right"
                                      title={<FontAwesomeIcon style={{width: 20, height: 20}} icon={faCog}/>}
                            >
                                <Dropdown.Item onClick={() => createVisualizationScreenshot()}>
                                    <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}}
                                                     icon={faCamera}/> Screenshot Chart
                                </Dropdown.Item>
                            </Dropdown>

                            <div style={{marginLeft: 15, marginTop: 6}}>
                                {dataSets.map((d) =>
                                    <Badge key={d} style={{marginRight: 5, cursor: 'pointer'}}
                                           variant={d === activeDataset ? "primary" : "light"}
                                           onClick={(e) => {
                                               changeDataSetToDisplay(d);
                                           }}>
                                        {d}
                                    </Badge>
                                )}
                                {activeDataset === "monitoring" ? <FontAwesomeIcon style={{marginLeft: 15, cursor: "pointer"}}
                                                                                   icon={faPlusCircle} onClick={() => setDisplayCreateNewMonitoringItemModal(true)}/> : null}
                            </div>
                        </div>

                        {displayCreateNewMonitoringItemModal ? <CreateNewMonitoringItemModal/> : null}

                        <Button type="button" className="float-right" variant="light" value="Search" onClick={(e) => {
                            e.preventDefault();
                            props.deleteContainer(props.id);
                        }}>X</Button>
                    </div>
                </Col>
            </Row>
        );
    }
    return (
        <ContainerTopControls/>
    );
}

export default WordCloudTopControls;