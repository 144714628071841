import {atom, atomFamily, selectorFamily} from "recoil";
import {visualizationItemsAtom} from "../../GridLayout/state/grid-state";

export const displayRightNavAtomFamily = atomFamily({
    key: "displayRightNavAtomFamilyKey",
    default: selectorFamily({
        key: 'displayRightNavAtomFamily/Default',
        get: param => ({get}) => {
            const items = get(visualizationItemsAtom);
            const lookUpItem = items.find((d) => d.id === param);
            return lookUpItem.usesRightNav;
        },
    }),
});

export const containerDimensionsAtomFamily = atomFamily({
    key: "containerDimensionsAtomFamilyKey",
    default: [0,0],
});

export const rightControlsDimensionsAtomFamily = atomFamily({
    key: "rightControlsDimensionsAtomFamilyKey",
    default: [0, 0],
});

export const visualizationScaleAtomFamily = atomFamily({
    key: "visualizationScaleAtomFamilyKey",
    default: 1,
});

export const visualizationTranslationXAtomFamily = atomFamily({
    key: "visualizationTranslationXAtomFamilyKey",
    default: 0,
});

export const hoveredElementIdAtomFamily = atomFamily({
    key: "hoveredElementIdAtomFamilyKey",
    default: null,
});

export const visualizationTranslationYAtomFamily = atomFamily({
    key: "visualizationTranslationYAtomFamilyKey",
    default: 0,
});

export const visualizationPinAtomFamily = atomFamily({
    key: "visualizationPinAtomFamilyTest-Keys",
    default: '-1',
});


export const visualizationMultiSelectionAtomFamily = atomFamily({
    key: "visualizationMultiSelectionAtomFamily-Keys",
    default: [],
});

// the subVisualization refers to stuff like barchart inside another Visualization container.
export const subVisualizationPinAtomFamily = atomFamily({
    key: "subVisualizationPinAtomFamily-Keys",
    default: '-1',
});


export const normalizeAtomFamily = atomFamily({
    key: "normalizeAtomFamily-Key",
    default: false,
});

export const maxRowsAtomFamily = atomFamily({
    key: "maxRowsAtomFamily-Key",
    default: 8,
});


// shared parallel corrdinates stuff

// chart spezific stuff.
export const colorSchemesAtom = atom({
    key: "colorSchemesAtom-Key",
    default:['interpolateRdYlGn', 'interpolateBlues', 'interpolateViridis']
});

export const activeColorSchemeAtomFamily = atomFamily({
    key: "activeColorSchemeAtomFamily-Key",
    default: 'interpolateBlues'
});


export const pathSchemeAtom = atom({
    key: "pathSchemeAtom-Key",
    default: ['straight_path', 'bezier_path', 'bezier_path_down', 'bezier_path_up']
});

export const activePathSchemeAtomFamily = atomFamily({
    key: "activePathSchemeAtomFamily-Key",
    default: 'bezier_path'
});


export const allowMultiSelectAtomFamily = atomFamily({
    key: "allowMultiSelectAtomFamily-Key",
    default: false
});

export const brushesAtomFamily = atomFamily({
    key: "brushesAtomFamily-Key",
    default: []
});

export const forceRedrawAtomFamily = atomFamily({
    key: "forceRedrawAtomFamily-Key",
    default: false
});

