import React from "react";
import {useRecoilValue, useRecoilValueLoadable, useSetRecoilState} from "recoil";
import {
    searchDatabase,
    searchTermAtom
} from "../../../../../dataProvider/vissights/search/search";
import {Accordion, Col, Row} from "react-bootstrap";
import {
    publicationDetailsAtom,
    publicationDetailsKeyAtom,
    publicationListChartDataAtomFamily,
} from "./state";
import FACET from "../../../../../dataProvider/vissights/utility/facet-extraction-utility";
import {topicsAtom} from "../../../../../dataProvider/vissights/overview/overview";
import {
    activatedMultiSelectionDataAtomFamily
} from "../ParallelCoordinatesChart/state/parallel-coordinates-chart-state";


function PublicationListStatic(props) {
    const componentId = props.id;
    const chartData = useRecoilValue(publicationListChartDataAtomFamily(componentId));
    const setSearchTerm = useSetRecoilState(searchTermAtom);
    const setPublicationDetailsKey = useSetRecoilState(publicationDetailsKeyAtom);
    let publicationDetailsLoadable = useRecoilValueLoadable(publicationDetailsAtom);
    // const setSimilarPublicationsKey = useRecoilState(simlarPublicationsKeyAtomFamily(componentId));
    // const similarPublicationsData = useRecoilValue(similarPublicationsDataAtomFamily(componentId));
    // special case is graphical search. We use the pub list to display only the filtered results.
    // const [graphicalSearchData, setGraphicalSearchData] = useRecoilState(graphicalSearchDataAtomFamily(componentId));
    // const [displayGraphicalSearchResults, setDisplayGraphicalSearchResults] = useRecoilState(displayGraphicalSearchResultsAtomFamily(componentId));
    //const toggleContextMenu = props.toggleContextMenu;
    // const graphicalSearchInfos = props.graphicalSearchInfos;
    const useMultiSelectionData = props.useMultiSelectionData;
    const multiSelectionData = useRecoilValue(activatedMultiSelectionDataAtomFamily(useMultiSelectionData));
    const topics = useRecoilValue(topicsAtom)
    const currentDb = useRecoilValue(searchDatabase);



    /*
    useEffect(() => {
        // console.log(graphicalSearchInfos);
       // if(graphicalSearchInfos !== undefined){
       //     setGraphicalSearchData(graphicalSearchInfos.tree.data);
      //      setDisplayGraphicalSearchResults(true);
         if (useMultiSelectionData !== undefined){
            dataToDisplay = multiSelectionData;
        } else {
            dataToDisplay = chartData;
        }
       // console.log('dataToDisp', dataToDisplay)
    })
 */

    //const refinedpublicationDetailsLoadable = publicationDetailsLoadable;
    //defaultActiveKey={chartData[0].key}

    /*
    const renderContent = (d) => {
        return <div className="whiteOnDarkMode">{renderValue(d)}</div>;
    }

        switch (d.state) {
            case 'hasValue':
                return (<div className="whiteOnDarkMode">

{ renderAbstract(d)}
{d.ee}

</div>);
case 'loading':
return (<div className="whiteOnDarkMode">Loading...</div>);
default:
return (<div className="whiteOnDarkMode">An error occured</div>);
// renderValue(d)
//
}
    */

    const renderContentDetails = (d) => {
        return (
            <div className="whiteOnDarkMode">
                <p><b>Abtract:</b></p>
                <Row>
                    <Col xs="12">
                        {renderAbstract(d)}
                    </Col>
                </Row>
                <Row>
                    <Col xs="4">
                        {renderTopics(d)}
                    </Col>
                    <Col xs="4">
                        {renderPublishingInformation(d)}
                    </Col>
                    <Col xs="4">
                        {renderLinks(d)}
                    </Col>
                </Row>
            </div>);
    }

    const renderAbstract = (d) => {
        // console.log(d);
        let abstract = "";
        if (d.abstract !== undefined) {
            abstract = d.abstract
        }
        if (d.data !== undefined) {
            if (d.data['APIspringerJSON'] !== undefined) {
                abstract = d.data['APIspringerJSON'].abstract
            }
        }
        if (d.data !== undefined) {
            if (d.data['crossref'] !== undefined) {
                abstract = d.data['crossref'][0].abstract
            }
        }
        // else if(d.data.APIspringerJSON.abstract !== undefined){
        //     abstract = d.data.APIspringerJSON.abstract
        // }
        return (<div>{abstract !== "" ? abstract : <span>no abstract available</span>}</div>)

    }

    const renderLinks = (d) => {
        const url = "https://dblp.uni-trier.de/" + d.url;
        return (
            <div>
                <b>EE: </b><a href={d.ee} target="_blank">{d.ee} </a><br/>
                {currentDb.name === 'dblp' ? <span><b>URL: </b><a href={url} target="_blank">{url}</a></span> : ""}
            </div>
        )
    }

    const renderTopics = (d) => {
        if (d.topics !== undefined) {
            const topicIds = Object.keys(d.topics);
            const topicWeight = Object.values(d.topics);
            return (
                <div>
                    <p><b>Topics:</b></p>
                    <ul>
                        {
                            topicIds.map((key, i) => {
                                return (
                                    <li key={i}>
                                        {FACET.getTopicNameById(topics, key)}: <span>{parseFloat(topicWeight[i]).toFixed(3)}</span>
                                    </li>)
                            })
                        }
                    </ul>
                </div>
            );
        } else {
            return (<div>no topics available</div>);
        }
    }

    const renderPublishingInformation = (d) => {
        let pubInfos = undefined;
        if (d.data !== undefined) {
            if (d.data['crossref'] !== undefined) {
                pubInfos = d.data['crossref'][0].title
            }
        }

        return (
            <div>
                {pubInfos !== undefined ? <span><b>Published in: {' '} </b>{pubInfos}</span> : <span><b>Published in:</b>{d.title}</span>}<br/>
                <b>Type:{' '} </b>{d.type} {d.pages !== undefined ? <span style={{fontSize: 11}}>(p. {d.pages})</span> : ""}
            </div>
        )
    }


    /*
         const keyText = type === 'topics'
            ? `${FACET.getTopicNameById(topics, key)} :`
            : `${' ' + key}:`;
        return (
            <Col xs='6' style={{display: 'flex'}} key={key}>
                <div>{keyText}{' '}</div>
                <div>{renderValue(value, key)}</div>
            </Col>
     */

    /*
        const renderValue = (value, type) => {
            if (value instanceof Object) {
                return Array.isArray(value)
                    ? renderList(value, type)
                    : renderMap(value, type);
            }

            switch (type) {
                case 'author':
                case 'editor':
                    return <a onClick={() => setSearchTerm(value)}>{value}</a>;
                case 'ee':
                    return <a href={value} target="_blank">{value}</a>;
                case 'url':
                    const v = `https://dblp.uni-trier.de/${value}`;
                    return <a href={v} target="_blank">{v}</a>;
                default:
                    return value
            }
        }

        const renderList = (list, type) => {
            return list.map((v, i) => {
                return <div key={i}>{renderValue(v, type)}</div>;
            });
        }

        const renderMap = (map, type) => {
            const attributes = ['type', 'editor', 'ee', 'url', 'booktitle', 'journal', 'volume', 'pages', 'crossref', 'address', 'cdrom', 'chapter', 'cite', 'isbn', 'month', 'note', 'number', 'publisher', 'school', 'series'];
            const items = [];
            attributes.forEach((attribute) => {
                if (!map.hasOwnProperty(attribute)) return;
                items.push(renderMapRow(type, attribute, map[attribute]));
            });
            for (const attribute in map) {
                if (!map.hasOwnProperty(attribute) || attributes.indexOf(attribute) !== -1) continue;
                items.push(renderMapRow(type, attribute, map[attribute]));
            }

            return <Row>{items}</Row>;
        }

        const renderMapRow = (type, key, value) => {
            //merge point
            let TempTopic = [];
            const keyText = type === 'topics'
                ? `${FACET.getTopicNameById(topics, key)} :`
                : `${' ' + key}:`;
            return (
                <Col xs='6' style={{display: 'flex'}} key={key}>
                    <div>{keyText}{' '}</div>
                    <div>{renderValue(value, key)}</div>
                </Col>
            );
        }
    */

    const content = (publication) => {
        return (
            <div key={'div-wrapper-around-toggle-for' + publication.key}>
                <Row className="ml-0 mr-0" key={'row-around-toggle-for' + publication.key}
                     style={{cursor: 'pointer'}}>
                    <Col className="pl-0 pr-0" xs={12}
                         key={'col-around-toggle-for' + publication.key}>
                        <Accordion.Toggle
                            key={'toggle-for' + publication.key}
                            as={"ul"} className="publication-list-accordion-toggles"
                                          variant="link" eventKey={publication.key}
                                          onClick={() => setPublicationDetailsKey(publication.key)}>
                                       <span style={{fontSize: 22}}>
                                           {publication.title} ({publication.year})
                                       </span>
                            <br/>
                            {publication.author !== undefined ? publication.author.map((e,i) =>
                                <span key={i + '-authors-' + e} style={{textDecoration: 'underline', marginRight: 5}}
                                      onClick={() => {
                                          setSearchTerm(e)
                                      }}>
                                               {e}
                                           </span>) : null}
                        </Accordion.Toggle>
                    </Col>
                </Row>
                <Row className="ml-0 mr-0">
                    <Accordion.Collapse eventKey={publication.key}>
                        <Col xs={12} key={'div-wrapper-around-collapse-for' + publication.key}>
                            <br/>
                            {renderContentDetails(publicationDetailsLoadable.contents)}
                            <br/>
                        </Col>
                    </Accordion.Collapse>
                </Row>
            </div>
        )
    }


    return (
        <div className="publication-list-wrapper" style={{height: props.height, backgroundColor: 'white', overflowX: "hidden"}}>
            <Accordion key={"Pub-Accordion"}>
                {
                    useMultiSelectionData !== undefined ?
                        multiSelectionData.map((publication) => {
                            return (
                                content(publication)
                            )
                        })
                        :
                        chartData.map((publication) => {
                            return (
                                content(publication)
                            )
                        })
                }
            </Accordion>
        </div>
    );
}

export default PublicationListStatic;

