import React, {useEffect, useRef} from "react";
import * as d3 from 'd3';
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {
    visualizationPinAtomFamily,
    visualizationScaleAtomFamily,
    visualizationTranslationXAtomFamily
} from "../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import {
    calculateRiverFillColorForAreaBySignalStrength,
    hoverFillColorForRiverArea,
    hoverStrokeColorForRiverArea, riverPreviewColor, standardStrokeColorForRiverArea
} from "../../../../../styles/colors";
import {alwaysDisplayTextLabelsAtomFamily} from "../TemporalOverviewChart/layouts/TemporalOverviewLayout/temporal-overview-chart-state";
import {activeFacetAtomFamily} from "../../../overview/visualizations/state";
import {recursiveDeepClone} from "../../../../../utility";


function TemporalSpreadChart(props) {
    const componentId = props.id;
    const ref = useRef()
    const containerWidth = props.width;
    const containerHeight = props.height;
    const toggleContextMenu = props.toggleContextMenu;
    const calculatedData = recursiveDeepClone(props.data);
    const toggleFacetFilter = props.toggleFacetFilter;
    const [scale, setNewScale] = useRecoilState(visualizationScaleAtomFamily(componentId));
    const [translationX, setTranslationX] = useRecoilState(visualizationTranslationXAtomFamily(componentId));
    // const [hoveredElementId, setHoveredElementId] = useRecoilState(hoveredElementIdAtomFamily(componentId));
    const alwaysDisplayLabels = useRecoilValue(alwaysDisplayTextLabelsAtomFamily(componentId));
    const [pinId, setNewPinId] = useRecoilState(visualizationPinAtomFamily(componentId));
    const resetPinId = useResetRecoilState(visualizationPinAtomFamily(componentId))
    const activeFacet = useRecoilValue(activeFacetAtomFamily(componentId));
    const axisOrientation = 'top'
    let zoom = null;
    const stacked = false;
    const normalized = false;

    useEffect(() => {
        prepareData(calculatedData);
        createChart();
    });

    const prepareData = (data) => {
        // compute y0
        if (stacked || normalized) {
            let stack = d3.stack(); // .keys(['y']).value([(d) => d.y]);
            if (normalized) {
                stack = stack.offset(d3.stackOffsetExpand);
            }
            stack(data); // data.map((d) => stack(d));
            // //console.log(data);
            // console.log('stacked');
        }
        if (!stacked) {
            return computeBaseline(data);
        }
    }




    const createChart = () => {
        // rebind zoom on changes.
        zoom = d3.zoom().scaleExtent([1, 5])
            .translateExtent([[0, 0], [containerWidth, 0]])
            .extent([[0, 0], [containerWidth, containerHeight]])
            .on('zoom',
                () => {
                    handleZoom(d3.event.transform);
                }
            );

        bindZoom(zoom);
        //if (scale !== 1 || translationX !== 0) {
        // reloadPreviousZoom(zoom);
        // }
        removeChart();
        drawAxis();

        // TODO: put the initializing stuff like here...
        updateChart();
    }


    const pinElementById = (pin) => {
        if (pin === pinId) {
            resetPinId();
        } else {
            setNewPinId(pin)
        }
    }

    const setFacetFilter = (facet, value) => {
        toggleFacetFilter(facet, value)
    }

    const drawAxis = () => {
        const w = containerWidth;
        const scales = scaleXY();

        let tickValues = scales.x.domain();
        if (w * scale < tickValues.length * (32 + 8) / 5) {
            tickValues = tickValues.filter((d) => d % 10 === 0);
        } else if (w * scale < tickValues.length * (32 + 8) / 2) {
            tickValues = tickValues.filter((d) => d % 5 === 0);
        } else if (w * scale < tickValues.length * (32 + 8)) {
            tickValues = tickValues.filter((d) => d % 2 === 0);
        }

        let xAxis;

        if (axisOrientation === 'top') {
            xAxis = d3.axisTop()
                .scale(scales.x)
                .tickValues(tickValues)
                .tickSize(1)
                .tickPadding(4);
        } else if (axisOrientation === 'bottom') {
            xAxis = d3.axisBottom()
                .scale(scales.x)
                .tickValues(tickValues)
                .tickSize(1)
                .tickPadding(4);
        }
        const svgElement = d3.select(ref.current);
        const g = svgElement.select('g');
        const axis = g.select('.axis');

        axis.attr('transform', 'translate(' + 0 + ', 15)')
            .transition()
            .call(xAxis);
    }


    const computeBaseline = (data) => {
        let yCenter = 0;
        data.forEach((di, i) => {
            if (i === 0) {
                yCenter = 0.5 * d3.max(di, (e) => {
                    return e.y;
                });
            } else {
                // separate space for each river
                yCenter += 0.5 * (d3.max(di, (e) => e.y) + d3.max(data[i - 1], (e) => e.y));
            }
            di.forEach((e) => {
                e.y0 = yCenter - 0.5 * e.y;
            });
        });
    }

    const updateChart = () => {

        const svg = d3.select(ref.current);

        svg
            .attr('width', containerWidth)
            .attr('height', containerHeight)
        const scales = scaleXY();
        let data;

        /*
        if (calculatedData.length > 0) {
            data = this.combineActiveAndHoveredData();
        } else {
            data = this.resultsActive;
        }
        */
        data = calculatedData;

        computeBaseline(data);

        const x = (d) => parseInt(scales.x(d.x), 10);
        const y0 = (d) => parseInt(scales.y(d.y0), 10);
        const y1 = (d) => parseInt(scales.y(d.y0 + d.y), 10);
        const ym = (d) => parseInt(scales.y(d.y0 + d.y / 2), 10);

        const chart = d3.select(ref.current)
            .select('g');

        const area = d3.area()
            .x(x).y0(y0).y1(y1)
            .curve(d3.curveCatmullRom);
        // .x(x).y0(y0).y1(y1).curve(d3.curveLinear);

        const path = chart
            .select('.points')
            .selectAll('path')
            .data(data, (d) => d.pos);

        const mouseover = (d, i, n) => {
            const currElement = n[i];
            // d3.select(this).style(stylePathMO)
            d3.select(currElement)
                .attr('opacity', 1)
                .attr('fill', hoverFillColorForRiverArea())
                .attr('stroke', hoverStrokeColorForRiverArea());
            const idFilter = (e) => e.id === d.id;
            d3.select(ref.current)
                .select('g')
                .select('.texts')
                .selectAll('text')
                .text((e) => e.name)
                // .attr('class', 'whiteOnDarkMode')
                .filter(idFilter)
                .attr('opacity', 1);
        }

        const mouseout = (d, i, n) => {
            const currElement = n[i];
            d3.select(currElement)
                .attr('opacity', (e) => {
                    if (pinId !== '-1') {
                        if (e.id === pinId) {
                            return 1;
                        } else {
                            return 0.4;
                        }
                    } else {
                        const date = new Date();
                        const year = date.getFullYear();
                        if (e.year > (year - 1)) {
                            return 0.4;
                        } else {
                            return 1;
                        }
                    }
                })
                .attr('fill', (e) => calculateRiverFillColorForAreaBySignalStrength(e.signalstrength))
                .attr('stroke', standardStrokeColorForRiverArea());
            const idFilter = (e) => e.id === d.id;
            d3.select(ref.current)
                .select('g')
                .select('.texts')
                .selectAll('text')
                .filter(idFilter)
                .attr('opacity', (e) => {
                    if (pinId !== '-1') {
                        if (e.id === pinId) {
                            return 1;
                        } else {
                            return 0.4;
                        }
                    } else {
                        return 1;
                    }
                })
                //.attr('class', 'whiteOnDarkMode');
        }

        const calculateOpactiy = (d) => {
            if (pinId !== '-1') {
                if (d.id === pinId) {
                    return 1;
                } else {
                    return 0.4;
                }
            } else {
                const date = new Date();
                const year = date.getFullYear();
                if (d.year > (year - 1)) {
                    return 1;
                }
            }
        }

        path
            .enter()
            .append('path')
            .on('click', (d) => pinElementById(d.id))
            .on('dblclick', (d) => setFacetFilter(activeFacet, d.id))
            .on('contextmenu', (d) => toggleContextMenu(d.name, ref.current))
            .on('mouseover', (d, i, n) => mouseover(d, i, n))
            .on('mouseout', (d, i, n) => mouseout(d, i, n))
            .attr('transform', 'translate(' + scales.x.bandwidth() / 2 + ',0)')
            .attr('fill', (d) => d.color === 0 ? riverPreviewColor() : calculateRiverFillColorForAreaBySignalStrength(d.signal))
            .attr('stroke', standardStrokeColorForRiverArea())
            .attr('cursor', 'pointer')
            // .transition()
            .attr('opacity', (d) => calculateOpactiy(d))
            .attr('d', area);


        path.exit()
            // .transition()
            .attr('opacity', 0)
            .remove();


        /**************************************TEXT INSIDE RIVERS**********************************/

        const maxY = (d) => {
            let max = null;
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < d.length; i++) {
                if (max === null || max.y < d[i].y) {
                    max = d[i];
                }
            }
            return max;
        };

        const text = chart
            .select('.texts')
            .selectAll('text');

        text
            .data(data, (d) => d.pos)
            .enter()
            .append('text')
            .attr('pointer-events', 'none')
            .attr('text-anchor', 'middle')
            .attr('opacity', 0)
            .attr('dy', '.3em')
            .text((d) => d.name)
            // .attr('class', 'whiteOnDarkMode')
            .attr('x', (d) => x(maxY(d)))
            .attr('y', (d) => ym(maxY(d)))
            // .transition()
            .attr('opacity', (d) => {
                if (pinId !== '-1') {
                    if (d.id === pinId) {
                        return 1;
                    } else {
                        return 0.4;
                    }
                } else {
                    return 1;
                }
            });


        text.exit()
            // .transition()
            .attr('opacity', 0)
            .remove();

        d3.select(ref.current)
            .select('g')
            .attr('transform', `translate(${translationX},0)`);


    }

    const removeChart = () => {
        d3.select(ref.current)
            .selectAll('g')
            .select('.points')
            .selectAll('path')
            .remove();
        d3.select(ref.current)
            .selectAll('g')
            .selectAll('.texts')
            .selectAll('text')
            .remove();
    }

    const scaleXY = () => {
        const w = containerWidth;
        const h = containerHeight;
        const data = calculatedData;

        const cData = data[data.length - 1] || [];
        // tslint:disable-next-line:prefer-const
        let [minX, maxX] = d3.extent(cData, (e) => e.x);
        // const maxY0Y = d3.max(cData, (e) => e.y0 + e.y);
        const maxY0Y = d3.max(cData, (e) => {
            return e.y0 + e.y;
        });
        /*
        if (this.normalized) {
          minX = 1960;
        }
    */
        return {
            x: d3.scaleBand()
                .domain(d3.range(minX, maxX + 1))
                .range([0, (w * scale) - 20], 1),
            y: d3.scaleLinear()
                .domain([0, maxY0Y + 1])
                .range([20, h], 1)
        };
    }

    const reloadPreviousZoom = (zoom) => {
        const transform = d3
            .zoomIdentity
            .translate(translationX, 0)
            .scale(scale);

        d3.select(ref.current)
            .transition()
            .duration(200)
            .call(zoom.transform, transform);
    }

    const handleZoom = (event) => {
        const newTranslationX = event.x;
        const newScale = event.k;
        setTranslationX(newTranslationX);
        setNewScale(newScale);
    }

    const bindZoom = (zoom) => {
        d3.select(ref.current)
            .call(zoom)
            .on('dblclick.zoom', null);
    }

    return (
        <svg ref={ref}>
            <g>
                <g className='points'/>
                <g className='axis'/>
                <g className='border'/>
                <g className='texts'/>
            </g>
        </svg>
    );
}

export default TemporalSpreadChart;


