import {Button, Col, Row} from "react-bootstrap";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSortAlphaDown, faSortAlphaUp, faSortAmountDown, faSortAmountUp} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {
    activeSortingMethodAtomFamily, titleSortingOrderAtomFamily,
    weightSortingOrderAtomFamily,
    yearSortingOrderAtomFamily
} from "./state-pub-list-top-control";

function PublicationListTopControls(props) {
    const componentId = props.id;
    const [activeSortingMethod, setActiveSortingMethod] = useRecoilState(activeSortingMethodAtomFamily(componentId));
    const [yearSortingOrder, setYearSortingOrder] = useRecoilState(yearSortingOrderAtomFamily(componentId))
    const [weightSortingOrder, setWeightSortingOrder] = useRecoilState(weightSortingOrderAtomFamily(componentId))
    const [titleSortingOrder, setTitleSortingOrder] = useRecoilState(titleSortingOrderAtomFamily(componentId))

    const changeYearSorting = (order) => {
        // if we are already sorting by amount we change the order.
        if (activeSortingMethod === 'year') {
            setYearSortingOrder(order);
        }
        // otherwise we come here from az sorting and only want to acitvate amount sorting.
        setActiveSortingMethod('year');
    }

    const changeWeightSorting = (order) => {
        // if we are already sorting by amount we change the order.
        if (activeSortingMethod === 'weight') {
            setWeightSortingOrder(order);
        }
        // otherwise we come here from az sorting and only want to acitvate amount sorting.
        setActiveSortingMethod('weight');
    }

    const changeTitleSorting = (order) => {
        // if we are already sorting by amount we change the order.
        if (activeSortingMethod === 'title') {
            setTitleSortingOrder(order);
        }
        // otherwise we come here from az sorting and only want to acitvate amount sorting.
        setActiveSortingMethod('title');
    }


    const ContainerTopControls = () => {
        return (
            <Row>
                <Col xs={12}>
                    <div className="grid-item-container-top-control">
                        <div className="float-left">
                            <b>Sorting: {' '}</b>
                            <span style={{paddingLeft: 10}}>Weight</span>

                            {
                                weightSortingOrder === 'desc' ?
                                    <FontAwesomeIcon icon={faSortAmountDown}
                                                     className={activeSortingMethod === 'weight' ? 'activeSortingIcon' : 'inactiveSortingIcon'}
                                                     style={{width: 24, paddingRight: 2, cursor: 'pointer'}}
                                                     onClick={() => {
                                                         changeWeightSorting('asc');
                                                     }}
                                    />
                                    :
                                    <FontAwesomeIcon icon={faSortAmountUp}
                                                     className={activeSortingMethod === 'weight' ? 'activeSortingIcon' : 'inactiveSortingIcon'}
                                                     style={{width: 24, paddingRight: 2, cursor: 'pointer'}}
                                                     onClick={() => {
                                                         changeWeightSorting('desc');
                                                     }}
                                    />
                            }


                            <span style={{paddingLeft: 10}}>Year</span>

                            {
                                yearSortingOrder === 'desc' ?

                                    <FontAwesomeIcon icon={faSortAmountDown}
                                                     className={activeSortingMethod === 'year' ? 'activeSortingIcon' : 'inactiveSortingIcon'}
                                                     style={{width: 24, paddingRight: 2, cursor: 'pointer'}}
                                                     onClick={() => {
                                                         changeYearSorting('asc')
                                                     }}
                                    />
                                    :
                                    <FontAwesomeIcon icon={faSortAmountUp}
                                                     className={activeSortingMethod === 'year' ? 'activeSortingIcon' : 'inactiveSortingIcon'}
                                                     style={{width: 24, paddingRight: 2, cursor: 'pointer'}}
                                                     onClick={() => {
                                                         changeYearSorting('desc');
                                                     }}
                                    />
                            }
                            <span style={{paddingLeft: 10}}>Title</span>
                            {
                                titleSortingOrder === 'asc' ?

                                    <FontAwesomeIcon icon={faSortAlphaDown}
                                                     className={activeSortingMethod === 'title' ? 'activeSortingIcon' : 'inactiveSortingIcon'}
                                                     style={{width: 24, cursor: 'pointer'}}
                                                     onClick={(e) => {
                                                         changeTitleSorting('desc')
                                                     }}
                                    />
                                    :
                                    <FontAwesomeIcon icon={faSortAlphaUp}
                                                     className={activeSortingMethod === 'title' ? 'activeSortingIcon' : 'inactiveSortingIcon'}
                                                     style={{width: 24, cursor: 'pointer'}}
                                                     onClick={(e) => {
                                                         changeTitleSorting('asc')
                                                     }}
                                    />
                            }
                        </div>
                        <Button type="button" className="float-right" variant="light" value="Search" onClick={(e) => {
                            e.preventDefault();
                            props.deleteContainer(props.id);
                        }}>X</Button>
                    </div>
                </Col>
            </Row>
        );
    }
    return (
        <ContainerTopControls/>
    );
}

export default PublicationListTopControls;