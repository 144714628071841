import {atomFamily} from "recoil";

export const activeSortingMethodAtomFamily = atomFamily({
    key: "activeSortingMethodAtomFamily-Key",
    default: 'year'
});


export const yearSortingOrderAtomFamily = atomFamily({
    key: "yearSortingOrderAtomFamily-Key",
    default: 'desc'
});

export const weightSortingOrderAtomFamily = atomFamily({
    key: "weightSortingOrderAtomFamily-Key",
    default: 'desc'
});

export const titleSortingOrderAtomFamily = atomFamily({
    key: "titleSortingOrderAtomFamily-Key",
    default: 'asc'
});
