import React, {useEffect} from 'react';
import LeftNavigation from "../../GlobalNavigations/LeftNavigation/LeftNavigation";
import RightNavigation from "../../GlobalNavigations/RightNavigation/RightNavigation";
import SubNavigation from "../../GlobalNavigations/SubNavigation/SubNavigation";
import CustomGrid from "../../Dashboards/GridLayout/GridLayout";
import {useRecoilValue, useSetRecoilState} from "recoil";

import {
    dashboardHeightAtom,
    dashboardWidthAtom,
    leftSideNavWidthAtom,
    rightSideNavWidthAtom,
} from "../../../config/sizings/sizings";
import {moduleToDisplayAtom} from "../../Dashboards/DashboardLayout";
import {searchDataAtom, searchDataSelector} from "../../../dataProvider/vissights/search/search";

function ZoomAndFilterDashboard(props) {
    // const data = useRecoilValue(facetedFilteredDataSelector);
    const searchResults = useRecoilValue(searchDataAtom);
    const leftSideNavigationWidth = useRecoilValue(leftSideNavWidthAtom);
    const rightSideNavigationWidth = useRecoilValue(rightSideNavWidthAtom);
    const dashboardWidth = useRecoilValue(dashboardWidthAtom)
    const dashboardHeight = useRecoilValue(dashboardHeightAtom);
    const Dashboard = () => {
        return <div><CustomGrid/></div>
    };
    const setModuleToDisplay = useSetRecoilState(moduleToDisplayAtom);

    useEffect(() => {
        setModuleToDisplay('zoomAndFilter')
    })
    return (
        <div>
            <div>
                <SubNavigation style={{height: props.subNavigationHeight}}/>
            </div>
            <div className="ZoomAndFilterDashboard" style={{display: "flex", height: dashboardHeight}}>
                <div style={{width: leftSideNavigationWidth, height: dashboardHeight}}>
                    <LeftNavigation/>
                </div>
                <div style={{width: dashboardWidth}}> {/* There is no data to display for the current search. You may search for another term or switch the database. */}
                    { searchResults.length <= 0 ? <div></div> : <Dashboard/>  }
                </div>
                <div style={{width: rightSideNavigationWidth}}>
                    <RightNavigation/>
                </div>
            </div>
        </div>

    );
}

export default ZoomAndFilterDashboard;