import React from 'react';
import {useRecoilState, useResetRecoilState} from "recoil";
import {recursiveDeepClone} from "../../../../utility";
import {poiAtomFamily, sliderValuesAtomFamily} from "../../LeftNavigation/LeftNavigationState";
import {Badge} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faX} from "@fortawesome/free-solid-svg-icons";


function PoiFilters(props) {
    // stores the stuff we display here.
    const [poiFilters, setPoiFilters] = useRecoilState(poiAtomFamily(props.filterSetId));
    const resetSliderValues = useResetRecoilState(sliderValuesAtomFamily(props.filterSetId));

    const deactivateElementFromFilterSet = (poi, index) => {
        let clone = recursiveDeepClone(poiFilters);
        clone.forEach((d) => {
            if (d.id === poi.id) {
                d.active = false;
            }
            return d;
        })
        setPoiFilters(clone);
        resetSliderValues();
        /*


        clone.forEach((d) => {
            if (d.facet === facet) {
                // filter out the value that was passed
                d.values = d.values.filter((e) => e !== filter);
                return d;
            }
            return d;
        });

        setPoiFilters(clone);
        resetSliderValues();
         */
    }


    const PoiFilters = () => {
        const d = props.d;
        const j = props.j;
        return (
            <>
                <Badge variant="primary" key={j}
                       style={{marginRight: 5, cursor: 'pointer'}}>
                    {'(POI) ' + d.name}
                </Badge>
                <FontAwesomeIcon icon={faX} style={{height: 12, marginTop: 3, marginLeft: 2, cursor: "pointer"}}
                                 onClick={() => {
                                     deactivateElementFromFilterSet(d, j); // pass the facet, the id of filter
                                 }}/>
            </>
        )
    }


    return (
        <PoiFilters/>
    );
}

export default PoiFilters;
