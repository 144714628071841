import {atomFamily, selectorFamily} from "recoil";
import {activeFacetAtomFamily} from "../../../../overview/visualizations/state";
import {
    preProcessedFilteredSearchDataSelector
} from "../../../../../../dataProvider/vissights/search/filters/filters";
import {genericSort, recursiveDeepClone} from "../../../../../../utility";
import FACET from "../../../../../../dataProvider/vissights/utility/facet-extraction-utility";

export const chartDataTemporalSpreadChartSelectorFamily = selectorFamily({
    key: 'chartDataTemporalSpreadChartSelectorFamily-Key',
    get: (id) => ({get}) => {
        const facet = get(activeFacetAtomFamily(id));
        const dataModel = get(preProcessedFilteredSearchDataSelector);
        // const yearFacet = dataModel[FACET.YEAR];
        return recursiveDeepClone(dataModel[facet]);
    }
});


export const chartDataTemporalSpreadChartYearsSelectorFamily = selectorFamily({
    key: 'chartDataTemporalSpreadChartYearsSelectorFamily-Key',
    get: (id) => ({get}) => {
        const dataModel = get(preProcessedFilteredSearchDataSelector);
        const yearFacet = dataModel[FACET.YEAR];
        return recursiveDeepClone(yearFacet);
    }
});


export const temporalSpreadChartChartDataAtomFamily = atomFamily({
    key: "temporalSpreadChartChartDataAtomFamily",
    default: selectorFamily({
        key: 'temporalSpreadChartChartDataAtomFamily/Default',
        get: id => ({get}) => {

            const rightControlItems = get(rightControlTemporalSpreadChartDataAtomFamily(id));
            const rightControlItemsActive = rightControlItems.filter((d) => d.active);
            let rightControlItemsActiveIds = [];

            rightControlItemsActive.map((d) => {
                rightControlItemsActiveIds.push(d.id)
            })

            const chartData = get(chartDataTemporalSpreadChartSelectorFamily(id));

            let clone = recursiveDeepClone(chartData);

            clone = clone.filter((d) => rightControlItemsActiveIds.includes(d.id))

            clone.map((d) => d.color = Math.random() * 360)


            return clone;


            // return chartDataTemporalSpreadChartSelectorFamily(id);
        },
    }),
});


export const temporalSpreadChartChartDataActiveAtomFamily = atomFamily({
    key: "temporalSpreadChartChartDataActiveAtomFamily",
    default: selectorFamily({
        key: 'temporalSpreadChartChartDataActiveAtomFamily/Default',
        get: id => ({get}) => {
            const data = get(temporalSpreadChartChartDataAtomFamily(id));
            const dataFiltered = data.filter((d) => d.active)
            return dataFiltered.sort(genericSort('amount', 'desc'));
        }
    }),
});


export const rightControlDataTemporalSpreadChartSelectorFamily = selectorFamily({
    key: 'rightControlDataTemporalSpreadChartSelectorFamily-Key',
    get: (id) => ({get}) => {
        const facet = get(activeFacetAtomFamily(id));
        const dataModel = get(preProcessedFilteredSearchDataSelector);
        const dataModelClone = recursiveDeepClone(dataModel[facet]);
        dataModelClone.map((d, i) => i < 9 ? d.active = true : d.active = false);
        return dataModelClone;
    }
});


export const rightControlTemporalSpreadChartDataAtomFamily = atomFamily({
    key: "rightControlTemporalSpreadChartDataAtomFamily",
    default: selectorFamily({
        key: 'rightControlTemporalSpreadChartDataAtomFamily/Default',
        get: id => () => {
            return rightControlDataTemporalSpreadChartSelectorFamily(id);
        },
    }),
});




