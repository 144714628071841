import React, {useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {
    dashboardHeightAtom,
    dashboardWidthAtom,
} from "../../../config/sizings/sizings";
import {moduleToDisplayAtom} from "../../Dashboards/DashboardLayout";
import keycloak from "../../../dataProvider/vissights/authentication/keycloak";
import {Col, Row} from "react-bootstrap";
import {myProjectsAtom} from "./MyProjects/my-projects-state";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ProjectsDashboard() {
    const setModuleToDisplay = useSetRecoilState(moduleToDisplayAtom);
    // const leftSideNavigationWidth = useRecoilValue(leftSideNavWidthAtom);
    const dashboardWidth = useRecoilValue(dashboardWidthAtom);
    const dashboardHeight = useRecoilValue(dashboardHeightAtom);
    const myProjects = useRecoilValue(myProjectsAtom);


    useEffect(() => {
        setModuleToDisplay('projects');
    }, [])

    // <ProfileLeftNaviagation width={Number(leftSideNavigationWidth)} height={Number(dashboardHeight)}> </ProfileLeftNaviagation>

    return (
        <div className="ProjectsDashboard" style={{display: "flex", height: Number(dashboardHeight)}}>
            <div style={{width: Number(dashboardWidth)}}>
                <h1><b>{keycloak.tokenParsed.preferred_username + "'s Projects"} </b></h1>
                <Row style={{marginTop: 50}}>
                    <Col xs={2}/>
                    <Col xs={2}>
                        {
                            myProjects.length <= 0 ? <div>Create new project <FontAwesomeIcon style={{width: 20, height: 20, cursor: 'pointer'}} icon={faPlusCircle}/> </div> : <ul>{myProjects.map((d) => {
                               <li>{d.name}</li>
                            })}</ul>
                        }
                    </Col>
                    <Col xs={2}/>
                </Row>
            </div>
        </div>
    )
}

export default ProjectsDashboard;

