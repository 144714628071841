import React, {useEffect, useRef, useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    subVisualizationPinAtomFamily,
    visualizationPinAtomFamily,
} from "../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import {
    displayAmountPublicationBubblesAtomFamily,
    displayLinkedCountriesLegendAtomFamily,
    displayPublicationLegendAtomFamily, displayTemporalOverviewInsideChartAtomFamily, displayTrajectoryLinesAtomFamily,
    geoChartMapDataAtom
} from "./state/geo-chart-state";
import {isoString} from './state/isostrings';
import {
    activeCountryBarChartBarFillColor,
    activeCountryBarChartBarStrokeColor,
    activeStrokeColorCountry,
    bubbleFillGradient,
    bubbleStrokeGradient,
    countryFillGradient,
    standardFillColorCountry,
    standardFillColorForBars,
    standardStrokeColorCountry,
    standardStrokeColorForBars
} from "../../../../../styles/colors";
import * as d3l from 'd3-svg-legend';
import * as d3 from 'd3';
import * as _ from 'lodash';
import FACET from "../../../../../dataProvider/vissights/utility/facet-extraction-utility";

// information about the tools activated / deactivated we store in local state of each chart
function GeoChart(props) {
    const componentId = props.id;
    const containerHeight = props.height;
    const toggleFacetFilter = props.toggleFacetFilter;
    const toggleContextMenu = props.toggleContextMenu;
    const data = props.data;
    // map data recieved as geojson data.
    let worldMapDataRaw = useRecoilValue(geoChartMapDataAtom);
    let worldMapData = _.cloneDeep(worldMapDataRaw);
    const scale = props.scale;
    const setNewScale = props.setNewScale;
    const translationX = props.translationX;
    const setTranslationX = props.setTranslationX;
    const translationY = props.translationY;
    const setTranslationY = props.setTranslationY;
    const containerWidth = props.width;
    const [pinnedCountry, setPinnedCountry] = useRecoilState(visualizationPinAtomFamily(componentId));
    const [pinnedYear, setPinnedYear] = useRecoilState(subVisualizationPinAtomFamily(componentId));
    const displayPublicationLegend = useRecoilValue(displayPublicationLegendAtomFamily(componentId));
    const displayLinkedCountriesLegend = useRecoilValue(displayLinkedCountriesLegendAtomFamily(componentId));
    const displayTemporalOverviewInsideChart = useRecoilValue(displayTemporalOverviewInsideChartAtomFamily(componentId));
    const displayAmountPublicationBubbles = useRecoilValue(displayAmountPublicationBubblesAtomFamily(componentId));
    const displayTrajectoryLines = useRecoilValue(displayTrajectoryLinesAtomFamily(componentId));
    const ref = useRef();
    const barChartWidth = containerWidth;
    const barChartHeight = Math.min(100, containerHeight / 5)
    // const [zoom, setZoom] = useRecoilValue(chartZoomAtomFamily(componentId));
    let zoom = null;


    // empty dependency array makes this equal to ComponentDidMount.
    useEffect(() => {
        zoom = d3.zoom().scaleExtent([containerWidth / 9, containerWidth])
            .translateExtent([[-(containerWidth / 50), -3], [(containerWidth / 50), (containerHeight / 140)]])
            .on('zoom',
                () => {
                    handleZoom(d3.event.transform);
                }
            );
        bindZoom(zoom);
        if (translationX === 0 && translationY === 0 && scale === 1) {
            //console.log('set initial zoom');
            setInitialZoom(zoom);
        }
        updateGeoChart();
        /*
            // If the chart is first drawn or resetted, due to a change in the wrapping containers size set it initially
        if (translationX === 0 && translationY === 0 && scale === 1) {
            console.log('set initial zoom');
            setInitialZoom(zoom);
        } else {
            // if it was alreasy set restore the previos values.
            console.log('reload zoom');
           // reloadPreviousZoom(zoom);
        }
         */
    })

    // on resize we reset zoom.
    useEffect(() => {
        setInitialZoom(zoom);
    }, [props.width, props.height]);

    useEffect(async () => {
        await updateGeoChart();
        drawPublicationLegend();
    });



    useEffect(() => {
        if (pinnedCountry !== '-1') {
            if (displayTemporalOverviewInsideChart) {
                removeBarChart();
                drawBarChart();
                drawBarChart();
                removeBubbles();
                drawBubbles();
            } else {
                removeBubbles();
                drawBubbles();
                removeBarChart();
            }
        } else {
            removeBarChart();
            removeBubbles();
        }

    }, [displayTemporalOverviewInsideChart, pinnedCountry, scale, translationX, translationY,
        pinnedYear, setPinnedYear, displayTrajectoryLines, displayLinkedCountriesLegend, displayAmountPublicationBubbles, props.data]);

    // set the zoom intially. This causes a change of the zoomIdentity and fires an action.
    const setInitialZoom = (zoom) => {
        const scale = containerWidth / 7;
        const translationX = containerWidth / 2;
        const translationY = containerHeight / 2 + scale * 0.35;

        const transform = d3.zoomIdentity
            .translate(translationX, translationY)
            .scale(scale);

        d3.select(ref.current)
             .transition()
             .duration(200)
            .call(zoom.transform, transform);

    }

    // changes in the zooming causes a rerendering with the fresh values
    const handleZoom = (value) => {
        const w = containerWidth;
        const h = containerHeight;

        const translationX = value.x;
        const translationY = value.y;
        const scale = value.k;

        storeScaleAndTranslation(scale, translationX, translationY);
    }

    const bindZoom = (zoom) => {
        d3.select(ref.current)
            .call(zoom)
            .on('dblclick.zoom', null);
    }

    // restores the previous scale and translation based on the state.
    const reloadPreviousZoom = (zoom) => {
        const transform = d3.zoomIdentity
            .translate(translationX, translationY)
            .scale(scale);

        d3.select(ref.current)
            // .transition()
            // .duration(600)
            .call(zoom.transform, transform);
    }


    const storeScaleAndTranslation = (scale, translationX, translationY) => {
        setNewScale(scale);
        setTranslationX(translationX);
        setTranslationY(translationY);
    }


    /* DRAW Charts */


    const drawPublicationLegend = () => {

        const legendsgroup = d3.select(ref.current)
            .selectAll('g.legends-group');

        // remove to let it rerender.
        legendsgroup.select('g').remove();
        // console.log("test render")
        // only display the legend if set to true.
        if (displayPublicationLegend) {
            let maxAmount = d3.max(data, (e) => e.amount);

            // color scale
            const gradient = d3.scaleLinear()
                .domain([1, maxAmount])
                .range(countryFillGradient());


            const countryLegend = legendsgroup
                .append('g')
                .attr('class', 'country-legend')
                .attr('class', 'fillWhiteOnDarkMode')
                .attr('transform', 'translate(' + 10 + ',' + (containerHeight - 200) + ')');

            countryLegend.append('text')
                .text('#Publications')
                .attr('transform', 'translate(' + 0 + ',' + (-10) + ')')
                .attr('class', 'fillWhiteOnDarkMode');

            const legendProps =
                d3l.legendColor()
                    .shapeHeight(30)
                    // .shapeWidth(40)
                    .cells(5)
                    .orient('vertical')
                    .labelFormat(d3.format('.0f'))
                    .scale(gradient)

            countryLegend
                .call(legendProps)

        }


    }




    const drawGeoChart = () => {
        // define appropriate projection
        const projection = d3.geoMercator()
            .translate([translationX, translationY])
            .scale(scale);

        const path = d3.geoPath().projection(projection);

        const world = worldMapData;


        // calculate the center of each country path with the path.centroid method.
        world.map((d) => d.centroid = path.centroid(d));

        // because of alaska the center is not suitable for the US.
        world.map((d) => {
            if (d.id === 'USA') {
                d.centroid[0] = d.centroid[0] + 30 * (scale / 100);
                d.centroid[1] = d.centroid[1] + 30 * (scale / 100);
            }
        });

        // add the iso strings
        const isos = JSON.parse(isoString());
        world.map((d) => d.iso = isos.find((e) => e.name === d.id));

        worldMapData = world;

        d3.select(ref.current)
            .attr('width', containerWidth)
            .attr('height', containerHeight)


        const chart = d3.select(ref.current)
            .selectAll('.map');

        d3.select(ref.current)
            .on('contextmenu', () => d3.event.preventDefault());

        let maxAmount = d3.max(data, (e) => e.amount);


        // color scale
        const gradient = d3.scaleLinear()
            .domain([1, maxAmount])
            .range(countryFillGradient());




        // mouse events
        const onMouseOver = (d, i, n) => {
            // refers to the current country which is hovered over (mouse over event)
            const currElement = n[i];
            d3.select(currElement)
                .attr('stroke', activeStrokeColorCountry())
                .attr('stroke-width', 1);

            d3.select(ref.current).select('.time').select('.bars').selectAll('g')
                // if there is currently a barchart, this highlights the years of the currently mouse overed country
                .filter((e) => data.lookup[d.id] ? data.lookup[d.id].years.hasOwnProperty(e.year) : false)
                .select('.bar')
                .attr('fill', activeCountryBarChartBarFillColor())
                .attr('stroke', activeCountryBarChartBarStrokeColor());

        };

        const onMouseOut = (d, i, n) => {
            const currElement = n[i];
            d3.select(currElement)
                .attr('stroke', (e) => e.id === pinnedCountry ? activeStrokeColorCountry() : standardStrokeColorCountry())
                .attr('stroke-width', (e) => e.id === pinnedCountry ? 1 : 0);

            d3.select(ref.current)
                .select('.time')
                .select('.bars')
                .selectAll('g')
                // remove highlighting on mouse leave.
                .filter((e) => data.lookup[d.id] ? data.lookup[d.id].years.hasOwnProperty(e.year) : false)
                .select('.bar')
                .attr('fill', standardFillColorForBars())
                .attr('stroke', standardStrokeColorForBars())
                .attr('stroke-width', 1)
                .attr('shape-rendering', 'crispEdges')
                .attr('pointer-events', 'none')
        };

        const landsGroup = chart.selectAll('path')
            .data(world)
            .enter()
            .append('g')
            .attr('class', 'country-group');

        landsGroup
            .append('path')
            .attr('d', path)
            .attr('stroke', (d) => d.id === pinnedCountry ? activeStrokeColorCountry : standardStrokeColorCountry)
            .attr('stroke-width', (d) => d.id === pinnedCountry ? 1 : 0)
            .attr('fill', (d) => data.lookup.hasOwnProperty(d.id) ? gradient(data.lookup[d.id].amount) : standardFillColorCountry())
            .attr('cursor', (d) => data.lookup.hasOwnProperty(d.id) ? 'pointer' : 'normal')// d.data.length > 0 ? 'pointer' : 'normal')
            .on('mouseover', onMouseOver)
            .on('mouseout', onMouseOut)
            .on('click', (d) => pinCountryById(d.id))
            .on('dblclick', (d) => setFacetFilterCountry(d.id))
            .on('contextmenu', (d) => toggleContextMenu(d.id, ref.current))

        landsGroup
            .append('title')
            .text((d) => {
                const num = data.lookup.hasOwnProperty(d.id) ? data.lookup[d.id].amount : 0;
                return num === 0 ? d.id : d.id + ' (' + num + ')';
            });
    }

    const setFacetFilterYear = (year) => {
        toggleFacetFilter(FACET.YEAR, year)
    }


    const setFacetFilterCountry = (country) => {
        toggleFacetFilter(FACET.COUNTRY, country)
    }


    const drawBarChart = () => {

        const width = containerWidth;
        const height = containerHeight;
        const svg = d3.select(ref.current);
        const barChart = svg.select('.time');
        barChart.attr('visibility', 'visible');

        const years = d3.values(calculateYearsForBarChart());
        const scales = scaleBarChartXY(years);

        barChart.attr('transform', `translate(0,${height - barChartHeight - 50})`);
        const [minYear, maxYear] = d3.extent(years, (e) => e.year);
        const maxAmount = d3.max(years, (e) => e.amount);

        /************** Calculate ticks **************/
            // calculation of ticks.
        let tickValues = scales.x.domain();
        if (width < tickValues.length * (32 + 8) / 5) {
            tickValues = scales.x.domain().filter((d) => d % 10 === 0);
        } else if (width < tickValues.length * (32 + 8) / 2) {
            tickValues = scales.x.domain().filter((d) => d % 5 === 0);
        } else if (width < tickValues.length * (32 + 8)) {
            tickValues = scales.x.domain().filter((d) => d % 2 === 0);
        }


        /****** X-AXIS *******/
        const xAxis = d3.axisBottom()
            .scale(scales.x)
            .tickValues(tickValues)
            .tickSize(1)
            .tickPadding(4);

        barChart.select('.axis')
            .attr('transform', `translate(0,${barChartHeight - 5})`)
            .attr('opacity', years.length === 0 ? 0 : 1)
            .call(xAxis);

        const bar = barChart
            .select('.bars')
            .selectAll('g')
            .data(years)

        const enter = bar
            .enter()
            .append('g')
            .attr('cursor', 'pointer')
            .on('click', (d) => focusYear(d.year))
            .on('dblclick', (d) => setFacetFilterYear(d.year));

        /**********background bars **********/
        enter.append('rect')
            .attr('class', 'bg')
            .attr('opacity', 0);

        /**********foreground bars **********/
        enter.append('rect')
            .attr('class', 'bar')
            .attr('fill', standardFillColorForBars())
            .attr('stroke', standardStrokeColorForBars())
            .attr('transform', `translate(0,${-5})`)
            .attr('stroke-width', 1)
            .attr('shape-rendering', 'crispEdges')
            .attr('pointer-events', 'none');

        /****************** AMOUNT Per YEAR TEXT LABLE *****************/
        enter.append('text')
            .attr('transform', `translate(0,${-5})`)
            .attr('pointer-events', 'none')
            .attr('dy', '.3em')
            .attr('text-anchor', 'middle')
            .attr('class', 'fillWhiteOnDarkMode');

        bar.select('.bg')
            .attr('x', (d) => scales.x(d.year))
            .attr('y', 0)
            .attr('width', scales.x.bandwidth())
            .attr('height', barChartHeight);


        bar.select('.bar')
            .attr('x', (d) => scales.x(d.year) + 2)
            .attr('y', (d) => barChartHeight - scales.y(d.amount))
            .attr('width', scales.x.bandwidth() - 4)
            .attr('height', (d) => scales.y(d.amount))
            .attr('opacity', 0)
            .transition()
            .attr('opacity', (d) => (d.year === pinnedYear || pinnedYear === '-1') ? 1 : 0.3);

        // Append amount text to bars
        bar.select('text')
            .attr('x', (d) => scales.x(d.year) + scales.x.bandwidth() / 2)
            .attr('y', (d) => barChartHeight - scales.y(d.amount))
            .text((d) => d.amount)
            .transition()
            .attr('opacity', (d) => (d.year === pinnedYear || pinnedYear === '-1') ? 1 : 0.3)
            .attr('class', 'fillWhiteOnDarkMode');
        ;

        // bar.exit().remove();
    }

    const drawBubbles = () => {
        if (pinnedCountry !== '-1' && pinnedCountry !== undefined && data.lookup[pinnedCountry] !== undefined) {

            let valuesArray = [];

            for (const value of Object.values(data.lookup[pinnedCountry].links)) {
                valuesArray.push(value);
            }

            // TODO: not the cleanest way... refactor
            valuesArray = valuesArray.sort((a, b) => a - b);
            valuesArray.pop();

            const min = d3.min(valuesArray);
            // cut off last element which is always the country itself
            const max = d3.max(valuesArray);

            const myScale = d3.scaleLinear()
                .domain([min, max])
                .range([10, 25]);

            // color scale
            const bubblesFillColorGradient = d3.scaleLinear()
                .domain([0, 10])
                .range(bubbleFillGradient());

            const bubblesStrokeColorGradient = d3.scaleLinear()
                .domain([0, 10])
                .range(bubbleStrokeGradient());

            const linkedCountries = data.lookup[pinnedCountry].links;
            const pinnedCountryMapData = worldMapData.find((d) => d.id === pinnedCountry);

            const animatedStartCX = pinnedCountryMapData.centroid[0];
            const animatedStartCY = pinnedCountryMapData.centroid[1];

            // prepare which data to send for trajectories menu.
            // trajData = [];
            // trajData.push(pinnedCountry);
            // trajData.push(linkedCountries);
            // trajData.push(worldMapData);

            /*************** LEGEND ***************/

            const legendsGroup = d3.select(ref.current)
                .selectAll('.legends-group');

            // only display the legend if set to true.
            if (displayLinkedCountriesLegend) {
                const bubblesLegend = legendsGroup
                    .append('g')
                    .attr('class', 'legend-bubbles')
                    .attr('transform', 'translate(' + 20 + ',' + (containerHeight - 300) + ')');

                bubblesLegend.append('text')
                    .text('#Collaborative Publications')
                    .attr('transform', 'translate(' + 0 + ',' + (-20) + ')')
                    .attr('class', 'fillWhiteOnDarkMode');

                const legendProps = d3l.legendSize()
                    .shape('circle')
                    .shapePadding(15)
                    .labelOffset(20)
                    .orient('horizontal')
                    .labelFormat(d3.format('.0f'))
                    .scale(myScale);

                bubblesLegend
                    .call(legendProps);
            }

            /**
             * As the package only gives the choice between color/ size scale we must improvise to combine size and color for the bubbles
             */

            d3.selectAll('.legend-bubbles')
                .selectAll('.legendCells')
                .selectAll('g')
                .selectAll('circle')
                // the data is already set to the calculated circle legend values, so we can just call our color scale
                .attr('fill', (d) => bubblesFillColorGradient(d))
                .attr('stroke', (d) => bubblesStrokeColorGradient(d));

            const mouseOverBubble = (d, i, n) => {
                const currentElement = n[i];
                d3.select(currentElement)
                    .selectAll('circle')
                    .attr('r', 100)
                    .attr('stroke-width', 2)
                    .attr('font-size', 5 + 'em')
                    .attr('dy', '.1em')
                    .attr('text-anchor', 'middle');

                d3.select(currentElement)
                    .selectAll('text.not-zoomed-text')
                    .attr('opacity', 0);

                d3.select(currentElement)
                    .selectAll('text.zoomed-text')
                    .attr('opacity', 1);
            };

            const mouseLeaveBubble = (d, i, n) => {
                const currentElement = n[i];
                d3.select(currentElement)
                    .selectAll('circle')
                    .attr('r', (e) => e.id in linkedCountries && e.id !== pinnedCountry ? (myScale(linkedCountries[e.id])) : 0)
                    .attr('stroke-width', 1);

                d3.select(currentElement)
                    .selectAll('text.not-zoomed-text')
                    .attr('opacity', 1);

                d3.select(currentElement)
                    .selectAll('text.zoomed-text')
                    .attr('opacity', 0);

            };


            // only draw the trajectory lines if it is enabled in menu
            if (displayTrajectoryLines) {
                const pathOverlay = d3.select(ref.current)
                    .selectAll('g.path-overlay')
                    .data(worldMapData)
                    .enter()
                    .append('g');

                pathOverlay
                    .append('line')
                    .attr('class', 'center-circle-line')
                    .attr('stroke', 'red')
                    .attr('stroke-width', (d) => d.id in linkedCountries ? 1 : 0)
                    .attr('stroke-dasharray', '0.5%, 0.5%')
                    .attr('x1', animatedStartCX)
                    .attr('y1', animatedStartCY)
                    .transition()
                    .delay(600)
                    .duration(800)
                    .attr('y2', (d) => d.centroid[1])
                    .attr('x2', (d) => d.centroid[0]);
            }


            // only draw the amount bubbles if it is enabled in menu
            if (displayAmountPublicationBubbles) {
                const circlesOverlay = d3.select(ref.current)
                    .selectAll('g.circles-overlay')
                    .data(worldMapData)
                    .enter()
                    .append('g')
                    .attr('class', (d) => d.id + '-group')
                    .on('mouseover', mouseOverBubble)
                    .on('mouseleave', mouseLeaveBubble)
                    .attr('transform', (d) => 'translate(' + d.centroid[0] + ',' + d.centroid[1] + ')');

                circlesOverlay
                    .append('circle')
                    .attr('fill', (d) => d.id in linkedCountries && d.id !== pinnedCountry ? bubblesFillColorGradient(linkedCountries[d.id]) : 'black')
                    .attr('stroke', (d) => d.id in linkedCountries && d.id !== pinnedCountry ? bubblesStrokeColorGradient(linkedCountries[d.id]) : 'black')
                    .transition()
                    .delay(300)
                    .duration(1000)
                    .attr('r', (d) => d.id in linkedCountries && d.id !== pinnedCountry ? (myScale(linkedCountries[d.id])) : 0)
                    .attr('stroke-width', 1);

                circlesOverlay
                    .append('text') //  d.iso !== undefined ? d.iso.iso : 'NC') + ' - ' + linkedCountries[d.id]
                    .attr('class', 'not-zoomed-text')
                    .text((d) =>{ console.log(d); return d.id in linkedCountries && d.id !== pinnedCountry ? d?.iso?.iso : null})
                    .attr('font-size', (d) => d.id in linkedCountries && d.id !== pinnedCountry ? (myScale(linkedCountries[d.id])) : 0 + 'em')
                    .attr('text-anchor', 'middle')
                    .attr('dy', '.3em')
                    .attr('opacity', 0)
                    .transition()
                    .delay(300)
                    .duration(500)
                    .attr('opacity', 1);

                circlesOverlay
                    .append('text') //  d.iso !== undefined ? d.iso.iso : 'NC') + ' - ' + linkedCountries[d.id]
                    .attr('class', 'zoomed-text')
                    .text((d) => d.id in linkedCountries && d.id !== pinnedCountry ? d.id : null)
                    .attr('font-size', 1.6 + 'em')
                    .attr('cursor', 'pointer')
                    .attr('dy', '0em')
                    .attr('text-anchor', 'middle')
                    .attr('opacity', 0);

                circlesOverlay
                    .append('text') //  d.iso !== undefined ? d.iso.iso : 'NC') + ' - ' + linkedCountries[d.id]
                    .attr('class', 'zoomed-text')
                    .text((d) => d.id in linkedCountries && d.id !== pinnedCountry ? linkedCountries[d.id] : null)
                    .attr('font-size', 1.6 + 'em')
                    .attr('cursor', 'pointer')
                    .attr('dy', '1.5em')
                    .attr('text-anchor', 'middle')
                    .attr('opacity', 0);
            }

        }
    }

    /************** Removal of old charts / items ************/
    const removeGeoChart = () => {
        d3.select(ref.current).select('.map').selectAll('g').remove();
        // d3.select(ref.current).select('g.legends-group').selectAll('g').remove();
    }

    const removeBarChart = () => {
        d3.select(ref.current).select('.bars').selectAll('g').remove();
        d3.select(ref.current).select('.axis').selectAll('g').remove();
        d3.select(ref.current).select('.axis').selectAll('path').remove();
    }

    const removeBubbles = () => {
        d3.select(ref.current).selectAll('circle').remove();
        d3.select(ref.current).selectAll('text.not-zoomed-text').remove();
        d3.select(ref.current).selectAll('text.zoomed-text').remove();
        d3.select(ref.current).selectAll('.center-circle-line').remove();
        d3.select(ref.current).selectAll('g.legends-group').selectAll('g.legend-bubbles').remove();

    }

    const removeOldActiveStroke = () => {
        d3.select('#Geo-Chart svg .map').selectAll('path')
            .attr('stroke', (d) => {
                return d.id === pinnedCountry ? activeStrokeColorCountry() : standardStrokeColorCountry()
            }).attr('stroke-width', (d) => {
            return d.id === pinnedCountry ? 1 : 0
        });
    }

    /************************ Update chart ********************/
        // calls remove and draw
    const updateGeoChart = () => {
            removeGeoChart();
            drawGeoChart();
        }

    const calculateYearsForBarChart = () => {
        const lookupData = data.lookup;
        const pin = pinnedCountry;
        const yearsToReturn = {};
        if (pin !== '-1' && pin !== undefined) {
            if (lookupData[pin]) {
                const pYears = lookupData[pin].years;
                for (const y in pYears) {
                    if (!pYears.hasOwnProperty(y)) continue;
                    if (yearsToReturn.hasOwnProperty(y)) {
                        yearsToReturn[y].amount += pYears[y];
                    } else {
                        yearsToReturn[y] = {
                            year: parseInt(y, 10),
                            amount: pYears[y],
                            authors: {}
                        };
                    }
                }
            }
        }
        return yearsToReturn;
    }

    const scaleBarChartXY = (yearData) => {

        const [minYear, maxYear] = d3.extent(yearData, (e) => {
            return e.year;
        });

        const maxAmount = d3.max(yearData, (e) => {
            return e.amount;
        });

        return {
            x: d3.scaleBand()
                .domain(d3.range(minYear, maxYear + 1))
                .range([0, barChartWidth], 0, 0),
            y: d3.scaleLinear()
                .domain([0, maxAmount])
                .range([0, barChartHeight])
        }
    }

    const pinCountryById = (id) => {
        if (id === pinnedCountry) {
            setPinnedCountry('-1');
        } else {
            setPinnedCountry(id)
        }
    }

    const focusYear = (year) => {
        if (year === pinnedYear) {
            setPinnedYear('-1');
        } else {
            setPinnedYear(year);
        }
    }


    return (
        <svg ref={ref}>
            <g className="map"/>
            <g className="time">
                <g className="bars"/>
                <g className="axis"/>
            </g>
            <g className="overlay"/>
            <g className="legends-group"/>
        </svg>
    );
}

export default GeoChart;


