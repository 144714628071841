/** Three functions to calculate signal strength with different approaches. The old one is calculateSignalStrength  **/

export const calculateSignalStrengthWithAverageOfAllAmounts = (d, total) => {
    const average = total / 100;
    if (d.amount >= average) {
        return 2;
    } else if (d.amount < average) {
        return 1;
    } else {
        return 1;
    }
}

export const calculateSignalStrengthWithTotalAmount = (d, total) => {
    const percentage = (d.amount / total) * 100;
    if (percentage > 6) {
        return 2;
    } else if (percentage > 1) {
        return 1;
    }
}

export const calculateSignalStrength = (d, totalSearchAnswerAmount) => {
    const percentage = (d.amount / totalSearchAnswerAmount) * 100;
    if (percentage > 8) {
        return 2;
    } else if (percentage > 1) {
        return 1;
    } else {
        return 0;
    }
}