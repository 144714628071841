import React, {useEffect, useRef} from "react";
import * as d3 from 'd3';
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {
    activeColorSchemeAtomFamily,
    activePathSchemeAtomFamily,
    brushesAtomFamily,
    visualizationPinAtomFamily,
} from "../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import {recursiveDeepClone} from "../../../../../utility";
import * as _ from 'lodash';

function OverviewParallelCoordinatesChart(props) {
    const ref = useRef();
    const componentId = props.id;
    const containerWidth = props.width;
    const containerHeight = props.height;
    const toggleFacetFilter = props.toggleFacetFilter;
    let chartData = recursiveDeepClone(props.data);
    let splitData = recursiveDeepClone(props.splitData);
    const [pinId, setNewPinId] = useRecoilState(visualizationPinAtomFamily(componentId));
    const resetPinId = useResetRecoilState(visualizationPinAtomFamily(componentId))
    const colorScheme = useRecoilValue(activeColorSchemeAtomFamily(componentId));
    const pathDrawing = useRecoilValue(activePathSchemeAtomFamily(componentId));
    const [brushes, setBrushes] = useRecoilState(brushesAtomFamily(componentId))
    let currentlyBrushed;
    let activeIndexes = [];
    let fullRedraw = false;

    useEffect(() => {
        updateChart();
    })
    useEffect(() => {
        removeOldGroups();
        // removeAxisBrushes();
        // drawChart();
        fullRedraw = true;
        drawChart();
    },[containerWidth, containerHeight, props.data])



    const updateChart = () => {
        removeLines();
        fullRedraw = false;
        drawChart();
    }


    const removeLines = () => {
        d3.select(ref.current)
            .selectAll('g')
            .selectAll('g .foreground').remove();

        d3.select(ref.current)
            .selectAll('g')
            .selectAll('g .background').remove();
    }

    const removeOldGroups = () => {
        d3.select(ref.current)
            .selectAll('g')
            .remove();
    }

    const removeAxisBrushes = () => {
        d3.select(ref.current)
            .selectAll('g')
            .selectAll('g .dimension')
            .remove();
    }

    const pinElementById = (pin) => {
        if (pin === pinId) {
            resetPinId();
        } else {
            setNewPinId(pin)
        }
    }

    const setFacetFilter = (facet, value) => {
        toggleFacetFilter(facet, value)
    }



    const lineColor = () => {
        const dimensions = d3.keys(chartData[0]).filter((d) => {
            return d !== 'name'
        });
        const cols = {};
        // tslint:disable-next-line:forin
        for (const i in dimensions) {
            // @ts-ignore
            const name = dimensions[i];
            cols[name] = d3.scaleSequential()
                .domain(d3.extent(chartData, (d) => {
                    return +d[name];
                }))
                 .interpolator(d3[colorScheme]);
        }
        return cols;
    };


    const drawChart = () => {
        const axis = d3.axisLeft();
        let dimensions = null;
        const pathDrawingMethod = pathDrawing;
        const lineColors = lineColor();

        const svg = d3.select(ref.current);

        svg.attr('width', containerWidth)
            .attr('height', containerHeight);

        dimensions = d3.keys(splitData)
            .filter((d) => d !== 'name');

        // For each dimension, I build a linear scale. I store all in a y object
        const y = {};

        dimensions.forEach((d) => {
            y[d] = d3.scaleLinear()
                .domain(d3.extent(chartData, (p) => +p[d]))
                .range([containerHeight - 20, 0])
        });

        const x = d3.scalePoint()
            .range([0, containerWidth])
            .padding(0.1)
            .domain(dimensions);

        const chartGroup = svg
            .append('g')
            .attr('transform', 'translate(' + 0 + ',' + 20 + ')');

        /**
         *  Implementation of differenct pathes e.g. bezier..
         *  CC0 Licence and extracted from arielmant0 @ https://observablehq.com/@arielmant0/bezier-parallel-coordinate-plots
         */

        const paths = {
            straight_path(d, dim, next) {
                return d3.line()([[x(dim), y[dim](d.from)], [x(next), y[next](d.to)]]);
            },
            bezier_path(d, dim, next) {
                return d3.linkHorizontal()
                    .x(([a]) => a)
                    .y(([, b]) => b)
                    ({source: [x(dim), y[dim](d.from)], target: [x(next), y[next](d.to)]})
            },
            bezier_path_down(d, dim, next) {
                const p = d3.path();
                p.moveTo(x(dim), y[dim](d.from));
                p.quadraticCurveTo(x(dim), y[next](d.to), x(next), y[next](d.to));
                return p.toString();
            },
            bezier_path_up(d, dim, next) {
                const p = d3.path();
                p.moveTo(x(dim), y[dim](d.from));
                p.quadraticCurveTo(x(next), y[dim](d.from), x(next), y[next](d.to));
                return p.toString();
            }
        };

        const foreground = chartGroup
            .append('g')
            .attr('class', 'foreground');

        const background = chartGroup
            .append('g')
            .attr('class', 'background');

        foreground
            .selectAll('g')
            .selectAll('path')
            .remove();

        background
            .selectAll('g')
            .selectAll('path')
            .remove();


        const click = (d) => {
            pinElementById(d.index)
        };

        // defines what happens when the mouse is over a bar.
        const onMouseOver = (d, i, n) => {
            const currElement = n[i];
            d3.select(currElement).attr('stroke-width', 4);
        };

        const onMouseOut = (d, i, n) => {
            const currElement = n[i];
            d3.select(currElement).attr('stroke-width', d.index !== pinId ? 1 : 4);
        };

        const addLinesBackground = (dim, next, data) => {
            foreground
                .append('g')
                .attr('class', dim)
                .selectAll('path')
                .data(data)
                .enter()
                .append('path')
                .on('mouseover', onMouseOver)
                .on('mouseout', onMouseOut)
                .on('click', click)
                // .attr('class', (d, i) => 'path-' + i)
                // here we will differentiate the methods
                .attr('d', (d) => d.active !== true ? paths[pathDrawingMethod](d, dim, next) : null)
                .attr('stroke', (d) => d.index !== pinId ? lineColors[dim](d.from) : 'red')
                .attr('stroke-width', (d) => d.index === pinId ? 4 : 1)
                .attr('stroke-opacity', (d) => {
                    if (pinId !== -1) {
                        if (d.index === pinId) {
                            return 1;
                        } else {
                            return 0.2;
                        }
                    } else {
                        return 1;
                    }
                })
                .attr('fill', 'none')
                .attr('cursor', 'pointer')
                .append('title')
                .text((d) => d.name);
        };

        const addLinesForeground = (dim, next, data) => {
            background
                .append('g')
                .attr('class', dim)
                .selectAll('path')
                .data(data)
                .enter()
                .append('path')
                .on('mouseover', onMouseOver)
                .on('mouseout', onMouseOut)
                .on('click', click)
                // .attr('class', (d, i) => 'path-' + i)
                // here we will differentiate the methods
                .attr('d', (d) => d.active ? paths[pathDrawingMethod](d, dim, next) : null)
                .attr('stroke', (d) => d.index !== pinId ? '#eeb33f' : 'red')
                .attr('stroke-width', (d) => d.index === pinId ? 4 : 2)
                .attr('stroke-opacity', (d) => {
                    if (pinId !== -1) {
                        if (d.index === pinId) {
                            return 1;
                        } else {
                            return 0.2;
                        }
                    } else {
                        return 1;
                    }
                })
                .attr('fill', 'none')
                .attr('cursor', 'pointer')
                .append('title')
                .text((d) => d.name);
        };

        // filter function for brushes. Calculates the intercetion of available line indexes.
        if (brushes !== undefined) {
            activeIndexes.splice(0, activeIndexes.length);
            // const calcActive = () => {
            brushes.forEach((g, j) => {
                for (const [key, value] of Object.entries(splitData)) {
                    if (key === g.dimension) {
                        const testArray = [];
                        // @ts-ignore
                        value.forEach((h) => {
                            if (brushes[j].extent[1] <= h.from && h.from <= brushes[j].extent[0]) {
                                //console.log(h);
                                testArray.push(h.index);
                            }
                        });
                        activeIndexes.push(testArray);
                    }
                }
            });

            for (const [key, value] of Object.entries(splitData)) {
                // @ts-ignore
                value.map((l) => {
                    l.active = false;
                })
            }

            //console.log(this.activeIndexes);

            const intersection = _.intersection.apply(_, activeIndexes)

            //console.log(intersection);

            for (const [key, value] of Object.entries(splitData)) {
                // @ts-ignore
                value.map((l) => {
                    intersection.forEach((a, b) => {
                        if (l.index === a) {
                            l.active = true;
                        }
                    })
                })
            }

        }

        for (let i = 0; i < dimensions.length; ++i) {
            const next = i < dimensions.length - 1 ? i + 1 : i - 1;
            addLinesBackground(dimensions[i], dimensions[next], splitData[dimensions[i]]);
            addLinesForeground(dimensions[i], dimensions[next], splitData[dimensions[i]]);
        }

        // we dont want to fire the store event too often, so we made it async
        const brushDef = () => {
            const actives = [];
            chartGroup.selectAll('.brush')
                .filter((d, i, n) => {
                    const currElement = n[i];
                    y[d].brushSelectionValue = d3.brushSelection(currElement);
                    return d3.brushSelection(currElement);
                })
                .each((d, i, n) => {
                    const currElement = n[i];
                    // Get extents of brush along each active selection axis (the Y axes)
                    actives.push({
                        dimension: d,
                        extent: d3.brushSelection(currElement).map(y[d].invert)
                    });
                });
            currentlyBrushed = actives;
        };

        const brushEnd = () => {
            // console.log(this.currentlyBrushed);
            // this.store.dispatch(new StoreCurrentBrushes(this.currentlyBrushed, this.componentId))
            setBrushes(currentlyBrushed);
        };

        const drawAxis = () => {
            // Add a group element for each dimension.
            const g = chartGroup.selectAll('.dimension')
                .data(dimensions)
                .enter()
                .append('g')
                .attr('class', 'dimension')
                .attr('transform', (d) => 'translate(' + x(d) + ')');

            // Add an axis and title.
            g.append('g')
                .attr('class', 'axis')
                .each((d, i, n) => {
                    const currElement = n[i];
                    if (d === 'name') {
                        // Object.keys(this.splitData.name).forEach((key, j) => {
                        // d3.select(currElement).append('text').text(key).attr('x', 5).attr('y',i * 10 + 4)
                        // })
                    } else
                        d3.select(currElement)
                            .call(axis.scale(y[d]))
                });
            g
                .append('text')
                .attr('class', 'whiteOnDarkMode')
                .attr('text-anchor', 'middle')
                .attr('y', -9)
                .text((d) => d);

            // Add brush for each axis.
            g.append('g')
                .attr('class', 'brush')
                .each((d, i, n) => {
                    const currElement = n[i];
                    d3.select(currElement)
                        .call(currElement.brush = d3.brushY()
                            .extent([[-10, 0], [10, containerHeight]])
                            .on('brush', brushDef)
                            .on('end', brushEnd)
                        )
                })
                .selectAll('rect')
                .attr('x', -8)
                .attr('width', 16);
        };

        // also redraw axis when true
         if (fullRedraw === true) {
          removeAxisBrushes();
             drawAxis();
          }
    }



    return (
        <svg ref={ref}>
            <g/>
        </svg>
    );

}

export default OverviewParallelCoordinatesChart;


