import React from "react";
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import * as _ from "lodash";
import {
    activeFilterSetIdAtom,
    facetFiltersAtomFamily
} from "../../../../../../../dataProvider/vissights/search/filters/filters";
import {sliderValuesAtomFamily} from "../../../../../../GlobalNavigations/LeftNavigation/LeftNavigationState";
import {treeMapBarChartDataActiveSelectorFamily} from "./treemap-bar-chart-state";
import TreeMapBarChart from "./TreeMapBarChart/TreeMapBarChart";
import TreeMapChartRightControls from "../TreeMapLayout/TreeMapChart/controls/TreeMapChartRightControls";
import TreeMapBarChartRightControls from "./TreeMapBarChart/controls/TreeMapBarChartRightControls";

function TreeMapBarChartLayout(props) {
    const componentId = props.id;
    // const dataModel = useRecoilValue(preProcessedFilteredSearchDataSelector);
    const activeFilterSetId = useRecoilValue(activeFilterSetIdAtom);
    const [facetFilters, setFacetFilters] = useRecoilState(facetFiltersAtomFamily(activeFilterSetId.toString()));
    const setSliderValues = useSetRecoilState(sliderValuesAtomFamily(activeFilterSetId.toString()));
    const resetSliderValues = useResetRecoilState(sliderValuesAtomFamily(activeFilterSetId.toString()));
    const treeMapBarChartData = useRecoilValue(treeMapBarChartDataActiveSelectorFamily(componentId));
    // stuff we pass to top controls
    const topControlConfig = props.topControls;
    const deleteContainer = props.deleteContainer;

    const toggleFacetFilter = (data) => {
        let facet;
        let value;
        const clone = _.cloneDeep(facetFilters);
        data.forEach((d) => {
            facet = d.facet;
            value = d.value;
            value = value.toString()

            // check if the facet is already created as another filter in that facet is created or sth like that.
            const isAlreadyCreated = clone.find((d) => {
                return d.facet === facet;
            });
            if (isAlreadyCreated !== undefined) {
                // check if we have to reset the slider.
                if (facet === 'Years') {
                    resetSliderValues();
                }
                clone.map((d) => {
                    if (d.facet === facet) {
                        // check if value is already set...
                        const valueAlready = d.values.find((e, i) => {
                            return e === value;
                        })
                        // if value is already set we remove it
                        if (valueAlready !== undefined) {
                            d.values = d.values.filter((d) => d !== value);
                        }
                        // if its new we add it.
                        else {
                            // d.value = d.value.push(value);
                            d.values.push(value);
                        }
                    }
                    return d;
                })
            } else {
                // check if we have to change the slider.
                if (facet === 'Years') {
                    setSliderValues([Number(value), Number(value)])
                }
                clone.push({facet: facet, values: [value]});
            }
        })


        //remove no longer used facets
        const filteredClone = clone.filter((d) => d.values.length > 0);
        setFacetFilters(filteredClone);
    }

    return (
        <div style={{display: "flex"}}>
            <div style={{width: props.containerDimensions[0]}}>
                <TreeMapBarChart id={componentId}
                                 data={treeMapBarChartData}
                                 width={props.containerDimensions[0]}
                                 height={props.containerDimensions[1]}
                                 toggleFacetFilter={toggleFacetFilter}
                                 toggleRightControls={props.toggleRightControls}
                                 displayRightNav={props.displayRightNav}
                                 toggleContextMenu={props.toggleContextMenu}/>
            </div>
            <div style={{width: 200, overflowY: "hidden"}}>
                <TreeMapBarChartRightControls id={componentId}
                                           height={props.containerDimensions[1]}
                                           width={props.containerDimensions[0]}
                                           topControlConfig={topControlConfig}
                                           toggleRightControls={props.toggleRightControls}
                                           displayRightNav={props.displayRightNav}/>
            </div>
        </div>
    );
}

export default TreeMapBarChartLayout;
