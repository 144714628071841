import React, {useEffect, useRef} from "react";
import * as d3 from 'd3';
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {
    visualizationPinAtomFamily,
} from "../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import {activeFacetAtomFamily} from "../../../overview/visualizations/state";
import {
    calculatePieSliceColorBySignalStrength, pieChartActivePieFillColor, pieChartActivePieStrokeColor,
    pieChartStandardPieStrokeColor,
    pieSlicePreviewColor
} from "../../../../../styles/colors";


function PieChart(props) {
    const componentId = props.id;
    const ref = useRef()
    const containerWidth = props.width;
    const containerHeight = props.height;
    const calculatedData = props.data;
    const toggleFacetFilter = props.toggleFacetFilter;
    // const [hoveredElementId, setHoveredElementId] = useRecoilState(hoveredElementIdAtomFamily(componentId));
    const [pinId, setNewPinId] = useRecoilState(visualizationPinAtomFamily(componentId));
    const resetPinId = useResetRecoilState(visualizationPinAtomFamily(componentId))
    const activeFacet = useRecoilValue(activeFacetAtomFamily(componentId));
    const toggleContextMenu = props.toggleContextMenu;

    useEffect(() => {
        createChart();
    });

    const createChart = () => {
        // rebind zoom on changes.
        removeChart();
        // drawAxisAndOverlay();
        updateChart();
    }


    const pinElementById = (pin) => {
        if (pin === pinId) {
            resetPinId();
        } else {
            setNewPinId(pin)
        }
    }

    const setFacetFilter = (facet, value) => {
        toggleFacetFilter(facet, value)
    }



    const updateChart = () => {
        /************* Define variables *************/
        const w = containerWidth;
        const h = containerHeight;
        const data = calculatedData;
        const radius = (Math.min(w, h) / 2) - 5;

        d3.select(ref.current)
            .attr('width', containerWidth)
            .attr('height', containerHeight)


        /************ Define our shorthand arrow functions, they do simple tasks and definitions for us *********/
            // defines what happens when the mouse is over a bar.
        const onMouseOver = (d, i, n) => {
                // element n[i] refers to the current element
                const currentElement = n[i];
                d3.select(currentElement)
                    .attr('fill', pieChartActivePieFillColor)
                    .attr('stroke', pieChartActivePieStrokeColor)
                    .attr('opacity', 1);
            };

        // defines what happens when the mouse leaves a bar.
        const onMouseLeave = (d, i, n) => {
            // element n[i] refers to the current element
            const currentElement = n[i];
            d3.select(currentElement)
                .attr('fill', (e) => calculatePieSliceColorBySignalStrength(e.data.signal))
                .attr('stroke', pieChartStandardPieStrokeColor);
            chartGroup.selectAll('path')
                .attr('opacity', (e) => calculateOpacity(e));
        };

        // checks if a pin is set, and changes the opacity.
        const calculateOpacity = (d) => {
            // wenn es einen aktiven pin gibt.
            if (pinId !== '-1') {
                return d.data.id === pinId ? 1 : 0.4;
            } else {
                return 1;
            }
        };

        // Computes the angle of an arc, converting from radians to degrees.
        const calculateAngle = (d) => {
            const angle = (d.startAngle + d.endAngle) * 90 / Math.PI - 90;
            return angle > 90 ? angle - 180 : angle;
        };

        const shortenText = (text, maxLength) => {
            let shortText = text;
            const length = text.length;
            if (length > maxLength / 8) {
                shortText = text.slice(0, maxLength / 8);
                shortText = shortText.slice(0, shortText.lastIndexOf(' '));
                return shortText + '..';
            }
            return shortText;
        }

        // Define the wrapping group
        const chartGroup =
            d3.select(ref.current)
                .select('g');
        // translate to the center of the container
        chartGroup.attr('transform', 'translate(' + (containerWidth / 2) + ',' + ((containerHeight) / 2) + ')');

        // Calculate the pie layout
        const pie = d3.pie()
            .value((d) => d.y)
            .padAngle(.01)
            .sort(null);
        // Call the calculation function with our data
        const dataReady = pie(data);

        // pieces are stored in a group for each slice with the text elements.
        const pieces = chartGroup
            .selectAll('path')
            .data(dataReady)
            .enter()
            .append('g');

        const arc = d3.arc()
            // change inner radius to create a donut chart
            .innerRadius(0)
            .outerRadius(radius);

        // adds the circle and the arcs.
        pieces
            .append('path')
            .attr('d', arc)
            .attr('fill', (d) => d.data.preview ? pieSlicePreviewColor() : calculatePieSliceColorBySignalStrength(d.data.signal))
            .attr('stroke', pieChartStandardPieStrokeColor)
            .attr('stroke-width', '1')
            .attr('opacity', (d) => calculateOpacity(d))
            .attr('cursor', 'pointer')
            .on('mouseover', onMouseOver)
            .on('mouseleave', onMouseLeave)
            .on('click', (d) => pinElementById(d.data.id))
            .on('dblclick', (d) => setFacetFilter(activeFacet, d.data.id))
            .on('contextmenu', (d) => toggleContextMenu(d.data.name, ref.current));


        // append text labels
        pieces
            .append('text')
            .text((d) => ((d.endAngle - d.startAngle) > 0.2) ? shortenText(d.data.name, (pieces._groups[0][0].getBBox().width)) : '')
            .attr('cursor', 'pointer')
            .attr('text-anchor', 'middle')
            .attr('dy', '.35em')
            .attr('transform', (d) => 'translate(' + arc.centroid(d) + ')rotate(' + calculateAngle(d) + ')')
            .attr('opacity', (d) => calculateOpacity(d));

        // Append tooltips.
        pieces
            .append('title')
            .text((d) => d.data.name + ' (' + d.data.y + ')');

    }

    const removeChart = () => {
        d3.select(ref.current).select('g').selectAll('path').remove();
        d3.select(ref.current).select('g').selectAll('text').remove();
    }

    return (
        <svg ref={ref}>
            <g/>
        </svg>
    );
}

export default PieChart;


