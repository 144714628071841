import React from "react";
import * as d3 from 'd3';
import {useRecoilState, useRecoilValue} from "recoil";
import LineChart from "./LineChart";
import * as _ from "lodash";
import LineChartRightControls from "./controls/LineChartRightControls";
import LineChartTopControls from "./controls/LineChartTopControls";
import {lineChartChartDataAtomFamily} from "./state/LineChartState";
import {hoveredElementIdAtomFamily} from "../../../../Dashboards/VisualizationContainer/state/visualizationContainerState";
import {useNormalizationAtomFamily} from "../state";


function LineChartContainer(props) {
    const componentId = props.id;
    const setModuleToDisplay = props.setModuleToDisplay;
    const setSearchTerm = props.setSearchTerm;
    // const activeAggregation = props.topControls[0].activeAggregation;
    const topControlConfig = props.topControls[0];
    const [chartData, setChartData] = useRecoilState(lineChartChartDataAtomFamily(componentId));
    const clone = _.cloneDeep(chartData);
    // const [hoveredElementId, setHoveredElementId] = useRecoilState(hoveredElementIdAtomFamily(componentId));
    const chartDataActive = clone.filter((d) => (d.active === true) || (d.hovered === true))
    const useNormalization = useRecoilValue(useNormalizationAtomFamily(componentId));
    // stuff we pass to top controls
    const deleteContainer = props.deleteContainer;
    // some final changes to bring the active chart data in the correct form for displaying the viz
    const calculateData = () => {
        const minYear = parseInt(d3.min(chartDataActive, (t) => {
            return d3.min(Object.keys(t.years));
        }), 10);
        const maxYear = parseInt(d3.max(chartDataActive, (t) => {
            return d3.max(Object.keys(t.years));
        }), 10);

        const calulatedChartDataActive = chartDataActive.map((t) => {
                const r = [];
                for (let year = minYear; year <= maxYear; year++) {
                    r.push({
                        x: year,
                        y: !useNormalization ? t.years[year] : t.yearsNormalized[year]
                    });
                }
                // console.log(t);
                r['id'] = t.id;
                r['name'] = t.text;
                r['amount'] = t.amount;
                r['color'] = t.color;
                return r;
            }
        );
        return calulatedChartDataActive;
    }
    const calculatedData = calculateData(chartDataActive)

    return (
        <div>
            <LineChartTopControls topControlConfig={topControlConfig}
                                  deleteContainer={deleteContainer}
                                  id={props.id}/>
            <div style={{display: "flex"}}>
                <div style={{width: props.containerDimensions[0]}}>
                    <LineChart     data={calculatedData}
                                   id={props.id}
                                   width={props.containerDimensions[0]}
                                   height={props.containerDimensions[1]}
                                   setSearchTermAndRerouteToZoomAndFilter={props.setSearchTermAndRerouteToZoomAndFilter}
                    />
                </div>
                <div style={{width: 200, overflowY: "hidden"}}>

                    <LineChartRightControls id={props.id}
                                            height={props.containerDimensions[1]}
                                            width={props.containerDimensions[0]}
                                            topControlConfig={topControlConfig}
                                            setLocalChartData={setChartData}
                                            toggleRightControls={props.toggleRightControls}
                                            displayRightNav={props.displayRightNav}/>
                </div>
            </div>
        </div>
    );
}

export default LineChartContainer;