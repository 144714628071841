import React from 'react';
import RightNavigationItem from "./RightNavigationItem/RightNavigationItem";
import {moduleToDisplayAtom} from "../../Dashboards/DashboardLayout";
import {useRecoilValue} from "recoil";
import {
    availableVisualizationsOverviewSelector,
    availableVisualizationsZoomAndFilterSelector
} from "./state/RightNavigationState";

function RightNavigation() {
    const module = useRecoilValue(moduleToDisplayAtom);
    const visualizationsZoomAndFilter = useRecoilValue(availableVisualizationsZoomAndFilterSelector);
    const visualizationsOverview = useRecoilValue(availableVisualizationsOverviewSelector);

    return (
        <div style={{marginTop: 8}}>
            {
                module === 'overview' ?
                    visualizationsOverview.map((d) => {
                        return (<RightNavigationItem key={d.visType} visType={d.visType} config={d}/>)
                    }) :
                    visualizationsZoomAndFilter.map((d) => {
                        return (<RightNavigationItem key={d.visType} visType={d.visType} config={d}/>)
                    })

            }
        </div>
    );
}

export default RightNavigation;

/*
visualizations.map((d) => {
            return (
                d.module === module ?
                    <RightNavigationItem key={d.visType} visType={d.visType}  config={d}/>
                    :
                    null
            )
        })
 */