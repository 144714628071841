import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {Col, DropdownButton, Form, Row, Dropdown, Button, SplitButton} from "react-bootstrap";
import Logo from '../../Misc/icons/logo/Logo';
import {
    assistedSearchAtom,
    displaySearchAdjustmentsModalAtom,
    searchDatabase,
    searchTermAtom, searchTermSelector
} from "../../../dataProvider/vissights/search/search";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faSearchPlus,
    faSearch,
    faChartBar,
    faCamera,
    faChartColumn,
    faUser,
    faSignOut, faChartSimple
} from '@fortawesome/free-solid-svg-icons'
import {moduleToDisplayAtom} from "../../Dashboards/DashboardLayout";
// import SearchAdjustmentsModal from "./modals/searchAdjustments";
import {
    displayRightSideNavAtom,
    mainNavigationHeightAtom
} from "../../../config/sizings/sizings";
import {availableDatabases} from "../../../dataProvider/vissights/overview/overview";
import IconReporting from "../../Misc/icons/menu/ReportingIcon";
import {useHistory} from 'react-router-dom'
import routes from './../../../config/routes';
import {currentThemeAtom, themeOptionsAtom} from "../../../styles/colors";
import domtoimage from "dom-to-image";
import uuid from "react-uuid";
import * as _ from "lodash";
import {
    dashboardScreenshotsAtom
} from "../../VisualizationModules/reporting/state/ReportingState";
import {displaySavedScreenshotModalAtom} from "../../Misc/Modals/SavedScreenshot";
import DropdownItem from "react-bootstrap/DropdownItem";
import SearchAdjustmentsModal from "./modals/searchAdjustments";
import keycloak from "../../../dataProvider/vissights/authentication/keycloak";
import IconProfile from "../../Misc/icons/profile/Profile";

function MainNavigation() {
    const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom);
    const actualSearchTerm = useRecoilValue(searchTermSelector);

    // const setStartSearch = useSetRecoilState(startSearchEvent);
    const [currDb, setDb] = useRecoilState(searchDatabase);
    // theming
    const themeOptions = useRecoilValue(themeOptionsAtom);
    // we use the available dbs from the backend.
    const availDbs = useRecoilValue(availableDatabases);
    const module = useRecoilValue(moduleToDisplayAtom);
    const mainNavigationHeight = useSetRecoilState(mainNavigationHeightAtom);
    // toggle the modal for assisted / advanced search -> we call that search Adjustments for now...
    const [displayModal, setDisplayModal] = useRecoilState(displaySearchAdjustmentsModalAtom);
    //const setDisplayModal = useSetRecoilState(displaySearchAdjustmentsModalAtom);
    const [currentTheme, setCurrentTheme] = useRecoilState(currentThemeAtom);
    const [displayRightSideNav, setDisplayRightSideNav] = useRecoilState(displayRightSideNavAtom);
    const [currentSearchTerm, setCurrentSearchTerm] = useState('');
    let history = useHistory()
    const [dashboardScreenshots, setDashboardScreenshots] = useRecoilState(dashboardScreenshotsAtom);
    const setDisplayScreenshotModal = useSetRecoilState(displaySavedScreenshotModalAtom);
    const useAssistedSearch = useRecoilValue(assistedSearchAtom);


    const startSearching = async () => {
        // call a search and change the module.
        //if(useAssistedSearch){
        //    await setSearchTerm(currentSearchTerm);
        //    // await setSearchTerm(actualSearchTerm);
        //    setCurrentSearchTerm(actualSearchTerm);
        //} else {
        //    await setSearchTerm(currentSearchTerm);
        //}
        await setSearchTerm(currentSearchTerm);
        history.push(routes.modules.zoomAndFilter)
    }

    useEffect(() => {
        // we have to overwrite the local state search term with what we store inside searchTermAtom.
        // this is necessary as we may change the search term from another component like visualizations...
        // console.log(searchTerm);
        setCurrentSearchTerm(searchTerm);
    }, [searchTerm])


    //useEffect(() => {
    //    if(keycloak.authenticated){
    //        history.push(routes.modules.profile);
    //    }
    //}, [keycloak.authenticated])


    const changeTheme = (theme) => {
        if (theme === 'dark') {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
        setCurrentTheme(theme);
    }

    const createDashboardScreenshot = () => {
        const node = document.getElementById('root'); // document.getElementById('componentId');
        domtoimage.toPng(node)
            .then((dataUrl) => {
                const newUUID = uuid();
                // store the image, the module, and the current database
                const autoGeneratedText = 'The following dashboard...';
                const metaDataObj = {
                    id: newUUID,
                    dataUrl,
                    module: module,
                    db: currDb.name,
                    searchTerm: searchTerm,
                    autoGeneratedText: autoGeneratedText
                }
                const clone = _.cloneDeep(dashboardScreenshots)
                clone.push({id: newUUID, data: metaDataObj});
                setDashboardScreenshots(clone);
            }).then(() => setDisplayScreenshotModal(true));
    }

    /** Define UI components in functional components themself to clean up code**/

    const SplitButtonDBChoose = () => {
        return (
            <SplitButton style={{width: 150, paddingTop: 10}} variant="light" id="dropdown-basic-button"
                         title={
                             <span style={{display: 'flex', height: 25}}>
                                         <span style={{}}> {currDb.icon}</span>
                                     <span style={{paddingTop: 0}}>{currDb.displayName}</span>
                                 </span>
                         }>
                {
                    availDbs.map((d) => {
                        return <Dropdown.Item className={currDb.name === d.name ? 'active' : ''}
                                              key={d.name}
                                              style={{
                                                  backgroundColor: currDb.name === d.name ? '#0069d9' : 'white',
                                                  display: 'flex',
                                                  paddingLeft: 10,
                                                  paddingRight: 10
                                              }}
                                              onClick={() => {
                                                  setDb(d);
                                              }}>
                            <span> {d.icon} </span>
                            <span style={{paddingLeft: 10}}>{d.displayName.toString()}</span>
                        </Dropdown.Item>
                    })
                }
            </SplitButton>
        )
    }

    /*
    const SearchField = () => {
        return (
            <div style={{display: 'flex'}}>
                <FontAwesomeIcon style={{height: 30, marginRight: 5, cursor: "pointer"}} icon={faSearchPlus}
                                 onClick={() => {
                                     setDisplayModal(true);
                                 }}>
                </FontAwesomeIcon>
                <Form>
                    <Form.Group controlId="main-navigation-search-form" className="main-navigation-search-form">
                        <Form.Control style={{borderRadius: 17, width: 220}} size="sm" type="text"
                                      placeholder="search..." value={currentSearchTerm}
                                      onChange={(e) => {
                                          setCurrentSearchTerm(e.target.value);
                                      }}/>
                    </Form.Group>
                </Form>
                <Button className="button-nav" onClick={() => {
                    startSearching();
                }}>
                    <FontAwesomeIcon icon={faSearch}/>
                </Button>
            </div>
        );
    }
    */

    const DropdownReportingTools = () => {
        return (
            <DropdownButton style={{width: 180}} variant="light" id="dropdown-basic-button"
                            title={'Reporting'}>
                <DropdownItem onClick={() => createDashboardScreenshot()}>
                    <FontAwesomeIcon
                        style={{marginRight: 10, width: 20, height: 20, cursor: "pointer"}}
                        icon={faCamera}
                    /> Screenshot Dashboard
                </DropdownItem>
                {
                    module !== 'reporting'?
                        <DropdownItem variant="light" className="svgIconReporting" onClick={() => {
                            //setModuleToDisplay('reporting')
                            history.push(routes.modules.reporting)
                        }}
                                      style={{display: 'flex', cursor: 'pointer'}}>
                            <IconReporting style={{width: 30, height: 30}}/> Create Report
                        </DropdownItem>
                        :
                        <DropdownItem variant="light" style={{display: 'flex', cursor: 'pointer'}}
                                      onClick={() => {
                                          startSearching();
                                      }}>
                            <FontAwesomeIcon style={{marginTop: 5, marginRight: 5}} icon={faChartSimple}/>
                            Go to Zoom and Filter
                        </DropdownItem>
                }

            </DropdownButton>
        )
    }

    const DropdownThemeSelect = () => {
        return (
            <DropdownButton style={{width: 180}} variant="light" id="dropdown-basic-button"
                            title='Theme'>
                {
                    themeOptions.map((d) => {
                        return <Dropdown.Item
                            className={currentTheme === d ? 'active' : ''}
                            key={d}
                            style={{
                                backgroundColor: currentTheme === d ? '#0069d9' : 'white',
                                display: 'flex',
                                paddingLeft: 10,
                                paddingRight: 10
                            }}
                            onClick={() => {
                                changeTheme(d);
                            }}>
                            {d}
                        </Dropdown.Item>
                    })
                }
            </DropdownButton>
        )
    }

    const ToggleDisplayCharts = () => {
        return (

            module !== "reporting" && module !== "profile" ?
                <div style={{float: 'right', cursor: 'pointer'}}
                     onClick={() => setDisplayRightSideNav(!displayRightSideNav)}>
                    <div style={{display: 'flex'}}>
                        {displayRightSideNav ? <div>Hide</div> : <div>Display</div>}
                        <FontAwesomeIcon icon={faChartColumn} style={{marginTop: 4, marginLeft: 4}}/>
                    </div>
                </div>
                : null

        )
    }


    const login = () => {

    }


    return (
        <div className="MainNav" style={{height: mainNavigationHeight}}>
            <Row className='ml-0 mr-0 pt-0'>
                <Col xs={1} className='pt-0.5' style={{cursor: 'pointer'}}
                     onClick={() => {
                         history.push(routes.modules.overview)
                     }}>
                    <Logo/>
                </Col>
                <Col xs={2}/>
                <Col xs={2} className="pt-2">
                    <SplitButtonDBChoose/>
                </Col>
                <Col xs={2} className="MainNavItem">
                    <div style={{display: 'flex'}}>
                        <FontAwesomeIcon style={{height: 20, marginRight: 5, marginTop: 5, cursor: "pointer"}}
                                         icon={faSearchPlus}
                                         onClick={() => {
                                             setDisplayModal(true);
                                         }}>
                        </FontAwesomeIcon>
                        <Form onSubmit={() => {
                            startSearching();
                        }}>
                            <Form.Group controlId="main-navigation-search-form" className="main-navigation-search-form">
                                <Form.Control style={{borderRadius: 17, width: 220}} size="sm" type="text"
                                              placeholder="search..." value={currentSearchTerm}
                                              onChange={(e) => {
                                                  setCurrentSearchTerm(e.target.value);
                                              }}/>
                            </Form.Group>
                        </Form>
                        <Button className="button-nav" onClick={() => {
                            startSearching();
                        }}>
                            <FontAwesomeIcon icon={faSearch}/>
                        </Button>
                    </div>
                </Col>
                <Col xs={1} className="MainNavItem">
                    <DropdownReportingTools/>
                </Col>
                <Col xs={1} className="MainNavItem">
                    <DropdownThemeSelect/>
                </Col>
                <Col xs={1}/>


                <Col xs={1} className="mt-2" >
                    {!keycloak.authenticated ?
                            <div className='iconWrapper' style={{float: "right"}} onClick={() => keycloak.login()} >
                                <i>
                                    <FontAwesomeIcon style={{height: 15, marginRight: 0, marginTop: 0, cursor: "pointer"}}
                                                     icon={faUser}/>
                                </i>
                            </div>
                        :
                        <div style={{display: 'flex', float: "right"}}>
                            <div style={{cursor: 'pointer',display: 'flex', marginRight: 10}}
                                 onClick={() => history.push(routes.modules.profile)}>
                                <IconProfile/>
                                <span style={{ marginLeft: 6, marginTop: 3 }}>
                                    <b>{keycloak.tokenParsed.preferred_username}</b>
                                </span>
                            </div>
                            <span
                                onClick={() => keycloak.logout()}
                            >
                            <FontAwesomeIcon style={{height: 20, marginRight: 0, marginTop: 5, cursor: "pointer"}}
                                             icon={faSignOut}/>
                        </span>
                        </div>
                    }
                </Col>
                <Col xs={1} className='mt-5'>
                    <ToggleDisplayCharts/>
                </Col>
            </Row>
            {displayModal ? <SearchAdjustmentsModal/> : null}
        </div>
    );
}

export default MainNavigation;
