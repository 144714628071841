import React from "react";
import * as d3 from 'd3';
import {useRecoilState, useRecoilValue} from "recoil";
import TemporalCloud from "./TemporalCloud";
import TemporalCloudTopControls from "./controls/TemporalCloudTopControls";
import TemporalCloudRightControls from "./controls/TemporalCloudRightControls";
import {atom} from "recoil";
import MaxItemsModal from "./MaxItemsModal";
import {temporalCloudChartDataAtomFamily} from "./state/TemporalCloudState";
import {genericSort} from "../../../../../utility";
import * as _ from "lodash";
import {useNormalizationAtomFamily} from "../state";
// call the fetchTopicOverview selector and store it as default value for the data atom
export const displayMaxItemsModalAtom = atom({
    key: "displayMaxItemsModal-Key",
    default: false,
});

function TemporalCloudContainer(props) {
    const componentId = props.id;
    // const activeAggregation = props.topControls[0].activeAggregation;
    const topControlConfig = props.topControls[0];
    const [chartData, setChartData] = useRecoilState(temporalCloudChartDataAtomFamily(componentId));
    const clone = _.cloneDeep(chartData);
    const chartDataActive = clone.filter((d) => d.active === true)
    // stuff we pass to top controls
    const deleteContainer = props.deleteContainer;
    const [displayModal, setDisplayModal] = useRecoilState(displayMaxItemsModalAtom);
    const useNormalization = useRecoilValue(useNormalizationAtomFamily(componentId));


    // some final changes to bring the active chart data in the correct form for displaying the viz
    const calculateData = () => {
        chartDataActive.sort(genericSort('amount', 'desc'))
        const minYear = parseInt(d3.min(chartDataActive, (t) => {
            return d3.min(Object.keys(t.years));
        }), 10);

        const maxYear = parseInt(d3.max(chartDataActive, (t) => {
            return d3.max(Object.keys(t.years));
        }), 10);

        const calulatedChartDataActive = chartDataActive.map((t) => {
                const r = [];
                for (let year = minYear; year <= maxYear; year++) {
                    r.push({
                        x: year,
                        y: !useNormalization ? t.years[year] : t.yearsNormalized[year]
                    });
                }
                // console.log(t);
                r['id'] = t.id;
                r['name'] = t.text;
                r['amount'] = t.amount;
                r['color'] = t.color;
                return r;
            }
        );
        return calulatedChartDataActive;
    }
    const calculatedData = calculateData(chartData);

    

    return (
        <div>
            {displayModal ? <MaxItemsModal/> : <div/>}
            <TemporalCloudTopControls topControlConfig={topControlConfig}
                                      deleteContainer={deleteContainer}
                                      id={props.id}/>
            <div style={{display: "flex"}}>
                <div style={{width: props.containerDimensions[0]}}>
                    <TemporalCloud calulatedData={calculatedData}
                                   width={props.containerDimensions[0]}
                                   height={props.containerDimensions[1]}
                                   setSearchTermAndRerouteToZoomAndFilter={props.setSearchTermAndRerouteToZoomAndFilter}
                    />
                </div>
                <div style={{width: props.rightControlDimensions[0], overflowY: "hidden"}}>
                    <TemporalCloudRightControls data={chartData}
                                                id={props.id}
                                                height={props.containerDimensions[1]}
                                                width={props.containerDimensions[0]}
                                                setDisplayModal={setDisplayModal}
                                                topControlConfig={topControlConfig}
                                                setLocalChartData={setChartData}
                                                toggleRightControls={props.toggleRightControls}
                                                displayRightNav={props.displayRightNav}
                    />
                </div>
            </div>
        </div>
    );
}

export default TemporalCloudContainer;