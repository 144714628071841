// loads config from config.json which is bundled into a separate chunk.js file.
export default {
    Config(callback) {
        if (this._config !== undefined) return callback(this._config);
        // eslint-disable-next-line no-restricted-properties
        require.ensure([], () => {
            this._config = require('./config.json');
            callback(this._config);
        });
    },
};
